import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  spinnerWrapper : {
    display: 'block', 
    width:'100%',
    height: '100%',
    padding: theme.spacing(2)
  },
  spinnerContainer : {
    width: '20vh',
    margin: '0 auto 0 auto',
    textAlign: 'center'
  },
  title: {
    textAlign: 'center'
  }
});

function Spinner(props) {
  const { classes, title, color, size } = props;
  return (
    <div className={classes.spinnerWrapper} style={props.style ? props.style : {}} >
      <div className={classes.spinnerContainer} >
        <CircularProgress size={size || 40} color={color || 'primary'} className={classes.progress} />
      </div>
      <Typography color={color || 'primary'} className={classes.title}>{title || 'Loading'}</Typography>
    </div>
  );
}

Spinner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Spinner);