import values from 'lodash/values'
import moment from 'moment'
import { firestore, db } from '../../../firebase/firebase.js'
const accountingRef = firestore.collection('accounting').doc('pflegekasse')
const servicesRef = accountingRef.collection('services')
const accountingSetRef = firestore.collection('accountingSet').doc('pflegekasse')

const deleteServices = true
const uploadInstitut = true
const uploadProvider = true
const generateInvoiceData = async ({ servicesByRequester, servicesByProvider, tasks, invoiceIds, editor, providerDone}) =>{

	const timeStamp = moment().format()
	editor.timeStamp = timeStamp
	const fsBatch = firestore.batch()

	const COUNTER = {
		provider: { withMail: 0, withoutMail: 0}
	}

	const INSTITUT = await Promise.all(servicesByRequester.map(async (requesterServices, i) =>{
		let counter = i+1
	
		const newInvoiceId = invoiceIds.institutId+counter
		const  requesterSnap = await requesterServices[0].requester.ref.get()
		const requester = await prepMemberData(requesterSnap.data())

		const { institut } = requester
		const accountingType = institut ? 'institut' : 'member'
		const recipient = prepRecipient(accountingType==='institut' ? institut : requester)
	
		const {month, year } = requesterServices[0]
		const date = `${year}-${month}`
		const  SERVICES_DATA = generateServiceData({
			services:requesterServices, 
			tasks,
			accountingType,
			newInvoiceId,
			editor
		})

		const sortId = newInvoiceId
		const text ={subject: ''}
		const INVOICE = {
			...SERVICES_DATA,
			sortId,
			providerDone,
			recipient,
			requester,
			year, month, date, text
		}
		if(accountingType==='member')
			INVOICE.takenByInstitut = 0

		return INVOICE
	}))
	const institutSetRef = accountingSetRef.collection('institut').doc()
	const institutInvoiceData = {values: INSTITUT, month: INSTITUT[0].month, year: INSTITUT[0].year}
	if(uploadInstitut)
		institutSetRef.set(institutInvoiceData)
	
	//if(withFirestoreUpdate)
		//fsBatch.institut.commit()
	//// Acconting Provider
	
	const PROVIDER = await Promise.all(servicesByProvider.map(async (providerServices, i) =>{
		const  counter = i+1
		const newInvoiceId = invoiceIds.providerId+counter
		const providerSnap = await providerServices[0].provider.ref.get()
		const provider = await prepMemberData(providerSnap.data())
	
		const accountingType = 'provider'
		const recipient = prepRecipient(provider)
		if(provider.email.length>=6) {
			COUNTER.provider.withMail++
		} else {
			COUNTER.provider.withoutMail++
		}

		const {month, year, donate } = providerServices[0]

		const  SERVICES_DATA =  generateServiceData({
			services:providerServices,
			tasks,
			accountingType,
			newInvoiceId,
			editor
		})
		const donateText = `Vielen Dank für Ihre Spende in Höhe von ${SERVICES_DATA.totals.totalPayment.toFixed(2)} Euro.`
		const nonDonateText = `Der Betrag in Höhe von ${SERVICES_DATA.totals.totalPayment.toFixed(2)} Euro wird ihrem Konto gutgeschrieben.`
		const text = {
			paymentInfo: donate ? donateText : nonDonateText 
		}
		
		const date = `${year}-${month}`
	
		const INVOICE = {
			...SERVICES_DATA,
			providerDone: true,
			recipient,
			provider,
			donate, year, month, text, date
		}

	
		providerServices.forEach(service=>{
			const serviceRef = servicesRef.doc(service.id)
			if(deleteServices)
				fsBatch.delete(serviceRef)						
		})
		return INVOICE
	}))

	const mailCounterProvider = {
		total: servicesByProvider.length,
		withoutMail: COUNTER.provider.withoutMail,
		withMail: COUNTER.provider.withMail,
	}
	
	db.ref('/invoiceResults/nachbarschaftshilfe/providerInfo/email').set(mailCounterProvider)
	
	const providerSetRef = accountingSetRef.collection('provider').doc()
	const providerInvoiceData = {values: PROVIDER, month: PROVIDER[0].month, year: PROVIDER[0].year}

	// providerSetRef.set(providerInvoiceData)
	if(uploadProvider)
		fsBatch.set(providerSetRef, providerInvoiceData)
	//delete upload invoiceSet services
	fsBatch.commit()

	return ({INSTITUT, PROVIDER})
}

const prepMemberData = async (member) => {

	const { userName, id, bankAccount, institut, pflegekasse, versNr, profile: {name, email, street, postCode, city}} = member
	
	const bankAccountRef = await bankAccount.get()
	const _bankAccount = bankAccountRef.data()
	const addRequesterData = institut ? {institut, pflegekasse, versNr} : {}
	return({
		...addRequesterData,
		id, userName, name, email, street, postCode, city,
		bankAccount: _bankAccount,
		ref: firestore.collection('membersPflegekasse').doc(id)
	})
}
const prepRecipient = ({ name, street, postCode, city, userName}) => {
	// in case name doesn't exists take userName prop
	const _name = name = name || userName
	return ({name: _name, street, postCode, city, })
}

const generateServiceData = ({services, tasks, accountingType, newInvoiceId, editor }) =>{
	//console.log(services, tasks, accountingType, newInvoiceId, editor  )
	const currentInvoice = { id: 'Fehler_keine_id', accountingType, services: [], totals: {} }   
	const TOTALS = { hours: 0, km: 0, hoursPayment: 0, kmPayment: 0, totalPayment: 0 }

	const prepServices = services=>values(services).map( (service, id) => {
	
		const prepedService = prepService(service, tasks, accountingType)
		TOTALS.hours = TOTALS.hours + Number(service.hours)
		TOTALS.km = TOTALS.km + Number(service.km)
		TOTALS.hoursPayment = TOTALS.hoursPayment + prepedService.totals.hoursPayment
		TOTALS.kmPayment = TOTALS.kmPayment + prepedService.totals.kmPayment
		TOTALS.totalPayment = TOTALS.totalPayment + prepedService.totals.totalPayment
		return prepedService
	})
	const prepedServices = prepServices(services)
	// needed for just two digets after . dont know why
	TOTALS.totalPayment = Number(TOTALS.totalPayment.toFixed(2))
	currentInvoice.totals = TOTALS
	currentInvoice.services = prepedServices
	currentInvoice.editors = accountingType==='provider' ? {provider:editor} : {institut: editor}
	currentInvoice.id = accountingType==='institut'
		? `P-${newInvoiceId}`
		: accountingType==='provider'
			? `A-${newInvoiceId}`
			: `M-${newInvoiceId}`
	return currentInvoice
}

const prepService = ( service, tasks, accountingType ) => {
	
    const task = tasks[service.taskId]
	// Check km payment is km * kmRate or km flat rate (true, false)

	let	kmPayment = Number(service.km)*task.kmRate
    let hoursPayment = 0
    let totalPayment = 0
    let hourlyRate = 0
    if(accountingType==='provider') {
        hoursPayment = (task.hourlyRate-task.genbundRate)*service.hours
        totalPayment = hoursPayment+kmPayment
        hourlyRate = task.hourlyRate-task.genbundRate
    } else {
        hoursPayment = task.hourlyRate*service.hours
        totalPayment = hoursPayment+kmPayment
        hourlyRate = task.hourlyRate
     
    }
	hoursPayment = Number(hoursPayment.toFixed(2))
	kmPayment = Number(kmPayment.toFixed(2))
	totalPayment = Number(totalPayment.toFixed(2))

    //delete service.taskId
    //delete service.requesterId
    return {
        ...service,
        task,
        totals: {
			hoursPayment,
			kmPayment,
        	totalPayment,
        	hourlyRate
		}
    }
}
/*
function isNumber(n){
    return Number(n)=== n
}
*/

export default generateInvoiceData