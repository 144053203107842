import { firestore } from '../firebase'
import { uploadFile, deleteUploadedFile } from '../storage/uploadFiles'
import moment from 'moment'
import { updateUploadedFiles } from 'store/actions/memberActions'
//import { auth } from '../firebase'
const membersDeletedRef = firestore.collection('membersDeleted')
const contactPersonsRef = firestore.collection('contactPersons')
const membersRef = firestore.collection('members')
const bankAccountsRef = firestore.collection('bankAccounts')

export const getMember = async (memberId, type) => {
    const profileRef = membersRef.doc(memberId)
    const profileSnap = await profileRef.get()
    return profileSnap.data()
}

export const getMemberShipFee = async (callback) => {
    const members = []
    const membersSnap = await membersRef
    .where('profile.membershipFee', '!=', '0')
    .get()

    const transferItems = []
    let total = 0
    let fileNr = 0
    membersSnap.forEach( memberSnap=>{
        const member = memberSnap.data()
        if(member.profile.membershipFee==='')
            return null
        if(!member.bankAccount)
            return null
        members.push(member)
    })
    members.map( async (member, i)=>{
        const bankAcccountSnap = await member.bankAccount.get()
        const bankAccount = bankAcccountSnap.data()
        const amount = Number(member.profile.membershipFee)
        total=total+amount
        const transferItem = {
            id: member.id,
            owner: member.profile.name || `${member.profile.firstName} ${member.profile.lastName}`,
            amount,
            bic: bankAccount.bic,
            iban: bankAccount.iban,
            text: `Mitgliedsbeitrag ${moment().format('YYYY')}`,
        }
        transferItems.push(transferItem)
        if(transferItems.length===998) {
            fileNr++
            callback({total, transferItems, fileNr})
            total=0
            transferItems.length=0
            return
        } else if(members.length===i+1) {
            fileNr++
           return callback({total, transferItems, fileNr})

        }
    })
}

export const getDonationMembers = async (callback) => {
    const donationSnap = await membersRef
    .where('profile.donation', '!=', "")
    .get()

    const donationMembers = []
    const transferItems = []
    let total = 0
    if(donationSnap.empty)
        return callback(false)
    donationSnap.forEach(_member=>{
      
        const member = _member.data()
        if(!member.bankAccount)
            return null

        donationMembers.push(member)
    })

    donationMembers.map(async (member, i)=>{
       
        const bankAccountSnap = await member.bankAccount.get()
        const bankAccount = bankAccountSnap.data()
        total = total+Number(member.profile.donation)
        transferItems.push({
            id: member.id,
            owner: member.profile.name || `${member.profile.fiestName} ${member.profile.lastName}`,
            amount: Number(member.profile.donation),
            bic: bankAccount.bic,
            iban: bankAccount.iban,
            text: `Spende Generationenbund ${moment().format('YYYY')}`,
        })

        if(donationMembers.length===i+1)
            callback({transferItems, total})
    })
}

export const moveMemberToDeleted = async (member, authUser) => {
    
    const timeStamp = moment().format()
    const fbBatch = firestore.batch()
    const deleted = {id: authUser.id, name: authUser.username, timeStamp}
    const editors = {...member.editors, deleted}
    member.editors = editors
    const deletedMember = {
        editor: {
            id: authUser.id,
            name: authUser.username
        },
        timeStamp,
        member,
    }
    if(member.bankAccount) {
        const bankAccountRef = bankAccountsRef.doc(member.id)
        fbBatch.delete(bankAccountRef)
    }
    if(member.contactPerson) {
        const contactPersonRef = contactPersonsRef.doc(member.id)
        fbBatch.delete(contactPersonRef)
    }
    if(member.files) {
        console.log('FILES TO DEELTE HERE CODE NESSASSARE')
    }
    const memberDeleteRef = membersDeletedRef.doc(member.id)
    fbBatch.set(memberDeleteRef, deletedMember)
    const memberRef = membersRef.doc(member.id)                                                
    fbBatch.delete(memberRef)
    return fbBatch.commit()
}

export const recoverMembers = (membersData, authUser) => {
    const fsBatch = firestore.batch()
    membersData.forEach((memberData)=>{
        const member = {...memberData.member}
        const { bankAccount, contactPerson } = member
        const recovered = {id: authUser.id, name: authUser.username, timeStamp: moment().format() }
        member.editors = {...member.editors, recovered}
        if(bankAccount) {
          const bankAccountREF = bankAccountsRef.doc(member.id)
          fsBatch.set(bankAccountREF, bankAccount)
          member.bankAccount = bankAccountREF
        }
        if(contactPerson) {
            const contactPersonREF = contactPersonsRef.doc(member.id)
            fsBatch.set(contactPersonREF, contactPerson)
            member.contactPerson = contactPersonREF
        }
        const deletedMemberREF = membersDeletedRef.doc(member.id)
        const memberREF = membersRef.doc(member.id)
        fsBatch.set(memberREF, member)
        fsBatch.delete(deletedMemberREF)
      })
      return fsBatch.commit()
}

export const getAccountingMember = async (memberId, accountingType) => {
    const pflegeMemberRef = membersRef.doc(memberId)
    const snap = await pflegeMemberRef.get()
    const  {userName, id, bankAccount, institut, versNr, profile: { email, city, postCode, street, firstName, lastName }  } = snap.data()
    const ref = pflegeMemberRef
    const member = {
        bankAccount,
        id,
        institut,
        email,
        userName,
        city,
        postCode,
        street,
        firstName,
        lastName,
        ref
    }
    if(accountingType==='provider') {
        delete member.institut
    } else if(institut) {
        member.versNr =  versNr   
    }
    return member
}

export const getBankAccount = async _accountId => {

    if(_accountId.includes('/')) {
        const idPart =_accountId.split('/')[1]
  
        _accountId = idPart
    }
    const bankAccountRef = firestore.collection('bankAccounts').doc(_accountId)
    const bankAccountSnap = await bankAccountRef.get()
    return bankAccountSnap.data()
}

export const getContactPerson = async memberId => {
    const providerBankAccountRef = firestore.collection('bankAccounts').doc(memberId)
    const providerBankAccountSnap = await providerBankAccountRef.get()
    return providerBankAccountSnap.data()
}

export const setFile = async ({file, files,  memberId, isEditMode}) => {
  
    const fileName = file.name
    const timestamp = moment().format()
    const newFiles = [...files]
    const callback = (url) => {
        newFiles.push({fileName, url, timestamp})
        updateUploadedFiles(newFiles)
        if(!isEditMode)
            return membersRef.doc(memberId).set({files: newFiles})
        return membersRef.doc(memberId).update({files: newFiles})
    }

    return uploadFile(fileName, file, memberId, callback)
}

export const deleteFile = async (files, fileToDelete, memberId) => {
 
    const newFiles = [...files]
    const indexToDelete = newFiles.indexOf(fileToDelete)
    newFiles.splice(indexToDelete, 1)

    deleteUploadedFile(fileToDelete.fileName, memberId)
    updateUploadedFiles(newFiles)
    const ref = membersRef.doc(memberId)
    ref.update({
        files: newFiles
    })
}