import { db, firestore } from '../firebase'
const invoiceIdsRef = db.ref('/invoiceIds')
const accountingREF = firestore.collection('accounting')
const pkREF = accountingREF.doc('pflegekasse')
const nhREF = accountingREF.doc('nachbarschaftshilfe')

export const getPflegekassenInvoiceIds = async (addCount) => {
	let error = true
	let errorText = ''
	// count up (values addCount: {provider: 0, institut: 0}) and set on firebase - but return  old invoices (they will be counted up by the invoiceDataGenerator)
	let newInvoiceIds
	let oldInvoiceIds
	const invoiceIdRef = invoiceIdsRef.child('pflegekasse')
	await invoiceIdRef.transaction((result) => {
	
		if (result) {
			oldInvoiceIds = result
			newInvoiceIds = {
				institutId: result.institutId+addCount.institut,
				providerId: result.providerId+addCount.provider
			}
			error = false
		} else {
			errorText = `Invoice ID error - no invoiceID found - using default Invoice Ids providerId: 10000000 institutId: 2000000`
			newInvoiceIds = {
				institutId: 10000,
				providerId: 10000
			}
		}
		return newInvoiceIds
	})
	if(error)
		oldInvoiceIds = {institutId: 'error', prvoiderId: 'error', message: errorText}
	return oldInvoiceIds
}

export const getNachbarschaftshilfeIds = async (addCount) => {
	let error = true
	let errorText = ''
	// count up (values addCount: {provider: 0, institut: 0}) and set on firebase - but return  old invoices (they will be counted up by the invoiceDataGenerator)
	let newInvoiceIds
	let oldInvoiceIds
	const invoiceIdRef = invoiceIdsRef.child('nachbarschaftshilfe')
	await invoiceIdRef.transaction((result) => {
	
		if (result) {
			oldInvoiceIds = result
			newInvoiceIds = {
				requesterId: result.requesterId+addCount.requester,
				providerId: result.providerId+addCount.provider
			}
			error = false
		} else {
			errorText = `Invoice ID error - no invoiceID found - using default Invoice Ids providerId: 10000000 requesterId: 2000000`
			newInvoiceIds = {
				requesterId: 100,
				providerId: 100
			}
		}
		return newInvoiceIds
	})
	if(error)
		oldInvoiceIds = {requesterId: 'error', prvoiderId: 'error', message: errorText}
	return oldInvoiceIds
}

export const checkInvoicesByMemberId = async ({roles, pflegeRoles, id}) => {

	if(roles.requester || roles.provider) {

		const nhRequesterSNAP = await nhREF.collection('services')
		.where('requester.id', '==', id).get()

		const nhProviderSNAP = await nhREF.collection('services')
		.where('provider.id', '==', id).get()

		if(nhRequesterSNAP.size>0 || nhProviderSNAP>0)
			return ({invoicesExist: true, app:'Nachbarschaftshilfe' })

	}
	if(pflegeRoles.requester ||  pflegeRoles.provider) {
		
		const pksRequesterSNAP = await pkREF.collection('services')
		.where('requester.id', '==', id).get()
		
		const pksProviderSNAP = await pkREF.collection('services')
		.where('provider.id', '==', id).get()

		const pksInstitutSNAP = await pkREF.collection('institut')
		.where('requester.id', '==', id).get()
		
		if(pksRequesterSNAP.size>0 || pksProviderSNAP.size>0 || pksInstitutSNAP>0) {
			return ({invoicesExist: true, app: 'Pflegekasse'})
		}
	}
	return ({invoicesExist: false})

}

