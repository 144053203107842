import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/styles'
import AttachmentIcon from '@material-ui/icons/AttachFile'

const useStyles = makeStyles((theme) => ({
   wrapper: {
       padding: theme.spacing(1),
       backgroundColor: 'whitesmoke',
    },
    input: {
        display: 'none'
    },
    fileNamesWrapper: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    fileChip: {
        margin: theme.spacing(0.5)
    }
}))

export const FileSelect = ({fileUploadCallback}) => {
    const classes = useStyles()
    const [filesData, setFilesData] = useState(undefined)
    const handleFileUpload = e => {
        const files = e.target.files
        const fileData = []
        for (let i = 0; i < files.length; i++) {
            const name = files[i].name
            fileData.push({
                name,
                file: files[i]
            })
        }
        if(filesData && filesData.length>0) {
            console.log('DAT SIND SCHON DATEEIEN')
            const newFileData = [...filesData, ...fileData]
            setFilesData(newFileData)
            return fileUploadCallback(newFileData)
        }
        setFilesData(fileData)
        return fileUploadCallback(fileData)
    }
    const handleDeleteFile = fileName => {
      const newFilesData = filesData.filter(fileData=>{
          if(fileData.name===fileName)
            return false
        return fileData
      })
      
      setFilesData(newFilesData.length===0 ? undefined : newFilesData)
      return fileUploadCallback(newFilesData.length===0 ? undefined : newFilesData)
    }
    return (
        <Paper elevation={3} className={classes.wrapper}>
            <input
                accept=".pdf, .doc, .docx, image/*, .xlsx, .zip"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleFileUpload}
            />
            <div style={{display: 'inline-block'}}>
            <label htmlFor="contained-button-file">
                <Button startIcon={<AttachmentIcon />} variant="outlined" color="primary" component="span" size='small'>
                    Datei(n) auswählen
                </Button>
            </label>
            </div>
            <div style={{display: 'inline-block'}} >
            <span className={classes.fileNamesWrapper}>
                {filesData && filesData.map((file, i)=>{
                    return (
                        <Chip
                            key={`${file.name}-${i}`}
                            label={file.name}
                            onDelete={()=>handleDeleteFile(file.name)}
                            className={classes.fileChip}
                            size='small'
                            color='primary'
                        />
                    )
                })}
            </span>
            </div>
        </Paper>
    )
}

export default FileSelect