import React from 'react'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    cursor: 'help',
    width: '100%',
    textAlign: 'center',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    backgroundColor: 'beige',
    padding: theme.spacing(1),
  },
  popoverContentWrapper: {
    maxWidth: '340px',
    maxHeight: '600px', 
    overflow: 'hidden',
    listStylePosition: 'outside',
    padding: 0,
  },
  listItem: {


  }
}));

export default function MouseOverPopover({items}) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl)

  return (
    <div className={classes.wrapper}>
      <div
          style={{verticalAlign: 'middle'}}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
      >
        <Typography children={items.length} variant='body2' />
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className={classes.popoverContentWrapper} component='ul' >
          {items.map(item=>
            <Typography className={classes.listItem} variant='caption' component='li' key={item}>{item}</Typography>
          )}
        </div>
        
      </Popover>
    </div>
  );
}