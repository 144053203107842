import React, { useState } from 'react'
import { useSnackbar } from 'notistack';
import CardGrid from 'Components/Cards/DefaultCardGrid'
import moment from 'moment'
import { connect } from 'react-redux'
import {actionTypes} from 'config/config'
import ButtonBoard from 'Components/Buttons/ButtonRow'
import TitleCard from 'Components/Title/TitleCard'
import Dialog from 'Components/PopUps/Dialog'
import arrSort from 'array-sort'
const resetDialog = {
    isOpen: false,
    text: '',
    title: '',
    buttons: []
}

const AccountingInvoiceView = ({selectedInvoice, tasks, dispatch})=> {
	const [ dialog, setDialog ] = useState(resetDialog)
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	
	const infoMessage = (text, variant) => {
        enqueueSnackbar(text, 
            {
                variant: variant ? variant : 'info', 
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
            }
        })
    }

	const handleDeleteService = (service) => {

		const confirmDeleteService = _service => {
			if(!_service)
				return setDialog(resetDialog)
			const payload = {
				accountingType: 'nachbarschaftshilfe',
				id: _service.id
			}
			dispatch({type: actionTypes.ACCOUNTING.DEL_SERVICE, payload})
			infoMessage(`${moment(_service.date).format('DD.MM.YYYY')} - ${_service.requester.name} wurde gelöscht!`, 'success')
			return setDialog(resetDialog)
		}
		setDialog({
			isOpen: true,
			title: 'Leistung löschen?',
			icon: 'warning',
			text: [
				`Bedarf: ${service.requester.name}`,
				`Helfer: ${service.provider.name}`,
				`${moment(service.date).format('DD.MM.YYYY')}`,
				`${tasks[service.taskId].name}`,
			 	`Stunden: ${service.hours.toFixed(2)}`,
				 `${service.km} x Km-Pausch.`
			],
			buttons: [
				{title: 'Löschen', variant: 'contained', color: 'secondary', onClick: ()=>confirmDeleteService(service)},
				{title: 'Abbrechen', variant: 'outlined', color: 'primary', onClick: ()=>confirmDeleteService(false)}
			]
		})	
	}
	const handleDeleteAllServices = () => {

		const confirmDeleteServices = (dellAll) => {

			if(dellAll) {
				const payload = {
					accountingType: 'nachbarschaftshilfe'
				}
				dispatch({type: actionTypes.ACCOUNTING.DEL_SERVICES, payload})
				infoMessage(`${moment(selectedInvoice[0].date).format('DD.MM.YYYY')} ${selectedInvoice[0].requester.name} gelöscht`)
			}
			return setDialog(resetDialog)
		}
		setDialog({
			isOpen: true,
			title: `ALLE Leistungen löschen?`,
			icon: 'warning',
			text: selectedInvoice.map(_service=>`${moment(_service.date).format('DD.MM.YYYY')} ${selectedInvoice[0].requester.name}`),
			buttons: [
				{title: 'Löschen', variant: 'contained', color: 'secondary', onClick: ()=>confirmDeleteServices(true)},
				{title: 'Abbrechen', variant: 'outlined', color: 'primary', onClick: ()=>confirmDeleteServices(false)}
			]
		})

	}
	const handleBack = () => {
		closeSnackbar()
		return dispatch({type: actionTypes.SET_ACCOUNTING_INVOICE, payload: undefined})
	}
	arrSort(selectedInvoice, 'date')
	const cardData = selectedInvoice.map((service, i) =>{	
		const currentTask = tasks[service.taskId]
		const { hourlyRate } = currentTask
		const { hours, date, km, provider } = service
		const kmPausch= km!==0 ? service.km*currentTask.kmRate : 0.00
		const total = (hours*hourlyRate)+kmPausch
		return ({
			header: {
				avatar: {
					content: i+1
				},
				title: moment(date).format('DD.MM.YYYY'),
				subheader: provider.name
			},
			content: {
				text: [
					{text: currentTask.name, component: 'li'},
					{text: `${hours.toFixed(2)} Std x ${(hourlyRate).toFixed(2)}€ = ${(hours*hourlyRate).toFixed(2)}€`, component: 'li'},
					{text: `Km-Pauschale: ${kmPausch.toFixed(2)}€`, component: 'li'},
					{text: `Gesamt: ${total.toFixed(2)}€`, component:'li', color: 'primary'}
				]
			},
			buttons: [
				{title: 'Löschen', onClick:handleDeleteService, color: 'secondary', variant: 'contained' }
			],
			item: service
			
		})})
		const title = selectedInvoice.length>0 && `${selectedInvoice[0].year} -> ${moment(selectedInvoice[0].date).format('MMMM')} -> ${selectedInvoice[0].requester.name}`
	return (
		<>
			<TitleCard title={title} />
			<CardGrid data={cardData} spacing={1} />
			<ButtonBoard buttons={[
					{title: 'Zurück', onClick:handleBack, variant: 'outlined', color: 'primary'},
					{title: 'Alle löschen', onClick:handleDeleteAllServices, variant: 'contained', color: 'secondary'}

				]}
			/>
			<Dialog data={dialog} />
		</>
	)
}

export default connect((state) => {
	return ({   
		selectedInvoice: state.accounting.selectedInvoice
  	})
})(AccountingInvoiceView)
