import moment from 'moment'
import { firestore } from '../../../../../firebase'

const accountingSetRef = firestore.collection('accountingSet').doc('pflegekasse')

const getInvoiceRefs = (invoiceId, newInvoiceId)=> {
    const accountingRef = firestore.collection('accounting').doc('pflegekasse') 
  
    const invoiceRef = accountingRef.collection('institut').doc(invoiceId)
    const newInvoiceRef = accountingRef.collection('requester').doc(newInvoiceId)
    return {invoiceRef, newInvoiceRef}
}

const generateInvoiceDataMembers = async ({ selectedInvoices, tasks, editor }) => {
    
    const timeStamp = moment().format()
    const fsBatch = firestore.batch()
    
    const REQUESTER = selectedInvoices.map((_invoice, i)=>{
        const invoice = {..._invoice}
        const invoiceSet = {count: selectedInvoices.length, id: i+1}
        const newInvoiceId = invoice.accountingType==='member'
            ? invoice.id
            : `B-${invoice.id.split('-')[1]}`
        const refs = getInvoiceRefs(invoice.id, newInvoiceId)
        const editors = {...invoice.editors, requester: {...editor, timeStamp}}
        // if accountingType allready Member just retrun nothing to convert
        if(invoice.accountingType==='member') {
         
            invoice.text = {
                paymentInfo: `Der Betrag in Höhe von ${invoice.totals.totalPayment.toFixed(2)} Euro wird von Ihrem Konto abgebucht.`
            }
        
           // fsBatch.set(refs.newInvoiceRef, {...invoice, editors, invoiceSet, text })
            fsBatch.delete(refs.invoiceRef)
            return invoice
        }
     
        const accountingType = 'requester'
        // set new invoice id - means exchange P with B for Bedarf
        const text = {
            paymentTable: [
                {text: 'Abrechnungsbetrag', value: invoice.totals.totalPayment },
                {text: 'Anteil Pflegekasse', value: invoice.totals.totalPayment },
                {text: 'Lastschriftbetrag', value: invoice.totals.totalPayment },
            ],
            paymentInfo: `Der Betrag in Höhe von ${invoice.takenByInstitut.toFixed(2)} € wurde von der Pflegekasse übernommen. Der Restbetrag in Höhe von ${(invoice.totals.totalPayment-invoice.takenByInstitut).toFixed(2)} € wird von Ihrem Konto abgebucht`
        }

        const { name, street, postCode, city } = invoice.requester
        const recipient = { name, street, postCode, city }
        const newInvoice = {...invoice, id: newInvoiceId, recipient, invoiceSet, editors, accountingType, text }
       // fsBatch.set(refs.newInvoiceRef, newInvoice)
        fsBatch.delete(refs.invoiceRef)
        return newInvoice
    })
    const memberAccountingSetRef = accountingSetRef.collection('requester').doc()
    const accountingSet = {
        month: REQUESTER[0].month,
        year: REQUESTER[0].year,
        values: REQUESTER 
    }
    memberAccountingSetRef.set(accountingSet)
    return fsBatch.commit()
    
}
export default generateInvoiceDataMembers