import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { formValueSelector } from 'redux-form'
import Fade from '@material-ui/core/Fade'
import DefaultReduxForm from 'Components/ReduxForms/DefaultForm'

const PflegekasseSF = ({instituts, hasPflegekasse, isRequester, hasInstitut}) => {

  return (
    <div>
            <DefaultReduxForm sectionName='pflegeRoles' inputs={[
                {id: 48, type: 'switch', name: 'provider', label: 'Angebot' },
                {id: 49, type: 'switch', name: 'requester', label: 'Bedarf' }
            ]}/>
      
        <Fade in={instituts && isRequester} mountOnEnter unmountOnExit >
            <DefaultReduxForm inputs={[
                {
                    id: 111,
                    label:'Kostenträger auswählen',
                    defaultValue: false,
                    type: 'select',
                    name: 'institut',
                    options: instituts && [
                        ...instituts.map(institut=>({
                            label: institut.name, value: institut.id
                        })), {label: 'Selbstzahler', value: false}],
                }
            ]}
            />
        </Fade>
        <Fade in={hasInstitut && isRequester} mountOnEnter unmountOnExit>
            <div>
            <DefaultReduxForm inputs={[{
                id: 50, type: 'text', name: 'versNr', label: 'Versicherungs-Nr', icon: {name: 'City'}, required: true }
            ]}
            />
            </div>
        </Fade>

    </div>
  )
}
const selector = formValueSelector('member')

export default compose(
    firestoreConnect(() => [  
      //  { collection: 'accounting', doc: 'pflegekasse', subcollections: [{ collection: 'services' }], storeAs: 'servicesPflegekasse' },
    ]), // or { collection: 'todos' }
    connect((state) => {
       // const profile = selector(state, 'profile')
        const pflegekasse = selector(state, 'pflegekasse')
        const pflegeRoles = selector(state, 'pflegeRoles')
        const institut = selector(state, 'institut')
    
        //console.log(institut, '-----------------------')
        return ({
            //isEducated: profile.educated,
            hasPflegekasse: pflegekasse,
            hasInstitut: Boolean(institut),
            isRequester: pflegeRoles ? pflegeRoles.requester : false,
            //instituts: state.firestore.ordered.instituts
      })}),
    )(PflegekasseSF)