import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { landingPageCFG } from '../../config/config'
import Typography from '@material-ui/core/Typography'
import { Route } from 'react-router-dom'
import LoginPage from './LoginPage'
import { publicRoutes } from '../../config/routes'
import PasswordForget from './PasswordForget'


const useStyles = makeStyles(theme => {

    return ({
        root: {
            width: '100%',
            overflow: 'hidden',
          
            backgroundImage: `url(${landingPageCFG.backgroundImage})`,
            minHeight: '100vh',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
           // padding: theme.spacing(4) + ' 0 ' + theme.spacing(4) + ' 0' 
        },
        wrapper: {
            minHeight: '85vh',
            textAlign: 'center',
            margin: '0 auto 0 auto',
            maxWidth: '520px',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            overflow: 'hidden'
           
          
          //  minHeight: '100vh',
            //padding: theme.spacing(),
        },
        headline: {
            color: 'white'
        },
        marginTop: {
            height: '15vh'
        }, 
    })
})

const PublicApp = ({children}) => {
    const classes = useStyles()
    return (
        <div className={classes.root} >
            <div className={classes.marginTop}></div>
                <Typography className={classes.headline} variant='h3' color='primary' children={landingPageCFG.title} gutterBottom/>
            <div className={classes.wrapper} >
                <Route exact path={publicRoutes.LOGIN} component={() => <LoginPage />} />
                <Route exact path={publicRoutes.PASSWORD_FORGET} component={() => <PasswordForget />} />
            </div>

        </div>
    )
}

export default PublicApp