import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import MailIcon from '@material-ui/icons/EmailOutlined'
import Button from '@material-ui/core/Button'
import Grow from '@material-ui/core/Grow'
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUpOutlined'

const useStyles = makeStyles((theme) => ({
    listWrapper: {
      backgroundColor: 'whitesmoke',
      maxHeight: '200px',
      display: 'flex',
      justifyContent: 'left',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: `0 auto ${theme.spacing(5)} auto`,
      overflowY: 'scroll'
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    growInWrapper: {
      marginBottom: theme.spacing(2)
    },
    extendButton: {
      marginBottom: theme.spacing(1)
    }
  }));
  
  export default function RecipentsList ({recipients, removeRecipientCallback, children}) {
    const classes = useStyles();
    const [listActive, setListActive ] = useState(false)  

    return (
        <div className={classes.root}>
            <Button
                startIcon={listActive ? <ArrowUpIcon /> : <ArrowDownIcon /> }
                endIcon={listActive ? <ArrowUpIcon /> : <ArrowDownIcon /> }
                className={classes.extendButton}
                variant='outlined'
                onClick={()=>setListActive(!listActive)}
                fullWidth
                size='small'

                color={listActive ? 'secondary' : 'primary'}
            >
                {listActive ? 'Empfänger verbergen' : `${recipients.length} Empfänger anzeigen`}
            </Button>
            <Grow in={listActive} mountOnEnter unmountOnExit >
              <div className={classes.growInWrapper}>
                <Paper component="ul" className={classes.listWrapper}>
                    {recipients.length<=0
                      ? <div>Keine E-Mail Empfänger ausgewählt!</div>
                      : recipients.map((data, i) => { 
                    return (
                        <li key={data.email+'-'+i}>
                        <Chip
                            icon={<MailIcon />}
                            label={data.name}
                            onDelete={()=>removeRecipientCallback(data)}
                            className={classes.chip}
                            size='small'
                        />
                        </li>
                    );
                    })}
                </Paper>
                {children}
              </div>
            </Grow>
      </div>
    );
  }
