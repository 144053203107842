import React from 'react'
import Paper from '@material-ui/core/Paper'
import values from 'lodash/values'
import Typography from '@material-ui/core/Typography'
import LoadingSpinner from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import moment from 'moment'
import LienearProgress from 'Components/Progress/LinearProgress'
import {SimpleList as List, SimpleListItem as ListItem } from 'Components/Lists/SimpleList'

const Spinner = <LoadingSpinner color='secondary'  />
const Checked = <CheckCircleIcon fontSize='large' htmlColor='green' />


const ResultState = ({data, date }) => {

	const progressArr = values(data.text)
	const {requesterInfo, providerInfo} = data

	const loadingText = [
		{title: 'Erstelle Abrechnungsdaten', text: 'berechne Abrechnungen Bedarf & Helfer'},
		{title: 'Erstelle Bedarf Abrechnungen', text: 'erstelle PDF Abrechnungen für Bedarf'},
		{title: 'Versende Bedarf Emails', text: 'versende E-Mails an Bedarf (B)'},
		//{title: 'Erstelle PDF Dateien', text: 'für die Helfer'},
		{title: 'Erstelle Helfer Abrechnungen', text: 'erstelle PDF Abrechnungen für Helfer'},
		{title: 'Versende Helfer Emails', text: 'versende E-Mails an Helfer (A)'}
	]

	if(requesterInfo && requesterInfo.email)
		loadingText[2].text = `${requesterInfo.email.withMail} Mitglieder mit E-Mail - ${requesterInfo.email.withoutMail} Mitglieder ohne E-Mail`
	
	if(providerInfo && providerInfo.email)
		loadingText[4].text = `${providerInfo.email.withMail} Mitglieder mit E-Mail - ${providerInfo.email.withoutMail} Mitglieder ohne E-Mail`

	let progress = progressArr.length===6  ? 100 : (progressArr.length-1)*20
	//let progress = progressArr.filter(value=>console.log(value)).length*(100/progressArr.length)
	let progressBuffer = progress+20
	//const arrLength = progressArr.length
	return (
		<Paper style={{padding: '1rem'}}>
			<Typography variant="h6" color="secondary">Fortschrit {moment(date).format('MMMM YYYY')}</Typography>
			<LienearProgress
				variant="buffer"
				value={progress}
				valueBuffer={progressBuffer}
				color={progress<=99 ? 'secondary' : 'primary'}
			/>
			<List dense={true}>
				{[1,2,3,4,5].map((propKey, i)=>{
					
					const title=loadingText[i.toString()].title
					const text=loadingText[i.toString()].text
					const isLoading = {
						0: data.text.length<=1,
						1: data.text.length<=2,
						2: data.text.length<=3,
						3: data.text.length<=4,
						4: data.text.length<=5
					}
					
					return <ListItem
						onItemClick={()=>{}}
						title={title}
						text={text}
						key={i}
						icon={isLoading[i] ? Spinner : Checked}
						index={i}
					/>
				})}
			</List>
		</Paper>
	)
}

export default ResultState