import { firestore } from '../../../firebase'
import { auth } from '../../../firebase'
import moment from 'moment'
import { getObjectDiff } from 'js/objectDiff'
import { memberFileUpload } from 'firebase/storage/uploadFiles'
import { map } from 'modern-async'
import { actionTypes } from 'config'
import { getNewMemberId } from 'firebase/database/memberIdHandler'
//import isEmail from 'validator/es/lib/isEmail'

const membersREF = firestore.collection('members')
const contactPersonsREF = firestore.collection('contactPersons')
const bankAccountsREF = firestore.collection('bankAccounts')

//const tasksREF = firestore.collection('tasks')
/*
function Sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
   }
*/

function Member ({profile, tasks, roles, contactPerson, bankAccount, pflegeRoles, institut, versNr,  editor, fsBatch, timeStamp, invoiceDispatching, instituts}) {
    const defaultEntryDate = moment().format('YYYY-MM-DD')
    this.id = membersREF.doc().id
    this.createdAt = timeStamp
    this.updatedAt = timeStamp
    this.userName = `${profile.lastName} ${profile.firstName}`
    this.editors = {created: editor}
    this.profile = {
        entryDate: defaultEntryDate,
        car: false,
        educated: false,
        phoneWork: '',
        phone: '',
        mobile: '',
        email: '',
        nameSufix: '', donation: '', membershipFee: '', ...profile}
    this.profile.name = `${profile.firstName} ${profile.lastName}`
    if(contactPerson) {
        this.contactPerson = contactPersonsREF.doc(this.id) 
        contactPerson.id = this.id
        fsBatch.set(this.contactPerson, contactPerson)
        this.copyRecipient = contactPerson.copyRecipient ? contactPerson.copyRecipient : false
    } else {
        this.contactPerson = false
    }
    if(bankAccount) {
        this.bankAccount = bankAccountsREF.doc(this.id)
        bankAccount.id = this.id
        bankAccount.accountOwner = this.userName
        fsBatch.set(this.bankAccount, bankAccount)
    } else {
        this.bankAccount = false
    }
    this.roles = roles || { requester: false, provider: false}
    this.tasks = tasks || false
    //this.files = files || false
    this.pflegeRoles = pflegeRoles || { requester: false, provider: false}
    this.pflegekasse = pflegeRoles
        ? pflegeRoles.requester || pflegeRoles.provider
        : false
    if(pflegeRoles && pflegeRoles.requester && Boolean(institut)) {
        this.versNr = versNr
    }
    if(invoiceDispatching) {
        this.invoiceDispatching = invoiceDispatching
    }
    
    this.institut = institut
        ? instituts[institut]
        : false
}

export const mySubmit = async (values, dispatch, memberForm ) =>{

    const { initialized, dirty, reset } = memberForm
    const timeStamp = moment().format()
    const fsBatch = firestore.batch()
    const editor = { id: auth.currentUser.uid, name: auth.currentUser.displayName, timeStamp }
    const newMemberId = await getNewMemberId()

    // Is new Member !initialized
    if(!initialized) {
       const newMember = new Member({
           ...values,
           editor,
           fsBatch,
           timeStamp,
           instituts: memberForm.instituts
        })
        newMember.memberId = newMemberId
        if(values.files) {
            const convertedFiles = await map(values.files, async fileField=>{
                const fileURL = await memberFileUpload({fileField, memberId: values.id })
                return ({...fileField, url: fileURL})
            })
            newMember.files = convertedFiles
        }
        fsBatch.set(membersREF.doc(newMember.id), {...newMember})
    
        //return fsBatch.commit()
    } else if (initialized && dirty) {
        const diffs = getObjectDiff(memberForm.initialValues, memberForm.values)
        const memberREF = membersREF.doc(values.id)
        editor.updatedValues = diffs
        fsBatch.update(memberREF, {
            editors: {
                ...values.editors,
                updates: values.editors.updates
                ? [...values.editors.updates, editor]
                : [editor]
            },
            updatedAt: timeStamp
        })
  

        // Profile values has been changed
        if(diffs.includes('profile')) {
            /*
            if(!isEmail(values.profile.email) && values.invoiceDispatching.emailMember) {
                fsBatch.update(memberREF, {invoiceDispatching: {...values.invoiceDispatching, emailMember: false} })
            }
            */
            fsBatch.update(memberREF, {userName: `${values.profile.lastName} ${values.profile.firstName}`})
            fsBatch.update(memberREF, { profile: values.profile})
        }
        // update contact Person if it has been changed
       
        if(diffs.includes('contactPerson')) {
            const contactPersonREF = contactPersonsREF.doc(values.id)
            // if contact person checkbox copyRecipient set active
            
            fsBatch.update(memberREF, { copyRecipient: values.contactPerson.copyRecipient || false})
            values.contactPerson.id = values.id
            if(!memberForm.initialValues.contactPerson) {
                fsBatch.update(memberREF, { contactPerson: contactPersonREF })
                fsBatch.set(contactPersonREF, values.contactPerson)
            } else {
                fsBatch.update(contactPersonREF, values.contactPerson)
            }
        }
        if(diffs.includes('bankAccount')){
            // if bankAccount is deleted
            const bankAccountREF = bankAccountsREF.doc(values.id)
            if(!values.bankAccount.iban && memberForm.initialValues.bankAccount) {
                fsBatch.delete(bankAccountREF)
                fsBatch.update(memberREF, {bankAccount: false})
            } else if (values.bankAccount && !memberForm.initialValues.bankAccount) {
                values.bankAccount.id = values.id
                fsBatch.update(memberREF, {bankAccount: bankAccountREF })
                fsBatch.set(bankAccountREF, values.bankAccount)
            } else if(Boolean(memberForm.initialValues.bankAccount)) {
                fsBatch.update(bankAccountREF, values.bankAccount)
            }
        }
        if(diffs.includes('roles')) {
            // delete tasks if provider role is set to false and was before true
            if(!values.roles.provider && memberForm.initialValues.roles.provider) {
                fsBatch.update(memberREF, {roles: values.roles, tasks: false})
            } else {
                fsBatch.update(memberREF, { roles: values.roles})
            }      
        }
        if(diffs.includes('tasks')) {
            fsBatch.update(memberREF, {tasks: values.tasks})
        }
        if(diffs.includes('files')) {
          
            const files = await map(values.files, async fileField=>{
                const fileURL = await memberFileUpload({fileField, memberId: values.id })
                return ({...fileField, url: fileURL})
            })
            fsBatch.update(memberREF, { files })
            
        }
        if(diffs.includes('pflegeRoles')) {
            
            const pflegekassenUpdates =   {
                pflegekasse: (Boolean(values.pflegeRoles.requester) || Boolean(values.pflegeRoles.provider)),
                pflegeRoles: {provider: false, requester: false, ...values.pflegeRoles},
            }
            if(values.pflegeRoles.requester && Boolean(values.institut)){
                pflegekassenUpdates.institut = memberForm.instituts[values.institut]
                pflegekassenUpdates.versNr = values.versNr
            } else {
                pflegekassenUpdates.institut = false
                pflegekassenUpdates.versNr = ''
            }
            fsBatch.update(memberREF, pflegekassenUpdates)
        }
        if(diffs.includes('institut')) {
            const isSelfpayer = (!values.institut && values.pflegeRoles.requester)
            const { instituts } = memberForm
            const { institut, versNr } = values
            const updates = {}

            if(isSelfpayer) {
                updates.institut = false
                updates.versNr = ''
            } else {
                updates.institut = instituts[institut]
                updates.versNr = versNr ? versNr : ''
            }
            fsBatch.update(memberREF, updates)
        }
  
        if(diffs.includes('invoiceDispatching')) {
          
            fsBatch.update(memberREF, {invoiceDispatching: values.invoiceDispatching} )
        }
        /*
        if(values.pflegekasse && !values.pflegeRoles.requester && !values.pflegeRoles.provider) {
            alert('DU MUSST WAS AUSWÄHLEN')
        }
        */
        //fsBatch.commit()
    }
    
    fsBatch.commit()
    dispatch({type: actionTypes.MEMBER.close})
    return dispatch(reset('member'))
}


