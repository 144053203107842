import React from 'react'
//import { connect } from 'react-redux'
//import { compose } from 'redux'
//import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import 'react-virtualized-select/styles.css'
import 'css/react-select.css'
// Then import the virtualized Select HOC
import VirtualSelect from 'react-virtualized-select'
//import { connect } from 'react-redux'
//import { compose } from 'redux'
//import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { get } from 'lodash'



const VSelect = ({data, style, valueKey, labelKey, selectedValue, callback, propKeys, id, disabled, clearable, searchable }) => {
  
  //const [state, setState ] = useState()
	const  handleSelectChange = e => {
        return callback(e)
	}

  const convertDataToOptions = () => {
      const convertedOptions = data.map((dataSet, i)=>({
          label: get(dataSet, propKeys.label),
          value: get(dataSet, propKeys.value),
          index: i
      }))
      return convertedOptions
  }
  return (
    <VirtualSelect
      style={style}
      valueKey={valueKey}
      labelKey={labelKey}
      clearable={clearable || false}
      searchable={searchable || true}
      disabled={disabled}
      key={id}
      value={selectedValue} 
      onChange={handleSelectChange}
      options={propKeys ? convertDataToOptions() : data}
    />
  )
}

export default VSelect