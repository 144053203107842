import React from 'react'
import { Field, FormSection } from 'redux-form'
import { Checkbox, Switch, FormControlLabel, TextField, MenuItem, Select, InputLabel, FormControl, FormHelperText, InputAdornment, Typography } from '@material-ui/core'
import IconSelector from 'Components/Icons/IconSelector'
import orderBy from 'lodash/orderBy'

//import moment from 'moment'
const renderFormHelper = ({ touched, error }) => {
  if (!(touched && error)) {
      return
    } else {
      return <FormHelperText>{touched && error}</FormHelperText>
    }
  }

const renderTextField = ({ label, input, meta: { touched, invalid, error, ...metaRest }, noValue, ...custom }) => {
  if(noValue && input.value.length===0) {
     input.value = noValue
  }

  return (
    <TextField 
        inputRef={custom.ref}
        size='small'
        fullWidth={true}
        label={label}
        placeholder={input.value || null}
        error={touched && invalid}
        helperText={touched && error}
        InputProps={{
          endAdornment: custom.icon && (
              <InputAdornment position="start">
                  <IconSelector
                      iconName={custom.icon && custom.icon.name}
                      options={{
                          color: 'primary',
                          ...custom.icon.options
                      }} />
              </InputAdornment>
          )
        }}
        {...input}
        {...custom}
    />
)}

const renderSelectField = ({
    input,
    label,
    meta: { touched, error, visited, ...metaRest },
    children,
    options,
    controlProps,
    selectProps,
    inputProps,
    ...rest
  }) => {
      const simpleSelectId = `simple-select-${input.id}` 
   
      return (
        <FormControl error={touched && Boolean(error)} fullWidth={true} size='small' {...controlProps}>
          <InputLabel htmlFor={simpleSelectId} >{label}</InputLabel>
          <Select
            error={touched && Boolean(error)}
            {...input}
            {...selectProps}
            inputProps={{
                ...inputProps,
                name: input.name,
                id: simpleSelectId
            }}
            //value={(input.value.length===0 && has(rest, 'defaultValue')) ? rest.defaultValue : newValue}
      
          >
            {children}
          </Select>
          {renderFormHelper({ touched, error })}
        </FormControl>
)}

const renderCheckboxMui = ({ input, label, meta, ...custom }) => {
  //console.log(meta, input)

  return ( 
    <li style={{listStyle: 'none'}}>
    <FormControlLabel 
      control={
        <Checkbox
          checked={input.value ? true : false}
          onChange={input.onChange}
          {...custom}
        />
      }
      label={<Typography variant='body2'>{label}</Typography>}
      size='small'
      {...input}
     
    />
    </li>
)}

const renderSwitchMui = ({input, label, meta : {error, touched}, ...custom}) => {
  //console.log(error, touched, 'xxxxxxxxxxxxxxxxxx')

  return (
    <FormControl error={Boolean(error)}>
    <FormControlLabel
    control={
      <Switch
        checked={input.value ? true : false }
        onChange={input.onChange}
        color="primary"
      />
    }
    label={label}
    {...input}
  />
  {error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
  )
}

const DefaultReduxForm = ({ inputs, sectionName, sectionStyle }) => {
  const renderTextInput = ({name, label, id, ...custom}) => {
 
    return (
      <Field
        key={id}
        name={name}
        component={renderTextField}
        label={label}
        {...custom}
  />)}

  const renderSelectInput = ({name, label, id, options, ...custom}) => {
  
    return <Field
      key={id}
      name={name}
      component={renderSelectField}
      label={label}
      children={options.map((option, i)=>
          <MenuItem dense key={i} value={option.value}>{option.labelGetter ? option.value[option.labelGetter] : option.label}</MenuItem>
      )}
      
      {...custom}
    />}
  const renderCheckbox = ({name, label, id, ...custom}) =>{
   return <Field  key={id} name={name} component={renderCheckboxMui} label={label}  {...custom} />}

  const renderSwitch = ({name, label, id, ...custom}, inputLength) => {
    return <Field key={id} name={name} component={renderSwitchMui} label={label} {...custom} />
  }


  return (
    sectionName 
    ? <FormSection name={sectionName} style={sectionStyle ? sectionStyle : {}}>
        {orderBy(inputs, 'id').map(input =>{
            if(input.type==='text' || input.type==='date' || input.type==='tel' || input.type==='email' || input.type==='number')
              return renderTextInput(input)
            if(input.type==='select')
              return renderSelectInput(input) 
            if(input.type==='checkbox')
              return <ul key={input.id} style={{padding: 0, margin: 0}}>{renderCheckbox(input)}</ul>
            if(input.type==='switch')
              return renderSwitch(input)
            return null
        })}  
      </FormSection> 
    : orderBy(inputs, 'id').map(input =>{
      if(input.type==='text' || input.type==='date' || input.type==='tel' || input.type==='email' || input.type==='number')
        return renderTextInput(input)
      if(input.type==='select')
        return renderSelectInput(input) 
      if(input.type==='checkbox')
        return <ul key={input.id} style={{padding: 0, margin: 0}}>{renderCheckbox(input)}</ul>
      if(input.type==='switch')
        return renderSwitch(input)
      return null
  })
  )
}

export default DefaultReduxForm
