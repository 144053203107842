import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import config from '../../config/config'
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { doSignOut } from '../../firebase/auth'
import headerImage from 'static/landingPageBG-desktop.jpg'
import AccountIcon from '@material-ui/icons/AccountCircle';
import { appName } from 'config/config'


const useStyles = makeStyles(theme => {
  
  
  return({
    root: {
      flexGrow: 1,
    },
    drawer: {
      ...theme.drawer

    },
    menuTitleWrapper: {
      paddingTop: '30px',
      height: '120px',
     // borderBottom: `1px solid ${theme.palette.primary.dark}`,
      marginBottom: theme.spacing(2),
      backgroundImage: `url(${headerImage})`,
      backgroundSize: 'cover',
      color: 'white',
      
    },
    menuTitle: {
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
      backgroundColor: 'rgb(255, 255, 255, 0.5)',
      padding: '4px',

    },
    listItem: {
      padding: '0 2.0em 0 2.0em'
    },
    listItemActive: {
      padding: '0 2.0em 0 2.0em',
      backgroundColor: theme.palette.primary.main,
      color: 'white'

    },
    listItemIconActive: {
      color: 'white'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  })});

const MobileNav = ({navItems, user, navigation}) => {
   
    const classes = useStyles()
    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = (open) => {
      setIsOpen(open);
    }

    return (
    
    <Fragment >
        <IconButton onClick={()=>toggleDrawer(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
        <MenuIcon style={{minWidth: '2.0rem', minHeight: '2.0rem'}}/>
      </IconButton>
      <Typography variant="h6" className={classes.title}>
        
      </Typography>
      <Button 
        color="inherit"
        onClick={doSignOut}
        >
        <AccountIcon />
      </Button>
      <Drawer PaperProps={{className: classes.drawer}} anchor="left" open={isOpen} onClose={()=>toggleDrawer(false)}>
        <MobileNavMenu navItems={navItems} toggleDrawer={toggleDrawer} />
      </Drawer>
    </Fragment>
        
    )

}


let MobileNavMenu = ({navigation,  navItems, dispatch, toggleDrawer}) => {

  const history = useHistory()
  const classes = useStyles()
  const navigateTo = goTo => {
    history.push(goTo)
    const payload = goTo
    dispatch({type: config.actionTypes.NAVIGATE_TO, payload})
    toggleDrawer(false)
  }

  return (
    <div>
      <div className={classes.menuTitleWrapper}>
        <Typography align='center' variant='h4' className={classes.menuTitle}>{appName}</Typography>
      </div>
    
      <List >
        {navItems.map((navItem, index) => {
          if(navItem.title==='Divider') {
            return <Divider style={{margin: '8px 0 8px 0'}} key={index} color='primary'/>
          }
          const NavIcon = navItem.icon
          return (
            <ListItem
              key={index}
              onClick={()=>navigateTo(navItem.path)}
              className={navigation.active === navItem.path ? classes.listItemActive : classes.listItem}
              button
              >
              <ListItemIcon><NavIcon className={navigation.active === navItem.path ? classes.listItemIconActive : classes.listItemIcon } /></ListItemIcon>
              <ListItemText>{navItem.title}</ListItemText>
            </ListItem>)
                
        })}
      </List>
    </div>
  )
}

const mapStateToProps = state => ({
  navigation: state.navigation,
  user: state.user
})

MobileNavMenu = connect(mapStateToProps)(MobileNavMenu)

export default connect(mapStateToProps)(MobileNav)

