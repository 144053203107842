import React from 'react'
//import { Route } from 'react-router-dom'
import Header from '../../App/Header'
import Footer from '../../App/Footer'
import DefaultPage from '../../Components/Layouts/DefaultPage'
import AppBar from '../../App/AppBar'
import MainNav from '../../App/MainNav'
//import PflegeMembersList from '../../Container/Members/Pflegemembers/MembersList'
import NachbarschaftshilfeApp from './NachbarschaftsApp'
import PflegekasseApp from './PflegekasseApp'
//import Test from 'Container/Members/Member/AddressForm'
import { routes as nachbarschaftshilfeRoutes  } from './NachbarschaftsApp'
import { routes as pflegekassenRoutes } from './PflegekasseApp'

const privateRoutes = {
    ...nachbarschaftshilfeRoutes,
    ...pflegekassenRoutes
}
export {
    nachbarschaftshilfeRoutes,
    pflegekassenRoutes,
    privateRoutes
}


const roles = [
    'admin',
    'appUser',
]

export const PrivateRoutes = ({match, role, user}) => {
  
    return (
        <React.Fragment >
            <Header user={user} />
            <AppBar 
                childreen={<MainNav activeApp={user.settings.activeApp} />}
                color={user.settings.activeApp==='nachbarschaftshilfe' ? 'primary' : 'secondary'}
            />
             <DefaultPage >
             {roles.includes(role) && (
                user.settings.activeApp==='nachbarschaftshilfe'
                    ? <NachbarschaftshilfeApp />
                    : <PflegekasseApp />
                
             )}
            </DefaultPage>
            <Footer /> 
        </React.Fragment>
        
    )
}

export default PrivateRoutes
