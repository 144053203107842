
import {firestore, db} from '../../firebase/firebase'
import _ from 'lodash'
//import fs from 'fs-extra'
const invoiceSetRef = db.ref('/invoiceSet')

export const generateRandomString = () => {
    const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';                     
    const charactersLength = characters.length;
    let randomString = '';
    const length = 32;
    for (let i = 0; i < length; i++) {
        randomString += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return randomString
}

const umlautMap = {
	'\u00dc': 'UE',
	'\u00c4': 'AE',
	'\u00d6': 'OE',
	'\u00fc': 'ue',
	'\u00e4': 'ae',
	'\u00f6': 'oe',
	'\u00df': 'ss',
  }
  
export const replaceUmlaute = (str) => {
	return str
	.replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
	  const big = umlautMap[a.slice(0, 1)];
	  return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
	})
	.replace(new RegExp('['+Object.keys(umlautMap).join('|')+']',"g"),
	  (a) => umlautMap[a]
	)
}
/*
export const removeTmpFile = async fileToDel => {
	return await fs.remove(fileToDel)
		.then(() => {
			//console.log('FILE DELETED', fileToDel)
			return 'success'
		})
		.catch(err => {
			console.error(err)
			return err
		})
}

export const writeFile = (filePath, fileContent) => {
	return new Promise((resolve, reject) => {
	 fs.writeFile(filePath, fileContent, writeFileError => {
	  if (writeFileError) {
	   reject(writeFileError);
	   return;
	  }
	  resolve(filePath);
	 });
	});
   }
   */

export const setDocURLInFirestore = (url, accountingType, id) => {

	const docRef = `accounting/${accountingType}/${id}`
	const splitedAccountingType = accountingType.split('/')
	const _accountingType = splitedAccountingType[1]

	return firestore.doc(docRef).set({
				pdfFiles: {[_accountingType]: url}
			}, { merge: true })	
}

export const setInvoiceSetTransactional = ({invoiceType, data}) => {
	console.log(invoiceType, 'TYPE')
	let currentInvoiceSetRef = invoiceSetRef.child(invoiceType || 'pflegekasse')
	const dataKeys = _.keys(data)
	const isSingleField = _.keys(data).length===1
	const isTextField = _.has(data, 'text')
	// if just one filed to update change ref to singe field instead to whole data set
	let invoiceSetDataRef = currentInvoiceSetRef
	if(isSingleField) {
		console.log(dataKeys[0])
		invoiceSetDataRef = currentInvoiceSetRef.child(dataKeys[0] || 'error')
	}
	invoiceSetDataRef.transaction((invoiceSetData) => {
		if(invoiceSetData) {
			console.log(invoiceSetData, 'TEXT')
			invoiceSetData = isSingleField
				? isTextField
					? _.isArray(invoiceSetData) ? [...invoiceSetData, data.text] : [data.text]
					: invoiceSetData=data[dataKeys[0]]
				: isTextField
					? invoiceSetData.text
						? {...invoiceSetData, ...data, text: [...invoiceSetData.text, data.text]}
						: {...invoiceSetData, ...data, text: [data.text]}
					: {...invoiceSetData, ...data}
		}
		return invoiceSetData
	})
}

export const setCollectorURLInFirebase = (uploadResponse, accountingType, text, isLoading )=> {
	const collectorURL = uploadResponse[0].publicUrl()
	return db
			.ref(`app/accounting/${accountingType}`)
			.set({isLoading, pdfURL: collectorURL, text})
}