import { dispatch } from '../index.js'
import {actionTypes} from '../../config'

const resetDialog = {
    isOpen: false, title: '', text: '', buttons: []
  }
export const dialog = (payload) => {

    if(payload==='reset' || payload===undefined)
        return dispatch({type: actionTypes.DIALOG, payload: {...resetDialog} })
    return dispatch({type: actionTypes.DIALOG, payload})
}
