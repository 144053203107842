import { firestore } from '../firebase'

const institutsREF = firestore.collection('instituts')
const membersREF = firestore.collection('members')

//const accountingREF = firestore.collection('accounting').doc('pflegekasse')

export const checkInstitutsDepencies = async (institutId) => {
	const COLLECTOR = { foundMembers: [], foundServices: {} }

	const membersWithInstitutIdREF = membersREF.where(`institut`, '!=', false)
	const membersWithInstitutIdSnap = await membersWithInstitutIdREF.get()

    membersWithInstitutIdSnap.forEach(m=>{
        const member = m.data()
        if(member.institut.id===institutId)
            COLLECTOR.foundMembers.push(m.data())
    })
    if(COLLECTOR.foundMembers.length===0)
        return ({error: false, message: 'No Members found'})
    return ({error: true, message: 'members found', values: COLLECTOR.foundMembers})
}

export const deleteInstitut = (taskId) => {
	return institutsREF.doc(taskId).delete()
}