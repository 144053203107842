import { auth } from '../firebase'
import { fetchUser, createUserProfile } from '../database/userHandler'
import { dispatchNonAuthUser } from '../../store/actions/userActions'
import INITAL_STATE from '../../config/INITAL_STATE'
//auth.signInWithEmailAndPassword(email, password)

export const watchUserAuth = () => {
  
    return auth.onAuthStateChanged( function(user) {
        // checkSubscriptionState(userId)
    
        if (!user) {        
            const user = INITAL_STATE.user
            user.isLoading = false
            return dispatchNonAuthUser(user)
        } 
        
        //console.log('userAuth-Id:', user.uid)
      //  console.log('authUser', user.emailVerified)
       
        return fetchUser(user.uid, user.emailVerified)
    })
}

export const sendEmailVerification = () => {
    auth.currentUser.sendEmailVerification().then(function() {
        console.log('Verification send')
    }, function(error) {
        console.log('verification ERROR', error)
    })
}

export const doCreateUserWithEmailAndPassword = (userData, errorCallback) => {
   auth.createUserWithEmailAndPassword(userData.email, userData.password)
   .then(function(currentUser) {
    sendEmailVerification()
    createUserProfile(currentUser.user.uid, userData)
    console.log('Verification send')
}, function(error) {
    console.log('verification ERROR', error)
    return errorCallback(error)
})
}



export const doSignInWithEmailAndPassword = async (email, password) => {
    let errorOutput = ''
    await auth.signInWithEmailAndPassword(email, password)
    .then((user)=>errorOutput = user
    )
    .catch(error => {
        errorOutput = error.code
      });
      //console.log(errorOutput, 'YYYY')
      return errorOutput
}
//doSignInWithEmailAndPassword('prinz2@mail.de', 'susi.123')

export const doSignOut = async () => {
    return auth.signOut().then(function() {    
        console.log('abgelmedet')
      }).catch(function(error) {
        console.log('Logout Error', error)
      });
}


export const sendPasswordResetEmail = (email, callback) => {
    
    return auth.sendPasswordResetEmail(email).then(function() {
    
        callback(null)
        return null
    }).catch(function(error) {
        error = error.code
        console.error('ERROR!', error)
        callback(error)
        return error
    })
   
}


