import React, { useState} from 'react'
import { useHistory } from 'react-router'
import { routes } from '../../config/config'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { sendPasswordResetEmail } from '../../firebase/auth'
const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(1)
    },
    input: {
        margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`
    }

}))
const PasswordForget =  () => {
    const history = useHistory()
    const classes = useStyles()
    const [error, setError] = useState(false)
    const onSubmit = async (e) => {
        e.preventDefault()
        const email = e.currentTarget[0].value
        const errorCallback = errorCode => {
            if(errorCode==='auth/user-not-found')
                return setError(true)
            if(errorCode===null) {
                setError(false)
                history.push(routes.public.LOGIN)

            }
        }
        sendPasswordResetEmail(email, errorCallback)
    }
    return (
        <Paper className={classes.paper}>
                <section className={classes.headlineWrapper}>
                    <Typography color='primary' variant='h5' children='Password zusenden' gutterBottom />
                <Typography variant='h6' >{error ? <span style={{color: 'red', fontWeight: 'strong'}}>Fehler! Die E-Mail Adresse existiert nicht</span> : 'Geben Sie ihre E-Mail Adresse ein'}</Typography>
            </section>
            <form onSubmit={onSubmit}>
                <TextField
                    name='email'
                    className={classes.input}
                    label='E-Mail Adresse'
                    variant='outlined'
                    color='primary'
                    fullWidth
                    type='email'
                    placeholder='Geben Sie Ihre Email Adresse ein'
                 />
                 <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                 >
                    Kennwort Zusenden
                 </Button>
            </form>
        </Paper>
    )
}

export default PasswordForget
