import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { IconButton, TextField, Button } from '@material-ui/core'
import moment from 'moment'
import { createTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import AddIcon from '@material-ui/icons/Add'
import NoRowsOverlay from 'Components/DataGrid/NoRowsOverlay'
import keys from 'lodash/keys'
import EditIcon from '@material-ui/icons/Edit'
import fileIconSelector from 'static/fileIcons/index'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { saveAs } from 'file-saver'
import { dispatchEditFile, dispatchOpenFileUpload } from 'store/actions/fileStorageActions'
import { deleteFiles } from 'firebase/database/fileStorageHandler'
import SimplePopover from 'Components/Popover/SimplePopover'
import { dialog } from 'store/actions/dialogActions'

import {
   // LicenseInfo,
    DataGrid,
    //onRowClick,
    //onRowOver,
    deDE,  
    //getGridDefaultColumnTypes,  
    GridToolbarColumnsButton,
    //GridToolbarDensitySelector,
   // GridToolbarExport,
    GridToolbarFilterButton,
  } from '@mui/x-data-grid'


const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => ({
    dataGrid: {
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none',
    }
    },
    searchContainer: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    textField: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      margin: 0,
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme },
)

const noViewExtensions = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx']
const columns = [
  {
    field: 'extension',
    align: 'center',
    headerName: 'Type',
    filterable: false,
    sortable: false,
    width: 60,
    editable: false,
    renderCell: params =>{
      const ext = params.row.ext  
      return <img height={30} src={fileIconSelector(ext)} alt={params.row.fileName} />
    }
  },
  {
    field: 'version',
    align: 'center',
    headerName: 'Version',
    //filterable: false,
    //sortable: false,
    width: 60,
    editable: false,
    valueFormatter: params=>params.value
  },
  {
    field: 'fileName',
    headerName: 'Dateiname',
    width: 190,
    editable: false,
  },
  {
    field: 'info',
    headerName: 'Info',
    //description: 'Wohnort sortieren oder Filtern',
    sortable: false,
    width: 220,
    renderCell: params=><SimplePopover text={params.row.info} value={params.row.info} />
  },
  {
    field: 'uploadBy',
    headerName: 'Upload von',
    editable: false,
    width: 140,
    valueGetter: (params)=>{
      return params.row.editors.created.name
    }
  },
  {
      field: 'uploadDate',
      headerName: 'Upload am',
      type: 'date',
      editable: false,
      width: 150,
      valueFormatter: params =>moment(params.value).format('DD.MM.YYYY - HH:MM'),
      valueGetter: (params)=>params.row.editors.created.timeStamp 
  },
  {
    field: 'Actions',
    type: 'actions',
    headerName: 'Optionen',
  
    width: 120,
    editable: false,
    renderCell: (params) => {
    return <>
      <IconButton disabled={noViewExtensions.includes(params.row.ext)} color='primary' size='small' onClick={()=>window.open(params.row.url)}  ><VisibilityIcon /></IconButton>
      <IconButton color='primary' size='small' onClick={()=>saveAs(params.row.url, `${params.row.fileName}.${params.row.ext}`)}  ><DownloadIcon /></IconButton>
      <IconButton color='primary' size='small' onClick={()=>dispatchEditFile(params.row)}  ><EditIcon /></IconButton>
      </>}
  },   
]

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
  

const FileDataGrid = ({fileStorage}) => {
  const classes = useStyles()
  const [filterModel, setFilterModel] = useState({ items: [] })
  const [sortModel, setSortModel ] = useState([{field: 'uploadDate', sort: 'desc'}])
  const [rows, setRows] = useState([])
  const [searchText, setSearchText] = useState('')
  const [selectedRows, setSelectedRows] = useState({ids: [], data: []})
  useEffect(() => {
      setRows(fileStorage)
      return () => {
        //   setSelectedRows(ids: [], data: [])
      }
  }, [fileStorage])

  const editorSubSearchFields = ['name', 'timeStamp']
  const rootSearchFields = ['name']
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
 
    const filteredRows = fileStorage.filter((row) => {
      return keys(row).some((field) => {
      
        if(field==='editors') {
          const resultArr = editorSubSearchFields.map(searchField=>{
            return searchRegex.test(moment(row[field].created[searchField]).format('DD.MM.YYYY').toString())
          })
          if(row[field].updated) {
            resultArr.push(editorSubSearchFields.map(searchField=>{
              return searchRegex.test(moment(row[field].updated[searchField]).format('DD.MM.YYYY').toString())
            }))
          }
          return resultArr.includes(true)
        } else if(rootSearchFields.includes(field)) {
            return searchRegex.test(row[field].toString())
        }
        return false
      })
    })
    setRows(filteredRows);
  }
  const handleSelectedRows = () => {
    const delFilesConfimed = () => {
      deleteFiles(selectedRows.data)
      dialog('reset')
      return setSelectedRows({ids: [], data: []})
    }

    dialog({isOpen: true, title: 'Datei(n) löschen', text: 'Wollen Sie die Datei(n) wirklich löschen?', buttons: [
      {title: 'Abbrechen', variant: 'outlined', color: 'primary', onClick: ()=>dialog('reset')},
      {title: 'Löschen', variant: 'contained', color: 'secondary', onClick: delFilesConfimed},
    ]})
    
  }
  return (
    <div style={{ display: 'flex', minHeight: 500 }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          editMode='row'
          rows={rows || []}
          columns={columns}
          //rowHeight={50}
          //editMode='row'
          initialState={{
              sorting: {
              sortModel: [{ field: 'timeStamp', sort: 'desc' }],
              },
          }}
          density='compact'
          checkboxSelection
          onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids)
              const _selectedRows = fileStorage.filter((row) =>
              selectedIDs.has(row.id),
              )
              return setSelectedRows({ids: ids, data:_selectedRows})
          }}
          selectionModel={selectedRows.ids}
          disableSelectionOnClick
      
          filterModel={filterModel}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          onFilterModelChange={(model) => setFilterModel(model)}
          className={classes.dataGrid}
          allowFiltering={true}
          loading={fileStorage===undefined}
          //onRowClick={row=>{handleRowClick(row)}}
          components={{ 
              Toolbar: QuickSearchToolbar,
              NoRowsOverlay: ()=><NoRowsOverlay title='Keine Mitglieder gefunden' />
          }}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          componentsProps={{
              toolbar: {
              value: searchText,
              onChange: (event) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(''),
              onDeleteClick: handleSelectedRows,
              onAddClick: dispatchOpenFileUpload,
              selectedRowsCount: selectedRows.ids.length,
              },
          }}
        />
      </div>
    </div>
  )
}

function QuickSearchToolbar(props) {

  const classes = useStyles()
  const isDisabled = props.selectedRowsCount===0
  return (
    <div className={classes.searchContainer} style={{margin: '1rem 0 1.5rem 0'}}>
        <div>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
        </div>
        <div>
          <Button
            disabled={!isDisabled}
            size='small'
            color='primary'
            variant='outlined'
            startIcon={<AddIcon /*color={isDisabled ? 'disabled' : 'primary'} *//>}
          // style={{color: props.selectedRowsCount<=0 ? 'lightgrey' : '#1976d2'}}
            onClick={props.onAddClick}
            //disabled={props.selectedRowsCount<=0}
          >
            Neue Datei
          </Button>
          <Button
            style={{marginLeft: '0.5rem'}}
            disabled={isDisabled}
            size='small'
            color='secondary'
            variant='outlined'
            startIcon={<DeleteIcon />}
          // style={{color: props.selectedRowsCount<=0 ? 'lightgrey' : '#1976d2'}}
            onClick={props.onDeleteClick}
            //disabled={props.selectedRowsCount<=0}
          >
            Löschen
          </Button>
        </div>
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Suchen…"
          className={classes.textField}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
            <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
            >
                <ClearIcon fontSize="small" />
            </IconButton>
            ),
        }}
        />
  </div>
    )
}

export default compose(
    firestoreConnect(() => {
      return [  
      //  { collection: 'accounting', doc: 'pflegekasse', subcollections: [{ collection: 'services' }], storeAs: 'servicesPflegekasse' },
     // { collection: 'tasks', where: [['type', '==', 'nachbarschaftshilfe']], storeAs: 'tasks' },
      { collection: 'fileStorage'},
  
    ]}), // or { collection: 'todos' }
    connect((state, props) => {
        return ({
        fileStorage: state.firestore.ordered.fileStorage
      })}),
    )(FileDataGrid)