import React, { useState }from 'react'
import { connect } from 'react-redux'
import TasksDataGrid from './TasksDataGrid'
import Dialog from './Dialog'
import AddTask from './AddTask'
import { dispatchResetFrom, dispatchSaveTask, dispatchDialogOpen, dispatchEditTask } from 'store/actions/tasksActions'
import { Paper, Typography } from '@material-ui/core'
import { deleteTask, checkTaskDepencies, deleteTaskFromMembers } from 'firebase/database/tasksHandler'
import Spinner from '@material-ui/core/CircularProgress'
import moment from 'moment'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'

const INIT_DATA_STATE = {items: null, title: null, type: null, appType: null, taskId: null}

const Tasks = ({task}) => {

    const [data, setData ] = useState({...INIT_DATA_STATE})
    const [dialog, setDialog] = useState(false)

    const handleNewTask = () => {
        return dispatchDialogOpen(true)
    }
    const handleSaveTask = () => {
        return dispatchSaveTask()
    }
    const handleCloseDialog = () => {
        //setReseachedServices(null)
        setDialog(false)
        setData({...INIT_DATA_STATE})
        return dispatchResetFrom()
    }
    const handleCheckTask = async row => {
        setDialog(true)
      
        const result = await checkTaskDepencies(row.id, row.type)
        if( result.error === true ) {
            if(result.type==='services') {
                // services in accounting exists - delete denied
                return setData({items: result.values, type: 'services', appType: row.type, taskId: row.id})
            }
            // one or more members has this task
            return setData({items: result.values, type: 'members', appType: row.type, taskId: row.id})   
        }
        // no services in accounting and 0 members has this taks
        return setData({items: null, type: 'delete', appType: row.type, taskId: row.id})
    }
    const handleDeleteTask = () => {
        if(data.type==='members')
            deleteTaskFromMembers(data.items, data.taskId)
        setDialog(false)
        deleteTask(data.taskId)
        return setData({...INIT_DATA_STATE})
    }
    const handleEditTask = row => {
        return dispatchEditTask(row) 
    }

    const disableSaveBtn = !(task.form.title.length>3 && task.form.name.length>3 && task.form.hourlyRate>1 && task.form.kmRate!==0 && task.form.genbundRate>=1)
    const disabledDeleteBtn = data.taskId===null
    const buttons = data.type==='services'
        ? [
            {
                children: 'OK',
                variant: 'outlined',
                color: 'primary',
                onClick:handleCloseDialog
            }
         ]
        : [
            {
                children: 'Abrechen',
                variant: 'outlined',
                color: 'primary',
                onClick: handleCloseDialog
            },
            {
                children: 'Löschen',
                disabled: disabledDeleteBtn,
                variant: 'contained',
                color: 'secondary',
                onClick: handleDeleteTask
            },
        ] 
 
    return (
        <div>
            <TasksDataGrid
                addTaskCallback={handleNewTask}
                deleteItemCallback={handleCheckTask}
                editItemCallback={handleEditTask}
            />
            <Dialog
                title={task.form.id ? 'Tätigkeit bearbeiten' : 'Tätigkeit hinzufügen'}
                //text='Datei Ausählen und ggf. Dateinnamen ändern. '
                open={task.dialog}
                closeBtn={handleCloseDialog}
                buttons={[
                    {
                        children: 'Speichern',
                        variant: 'outlined',
                        color: 'primary',
                        disabled: disableSaveBtn,
                        onClick:handleSaveTask
                    },
                    {children: 'Abbrechen', variant: 'outlined', onClick:handleCloseDialog, color:'secondary'}
                ]
            }>
              <AddTask  />
            </Dialog>
            <Dialog
                title={'Tätigkeit löschen'}
                //text='Datei Ausählen und ggf. Dateinnamen ändern. '
                open={dialog}
                closeBtn={handleCloseDialog}
                buttons={buttons}>
                {data.items
                    ? <ItemsFound data={data} />
                    : data.type==='delete'
                        ? <DeleteAllowedMessage />
                        : <div
                        style={{textAlign: 'center'}}
                        >
                            <Spinner />
                            <Typography align='center' variant='subtitle2' color='primary'>Überprüfe Abhängikeiten</Typography>
                    </div>
                }
            </Dialog>
        </div>
   )
}

export default connect(state=>({
    task: state.tasks
}))(Tasks)

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'left',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
      marginTop: theme.spacing(2),
      maxHeight: '300px',
      overflowY: 'scroll',
      overflowX: 'hidden',
      padding: theme.spacing(1)
    },
}))

const ItemsFound = ({data}) => {

    const classes = useStyles()
    const isNH = data.appType==='nachbarschaftshilfe'
    const isService = data.type==='services'
    const renderClip = item =>
        <Chip
            size='small'
            key={item.id}
            avatar={<Avatar>{isNH ? 'NH' : 'PK'}</Avatar>}
            color={isNH ? 'primary' : 'secondary'}
            label={isService ? `${moment(item.date).format('YYYY MMM')} ${item.requester.name} `: item.userName}
        />
    const text = data.type==='services'
    ? 'Es existieren Leistungen mit dieser Tätigkeit die noch nicht abgerechnet wurden. Sie müssen die Leistung(en) abrechnen oder löschen!'
    : 'Es existieren Mitlieder mit dieser Tätigkeit. Die Tätigkeit wird von jedem Mitglied entfernt!'
    return (
        <div>
            {data.items &&
                <div>
                    <Typography
                        gutterBottom
                        align='center'
                        variant='subtitle1'
                        color='error'
                    >
                        {data.type==='services' ? 'Löschen nicht möglich!' : 'Tätigkeit ist Mitglied(ern) zugeordnet'}
                    </Typography>
                    <Typography
                        gutterBottom
                        variant='subtitle2'
                    
                    >
                        {text}
                    </Typography>
                </div>
            }
            {data.items && <Paper className={classes.root}>
                {data.items.map(item=>renderClip(item))}
            </Paper>}
        </div>
    )
}

const DeleteAllowedMessage = () =>
<div>
    <Typography align='center' varaint='subtitle1' color='primary' gutterBottom>Keine Abhängikeiten gefunden</Typography>
    <Typography align='center' variant='subtitle2' color='error' >Wollen Sie die Leistung wirklich löschen?</Typography>
</div>