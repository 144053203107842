import React from 'react'
import { Typography } from '@material-ui/core'
import ButtonBoard from 'Components/Buttons/ButtonRow'

const ChartHeadLine = ({ title, children, chartRef, downloadHTMLChart, printChart, subtitle}) => {
  
    return (
        <section>
            <div id='html-chart-with-title' style={{paddingBottom: '1rem', backgroundColor: 'whitesmoke'}}>
                <div id='chart-header' style={{paddingLeft: '2rem'}}>
                    <Typography align='center' variant='h5' >
                        {title}
                    </Typography>
                    {subtitle && <Typography align='center' variant='subtitle2' >
                        {subtitle}
                    </Typography>}
                </div>
               
                <div id='html-chart' style={{paddingTop: '1rem', backgroundColor: 'whitesmoke'}}>
                    {children}
                </div>
            </div>
            <div  style={{marginBottom: '1.5rem'}}>
                <ButtonBoard
                    buttons= {[
                        {onClick: ()=>downloadHTMLChart(true), title: [{value: 'Download'}, {value: 'mit Überschrift' , color: 'lightGreen'}], size: 'small',},
                        {onClick: ()=>downloadHTMLChart(false), title: [{value: 'Download'}, {value: 'ohne Überschrift', color: 'red'}], size: 'small'},
                        {onClick: ()=>printChart(false), title: 'Drucken', size: 'small'}
                    ]}
                    title='Chart Download:'
                />
            </div>
        </section>
    )
}

export default ChartHeadLine