import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography' 
import ErrorIcon from '@material-ui/icons/Error'
import isArray from 'lodash/isArray'
import { v4 as uuid } from 'uuid'
import makeStyles from '@material-ui/styles/makeStyles'
import ButtonBoard from 'Components/Buttons/ButtonRow'

const useStyles = makeStyles(theme=>({
	root: {
		marginTop: theme.spacing(1),
		padding: theme.spacing(0, 0, 2, 0)
	},
	card: {
		padding: theme.spacing(0.5),
		marginBottom: theme.spacing(1)
	},
	icon: {
		marginLeft: '1rem',
		verticalAlign: 'middle',
		fontSize: '2.6rem'
	}
}))
const ErrorMessage = ({error, callback}) =>{ 
	const classes= useStyles()
	return <Paper className={classes.root}>
				<Card className={classes.card}>
					<Typography align='center' variant='h5' color='error'>
						{error.error} <ErrorIcon color='error' fontSize='large' className={classes.icon} />
					</Typography>
				</Card>
				{isArray(error.text)
					? error.text.map(p=><Typography key={uuid()} align='center' variant='subtitle1' paragraph children={p} />)
					: <Typography align='center' variant='subtitle1' paragraph cildren={error.text}/>
				}
				<ButtonBoard
					buttons={ [{title: 'Zurück', onClick: ()=>callback(undefined), color: 'primary', variant: 'outlined'}] }
				/>
			</Paper>}


ErrorMessage.propTypes = {
	error: PropTypes.object.isRequired
};

export default ErrorMessage;
