import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import ArchivList from './ArchivList'
import FormWrapper from '../../Components/Forms/FormWrapper'
import VSelect from 'Components/Select/VSelect'
import DefaultGrid from 'Components/Grid/DefaultGrid'
import ArchivSearch from './ArchivSearch'
import AntSwitch from 'Components/Switch/AntSwitch'
import { db  } from '../../firebase/firebase'
import { getInvoicesByMonth } from 'firebase/database/archivHandler'
import moment from 'moment'

const monthSelectData = [
    { label: 'Monat wählen', value: 'xx' },
    { label: 'Januar', value: '01' },
    { label: 'Februar', value: '02' },
    { label: 'März', value: '03' },
    { label: 'April', value: '04' },
    { label: 'Mai', value: '05' },
    { label: 'Juni', value: '06' },
    { label: 'Juli', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'Oktober', value: '10' },
    { label: 'November', value: '11' },
    { label: 'Dezember', value: '12' },
]

const getYearSelectData = () => {
    const startYear = 2023
    const currentYear = Number(moment().format('YYYY'))
    const selectYears = [
        { label: startYear, value: startYear},
    ]
    for(let i=1; startYear+i<currentYear; i++) {
        const year = startYear+i
        selectYears.push({label: year, value: year})
    }
    if(startYear!==currentYear)
        selectYears.push({label: currentYear, value: currentYear})
    return selectYears
}



const accountingTypeData = {
    nachbarschaftshilfe: [
        {label: 'Bedarf (B)', value: 'requester'},
        {label: 'Helfer (A)', value: 'provider'}
    ],
    pflegekasse: [
        {label: 'Bedarf (B)', value: 'requester'},
        {label: 'Helfer (A)', value: 'provider'},
        {label: 'Pflegekasse (P)', value: 'institutDONE'}
    ]
}

const defaultYear = Number(moment().format('YYYY'))

export class ArchivDashboard extends PureComponent {
    state = {
        accountingType: {label: 'Bedarf', value: 'requester'},
        selectedMonth: {label: 'Monat Wählen', value: 'xx'},
        selectedYear: {label: defaultYear, value: defaultYear },
        search: {
            value: null,
            state: 0,
            result: null,
            option: 'invoiceId'
        },
        filterListIsLoading: false,
        invoices: null,
    }
   
    handleSelectAccountingType = async e => {
        if(this.state.selectedMonth.value!=='xx') {
            const {activeApp} = this.props
            this.setState({filterListIsLoading: true, invoices: null})
            const result = await getInvoicesByMonth({
                activeApp,
                year: this.state.selectedYear.value,
                accountingType: e.value,
                month: this.state.selectedMonth.value
            })
            return this.setState({
                accountingType: e,
                filterListIsLoading: false,
                invoices: result
            })
        }
        return this.setState({accountingType: e})
   
    }
    handleSelectMonth = async e => { 
        const { activeApp } = this.props

        const result = await getInvoicesByMonth({
            year: this.state.selectedYear.value,
            activeApp,
            accountingType: this.state.accountingType.value,
            month: monthSelectData[e.index].value 
        })

        return this.setState({
            selectedMonth: monthSelectData[e.index],
            invoices: result,
            filterListIsLoading: false
        })
    }

    handleSelectYear = async (e) => {
        const { activeApp } = this.props
        if(e.value!==this.state.selectedYear && this.state.accountingType.value!=='xx') {
            this.setState({filterListIsLoading: true, invoices: null})
            
            const result = await getInvoicesByMonth({
                year: e.value,
                activeApp,
                accountingType: this.state.accountingType.value,
                month: this.state.selectedMonth.value
            })

            return this.setState({
                selectedYear: e,
                invoices: result,
                filterListIsLoading: false
            })
        }
     
        return this.setState({selectedYear: e})
    }

    setSearchState = (searchState, invoiceId, result, option) => 
        this.setState({search: {
            value: invoiceId,
            state: searchState,
            result,
            option
    }})

    setFilterSearchSwitch = () => {
        if(this.props.searchActive && this.state.search.value!==null) {
            this.setSearchState(0, null, null)
        }
        const settingsREF = db.ref(`/users/${this.props.user.id}/settings/archiv/searchActive`)
        return settingsREF.set(!this.props.searchActive)
    }

    render() {
        return (
            <div>
                <AntSwitch
                    isChecked={this.props.searchActive}
                    handleChange={this.setFilterSearchSwitch}
                    labelRight='Suchen'
                    labelLeft='Filtern'
                    style={{marginBottom: '0.5rem', display: 'inline-block'}}
                />
                <DefaultGrid classes={{}} container={{spacing: 1}}>
                    {this.props.searchActive
                    ? <DefaultGrid.Row classes={{}} style={{borderRadius: '8px'}}>
                            <ArchivSearch
                                //itemsCount={this.state.search.result && this.state.search.result.length}
                                activeApp={this.props.activeApp}
                                setSearchState={this.setSearchState}
                                search={this.state.search}
                            />
                      </DefaultGrid.Row>
                    : <DefaultGrid.Row classes={{}} >
                        <FormWrapper title='Abrechnungen filtern' compProps={{title: {variant: 'body1', color: 'primary'}}} >
                            <VSelect
                                size='small'
                                id='accountingTypeSelect'
                                data={accountingTypeData[this.props.user.settings.activeApp]}
                                selectedValue={this.state.accountingType}
                                callback={e=>this.handleSelectAccountingType(e)}
                                propKeys={{label: 'label', value: 'value'}}  
                            />
                        </FormWrapper>
                        <FormWrapper title='Abrechnungs Monat' compProps={{title: {variant: 'body1', color: 'primary'}}} >
                            <VSelect
                                id='accountingMonthSelect'
                                data={monthSelectData}
                                selectedValue={this.state.selectedMonth}
                                callback={e=>this.handleSelectMonth(e)}
                                propKeys={{label: 'label', value: 'value'}}  
                            />
                        </FormWrapper>
                        <FormWrapper title='Abrechnungs Jahr' compProps={{title: {variant: 'body1', color: 'primary'}}} >
                            <VSelect
                                id='accountingYearSelect'
                                data={getYearSelectData()}
                                selectedValue={this.state.selectedYear}
                                callback={e=>this.handleSelectYear(e)}
                                propKeys={{label: 'label', value: 'value'}}  
                            />
                        </FormWrapper>
                    </DefaultGrid.Row>
                }
                </DefaultGrid>
                {this.props.searchActive
                ? <ArchivList
                    invoices={this.state.search.state!==99 ? this.state.search.result :  []}
                    isLoading={this.state.search.state===1}
                    search={this.state.search}
                    activeApp={this.props.activeApp}
                />
                
                : <ArchivList
                    invoices={this.state.invoices}
                    isLoading={this.state.filterListIsLoading}
                    activeApp={this.props.user.settings.activeApp}
                    accountingType={this.state.accountingType.value}
                    selectedMonth={this.state.selectedMonth.value}
                />}
            </div>
       
        )
  }
}


export default connect((state) => {
    return ({   
        user: state.user,
        activeApp: state.user.settings.activeApp,
        searchActive: state.user.settings.archiv ? state.user.settings.archiv.searchActive : false,
    })
})(ArchivDashboard)
