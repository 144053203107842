import xls from './excel.svg'
import xlsx from './excel2.svg'
import file from './file.svg'
import folder from './folder.svg'
import image from './image.svg'
import outlook from './outlook.svg'
import pdf from './pdf.svg'
import ppt from './powerpoint.svg'
import txt from './txt.svg'
import doc from './word.svg'
import docx from './word.svg'
import zip from './zip.svg'
import bmp from './bmp.svg'
import csv from './csv.svg'
import jpg from './jpg.svg'
import png from './png.svg'

const icons = {csv, xls, xlsx, file, folder, jpg, image, png, tif: image, bmp, msg: outlook, pdf, ppt, pptx: ppt, txt, doc, docx, zip}
const iconSelector = icon => {
	const _icon = icon.toLowerCase()
    if(Boolean(icons[_icon]))
        return icons[_icon]
    return file
}

export default iconSelector