import React, { useState, useEffect, useMemo} from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { IconButton, TextField, Typography, Button, Grid, Chip } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import NoRowsOverlay from 'Components/DataGrid/NoRowsOverlay'
import keys from 'lodash/keys'
import { useHistory } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'

import {
    // LicenseInfo,
        DataGrid,
        //onRowClick,
        //onRowOver,
        deDE,  
        GridToolbarColumnsButton,
        //GridToolbarDensitySelector,
    // GridToolbarExport,
        GridToolbarFilterButton,
} from '@mui/x-data-grid'

function ucFirst(string) { 
  return string.substring(0, 1).toUpperCase() + string.substring(1)
}

const defaultTheme = createTheme();
  const useStyles = makeStyles(
     (theme) => ({
       dataGrid: {
         '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
           outline: 'none',
       }
       },
       searchContainer: {
         padding: theme.spacing(0.5, 0.5, 0),
         justifyContent: 'space-between',
         display: 'flex',
         alignItems: 'flex-start',
         flexWrap: 'wrap',
       },
       textField: {
         [theme.breakpoints.down('xs')]: {
           width: '100%',
         },
         margin: 0,
         '& .MuiSvgIcon-root': {
           marginRight: theme.spacing(0.5),
         },
         '& .MuiInput-underline:before': {
           borderBottom: `1px solid ${theme.palette.divider}`,
         },
       },
     }),
     { defaultTheme },
   )

const TasksDataGrid = ({tasks, addTaskCallback, selectedRowsCallback, deleteItemCallback, editItemCallback}) => {
    const history = useHistory()
    const classes = useStyles()
    const [filterModel, setFilterModel] = useState({ items: [] })
    const [rows, setRows] = useState([])
    const [searchText, setSearchText] = useState('')
    const [selectedRows, setSelectedRows] = useState({ids: [], data: []})

    useEffect(() => {
        setRows(tasks)
        return () => {
          //   setSelectedRows(ids: [], data: [])
        }
    }, [tasks])

    const columns = useMemo(()=> [
      {
        field: 'type',
        headerName: 'App',
        editable: false,
        width: 160,
        align:'center',
        renderCell: params=>{
          const app = params.row.type
          const isNH = app==='nachbarschaftshilfe'
            return <Chip variant='outlined' label={ucFirst(app)} size='small' color={isNH ? 'primary' : 'secondary'} />
              
          }
      
      },
      {
        field: 'name',
        headerName: 'Name (intern)',
        width: 200,
        editable: false,
  
      },
      {
        field: 'hourlyRate',
        headerName: 'Bedarf',
        description: 'Std. Betrag Bedarf',
        align: 'center',
        sortable: true,
        renderCell: params=>`${params.row.hourlyRate} €`
      },
      {
        field: 'providerRate',
        headerName: 'Helfer',
        editable: false,
        align: 'center',
        renderCell: params => {
          const render = params.row.hourlyRate-params.row.genbundRate
          return `${render} €`
        }
      },
      {
        field: 'genbundRate',
        headerName: 'Genbund',
        editable: false,
        align: 'center',
        renderCell: params => {
          return params.row.genbundRate+' €'
        }
      },
      {
        field: 'kmRate',
        headerName: 'KM Satz',
        editable: false,
        align: 'center',
        renderCell: params=>`${params.row.kmRate} €`
        //width: 200,
      },
      {
        field: 'actions',
        headerName: 'Optionen',
        editable: false,
        align:'center',
        renderCell: params =>{
          return <>
            <IconButton color='secondary' size='small' onClick={()=>deleteItemCallback(params.row)}  ><DeleteIcon /></IconButton>
            <IconButton color='primary' size='small' onClick={()=>editItemCallback(params.row)}  ><EditIcon /></IconButton>
            </>
        }
      }
      
    ], [deleteItemCallback, editItemCallback])

  
  const requestSearch = (searchValue) => {
    setSearchText(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    const filteredRows = tasks.filter((row) => {
      return keys(row).some((field) => {
        if(field==='name') {
          return searchRegex.test(row[field].toString())
        }
        return false
      })
        
      })
        setRows(filteredRows)
    }

    const handleSelectedRows = () => {
        selectedRowsCallback(selectedRows)
        return setSelectedRows({ids: [], data: []}) 
    }

  return (
    <div>
      <Grid container style={{marginBottom: '1rem'}}>
          <Grid item md={6} >
              <Typography variant="h6" gutterBottom >Tätigkeiten</Typography>
          </Grid>
      <Grid item md={6} style={{textAlign: 'right'}}>
              <Button startIcon={<ArrowBackIcon />} size='small' variant='outlined' color='primary' onClick={()=>history.goBack( )} >
                  Zurück
              </Button>
      </Grid>
            </Grid>
    <div style={{ display: 'flex', minHeight: 400 }}>
      <div style={{ flexGrow: 1 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            editMode='row'
            initialState={{
              sorting: {
                sortModel: [{ field: 'type', sort: 'desc' }],
              },
            }}
            density='compact'
            checkboxSelection
            onSelectionModelChange={(ids) => {
              console.log(ids)
              const selectedIDs = new Set(ids)
              const _selectedRows = tasks.filter((row) =>
                selectedIDs.has(row.id),
              )
              return setSelectedRows({ids: ids, data:_selectedRows})
            }}
            selectionModel={selectedRows.ids}
            disableSelectionOnClick
        
            filterModel={filterModel}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            onFilterModelChange={(model) => setFilterModel(model)}
            className={classes.dataGrid}
            allowFiltering={true}
            loading={tasks.length===0}
            //onRowClick={row=>{handleRowClick(row)}}
            components={{ 
                Toolbar: QuickSearchToolbar,
                NoRowsOverlay: ()=><NoRowsOverlay title='Keine Mitglieder gefunden' />
            }}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(''),
                onDeleteForEverClick: handleSelectedRows,
                onAddTaskClick: addTaskCallback,
                selectedRowsCount: selectedRows.ids.length,
              },
            }}
          />            
            </div>
        </div>
    </div>
)}



function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

function QuickSearchToolbar(props) {
  
    const classes = useStyles()
    return (
        <div className={classes.searchContainer} style={{margin: '1rem 0 1.5rem 0'}}>
            <div>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </div>
              <Button
                style={{marginRight: '0.5rem'}}
                size='small'
                color='primary'
                variant='outlined'
                startIcon={<AddIcon />}
              // style={{color: props.selectedRowsCount<=0 ? 'lightgrey' : '#1976d2'}}
                onClick={props.onAddTaskClick}
                //disabled={props.selectedRowsCount<=0}
                >
                  Tätigkeit
              </Button>
     
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Suchen…"
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                    <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{ visibility: props.value ? 'visible' : 'hidden' }}
                        onClick={props.clearSearch}
                    >
                        <ClearIcon fontSize="small" />
                    </IconButton>
                    ),
            }}
            />
      </div>
    )
}

export default compose(
    firestoreConnect(() => {
      return [  
      //  { collection: 'accounting', doc: 'pflegekasse', subcollections: [{ collection: 'services' }], storeAs: 'servicesPflegekasse' },
     // { collection: 'tasks', where: [['type', '==', 'nachbarschaftshilfe']], storeAs: 'tasks' },
      { collection: 'tasks'},
  
    ]}), // or { collection: 'todos' }
    connect((state, props) => {
        return ({
        tasks: state.firestore.ordered.tasks || []
        //membersDeleted: state.firestore.ordered.membersDeleted || [],
        //member: state.firestore.data.members ? state.firestore.data.members['0Gcc9zcifuxJ0zKNoPDK'] : undefined,
      })}),
    )(TasksDataGrid)