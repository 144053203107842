import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
//import Container from '@material-ui/core/Container';
//import ListItem from '@material-ui/core/ListItem';
//import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import Slide from '@material-ui/core/Slide'

const useStyles = makeStyles((theme) => ({
  root: {
   backgroundColor: theme.palette.background,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 5,
  },
  status: {
    flex: 1,
    textTransform: 'uppercase',
    borderRadius: '8px',
    fontWeight: 'bold',
  },
  contentWrapper: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={120}/>
})

function FullScreenDialog({isOpen, onCloseCallback, onSaveCallback, onDeleteCallback, children, title, status, statusColor, saveBtnDisabled, deleteBtnDisabled}) {
  const classes = useStyles()
  return (
    <Dialog fullScreen open={isOpen} TransitionComponent={Transition} classes={{paper: classes.root}} >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onCloseCallback} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title || 'GENFAKT'}
          </Typography>
          <Typography style={{color: statusColor}} variant="subtitle1" className={classes.status} align='center'>
            {status || ''}
          </Typography>
          {onDeleteCallback && <Button
            disabled={deleteBtnDisabled}
            size='small'
            style={{marginRight: '1rem'}}
            color='secondary'
            variant='contained'
            startIcon={<DeleteIcon />}
            onClick={onDeleteCallback}>
                Löschen
            </Button>}
          {onSaveCallback && <Button
            size='small'
            color={saveBtnDisabled ? 'inherit' : 'primary'}
            variant={saveBtnDisabled ? 'text' : 'contained'} 
            startIcon={<SaveIcon />} autoFocus onClick={onSaveCallback}
            disabled={saveBtnDisabled}
          >
              Speichern
          </Button>}
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper} >
        {children}
      </div>
    </Dialog>
  )
}

export default connect((state) => {
  return ({
 // formChanged: state.member.formChanged,
})})(FullScreenDialog)

