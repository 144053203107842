import React from 'react'
import Paper from '@material-ui/core/Paper'
const MemberAddressCard = ({profile}) => {
	const { firstName, lastName, city, street, email, phone, mobile, car } = profile
	return (
		<Paper style={{padding: '0.5rem', color: 'white', backgroundColor: ''}}>
			<ul>
				<li>{`${lastName} ${firstName}`}</li>
				<li>{city}</li>
				<li>{street}</li>
				<li>{phone || 'Nicht vorhanden'}</li>
				<li>{mobile || 'Nicht vorhanden'}</li>
				<li>{email ? <a href={`mailto:${email}`} >{email}</a> : 'Nicht vorhanden'}</li>
				<li>Kfz: {car ? 'JA' : 'NEIN'}</li>
			</ul>
		</Paper>
	)
}

export default MemberAddressCard