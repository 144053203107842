import React from 'react'
import { saveAs } from 'file-saver'
import print from 'print-js'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import IconSelector from 'Components/Icons/IconSelector'

const dividerStyle = {backgroundColor: '#3f51b5', paddingTop: '2px', marginTop: '0.5rem', marginBottom: '0.5rem'}
const btnSize='small'

const FileResults = ({data, date}) => {

	const handleOpenDoc = url => {
		window.open(url, '_blank').focus()
		return
	}
	const handleDownloadDoc = (url, fileName)=> {
		saveAs(
			url,
			fileName
		)
		return
	}
	const handlePrint = (fileURL) => {
		const printOptions = print.Configuration = {
			printable: fileURL,
			type: 'pdf'
		}
		print(printOptions)
		return
	}
	const { requesterInfo, providerInfo } = data


	return (
		<Paper style={{padding: '1rem'}}>
			<Typography variant="h6" color="secondary">Ergebnisse</Typography>
			<Divider style={dividerStyle} />
			{(requesterInfo && requesterInfo.pdf) && <div >
				{requesterInfo.pdf.url
					? <PDFInfos
						accountingType='Bedarf ohne Email'
						handleDownloadDoc={handleDownloadDoc}
						handleOpenDoc={handleOpenDoc}
						handlePrint={handlePrint}
						pdfInfo={requesterInfo.pdf}
					/>
					: <NoPDFMessage memberType='Bedarf' />
				}
				{requesterInfo.xml && <div style={{marginTop: '1rem'}}>
					<XMLInfo handleDownloadXML={handleDownloadDoc} xml={requesterInfo.xml} accountingType='Bedarf' />
				</div>}
			</div>}
			<Divider />
			{(providerInfo && providerInfo.pdf) && <div style={{marginTop: '1rem'}}>
				{providerInfo.pdf.url
					? <PDFInfos
						accountingType='Helfer ohne Email'
						handleDownloadDoc={handleDownloadDoc}
						handleOpenDoc={handleOpenDoc}
						handlePrint={handlePrint}
						pdfInfo={providerInfo.pdf}
						/>
					: <NoPDFMessage memberType='Helfer' />
				}
				{providerInfo.xml && <div style={{marginTop: '1rem'}}>
					<XMLInfo handleDownloadXML={handleDownloadDoc} xml={providerInfo.xml} accountingType='Helfer' />
				</div>}
			</div>}
		</Paper>
	)
}

const PDFInfos = ({pdfInfo, handleOpenDoc, handleDownloadDoc, handlePrint, accountingType}) =>{
	const btnVariant = 'text'
	const btnColor = 'default'
	return (
		<>
			<Typography color='primary' variant='subtitle1'>{accountingType} PDF</Typography>
			<Button
				startIcon={<IconSelector iconName='Open' />}
				variant={btnVariant}
				color={btnColor}
				size={btnSize}
				onClick={()=>handleOpenDoc(pdfInfo.url)}
				children='Öffnen'
			/>
			<Button
				startIcon={<IconSelector iconName='Save' />}
				variant={btnVariant}
				color={btnColor}
				size={btnSize}
				onClick={()=>handleDownloadDoc(pdfInfo.url, pdfInfo.fileName)} 
				children='Speichern'
			/>
			<Button
				startIcon={<IconSelector iconName='Print' />}
				children='Drucken'
				variant={btnVariant}
				color={btnColor}
				size={btnSize}
				onClick={()=>handlePrint(pdfInfo.url)}

			/>
			<Divider />
		</>
	)
}
const XMLInfo = ({handleDownloadXML, xml, accountingType}) => {
	const btnVariant='text'
	const btnColor='default'
	return (
		<>
			<Typography color='primary' variant='subtitle1'>{accountingType} XML (Sparkasse)</Typography>
			<Button
				startIcon={<IconSelector iconName='Save' />}
				variant={btnVariant}
				color={btnColor}
				size={btnSize}
				onClick={()=>handleDownloadXML(xml.url, xml.fileName)}
				children='Speichern'
			/>
		</>
	)
}

const NoPDFMessage = ({memberType}) =>
	<>
		<Typography color='primary' variant='subtitle1'>{`${memberType} PDF`}</Typography>
		<Typography
			component='div'
			color='secondary'
			variant='button'
		>
			{`Alle Abrechnungen wurden per Email versendet`}
		</Typography>
		<Divider />
	</>




export default FileResults
