import React from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/styles/withStyles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { routes } from 'config/config'
import { useLocation } from 'react-router-dom'
import values from 'lodash/values'
import Dialog from 'Components/PopUps/Dialog'
import LoadingSpinner from 'Components/Loading/Spinner'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
const styles = theme => {
    return { 
        root: {
            marginTop: theme.spacing(2),
            paddingTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
           // border: '1px solid red'
        }, 
    }
}
const DefaultPage = (props) => {
    const { title, children, classes, isLoading } = props
    const location = useLocation()

    let _title
    values(routes.private).forEach(route=>{
        if(location.pathname===route.path) {
            _title=route.title
        }
    })

    return (
      
        <Container component={Paper} id='App-body' className={classes.root} maxWidth="lg">
            <Typography variant="h6" gutterBottom >
                {title || _title}
            </Typography>
            {isLoading.value
                ? <LoadingSpinner title={isLoading.title} />
                : children
            }
            <Dialog data={props.popUp} >{props.popUp.children}</Dialog>
        </Container>
    )
}

const mapStateToProps = state => ({
    popUp: state.app.dialog,
    isLoading: state.app.page.isLoading
})

export default compose(
    withStyles(styles),
    connect(mapStateToProps)
)(DefaultPage)