import { appName } from '../config/config'
import React from 'react';
//import { auth } from '../firebase/firebase'
import { connect } from 'react-redux';
import { compose } from 'redux';
//import { withRouter } from 'react-router-dom';
import { watchUserAuth } from 'firebase/auth/index'
import AppLoading from '../Components/Loading/AppLoading'

//import * as routes from '../constants/routes'
//import Login from '../Login'
//import LoadingPage from '../../Components/Layouts/Pages/LoadingPage'
import moment from 'moment'
import 'moment/locale/de'
moment.localeData('de')


const initializeApp = (settings) => (Component) => {
  class InitializeApp extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
    
      }
    }
    async componentDidMount() {
     //   firebase.initializeApp(config.firebase.config)
     watchUserAuth() 
    
    }
   
  
    render() {
   
      const { user } = this.props

     // console.log('isAuth', isAuth, 'emailVerified', emailVerified)
    return (

      user.isLoading
        ? <AppLoading title={appName} />
        : <Component userRole={user.role}
            isAuth={user.isAuth}
            user={user}
          />
    )
    }
  }

  const mapStateToProps = (state) => ({
    user: state.user,
    isLoading: state.app.isLoading,
    navigation: state.navigation
  });

  return compose(

    connect(mapStateToProps),
  )(InitializeApp);
}

export default initializeApp