const INIT_STATE = {
    app : {
        isLoading: true,
        page: {
            isLoading: {
                value: false,
                title: undefined
            }
        },
        popUp: {
            fullScreen: false,
        },
        dialog: {
            isOpen: false,
            isLoading: false,
            title: '',
            text: '',
            buttons: []
        },
    },
    user: {
        id: null,
        isLoading: true,
        isAuth: false,
        emailVerified: false,
        role: {
            public: true,
        },
        firebase: null  ,
    },
   
    
}

export default INIT_STATE