import { dispatch } from '../index.js'
import { STATISTIC } from 'config/actionTypes'
import { firestore } from '../../firebase/firebase'
import values from 'lodash/values'
import moment from 'moment'
import arrSort from 'array-sort'
import { genBundCities } from 'config/config.js'
const accountingREF = firestore.collection('accounting')
const membersREF = firestore.collection('members')



export const dispatchChartSelection = data =>
    dispatch({type: STATISTIC.CHART_SELECT, payload: data})

const getTotalMembers = async (startYear, startMonth, endYear, endMonth) => {
    const totalMembersSnap = await membersREF
    .where('profile.entryDate', '>=', `${startYear}-${startMonth}-01`)
    .where('profile.entryDate', '<=', `${endYear}-${endMonth}-31`)
    .get()
    const totalMembersResult = [{name: 'Mitglieder gesamt', Mitglieder: totalMembersSnap.size}]
    return totalMembersResult
}

const getMembersGrows = async (startYear, startMonth, endYear, endMonth) => {
    const membersStartCount = await membersREF
    .where('profile.entryDate', '<', `${startYear}-${startMonth}-01`)
    .get()

    const membersGrowsSnap = await membersREF
    .where('profile.entryDate', '>=', `${startYear}-${startMonth}-01`)
    .where('profile.entryDate', '<=', `${endYear}-${endMonth}-31`)
    .orderBy('profile.entryDate')
    .get()
    const memResult = {}
    let count = 0
    let totalMembersCount = 0

    membersGrowsSnap.forEach(memSnap=>{
        count++
        const member = memSnap.data()
        const { entryDate } = member.profile
        const year = entryDate.split('-')[0]
   
        if(count===1) {
            totalMembersCount = membersStartCount.size
        } else {
            totalMembersCount = totalMembersCount+1
        }

        if(memResult[year]) {
            memResult[year] = {name: year, Zuwachs: memResult[year].Zuwachs+1, Mitglieder: totalMembersCount+1}
        } else {
            memResult[year] = {name: year, Zuwachs: 1, Mitglieder: totalMembersCount }

        }
    })
    return values(memResult)
}

const getMembersByTown = async (startYear, startMonth, endYear, endMonth) => {
    
    const membersByTownSnap = await membersREF
    .where('profile.entryDate', '>=', `${startYear}-${startMonth}-01`)
    .where('profile.entryDate', '<=', `${endYear}-${endMonth}-31`)
    .orderBy('profile.entryDate')
    .get()
    const membersByTownResult = {}
  
    membersByTownSnap.forEach(memSnap=>{
        const member = memSnap.data()
        const { city } = member.profile
        if(genBundCities.includes(city)) {
            if(membersByTownResult[city]){
                membersByTownResult[city] = {name: city, Mitglieder: membersByTownResult[city].Mitglieder+1 }
            } else {
                membersByTownResult[city] = {name: city, Mitglieder: 1}
            }
        }
    })

    return arrSort(values(membersByTownResult), 'Mitglieder', {reverse: true})
}

const getTotalPflegeMembers = async (startYear, startMonth, endYear, endMonth ) => {
    const pflegeMembersSnap = await membersREF
    .where('pflegekasse', '==', true)
    .where('profile.entryDate', '>=', `${startYear}-${startMonth}-01`)
    .where('profile.entryDate', '<=', `${endYear}-${endMonth}-31`)
    .orderBy('profile.entryDate')
    .get()
    const totalPflegeMembersResult = [{name: 'Pflegekassen Mitglieder gesamt', Mitglieder: pflegeMembersSnap.size}]


    return totalPflegeMembersResult
}

const getPflegeMembersGrows = async(startYear, startMonth, endYear, endMonth) => {
    const membersStartCount = await membersREF
    .where('pflegekasse', '==', true)
    .where('profile.entryDate', '<', `${startYear}-${startMonth}-01`)
    .get()

    const membersGrowsSnap = await membersREF
    .where('pflegekasse', '==', true)
    .where('profile.entryDate', '>=', `${startYear}-${startMonth}-01`)
    .where('profile.entryDate', '<=', `${endYear}-${endMonth}-31`)
    .orderBy('profile.entryDate')
    .get()
    const memResult = {}
    let count = 0
    let totalMembersCount = 0

    membersGrowsSnap.forEach(memSnap=>{
        count++
        const member = memSnap.data()
        const { entryDate } = member.profile
        const year = entryDate.split('-')[0]
   
        if(count===1) {
            totalMembersCount = membersStartCount.size
        } else {
            totalMembersCount = totalMembersCount+1
        }

        if(memResult[year]) {
            memResult[year] = {name: year, Zuwachs: memResult[year].Zuwachs+1, Mitglieder: totalMembersCount+1}
        } else {
            memResult[year] = {name: year, Zuwachs: 1, Mitglieder: totalMembersCount }

        }
    })
    return values(memResult)
}

const getPflegeMembersByTown = async (startYear, startMonth, endYear, endMonth) => {
    const pflegeMembersByTownSnap = await membersREF
    .where('pflegekasse', '==', true)
    .where('profile.entryDate', '>=', `${startYear}-${startMonth}-01`)
    .where('profile.entryDate', '<=', `${endYear}-${endMonth}-31`)
    .orderBy('profile.entryDate')
    .get()
    const pflegeMembersByTownResult = {}
  
    pflegeMembersByTownSnap.forEach(memSnap=>{
        const member = memSnap.data()
        const { city } = member.profile
        if(genBundCities.includes(city)) {
            if(pflegeMembersByTownResult[city]){
                pflegeMembersByTownResult[city] = {name: city, Mitglieder: pflegeMembersByTownResult[city].Mitglieder+1 }
            } else {
                pflegeMembersByTownResult[city] = {name: city, Mitglieder: 1}
            }
        }
    })

    return arrSort(values(pflegeMembersByTownResult), 'Mitglieder', {reverse: true})

}

const getMembersByRole = async (activeApp, startYear, startMonth, endYear, endMonth ) => {
    let membersByRoleSnap = ''
    const membersByRoleResult = {
        requester: {name: 'Bedarf', Bedarf: 0},
        provider: { name: 'Angebot', Angebot: 0},
        passive: {name: 'Passiv', Passiv: 0},
    }
    if(activeApp==='pflegekasse') {
        delete membersByRoleResult.passive
        membersByRoleSnap = await membersREF
        .where('pflegekasse', '==', true)
        .where('profile.entryDate', '>=', `${startYear}-${startMonth}-01`)
        .where('profile.entryDate', '<=', `${endYear}-${endMonth}-31`)
        .orderBy('profile.entryDate')
        .get()

    } else if(activeApp==='nachbarschaftshilfe') {
        membersByRoleSnap = await membersREF
        .where('profile.entryDate', '>=', `${startYear}-${startMonth}-01`)
        .where('profile.entryDate', '<=', `${endYear}-${endMonth}-31`)
        .orderBy('profile.entryDate')
        .get()
    }
    membersByRoleSnap.forEach(_member=>{
        const member = _member.data()
        const requester = activeApp==='nachbarschaftshilfe'
            ? member.roles.requester
            : member.pflegeRoles.requester
        const provider = activeApp==='nachbarschaftshilfe'
            ? member.roles.provider
            : member.pflegeRoles.provider
        if(requester) {
            membersByRoleResult.requester.Bedarf = membersByRoleResult.requester.Bedarf+1
        }
        if(provider) {
            membersByRoleResult.provider.Angebot = membersByRoleResult.provider.Angebot+1
        }
        if(activeApp==='nachbarschaftshilfe' && !provider && !requester) {
            membersByRoleResult.passive.Passiv = membersByRoleResult.passive.Passiv+1
        }
    })

    return values(membersByRoleResult)
}
const getMembersRolesByTown = async (activeApp, startYear, startMonth, endYear, endMonth) => {
    let membersRoleByTownSnap = ''
    const membersRoleByTownResult = {}
    if(activeApp==='pflegekasse') {
        membersRoleByTownSnap = await membersREF
        .where('pflegekasse', '==', true)
        .where('profile.entryDate', '>=', `${startYear}-${startMonth}-01`)
        .where('profile.entryDate', '<=', `${endYear}-${endMonth}-31`)
        .orderBy('profile.entryDate')
        .get()

    } else if(activeApp==='nachbarschaftshilfe') {
        membersRoleByTownSnap = await membersREF
        .where('profile.entryDate', '>=', `${startYear}-${startMonth}-01`)
        .where('profile.entryDate', '<=', `${endYear}-${endMonth}-31`)
        .orderBy('profile.entryDate')
        .get()
    }

    membersRoleByTownSnap.forEach(_member=>{
        const member = _member.data()
        const city = member.profile.city
        
        if(genBundCities.includes(city)) {
            const { provider, requester } = activeApp==='nachbarschaftshilfe'
                ? member.roles
                : member.pflegeRoles
            if(membersRoleByTownResult[city]) {
                membersRoleByTownResult[city] = {
                    name: city,
                    Angebot: provider ? membersRoleByTownResult[city].Angebot+1 : membersRoleByTownResult[city].Angebot,
                    Bedarf: requester ? membersRoleByTownResult[city].Bedarf+1 : membersRoleByTownResult[city].Bedarf,
                }
            } else {
                membersRoleByTownResult[city] = {
                    name: city,
                    Angebot: provider ? 1 : 0,
                    Bedarf: requester ? 1 : 0
                }

            }
        }
    })

    return arrSort(values(membersRoleByTownResult), 'Bedarf', {reverse: true})
}


// ACOUNTING ----------------------------------------------------------

const getHoursByMonth = async (activeApp, startYear, startMonth, endYear, endMonth ) => {

    const hoursByMonthSnap = await accountingREF.doc(activeApp).collection('requester')
    .where('year', '>=', `${startYear}`)
    .where('year', '<=', `${endYear}`)
    .get()
    const hoursResult = {}
    const isOneYear = startYear===endYear
    hoursByMonthSnap.forEach(_invoice=>{

        const invoice = _invoice.data()
        const { month, year, date } = invoice
        const {hours} = invoice.totals

        //const monthName = moment(`${startYear}-${month}-01`).format('MMM')
        if(month>=startMonth && month<=endMonth) {
            if(hoursResult[month]) {
                hoursResult[month] = {name: isOneYear ? moment(date).format('MMM') : date, month, year, Stunden: hoursResult[month].Stunden+Number(hours.toFixed(0)) }
            } else {
                hoursResult[month] = {name: isOneYear ? moment(date).format('MMM') : date, month, year, Stunden: Number(hours.toFixed(0))}
            }
        }
    })
    // get open Invoice hours as well if pflegekasse is selected
    if(activeApp==='pflegekasse') {
        const openHoursByMonthSnap = await accountingREF.doc(activeApp).collection('institut')
        .where('year', '>=', `${startYear}`)
        .where('year', '<=', `${endYear}`)
        .get()
        openHoursByMonthSnap.forEach(_openInvoice=>{
            const openInvoice = _openInvoice.data()
            const { month, year, date } = openInvoice
            const {hours} = openInvoice.totals
            if(month>=startMonth && month<=endMonth) {
                if(hoursResult[month]) {
                    hoursResult[month] = {name: isOneYear ? moment(date).format('MMM') : date, month, year, Stunden: hoursResult[month].Stunden+Number(hours.toFixed(0)) }
                } else {
                    hoursResult[month] = {name: isOneYear ? moment(date).format('MMM') : date, month, year, Stunden: Number(hours.toFixed(0))}
                }
            }

        })
    }

    
    return arrSort(values(hoursResult), ['year', 'month'])
}

const getHoursByTown = async (activeApp, startYear, startMonth, endYear, endMonth) => {
    const hoursByTownSnap = await accountingREF.doc(activeApp).collection('requester')
    .where('year', '>=', `${startYear}`)
    .where('year', '<=', `${endYear}`)
    .get()
    const hoursByTownResult = {}
 
    hoursByTownSnap.forEach(_invoice=>{
        const invoice = _invoice.data()
        const { month, year, date } = invoice
        const {hours} = invoice.totals
        const { city } = invoice.requester

        //const monthName = moment(`${startYear}-${month}-01`).format('MMM')
        if(month>=startMonth && month<=endMonth) {
            if(hoursByTownResult[city]) {
                hoursByTownResult[city] = {name: city, date, month, year, Stunden: hoursByTownResult[city].Stunden+Number(hours.toFixed(0)) }
            } else {
                hoursByTownResult[city] = {name: city, month, year, Stunden: Number(hours.toFixed(0))}
            }
        }
    })
    if(activeApp==='pflegekasse') {
        const openHoursByTownSnap = await accountingREF.doc(activeApp).collection('institut')
        .where('year', '>=', `${startYear}`)
        .where('year', '<=', `${endYear}`)
        .get()
        openHoursByTownSnap.forEach(_openInvoice=>{
            const openInvoice = _openInvoice.data()
            const { month, year, date } = openInvoice
            const {hours} = openInvoice.totals
            const { city } = openInvoice.requester

            if(month>=startMonth && month<=endMonth) {
                if(hoursByTownResult[city]) {
                    hoursByTownResult[city] = {name: city, date, month, year, Stunden: hoursByTownResult[city].Stunden+Number(hours.toFixed(0)) }
                } else {
                    hoursByTownResult[city] = {name: city, month, year, Stunden: Number(hours.toFixed(0))}
                }
            }
            
        })
  

    }
    return arrSort(values(hoursByTownResult), 'Stunden', {reverse: true})
}

const getTopTenHours = async (activeApp, startYear, startMonth, endYear, endMonth, memberType) => {
    const topTenSnap = await accountingREF.doc(activeApp).collection(memberType)
        .where('date', '>=', `${startYear}-${startMonth}`)
        .where('date', '<=', `${endYear}-${endMonth}`)
        .get()
    
    const result = {}
    
    topTenSnap.forEach(_invoice=>{
        const invoice = _invoice.data()
       if(result[invoice[memberType].name]) {
        result[invoice[memberType].name] = {name:invoice[memberType].name, Stunden: Number(invoice.totals.hours.toFixed(0))+result[invoice[memberType].name].Stunden}
       } else {
            result[invoice[memberType].name] = {name:invoice[memberType].name, Stunden: Number(invoice.totals.hours.toFixed(0))}
       }
    })
    if(activeApp==='pflegekasse' && memberType==='requester ') {
        const topTenOpenSnap = await accountingREF.doc(activeApp).collection('institut')
        .where('date', '>=', `${startYear}-${startMonth}`)
        .where('date', '<=', `${endYear}-${endMonth}`)
        .get()
        
        topTenOpenSnap.forEach(_openInvoice=>{
            const openInvoice = _openInvoice.data()
            if(result[openInvoice[memberType].name]) {
                result[openInvoice[memberType].name] = {name:openInvoice[memberType].name, Stunden: Number(openInvoice.totals.hours.toFixed(0))+result[openInvoice[memberType].name].Stunden}
            } else {
                result[openInvoice[memberType].name] = {name:openInvoice[memberType].name, Stunden: Number(openInvoice.totals.hours.toFixed(0))} 
            }
        })
        
    }
    const _result = []
    const valuesArr = arrSort(values(result), 'Stunden', {reverse: true})
    let counter = 0
    valuesArr.forEach((member)=>{
        counter++
        if(counter<=10) {
            _result.push(member)
        }
   
        
    })
    return _result
    
}
const getInvoicesByTasks = async (activeApp, startYear, startMonth, endYear, endMonth) => {
    const invoicesByTaskSnap = await accountingREF.doc(activeApp).collection('requester')
    .where('date', '>=', `${startYear}-${startMonth}`)
    .where('date', '<=', `${endYear}-${endMonth}`)
    .get()
    const hoursByTaksResult = {}
    invoicesByTaskSnap.forEach(_invoice=>{
        const invoice = _invoice.data()
        
        invoice.services.forEach(service => {
            const { hours, taskId} = service
            const { name } = service.task
            const shortedName = name.length>24
                ? name.slice(0, 23)
                : name
            if(hoursByTaksResult[taskId]) {
                hoursByTaksResult[taskId] = { id: taskId, name: shortedName, Stunden: hoursByTaksResult[taskId].Stunden+Number(hours.toFixed(0))}
            } else {
                hoursByTaksResult[taskId] = { id: taskId, name: shortedName, Stunden: Number(hours.toFixed(0)) }
            }
        })
    })
    return arrSort(values(hoursByTaksResult), 'Stunden', { reverse: true})
}
export const getStatData = async ({ activeApp, chartCategory, chartType, startYear, endYear, startMonth, endMonth}) => {
    dispatch({type: STATISTIC.IS_LOADING, payload: true})
    const startMonthTitle = moment(`2024-${startMonth}-01`).format('MMM')
    const endMonthTitle = moment(`2024-${endMonth}-01`).format('MMM')
    if(activeApp==='nachbarschaftshilfe') { 
        if(chartCategory==='members') {
            const totalMembersResult = {
                subtitle: `${startMonthTitle} ${startYear} bis ${endMonthTitle} ${endYear}`,
            }
            if(chartType==='totalMembers') {
                totalMembersResult.title = 'Mitglieder gesamt'
                totalMembersResult.dataKeys = ['Mitglieder']
                totalMembersResult.stacked = false
                totalMembersResult.xAxis = {hide: true}
                totalMembersResult.values = await getTotalMembers(startYear, startMonth, endYear, endMonth)
            } else if(chartType==='membersGrowth') {
                totalMembersResult.title = 'Mitglieder Zuwachs'
                totalMembersResult.dataKeys = ['Mitglieder', 'Zuwachs']
                totalMembersResult.stacked = true
                totalMembersResult.values = await getMembersGrows(startYear, startMonth, endYear, endMonth) 
            } else if(chartType==='membersByTown') {
                totalMembersResult.title = 'Mitglieder nach Ort'
                totalMembersResult.xAxis = {angle: 90, height: 140, tickMargin: 60}
                totalMembersResult.dataKeys = ['Mitglieder']
                totalMembersResult.stacked = false
                totalMembersResult.values = await getMembersByTown(startYear, startMonth, endYear, endMonth)
            } else if(chartType==='membersByRole') {
                totalMembersResult.title = 'Mitglieder nach Rolle'
                totalMembersResult.dataKeys = ['Bedarf', 'Angebot', 'Passiv']
                totalMembersResult.stacked = false
                totalMembersResult.values = await getMembersByRole(activeApp, startYear, startMonth, endYear, endMonth) 
            } else if(chartType==='rolesByTown') {
                totalMembersResult.title = 'Mitglieder Rolle nach Ort'
                totalMembersResult.dataKeys = ['Bedarf', 'Angebot']
                totalMembersResult.stacked = false
                totalMembersResult.xAxis = { angle: 90, height: 140, tickMargin: 60}
                totalMembersResult.values = await getMembersRolesByTown(activeApp, startYear, startMonth, endYear, endMonth) 

            }


            return dispatch({type: STATISTIC.GET_SELECTED_DATA, payload: totalMembersResult})
        } else if(chartCategory==='accounting') {
            const totalAccountingResult = {
                subtitle: `${startMonthTitle} ${startYear} bis ${endMonthTitle} ${endYear}`,
            }
            if(chartType==='hoursByMonth') {
                totalAccountingResult.title = 'Stunden pro Monat'
                totalAccountingResult.dataKeys = ['Stunden']
                totalAccountingResult.stacked = false
                totalAccountingResult.multixAxis = startYear!==endYear
                totalAccountingResult.values = await getHoursByMonth(activeApp, startYear, startMonth, endYear, endMonth)
            }
            if(chartType==='hoursByTown') {
                totalAccountingResult.title = 'Stunden nach Ort'
                totalAccountingResult.dataKeys = ['Stunden']
                totalAccountingResult.xAxis = {angle: 90, height: 140, tickMargin: 60}
                totalAccountingResult.multixAxis = false
                totalAccountingResult.stacked = false
                totalAccountingResult.values = await getHoursByTown(activeApp, startYear, startMonth, endYear, endMonth)
            }
            if(chartType==='topTenProvider') {
                totalAccountingResult.title = 'Top 10 Helfer geleistete Stunden'
                totalAccountingResult.dataKeys = ['Stunden']
                totalAccountingResult.xAxis = {angle: 90, height: 140, tickMargin: 60}
                totalAccountingResult.multixAxis = false
                totalAccountingResult.stacked = false
                totalAccountingResult.values = await getTopTenHours(activeApp, startYear, startMonth, endYear, endMonth, 'provider')
            }
            if(chartType==='topTenRequester') {
                totalAccountingResult.title = 'Top 10 Mitglieder Bedarf Stunden'
                totalAccountingResult.dataKeys = ['Stunden']
                totalAccountingResult.xAxis = {angle: 90, height: 140, tickMargin: 60}
                totalAccountingResult.multixAxis = false
                totalAccountingResult.stacked = false
                totalAccountingResult.values = await getTopTenHours(activeApp, startYear, startMonth, endYear, endMonth, 'requester')
            }
            if(chartType==='hoursByTask') {
                totalAccountingResult.title = 'Stunden nach Tätigkeiten'
                totalAccountingResult.dataKeys = ['Stunden']
                totalAccountingResult.xAxis = {angle: 90, height: 185, tickMargin: 85, orientation: 'bottom'}
                totalAccountingResult.multixAxis = false
                totalAccountingResult.stacked = false
                totalAccountingResult.values = await getInvoicesByTasks(activeApp, startYear, startMonth, endYear, endMonth, 'requester')
              
            }
            return dispatch({type: STATISTIC.GET_SELECTED_DATA, payload: totalAccountingResult})
        }
    }  else if(activeApp==='pflegekasse') {
        if(chartCategory==='members') {
            const totalPflegeMembersResult = {
                subtitle: `${startMonthTitle} ${startYear} bis ${endMonthTitle} ${endYear}`,
            }
            if(chartType==='totalMembers') {
             
                totalPflegeMembersResult.title = 'Pflegekassen Mitglieder'
                totalPflegeMembersResult.stacked = false
                totalPflegeMembersResult.xAxis = {hide: true}
                totalPflegeMembersResult.dataKeys = ['Mitglieder']
                totalPflegeMembersResult.values = await getTotalPflegeMembers(startYear, startMonth, endYear, endMonth)
            } else if(chartType==='membersGrowth') {
                totalPflegeMembersResult.title = 'Pflegekassen Mitglieder Zuwachs'
                totalPflegeMembersResult.dataKeys = ['Mitglieder', 'Zuwachs']
                totalPflegeMembersResult.stacked = true
                totalPflegeMembersResult.values = await getPflegeMembersGrows(startYear, startMonth, endYear, endMonth) 
            } else if(chartType==='membersByTown') {
                totalPflegeMembersResult.title = 'Pflegekassen Mitglieder nach Ort'
                totalPflegeMembersResult.xAxis = {angle: 90, height: 140, tickMargin: 60}
                totalPflegeMembersResult.dataKeys = ['Mitglieder']
                totalPflegeMembersResult.stacked = false
                totalPflegeMembersResult.values = await getPflegeMembersByTown(startYear, startMonth, endYear, endMonth)
            } else if(chartType==='membersByRole') {
                totalPflegeMembersResult.title = 'Pflegekassen Mitglieder nach Rolle'
                totalPflegeMembersResult.dataKeys = ['Bedarf', 'Angebot']
                totalPflegeMembersResult.stacked = false
                totalPflegeMembersResult.xAxis = {hide: true}
                totalPflegeMembersResult.values = await getMembersByRole(activeApp, startYear, startMonth, endYear, endMonth) 
            }else if(chartType==='rolesByTown') {
                totalPflegeMembersResult.title = 'Pflegekassen Mitglieder Rolle nach Ort'
                totalPflegeMembersResult.dataKeys = ['Bedarf', 'Angebot']
                totalPflegeMembersResult.stacked = false
                totalPflegeMembersResult.xAxis = { angle: 90, height: 140, tickMargin: 60}
                totalPflegeMembersResult.values = await getMembersRolesByTown(activeApp, startYear, startMonth, endYear, endMonth) 

            }
        //    const pflegeMembersResult = await getPflegeMembersResult(startYear, startMonth, endMonth, )
            return dispatch({type: STATISTIC.GET_SELECTED_DATA, payload: totalPflegeMembersResult})
        } else {
            const totalPflegeAccountingResult = {
                subtitle: `${startMonthTitle} ${startYear} bis ${endMonthTitle} ${endYear}`,
            }
        
            if(chartType==='hoursByMonth') {
                totalPflegeAccountingResult.title = 'Pflegekassen Abrechnung - Stunden pro Monat'
                totalPflegeAccountingResult.dataKeys = ['Stunden']
                totalPflegeAccountingResult.stacked = false
                totalPflegeAccountingResult.multixAxis = startYear!==endYear
                totalPflegeAccountingResult.values = await getHoursByMonth(activeApp, startYear, startMonth, endYear, endMonth)
            }
            if(chartType==='hoursByTown') {
                totalPflegeAccountingResult.title = 'Pflegekassen Abrechnung - Stunden nach Ort'
                totalPflegeAccountingResult.dataKeys = ['Stunden']
                totalPflegeAccountingResult.stacked = false
                totalPflegeAccountingResult.multixAxis = false
                totalPflegeAccountingResult.xAxis = { angle: 90, height: 140, tickMargin: 60}
                totalPflegeAccountingResult.values = await getHoursByTown(activeApp, startYear, startMonth, endYear, endMonth)

            }
            if(chartType==='topTenProvider') {
                totalPflegeAccountingResult.title = 'Top 10 Helfer geleistete Stunden Pflegekasse'
                totalPflegeAccountingResult.dataKeys = ['Stunden']
                totalPflegeAccountingResult.xAxis = {angle: 90, height: 140, tickMargin: 60}
                totalPflegeAccountingResult.multixAxis = false
                totalPflegeAccountingResult.stacked = false
                totalPflegeAccountingResult.values = await getTopTenHours(activeApp, startYear, startMonth, endYear, endMonth, 'provider')
            }
            if(chartType==='topTenRequester') {
                totalPflegeAccountingResult.title = 'Top 10 Mitglieder Bedarf Stunden Pflegekasse'
                totalPflegeAccountingResult.dataKeys = ['Stunden']
                totalPflegeAccountingResult.xAxis = {angle: 90, height: 140, tickMargin: 60}
                totalPflegeAccountingResult.multixAxis = false
                totalPflegeAccountingResult.stacked = false
                totalPflegeAccountingResult.values = await getTopTenHours(activeApp, startYear, startMonth, endYear, endMonth, 'requester')
            }
            if(chartType==='hoursByTask') {
                totalPflegeAccountingResult.title = 'Stunden nach Pflegekassen Tätigkeiten'
                totalPflegeAccountingResult.dataKeys = ['Stunden']
                totalPflegeAccountingResult.xAxis = {angle: 90, height: 185, tickMargin: 85, orientation: 'bottom'}
                totalPflegeAccountingResult.multixAxis = false
                totalPflegeAccountingResult.stacked = false
                totalPflegeAccountingResult.values = await getInvoicesByTasks(activeApp, startYear, startMonth, endYear, endMonth, 'requester')
              
            }
            
            return dispatch({type: STATISTIC.GET_SELECTED_DATA, payload: totalPflegeAccountingResult})


        }
    }
}

