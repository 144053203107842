import React from 'react'
import AccountBalance from '@material-ui/icons/AccountBalance'
import AccountWallet from '@material-ui/icons/AccountBalanceWallet';
import PostAdd from '@material-ui/icons/PostAdd'
import Business from '@material-ui/icons/Business'
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import Accessibility from '@material-ui/icons/Accessibility'
import Description from '@material-ui/icons/Description'
import BarChart from '@material-ui/icons/BarChart'
import ImportExport from '@material-ui/icons/ImportExport'
import Help from '@material-ui/icons/Help'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import Loyalty from '@material-ui/icons/Loyalty'
import Open from '@material-ui/icons/OpenInBrowser'
import Save from '@material-ui/icons/Save'
import Print from '@material-ui/icons/Print'
import AddCircle from '@material-ui/icons/AddCircle'
import PersonOutlined from '@material-ui/icons/PersonOutline'
import Person from '@material-ui/icons/Person'
import UnreadMailbox from '@material-ui/icons/MarkunreadMailbox'
import AddToQueue from '@material-ui/icons/AddToQueue'
import Phone from '@material-ui/icons/Phone'
import MobilePhone from '@material-ui/icons/Smartphone'
import Mail from '@material-ui/icons/Mail'
import Birthday from '@material-ui/icons/Cake'
import GeoLocation from '@material-ui/icons/LocationOn'
import Search from '@material-ui/icons/Search'
import City from '@material-ui/icons/LocationCity'
import Close from '@material-ui/icons/Close'
import Assignment from '@material-ui/icons/AssignmentInd'
import Today from '@material-ui/icons/Today'
import EuroSymbol from '@material-ui/icons/EuroSymbol'
import Settings from '@material-ui/icons/Settings'
import PhoneMsg from '@material-ui/icons/PermPhoneMsg'
import People from '@material-ui/icons/EmojiPeople'
import Event from '@material-ui/icons/Event'
import IBAN from '@material-ui/icons/LocalAtm'
import Money from '@material-ui/icons/Money'
import Storage from '@material-ui/icons/Storage'
import Receipt from '@material-ui/icons/Receipt'
import Nav from '@material-ui/icons/Navigation'
import Apps from '@material-ui/icons/Apps'
import has from 'lodash/has'
export const iconList = {
	Receipt,
	Storage,
	Event,
	IBAN,
	Money,
	AccountBalance,
	AccountWallet,
	Search,
	PostAdd,
	Business,
	BusinessCenter,
	Accessibility,
	Description,
	BarChart,
	ImportExport,
	Help,
	Loyalty,
	Open,
	Save,
	Print,
	AddCircle,
	PersonOutlined,
	Person,
	UnreadMailbox,
	AddToQueue,
	Phone,
	PhoneMsg,
	People,
	MobilePhone,
	Mail,
	Birthday,
	GeoLocation,
	City,
	Close,
	Assignment,
	Today,
	EuroSymbol,
	Settings,
	Nav,
	Apps,
}

const DefaultIcon = BeenhereIcon

const IconSelector = ({iconName, options}) =>{

	const isValideIconName = has(iconList, iconName)
	const SelectedIcon = iconList[iconName]
	if(!isValideIconName)
		return <DefaultIcon color='primary' />
	return  <SelectedIcon {...options} />
}

export default IconSelector