import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import transform from 'lodash/transform'
import isArray from 'lodash/isArray'
    
export function getObjectDiff(obj1, obj2) {
    const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key);
        } else if (isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
        }
        return result;
    }, Object.keys(obj2));

    return diff;
}

export function isEquivalent(a, b) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
  
    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
       return false;
    }
  
    for (var i = 0; i < aProps.length; i++) {
       var propName = aProps[i];
  
       // If values of same property are not equal,
       // objects are not equivalent
       if (a[propName] !== b[propName]) {
          return false;
       }
    }
  
    // If we made it this far, objects
    // are considered equivalent
    return true;
  }

export function difference(origObj, newObj) {
    function changes(newObj, origObj) {
      let arrayIndexCounter = 0
      return transform(newObj, function (result, value, key) {
        if (!isEqual(value, origObj[key])) {
          let resultKey = isArray(origObj) ? arrayIndexCounter++ : key
          result[resultKey] = (isObject(value) && isObject(origObj[key])) ? changes(value, origObj[key]) : value
        }
      })
    }
    return changes(newObj, origObj)
  }
const exports = ({getObjectDiff, isEquivalent, difference})
export default exports