import values from 'lodash/values'
import moment from 'moment'
import { firestore, db } from '../../../firebase/firebase.js'
import { map } from 'modern-async'
const accountingRef = firestore.collection('accounting').doc('nachbarschaftshilfe')
const servicesRef = accountingRef.collection('services')
const accountingSetRef = firestore.collection('accountingSet').doc('nachbarschaftshilfe')
const deleteFirestoreServices = true

const generateInvoiceData = async ({ servicesByRequester, servicesByProvider, tasks, invoiceIds, editor, providerDone}) =>{

	const timeStamp = moment().format()
	editor.timeStamp = timeStamp
	const fsBatch = firestore.batch()
	const COUNTER = {
		requester: { withMail: 0, withoutMail: 0 },
		provider: { withMail: 0, withoutMail: 0}
	}
	
	const REQUESTER = await map(servicesByRequester, async (requesterServices, i) =>{
		let counter = i+1
		const newInvoiceId = invoiceIds.requesterId+counter
		const  requesterSnap = await requesterServices[0].requester.ref.get()
	
		const requester = await prepMemberData(requesterSnap.data())
		const accountingType = 'requester'
		// Count requester with Emails
		
		if(requester.email.length>=6) {
			COUNTER.requester.withMail++
		} else {
			COUNTER.requester.withoutMail++
		}
		const recipient = prepRecipient(requester)
		const {month, year } = requesterServices[0]
		const date = `${year}-${month}`
		const  SERVICES_DATA = generateServiceData({
			services: requesterServices, 
			tasks,
			accountingType,
			newInvoiceId,
			editor
		})
		const text = { paymentInfo: `Der Betrag in Höhe von ${SERVICES_DATA.totals.totalPayment.toFixed(2)} Euro wird von ihrem Konto abgebucht.` }
		const INVOICE = {
			...SERVICES_DATA,
			providerDone,
			recipient,
			requester,
			year, month, date, text
		}
		return INVOICE
	})
	
	const requesterSetRef = accountingSetRef.collection('requester').doc()
	const mailCounterRequester = {
		total: servicesByRequester.length,
		withoutMail: COUNTER.requester.withoutMail,
		withMail: COUNTER.requester.withMail,
	}
	const requesterInvoiceData = {
		mailCounter: mailCounterRequester,
		values: REQUESTER,
		month: REQUESTER[0].month,
		year: REQUESTER[0].year
	}
	requesterSetRef.set(requesterInvoiceData)
	//// Acconting Provider
	const PROVIDER = await map(servicesByProvider, async (providerServices, i) =>{
		const  counter = i+1
		const newInvoiceId = invoiceIds.providerId+counter
		const providerSnap = await providerServices[0].provider.ref.get()
		const provider = await prepMemberData(providerSnap.data())
		
		const accountingType = 'provider'
		const recipient = prepRecipient(provider)

		// Count requester with Emails
		if(provider.email.length>=6) {
			COUNTER.provider.withMail++
		} else {
			COUNTER.provider.withoutMail++
		}
	
		const {month, year, donate } = providerServices[providerServices.length-1]
		const  SERVICES_DATA =  generateServiceData({
			services:providerServices,
			// FOOBAR ? can be deleted deleteFirestoreServices, 
			tasks,
			accountingType,
			newInvoiceId,
			editor
		})
		const donateText = `Vielen Dank für Ihre Spende in Höhe von ${SERVICES_DATA.totals.totalPayment} Euro.`
		const nonDonateText = `Der Betrag in Höhe von ${SERVICES_DATA.totals.totalPayment.toFixed(2)} Euro wird ihrem Konto gutgeschrieben.`
		const text = {
			paymentInfo: donate ? donateText : nonDonateText 
		}
		const date = `${year}-${month}`
		const INVOICE = {
			...SERVICES_DATA,
			providerDone: true,
			recipient,
			provider,
			donate, year, month, text, date
		}

		if(deleteFirestoreServices) {
			providerServices.forEach(service=>{
				const serviceRef = servicesRef.doc(service.id)
				fsBatch.delete(serviceRef)						
			})
		}
		return INVOICE
	})

	const providerSetRef = accountingSetRef.collection('provider').doc()
	const providerInvoiceData = {
		values: PROVIDER,
		year: PROVIDER[0].year,
		month: PROVIDER[0].month
	}
	const mailCounterProvider = {
		total: servicesByProvider.length,
		withoutMail: COUNTER.provider.withoutMail,
		withMail: COUNTER.provider.withMail,
	}
	const fbUpdates = {}
	fbUpdates['/invoiceResults/nachbarschaftshilfe/requesterInfo/email'] = mailCounterRequester
	fbUpdates['/invoiceResults/nachbarschaftshilfe/providerInfo/email'] = mailCounterProvider

	fsBatch.set(providerSetRef, providerInvoiceData)
	fsBatch.commit()
	db.ref().update(fbUpdates)
	return ({REQUESTER, PROVIDER})
}

const prepMemberData = async member => {

	const { userName, id, bankAccount, profile: {name, email, street, postCode, city}} = member
	const bankAccountRef = await bankAccount.get()
	const _bankAccount = bankAccountRef.data()

	return({
		id, userName, name, email, street, postCode, city,
		bankAccount: _bankAccount,
		ref: firestore.collection('members').doc(id)
	})
}
const prepRecipient = ({ name, userName, street, postCode, city}) => {
	const _name = name || userName || 'NO NAME DEFINED'
	return ({name: _name, street, postCode, city, })
}

const generateServiceData = ({services, tasks, accountingType, newInvoiceId, editor }) =>{
	
	//console.log(services, tasks, accountingType, newInvoiceId, editor  )
	const currentInvoice = { id: 'Fehler_keine_id', accountingType, services: [], totals: {} }   
	const TOTALS = { hours: 0, km: 0, hoursPayment: 0, kmPayment: 0, totalPayment: 0 }

	const prepServices = services=>values(services).map( service => {
		const prepedService = prepService(service, tasks, accountingType)
		TOTALS.hours = TOTALS.hours + service.hours
		TOTALS.km = TOTALS.km + Number(service.km)
		TOTALS.hoursPayment = TOTALS.hoursPayment + prepedService.totals.hoursPayment
		TOTALS.kmPayment = TOTALS.kmPayment + prepedService.totals.kmPayment
		TOTALS.totalPayment = TOTALS.totalPayment + prepedService.totals.totalPayment
		return prepedService
	})
	const prepedServices = prepServices(services)
	currentInvoice.totals = TOTALS
	currentInvoice.services = prepedServices
	currentInvoice.editors = accountingType==='provider' ? {provider:editor} : {requester: editor}
	currentInvoice.id = accountingType==='institut'
		? `P-${newInvoiceId}`
		: accountingType==='provider'
			? `A-${newInvoiceId}`
			: `B-${newInvoiceId}`
	return currentInvoice
}

const prepService = ( service, tasks, accountingType ) => {

    const task = tasks[service.taskId]

	// Check km payment is km * kmRate or km flat rate (true, false)
    let	kmPayment = Number(service.km)*Number(task.kmRate)
    let hoursPayment = 0
    let totalPayment = 0
    let hourlyRate = 0

    if(accountingType==='provider') {
        hoursPayment = Number((task.hourlyRate-task.genbundRate))*Number(service.hours)
        totalPayment = hoursPayment+kmPayment
        hourlyRate = task.hourlyRate-task.genbundRate
    } else {
        hoursPayment = task.hourlyRate*service.hours
        totalPayment = hoursPayment+kmPayment
        hourlyRate = task.hourlyRate
    }

	hoursPayment = Number(hoursPayment.toFixed(2))
	kmPayment = Number(kmPayment.toFixed(2))
	totalPayment = Number(totalPayment.toFixed(2))

    //delete service.taskId
    //delete service.requesterId

    return {
        ...service,
        task,
        totals: {
			hoursPayment,
			kmPayment,
        	totalPayment,
        	hourlyRate
		}
    }
}
/*
function isNumber(n){
    return Number(n)=== n
}
*/
export default generateInvoiceData