import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import StornoResult from './StornoResult'
import { dialog } from 'store/actions/dialogActions'
import { db } from '../../../firebase'
//import moment from 'moment'0
//import values from 'lodash/values'

//const accountingREF = firestore.collection('accounting').doc('nachbarschaftshilfe')
const INIT_STATE = {
    searchedInvoiceId: undefined,
    providerInvoices: undefined
}
class InvoiceStorno extends PureComponent {
  state = { ...INIT_STATE }

  hanldeSearchInvoice = (e) => {
    e.preventDefault()
    this.setState({searchedInvoiceId: e.target[0].value})
  }
  handleCloseInvoiceStorno = () => {
    dialog('reset')
    return this.setState({...INIT_STATE})
  }
  handleStorno = (data) => {
 
    const handleStorno = () => {
 
      const requester = {
        id: data.requester.id,
        fileURL: data.requester.pdfFiles.requester,
        fileName: data.requester.pdfFiles.fileName
      }
      const providers = data.provider.map(inv=>({
        id: inv.id,
        fileURL: inv.pdfFiles.provider,
        fileName: inv.pdfFiles.fileName
      }))
      db.ref('/invoiceStorno/nachbarschaftshilfe').push({
        requester,
        providers
      })
      /*
      console.log(this.state.providerInvoices)
      const fsBatch = firestore.batch()
      const requesterInvoiceREF = accountingREF.collection('requester').doc(this.state.searchedInvoiceId)
      const canceled = {
        timestamp: moment().format(),
        editor: {id: this.props.user.id, name: this.props.user.userName}
      }
      fsBatch.update(requesterInvoiceREF, canceled)
      this.state.providerInvoices.forEach(providerInvoice=> {
        const invoiceREF = accountingREF.collection('provider').doc(providerInvoice.id)
        fsBatch.update(invoiceREF, canceled)
      })
      fsBatch.commit()
      */
     // this.setState({...INIT_STATE})
      return dialog({
        isOpen: true,
        title: 'Abrechnung storniert',
        text: [`Abrechnung ${this.state.searchedInvoiceId} wurde storniert!`],
        buttons: [
          { title: 'OK', onClick:this.handleCloseInvoiceStorno}
        ]
      })
    }

    dialog({
      isOpen: Boolean(this.state.searchedInvoiceId),
      title: 'Abrechnungen Stornieren',
      icon: 'delete',
      text: ['Wollen Sie die Abrechnungen wirklich stornieren?'],
      buttons: [
        {title: 'Stornieren', color:'secondary', onClick:handleStorno},
        {title: 'Abbrechen', onClick:()=>dialog('reset')}
      ]
    })
  }
  handleSetProviderInvoices = (data) => {
    this.setState({providerInvoices: data})
  }
  handleStornoResultClose = () => {
    return this.setState({...INIT_STATE})
  }
  render() {
    
    return (
      <div style={{marginTop: '0.5rem'}}>
        <form onSubmit={this.hanldeSearchInvoice}>
          <TextField
            color='primary'
            style={{marginRight: '8px'}}
            placeholder='Abr.-Nr. eingeben'
          />
          <Button type='submit' size='small' variant='outlined' color='primary'>Abrechnung Suchen</Button>
        </form>
        {this.state.searchedInvoiceId && <StornoResult
          invoiceId={this.state.searchedInvoiceId}
          stornoCallback={this.handleStorno}
          onCloseCallback={this.handleStornoResultClose}
          setProviderInvoicesCallback={this.handleSetProviderInvoices}
        />}
      </div>
    )
  }
}


export default connect((state) => {
  return ({   
      user: state.user,
      //	groupedByYear: groupBy(state.firestore.ordered.services, 'year'),
      //tasks: state.firestore.data.tasks
  })}
)
(InvoiceStorno)


