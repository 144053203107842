import React from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'


const useStyles = makeStyles(theme => ({
	paper: {
	  padding: theme.spacing(1),
	  backgroundColor: theme.palette.common.white,
	},
	title: {
	  
	},
	subtitel: {

	},
	divider: {
	 // marginBottom: theme.spacing(1)
	},
	alertBackground: {
		padding: theme.spacing(1),
		backgroundColor: theme.palette.secondary.dark,
		
	}
}))

const defaultProps =  {
	paper: {},
	title: {
		variant: 'h6',
		color: 'primary',
	},
	divider: {},
	bodyWrapper: {},
	subtitle: {
		align: 'center',
		variant: 'subtitle2',
		color: 'primary',
	}
  }

const FormWrapper = ({ title, subtitle, subtitleProps, children, compProps }) => {
	const classes = useStyles()
	const _props = {...defaultProps, ...compProps}

	let _subtitleProps = {}
	if(subtitleProps)
		_subtitleProps = subtitleProps
	return (
	  <Paper className={classes.paper} {..._props.paper} >
		<Typography className={classes.title} children={title}  {..._props.title} />
		{subtitle && <Typography className={classes.subtitle} children={subtitle} {..._subtitleProps} />}
		<Divider className={classes.divider} {..._props.divider} />
			<div className={classes.bodyWrapper} {..._props.bodyWrapper}>
		  		{children}
		  	</div>

	</Paper>
	)
  }



  export default FormWrapper


