import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/firestore'
import "firebase/functions";
import firebaseConfig from '../config/firebase.cfg'
//import statisticData from "../imports/statistics-data.json"

//firebase.auth().languageCode = 'de'
/*
const email = 'prinz2@mail.de'
const password='susi.123'
  // Get a reference to the database service
  */

 export const rrfConfig = {
  userProfile: 'users'
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}
const useEmulators = false
// FOOBAR OLD TO PROD
firebase.initializeApp(useEmulators ? firebaseConfig.EMU : firebaseConfig.PROD)
console.log(firebase.app().options.projectId)


//firebase.functions().useEmulator("http://localhost:5010")
/*
FIREBASE ADMIN SETTINGS 
if(useEmulators) {
  // firebase.auth().useEmulator("http://localhost:5099")
   firebase.auth().useEmulator("http://localhost:9099")
   firebase.firestore().useEmulator("localhost", 8080)
   firebase.database().useEmulator('localhost', 9000)
   firebase.storage().useEmulator("localhost", 9199)
   firebase.functions().useEmulator("localhost", 5001)
 }
*/
 if(useEmulators) {
 // firebase.auth().useEmulator("http://localhost:5099")
  firebase.auth().useEmulator("http://localhost:5099")
  firebase.firestore().useEmulator("localhost", 5020)
  firebase.database().useEmulator('localhost', 5030)
  firebase.storage().useEmulator("localhost", 9199)
  firebase.functions().useEmulator("localhost", 5010)
}

const db = firebase.database()
const auth = firebase.auth()
const storage = firebase.storage()
const firestore = firebase.firestore()
//const functions = firebase.functions()
//console.log(process.env)
/*
const setData = () => {
  const ref = db.ref('/statistics')
  console.log(statisticData)
}
*/
/*
const m = async () => {
  const t = firestore.collection('members').doc('0MHzwmRebw6TDmTYpmfv')
  const l = await t.get()
  console.log('l', l.data())
  const ml = firestore.batch()
}
m()




const fireStoreTest = async () => {
  const t = firestore.collection('counters').doc('accounting')
  const n = await t.get()
  console.log(n.data())
}
fireStoreTest()
*/

/*
const mkdata = async () => {
  const ref = await db.ref('/test/39393939')
const test = await ref.set({nase: 'test'})
console.log(test)
}
mkdata()
*/
// Makes offline cache available 
/*
 firestore.enablePersistence()
  .catch(function(err) {
      if (err.code === 'failed-precondition') {
          console.log('OFFLINE ERROR FIRESTORE')
      } else if (err.code === 'unimplemented') {
          console.log('OFFLINE - WIRD VOM BROWSER NICHT SUPPORTET')
      }
  });
  */
// Subsequent queries will use persistence, if it was enabled successfully

//const functions = firebase.functions()


export {
  db, auth,
  firestore,
  storage,
  
}
export default firebase


