import React from 'react'
import { FormGroup, Button, TextField, Typography, Divider, Grid  } from '@material-ui/core'
import { connect } from 'react-redux'
import AddIcon from '@material-ui/icons/AttachFile'
import fileIconSelector from 'static/fileIcons'
import arrSort from 'array-sort'
import { dispatchNewFileBlob, dispatchFileInfo, dispatchUpdateFileName } from 'store/actions/fileStorageActions'
import moment from 'moment'
import { v4 as uuid } from 'uuid';
const FileUploadForm = ({fileStorage}) => {
  const {isEditMode} = fileStorage
  const handleUploadFile = e => {
    const fileBlob = e.target.files[0]
    const oldFileName = isEditMode ? `${fileStorage.fileName}.${fileStorage.ext}` : null
    return dispatchNewFileBlob(fileBlob, oldFileName)
  }

  const handleFileName = (value) => {
    const cleanValue = value.replace(/[.$%&,/'*?=()$§!:;<>|"'}{]/g, '')
    return dispatchUpdateFileName(cleanValue)
  }
  const sizeProps = fileStorage.size>1000 ? {color: 'error', children: `Dateigröße: ${fileStorage.size/1000} MB`} : {color: 'primary', children: `Dateigröße: ${fileStorage.size} KB`}
  return (
    <div style={{width: '100%'}}>
      <FormGroup>
        <div>
      <div style={{textAlign: 'center', marginBottom: '0.5rem'}}>
          <img  height={60} src={fileIconSelector(fileStorage.ext)} alt='file-img-icon' />
          <Typography align='center' variant='body2' {...sizeProps} />
      </div>
      </div>
        <FileInput fileUpload={handleUploadFile}/>
        <TextField
          disabled={fileStorage.blob.length===0}
          color='primary'
          fullWidth
          size='small'
          label='Dateiname'
          value={fileStorage.fileName}
          onChange={e=>{handleFileName(e.target.value)}}
         />
         <TextField
          multiline
          minRows={2}
          maxRows={2}
          size='small'
          label='Datei Info'
          value={fileStorage.info}
          onChange={e=>{dispatchFileInfo(e.target.value)}}
         />
      </FormGroup>
      <Editors data={fileStorage.editors}/>
    </div>
  )
}

const EditorRow = ({name, timeStamp, version, title}) => 
  <>
    <Grid item md={2}>
      <Typography variant='caption'>{title}</Typography>
    </Grid>
    <Grid item md={1}>
      <Typography variant='caption'>{version}</Typography>
    </Grid>
    <Grid item md={3} >
      <Typography variant='caption'>{name}</Typography>
    </Grid>
    <Grid item md={3} >
      <Typography variant='caption'>{moment(timeStamp).format('DD.MM.YYYY - HH:mm')}</Typography>
    </Grid>
</>


const Editors = ({data}) => {
  if(!data)
  return null

  const renderRows = () => {
    const updated = data.updated ? [...data.updated] : []
    arrSort(updated, 'timeStamp', {reverse: true})
    return(
      <>
      <Grid item md={12} container><EditorRow {...data.created} title='Erstellt' /></Grid>
      <Grid item md={12} ><Divider /></Grid>
      {data.updated && updated.map(update=>
        <Grid key={uuid()} item md={12} container>
          <EditorRow {...update} title='Bearbeitet'/>
          <Grid item md={12} >
            <Divider />
          </Grid>
        </Grid>)}
      
      </>
    )
  }

  return (
    <div style={{marginTop: '1rem', opacity: 0.85}}>
        <Typography color='primary' variant='body2'>Historie</Typography>
        <Divider />
        <Grid style={{maxHeight: '8rem', overflowY: 'hidden'}} container >
          {renderRows()}
        </Grid>
    </div>
  )
}

const FileInput = ({fileUpload}) => {
 
  return (
        <div style={{marginBottom: '1rem'}}>
            <input
                accept="file/*"
                style={{display: 'none'}}
                id="contained-button-file-file-storage"
                multiple
                type="file"
                onChange={fileUpload}
            />
                <label htmlFor="contained-button-file-file-storage">
                    <Button
                      startIcon={<AddIcon />}
                      variant="contained"
                      size='small'
                      color="primary"
                      component="span"
                      fullWidth
                    >
                      Datei auswählen
                        {/*progress ? `${progress.toFixed(2)}%` : <AddIcon />*/}
                    </Button>
                </label>
        </div>
    )
}


export default connect((state, props) => ({
  fileStorage: state.fileStorage,
}))(FileUploadForm)

//export default FileUploadForm