import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TextField, MenuItem, FormGroup, InputAdornment } from '@material-ui/core'
import { dispatchTaskForm } from 'store/actions/tasksActions'


export class AddTask extends Component {
 
    setStateByPropKey = (propKey, _value) => {
        let value = _value
        if(propKey==='hourlyRate' || propKey==='genbundRate' || propKey==='kmRate')
            value = Number(_value)
        return dispatchTaskForm({field: propKey, value})

    }
    inputFields = [
        {
            key: 1,
            select: true, type: 'select', label: 'App', propKey: 'type',
            onChange: this.setStateByPropKey,
            options: [
                {value: 'nachbarschaftshilfe', label: 'Nachbarschaftshilfe'},
                {value: 'pflegekasse', label: 'Pflegekasse'}
            ]
        },
        {key: 2, label: 'Titel (Anz. Abrechnung)', propKey: 'title', onChange: this.setStateByPropKey},
        {key: 3, label: 'Name (Anz. intern):', propKey: 'name', onChange: this.setStateByPropKey},
        {key: 4, type: 'number', label: 'Stundensatz', propKey: 'hourlyRate', InputProps: { startAdornment: <InputAdornment position="start">€</InputAdornment>, inputProps: {min: "1",  max:"50"}}, onChange: this.setStateByPropKey},
        {key: 5, type: 'number', label: 'KM Satz', propKey: 'kmRate', InputProps: { startAdornment: <InputAdornment position="start">€</InputAdornment>, inputProps: {min: "0",  max:"5"}}, onChange: this.setStateByPropKey},
        {key: 6, type: 'number', label: 'Genbund', propKey: 'genbundRate', InputProps: { startAdornment: <InputAdornment position="start">€</InputAdornment>, inputProps: {min: "1",  max:"20"}}, onChange: this.setStateByPropKey},

    ]
    inputKeysForFormGroup = [4,5,6]
    render() {

        const RENDERER = {singleFields: [], groupFields: []}
        this.inputFields.forEach(input=>{
            const isGroupField = this.inputKeysForFormGroup.includes(input.key)
            const renderTextField = <TextField
                size='small'
                select={input.select}
                InputProps={input.InputProps}
                type={input.type || 'text'}
                value={this.props.task[input.propKey]}
                style={{marginBottom: '4px', maxWidth: isGroupField ? '30%' : '', marginRight: isGroupField ? '0.5rem' : ''}}
                key={input.key} label={input.label}
                onChange={e=>input.onChange(input.propKey, e.target.value)}
                fullWidth={!isGroupField}
                
                >
                {input.select && input.options.map(opt=>(
                    <MenuItem dense key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                ))}
            </TextField>
            if(this.inputKeysForFormGroup.includes(input.key)) {
                RENDERER.groupFields.push(renderTextField)
            } else {
                RENDERER.singleFields.push(renderTextField)
            }
        })
        return (
            <form >
                <div>
                    {RENDERER.singleFields}
                    <FormGroup row>
                        {RENDERER.groupFields}
                    </FormGroup>
                </div>
            </form>
        )
    }
}
export default connect((state) => ({
    task: state.tasks.form,
}))(AddTask)
