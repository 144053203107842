import React from 'react'
import { RadioGroup, Radio, FormControlLabel, Typography} from '@material-ui/core'
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
      radioGroup: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2)
      },
      radioButton: {
          padding: 0,
      },
      radioButtonLabel: {
          marginLeft: 0,
          "& svg": {
          width: "0.8em",
          height: "0.8em"
          }
    }
  })
)

const ArchivSearchSelect = ({value, onChange, options = [], name, asRow, ariaLabel}) => {
    const classes = useStyles()
  return (
    <RadioGroup
        className={classes.radioGroup}
        aria-label={ariaLabel || "radio-group"}
        name={name || "radioGroup"}
        //className={classes.group}
        value={value}
        onChange={onChange}
        row={asRow || true}
 
    >
        {options.map((opt, i)=>
            <FormControlLabel
                className={classes.radioButtonLabel}
                key={`${opt.value}-${i}`}
                value={opt.value}
                control={<Radio className={classes.radioButton} />}
                label={<Typography variant='body2' color='primary' children={opt.label} />} 
                
            />
        )}
   
</RadioGroup>
  )
}

export default ArchivSearchSelect