//import { applyMiddleware } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { reducer as formReducer } from 'redux-form'
import rootReducer from './reducers'
import thunk from 'redux-thunk'
import config from '../config'
// alt vor middlewwareconst store = createStore(rootReducer);
//import { composeWithDevTools } from 'redux-devtools-extension'; //redux dev tool extention


const store = configureStore({
    reducer: {
        ...rootReducer,
        form: formReducer
    },
    preloadedState: config.INITAL_STATE,
    middleware: [thunk],

})
if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
  }
/*
const store = configureStore({
    reducer: {
        ...rootReducer  
    }
    
    composeWithDevTools(
        applyMiddleware(thunk),
})
*/
const dispatch = store.dispatch
export {
    dispatch,
    store,
}

export default store