import React from 'react'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import {v4 as uuid} from 'uuid'

const EditorsCard = ({editors, hasTask}) =>
<Grid container style={{overflowY: 'scroll', overflowX: 'hidden', maxHeight: '5rem'}}>
	{[...editors].reverse().map(editor=>
		<Grid item container spacing={0} key={uuid()} style={{color: 'white'}}>
			<Grid item md={7} >
			<Typography display='block' variant='caption' align='left' >
				{editor.msg} {editor.userName}
			</Typography>
			</Grid>
			<Grid item md={5} >
				<Typography display='block' variant='caption' align='center' >
					{moment(editor.timeStamp).format('DD.MM.YYYY - HH:mm')}
				</Typography>
			</Grid>
		</Grid>
	)}
</Grid>

export default EditorsCard