import React, { useState } from 'react'
//import Loading from 'Components/Loading/Spinner'
import { db } from '../../../../firebase/firebase.js'
import { dispatch } from '../../../../store/index'
import actionTypes from 'config/actionTypes'
import LienearProgress from 'Components/Progress/LinearProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Paper from '@material-ui/core/Paper'
import LoadingSpinner from '@material-ui/core/CircularProgress'
import {SimpleList as List, SimpleListItem as ListItem } from 'Components/Lists/SimpleList'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import { saveAs } from 'file-saver'
import print from 'print-js'
import ButtonBoard from 'Components/Buttons/ButtonRow'
import IconSelector from 'Components/Icons/IconSelector'
const Spinner = <LoadingSpinner color='secondary'  />
const Checked = <CheckCircleIcon fontSize='large' htmlColor='green' />

const loadingText = [
	{title: 'Erstelle Abrechnungsdaten', text: 'berechne Daten...'},
	{title: 'Validiere XML Daten für (Sparkasse)', text: 'Helfer Gutschriften'},
	{title: 'Erstelle PDF Dateien', text: 'für Pflegekassen und Helfer'},
	//{title: 'Erstelle PDF Dateien', text: 'für die Helfer'},
	{title: 'Versende Abrechnungs Emails', text: 'an Helfer mit Email'},
	{title: 'Datein werden bereitgestellt', text: 'Abrechnung abgeschlossen'}
]
const dividerStyle = {backgroundColor: '#3f51b5', paddingTop: '2px', marginTop: '0.5rem', marginBottom: '0.5rem'}
const btnSize='small'
const AccountingResults = ({data, date})  => {
	const {institutsInfo, providersInfo} = data
	const handleOpenDoc = url => {
		window.open(url)
		return
	}
	
	const handleDownloadDoc = async (url, fileName)=> {
		 //disable-es-lint-next-line
		return saveAs(url, fileName)
	}
	const handlePrint = (fileURL) => {
	//	const blob = await fetch(url).then(r=>r.blob())
		const printOptions = print.Configuration = {
			printable: fileURL,
			type: 'pdf'
		}
		print(printOptions)
		return
	}
	const handleDownloadXML = async (url, fileName) => {
		// made blob because url direct to fileSave got a http request error on pdfs its working
		const blob = await fetch(url).then(r=>r.blob())
	
		new Promise(resolve => {
			resolve(saveAs(blob, fileName));
		  })
		  .then(result =>{
			  return result
		  } )
		  .catch(err => console.log("Error downloading: " + err));
		return
	}
	const handleCloseResult = () => {
		dispatch({type: actionTypes.SET_ACCOUNTING_MONTH, payload: undefined})
		return db.ref('/invoiceResults/pflegekasse').set({isLoading: false})
	}

	const hideCloseButton = data.text.length<5
	return (
		<Grid container spacing={1} >
			<Grid item md={6} >
				<AccountingState data={data} date={date} />
			</Grid>
			<Grid item md={6} >
				<Paper style={{padding: '1rem'}}>
					<Typography variant="h6" color="secondary">Ergebnisse</Typography>
					<Divider style={dividerStyle} />
					{institutsInfo && <div>
						{institutsInfo.pdfURL
							? <PDFInfos
								accountingType='Pflegekassen'
								handleDownloadDoc={handleDownloadDoc}
								handleOpenDoc={handleOpenDoc}
								handlePrint={handlePrint}
								membersInfo={institutsInfo}
							/>
							: <NoPDFMessage memberType='Pflegekasse' />
						}
					</div>}
					{providersInfo && <div style={{marginTop: '1rem'}}>
						{providersInfo.pdfURL
							? <PDFInfos
							
								accountingType='Helfer ohne Email'
								handleDownloadDoc={handleDownloadDoc}
								handleOpenDoc={handleOpenDoc}
								handlePrint={handlePrint}
								membersInfo={providersInfo}
								/>
							: <NoPDFMessage memberType='Helfer' />
						}
						{providersInfo.xml && <div style={{marginTop: '1rem'}}>
							<XMLInfo handleDownloadXML={handleDownloadXML} xml={providersInfo.xml} accountingType='Helfer' />
						</div>}
					</div>}
				
				</Paper>
			</Grid>
			<Grid item md={12} >
				<ButtonBoard buttons={[{ 
					title: 'Schließen',
					disabled: hideCloseButton,
					onClick:handleCloseResult,
					variant: 'outlined',
					color: 'secondary' }
					]}
				/>
			</Grid>
		</Grid>
	)
}
const NoPDFMessage = ({memberType}) =>
	<>
		<Typography color='primary' variant='subtitle1'>{`${memberType} PDF`}</Typography>
		<Typography
			component='div'
			color='secondary'
			variant='button'
		>
			{memberType==='Pflegekasse' ? 'Nur Selbstzahler - keine PDF Datei' : `Alle Abrechnungen wurden per Email versendet`}
		</Typography>
		<Divider />
	</>
	
const PDFInfos = ({membersInfo, handleOpenDoc, handleDownloadDoc, handlePrint, accountingType}) =>{
	const btnVariant = 'text'
	const btnColor = 'default'
	
	return (
		<>
			<Typography color='primary' variant='subtitle1'>{accountingType} PDF</Typography>
			<Button
				startIcon={<IconSelector iconName='Open' />}
				variant={btnVariant}
				color={btnColor}
				size={btnSize}
				onClick={()=>handleOpenDoc(membersInfo.pdfURL)}
				children='Öffnen'
			/>
			<Button
				startIcon={<IconSelector iconName='Save' />}
				variant={btnVariant}
				color={btnColor}
				size={btnSize}
				onClick={()=>handleDownloadDoc(membersInfo.pdfURL, membersInfo.fileName)} 
				children='Speichern'
			/>
			<Button
				startIcon={<IconSelector iconName='Print' />}
				children='Drucken'
				variant={btnVariant}
				color={btnColor}
				size={btnSize}
				onClick={()=>handlePrint(membersInfo.pdfURL)}

			/>
			<Divider />
		</>
	)
}
const XMLInfo = ({handleDownloadXML, xml, accountingType}) => {
	
	const btnVariant='text'
	const btnColor='default'
	return (
		<>
			<Typography color='primary' variant='subtitle1'>{accountingType} XML (Sparkasse)</Typography>
			<Button
				startIcon={<IconSelector iconName='Save' />}
				variant={btnVariant}
				color={btnColor}
				size={btnSize}
				onClick={()=>handleDownloadXML(xml.url, xml.fileName)}
				children='Speichern'
			/>
		</>
	)
}


const AccountingState = ({data: {text}, date}) => {
	const [loadingDone, setLoadingDone ] = useState(false)
	let loadingState

	let progress = text && text.length>=2 ? (20*(text.length-1)) : 0
	let progressBuffer = progress+20
	if(loadingDone)
		progress = 100
	return (
		<Paper style={{padding: '1rem', minHeight: '360px'}}>
			<Typography variant="h6" color="secondary">Fortschrit {moment(date).format('MMMM YYYY')}</Typography>
			<LienearProgress
				variant="buffer"
				value={progress}
				valueBuffer={progressBuffer}
				color={progress<=99 ? 'secondary' : 'primary'}
			/>
			<List dense={true} >
				{text && text.map((step, i)=>{
					const isDone = step==='done'
					const isLast = text.length===i+1
					if(isDone) {
						if(!loadingDone)
							setLoadingDone(true)
							loadingText[i]={title: 'Abgechlossen', text: 'Abrechnung abgeschlossen'}
					}
					loadingState=isDone
						? Checked
						: isLast
							? Spinner
							: Checked
					return <ListItem
								onItemClick={()=>{}}
								title={loadingText[i].title}
								text={loadingText[i].text}
								key={i}
								icon={loadingState}
							/>
				})}
			</List>
		</Paper>
	)
}

export default AccountingResults
