import { actionTypes } from '../../config'
import indexOf from 'lodash/indexOf'
import { firestore } from '../../firebase/firebase'

const INIT_STATE = {
	selectedInvoice: undefined,
	selectedMonth: undefined,
	startAccounting: false
}
function accountingReducer(state = INIT_STATE, action) {
    
    switch(action.type) {
		case actionTypes.START_ACCOUNTING : {	
			return ({
				...state,
				startAccounting: action.payload,
			})
		}
		case actionTypes.SET_ACCOUNTING_MONTH : {	
			return ({
				...state,
				selectedMonth: action.payload,
			})
		}
		case actionTypes.SET_ACCOUNTING_INVOICE : {	
			return ({
				...state,
				selectedInvoice: action.payload,
			})
		}
		case actionTypes.ACCOUNTING.DEL_SERVICE : {	
			let selectedInvoice = [...state.selectedInvoice]
			let selectedMonth = [...state.selectedMonth]
			const { accountingType, id } = action.payload
			firestore.doc(`accounting/${accountingType}/services/${id}`).delete()

			const iDelIndex = indexOf(selectedInvoice, action.payload)
			const mDelIndex = indexOf(selectedMonth, action.payload )

			selectedInvoice.splice(iDelIndex, 1)
			selectedMonth.splice(mDelIndex, 1)

			if(selectedInvoice.length===0)
				selectedInvoice = undefined
			if(selectedMonth.length===0)
				selectedMonth = undefined
			return ({
				...state,
				selectedInvoice,
				selectedMonth
			})
		}
		case actionTypes.ACCOUNTING.DEL_SERVICES : {	
			let selectedInvoice=[...state.selectedInvoice]
			let selectedMonth = [...state.selectedMonth]
			const fsBatch = firestore.batch()
			state.selectedInvoice.forEach(service=>{
				const deleteRef =  firestore.doc(`accounting/${action.payload.accountingType}/services/${service.id}`)
				const delIndex = indexOf(selectedMonth, service)
				selectedMonth.splice(delIndex, 1)
				fsBatch.delete(deleteRef)
			})
			fsBatch.commit()
			selectedInvoice = undefined
			if(selectedMonth.length===0)
				selectedMonth = undefined
			return ({
				...state,
				selectedInvoice,
				selectedMonth
			})
		}
        default : return state;
    }
}
    
    export default accountingReducer;