import React, {createRef, useEffect}  from 'react'
import { connect } from 'react-redux'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { dispatchFilteredEvents } from 'store/actions/planningActions'

const menuItems = [
    {id: 1, value: 1, label: 'Offene Events'},
    {id: 2, value: 2, label: 'Vermittelte Events'},
    {id: 3, value: 0, label: 'Infos'}
]


const FilterByEventType = ({events, style, filters, byEventStateFilter}) => {
    const inputRef = createRef()
    const handleOnChange = e => {
        const inputValue = e.target.value
            const isActive = inputValue!=='noValue'
    
        return dispatchFilteredEvents({filter: filters.byEvent, isActive, inputValue, events})
    }
    
    useEffect(() => {
        const inputValue = inputRef.current.value
        const isActive = inputValue!=='noValue'
        if(isActive) {
            dispatchFilteredEvents({filter: filters.byEvent, isActive: true, inputValue, events })
        }
      return () => {
        
      }
      // eslint-disable-next-line
    }, [events, filters.byEvent])
    
    return(
        <FormControl style={style} error={byEventStateFilter.isActive}  >
            <InputLabel id="filter-by-events-select-label">Nach Satus filtern</InputLabel>
            <Select
                name='planing-filter-by-event-state'
                inputRef={inputRef}
                labelId="filter-by-events-select-label"
                id="fiter-by-event-type"
                value={byEventStateFilter.inputValue}
                onChange={handleOnChange}
            >
                <MenuItem key='defaultValue' value="noValue">
                    <em style={{color: 'grey'}}>Kein Filter</em>
                </MenuItem>
                {menuItems.map(item=>{
                    return <MenuItem key={item.id} value={item.value}>{item.label}</MenuItem>}
                
                )}
           
            </Select>
            {/*<FormHelperText>Some important helper text</FormHelperText>*/}
        </FormControl>
    )
}


export default connect(state=>({
    events: state.firestore.ordered.planning,
    byEventStateFilter: state.planning.byEventStateFilter,
    filters: state.planning.filters,
  }))(FilterByEventType)