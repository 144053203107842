import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, Grid, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'


const useStyles = makeStyles(theme=>{
  //console.log(theme)
  return({
    title: {
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`
      
    }

  })
})

export default  function AlertDialog({open, closeBtn, children, buttons, handleClose, title, text, Form}) {
  const classes = useStyles()
  return (
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="institut-dialog-title"
        aria-describedby="institut-dialog-description"
      >
        <DialogTitle className={classes.title} id="file-upload-dialog-title">
          <Grid container >
            <Grid item md={11} xs={11} style={{textAlign: 'left'}}>
              {title}
            </Grid>
            <Grid item md={1} xs={1} style={{textAlign: 'right'}}>
              {closeBtn && <IconButton size='small' onClick={closeBtn}>
                <CloseIcon />
              </IconButton>}
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        {Form 
          ? Form 
          : <DialogContent>
          {text && <DialogContentText id="file-upload-dialog-description">
            {text}
          </DialogContentText>}
            {children}
        </DialogContent>}
        <DialogActions>
            {buttons && buttons.map((button, i)=>
                <Button key={i} {...button} />
            )}
        </DialogActions>
      </Dialog>
  )
}