import React from 'react'
//import { firestore } from '../../../../firebase'
//import moment from 'moment'
//import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
//import DefaultCard from 'Components/Cards/DefaultCard'
//import TitleCard from 'Components/Title/TitleCard'
//import Dialog from 'Components/PopUps/Dialog'
//import ButtonBoard from 'Components/Buttons/ButtonRow'
import AccountingMonthView from './AccountingMonthView'
import AccountingInvoiceView from './AccountingInvoiceView'
import { connect } from 'react-redux'

const AccountingDetails = ({selectedMonth, selectedInvoice, tasks, invoiceIds, authUser}) => {
	const editor = {
		name: authUser.firebase.userName || authUser.firebase.username,
		id: authUser.id
	}
	return (
		<Box>
			 {!selectedInvoice
				? <AccountingMonthView
					editor={editor}
					selectedInvoices={selectedMonth}
					tasks={tasks}
					/>
			 	: <AccountingInvoiceView tasks={tasks} />
			}
		</Box>
	)
}


export default connect((state) => {
	return ({
		authUser: state.user,
		selectedInvoice: state.accounting.selectedInvoice,
		selectedMonth: state.accounting.selectedMonth
  	})
})(AccountingDetails)