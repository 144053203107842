import React from 'react'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { groupBy, keys } from 'lodash'
import { Box } from '@material-ui/core'
import TitleCard from 'Components/Title/TitleCard'
import CardGrid from  'Components/Cards/DefaultCardGrid'
import moment from 'moment'
import arrSort from 'array-sort'
import { v4 as uuid } from 'uuid'
import { actionTypes } from 'config/config'
import LoadingHandler from 'Components/Loading/LoadingHandler'
export const AccountingList = (props) => {
	const years = props.isLoaded ? arrSort(keys(props.groupedByYear)) : []

	const handleMonthClick = selectedMonth => {
		return props.dispatch({type: actionTypes.SET_ACCOUNTING_MONTH, payload: selectedMonth})
	}

  	return (
		<LoadingHandler isLoaded={props.isLoaded} isEmpty={props.isEmpty} buttons={[{type: 'history', ROUTE: 'ADD_PFLEGE_SERVICES', variant: 'outlined', title: 'Zur Leistungseingabe', fullWidth: true, loadingText: 'Lade Leistungen...'}]}>
		<Box>
			{years.map((year, i)=> {
				const accountingYear = props.groupedByYear[year]
				const groupedByMonth = groupBy(accountingYear, 'month')
				const months = arrSort(keys(groupedByMonth))
			
				const cardData = months.map( month =>{
					
					const accountingMonth = groupedByMonth[month]
					return ({
						header: {
							avatar: {
								content: accountingMonth.length || 0
							},
							title: moment(accountingMonth[0].date).format('MMMM'),
							subheader: `${moment(accountingMonth[0].date).format('MM YYYY')}`
						},
						item: accountingMonth
					})
				})
				return (
					<Box key={uuid()}>
						<TitleCard title={`${year}`} />
						<CardGrid data={cardData} tooltip={{title: 'Klicken um Abrechnung zu öffnen'}} onCardClick={handleMonthClick} />
					</Box>
				)
			})}
		</Box>
		</LoadingHandler>
	)
}

export default compose(
    firestoreConnect(() => [  
      { collection: 'accounting', doc: 'pflegekasse', subcollections: [{ collection: 'services' }], storeAs: 'services' },
		//{ collection: 'tasks', where: [['type', '==', 'pflegekasse']], storeAs: 'tasks' }

    ]), // or { collection: 'todos' }
    connect((state) => {
        return ({   
        isLoaded: isLoaded(state.firestore.data.invoices),
		isEmpty: isEmpty(state.firestore.data.invoices),
	//	groupedByYear: groupBy(state.firestore.ordered.services, 'year'),
		//tasks: state.firestore.data.tasks
      })})
    )(AccountingList)
