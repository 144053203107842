import React, { useState, useEffect, useMemo} from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { IconButton, TextField, Button } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import NoRowsOverlay from 'Components/DataGrid/NoRowsOverlay'
import keys from 'lodash/keys'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'

import {
    // LicenseInfo,
        DataGrid,
        //onRowClick,
        //onRowOver,
        deDE,  
        GridToolbarColumnsButton,
        //GridToolbarDensitySelector,
    // GridToolbarExport,
        GridToolbarFilterButton,
} from '@mui/x-data-grid'



const defaultTheme = createTheme();
  const useStyles = makeStyles(
     (theme) => ({
       dataGrid: {
         '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
           outline: 'none',
       }
       },
       searchContainer: {
         padding: theme.spacing(0.5, 0.5, 0),
         justifyContent: 'space-between',
         display: 'flex',
         alignItems: 'flex-start',
         flexWrap: 'wrap',
       },
       textField: {
         [theme.breakpoints.down('xs')]: {
           width: '100%',
         },
         margin: 0,
         '& .MuiSvgIcon-root': {
           marginRight: theme.spacing(0.5),
         },
         '& .MuiInput-underline:before': {
           borderBottom: `1px solid ${theme.palette.divider}`,
         },
       },
     }),
     { defaultTheme },
   )

const InstitutsDataGrid = ({instituts, addInstitutCallback, selectedRowsCallback, deleteItemCallback, editItemCallback, rest}) => {
    const classes = useStyles()
    const [filterModel, setFilterModel] = useState({ items: [] })
    const [rows, setRows] = useState([])
    const [searchText, setSearchText] = useState('')
    const [selectedRows, setSelectedRows] = useState({ids: [], data: []})
 
    useEffect(() => {
        setRows(instituts)
        return () => {
          //   setSelectedRows(ids: [], data: [])
        }
    }, [instituts])

    const columns = useMemo(()=> [
      {
        field: 'name',
        headerName: 'Name',
        editable: false,
        width: 400,
        align:'left',      
      },
      {
        field: 'city',
        headerName: 'Ort',
        width: 140,
        align: 'left',
        sortable: true,
      },
      {
        field: 'postCode',
        width: 80,
        headerName: 'PLZ',
        editable: false,
        align: 'center',
      },
      {
        field: 'street',
        width: 190,
        headerName: 'Straße',
        editable: false,
        align: 'left',
      },
      {
        field: 'contactPerson',
        width: 80,
        headerName: 'Ansprechpartner',
        editable: false,
        align: 'left',
      },
      {
        field: 'actions',
        headerName: 'Optionen',
        editable: false,
        align:'center',
        renderCell: params =>{
          return <>
            <IconButton color='secondary' size='small' onClick={()=>deleteItemCallback(params.row)}  ><DeleteIcon /></IconButton>
            <IconButton color='primary' size='small' onClick={()=>editItemCallback(params.row)}  ><EditIcon /></IconButton>
            </>
        }
      }
      
    ], [deleteItemCallback, editItemCallback])

  
    const requestSearch = (searchValue) => {
    setSearchText(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    const filteredRows = instituts.filter((row) => {
        return keys(row).some((field) => {
                if(field==='name' ||field==='street' || field==='city' || field==='postCode') {
                    return searchRegex.test(row[field].toString())
                }
                return false
            })
        })
        setRows(filteredRows)
    }

    const handleSelectedRows = () => {
        selectedRowsCallback(selectedRows)
        return setSelectedRows({ids: [], data: []}) 
    }

  return (
    <div style={{ display: 'flex', minHeight: 400 }}>
      <div style={{ flexGrow: 1 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            editMode='row'
            initialState={{
              sorting: {
                sortModel: [{ field: 'type', sort: 'desc' }],
              },
            }}
            density='compact'
            // checkboxSelection
            onSelectionModelChange={(ids) => {
            
              const selectedIDs = new Set(ids)
              const _selectedRows = instituts.filter((row) =>
                selectedIDs.has(row.id),
              )
              return setSelectedRows({ids: ids, data:_selectedRows})
            }}
            selectionModel={selectedRows.ids}
            disableSelectionOnClick
        
            filterModel={filterModel}
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            onFilterModelChange={(model) => setFilterModel(model)}
            className={classes.dataGrid}
            allowFiltering={true}
            loading={instituts.length===0}
            //onRowClick={row=>{handleRowClick(row)}}
            components={{ 
                Toolbar: QuickSearchToolbar,
                NoRowsOverlay: ()=><NoRowsOverlay title='Keine Mitglieder gefunden' />
            }}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(''),
                onDeleteForEverClick: handleSelectedRows,
                onAddInstitutClick: addInstitutCallback,
                selectedRowsCount: selectedRows.ids.length,
              },
            }}
          />            
            </div>
        </div>
)}



function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

function QuickSearchToolbar(props) {
   
    const classes = useStyles()
    return (
        <div className={classes.searchContainer} style={{margin: '1rem 0 1.5rem 0'}}>
            <div>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </div>
              <Button
                style={{marginRight: '0.5rem'}}
                size='small'
                color='primary'
                variant='outlined'
                startIcon={<AddIcon />}
                onClick={props.onAddInstitutClick}
                >
                  Pflegekasse
              </Button>
     
            <TextField
                error={Boolean(props.value)}
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Suchen…"
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" color={Boolean(props.value) ? 'secondary' : 'primary'}/>,
                    endAdornment: (
                    <IconButton
                        color={Boolean(props.value) ? 'secondary' : 'primary'}
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{ visibility: props.value ? 'visible' : 'hidden' }}
                        onClick={props.clearSearch}
                    >
                        <ClearIcon fontSize="small" />
                    </IconButton>
                    ),
            }}
            />
      </div>
    )
}

export default compose(
    firestoreConnect(() => {
      return [  
      //  { collection: 'accounting', doc: 'pflegekasse', subcollections: [{ collection: 'services' }], storeAs: 'servicesPflegekasse' },
     // { collection: 'tasks', where: [['type', '==', 'nachbarschaftshilfe']], storeAs: 'tasks' },
      { collection: 'instituts'},
  
    ]}), // or { collection: 'todos' }
    connect((state, props) => {
        return ({
        instituts: state.firestore.ordered.instituts || []
        //membersDeleted: state.firestore.ordered.membersDeleted || [],
        //member: state.firestore.data.members ? state.firestore.data.members['0Gcc9zcifuxJ0zKNoPDK'] : undefined,
      })}),
    )(InstitutsDataGrid)