import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import ChartSelect from './ChartSelect'
import BarChart from '../Charts/BarChart'
import Spinner from 'Components/Loading/Spinner'
import {STATISTIC } from 'config/actionTypes'
/*
import ButtonRow from 'Components/Buttons/ButtonRow'
import BarChart from 'Container/Charts/BarChart'
import LineChart from 'Container/Charts/LineChart'
import Grow from '@material-ui/core/Grow'
*/
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
//import ArrowLeft from '@material-ui/icons/ArrowBack'
class StatisticDashboard extends Component {

    componentWillUnmount() {
        this.props.dispatch({type: STATISTIC.RESET})
      }

  
    chartRef = createRef()
    render() {
        return (
            <div style={{minHeight: '800px'}}>
                <Grid container spacing={2} >
                    <Grid item md={3}>
                        <ChartSelect fullWidth={true} />
                    </Grid>
                    <Grid item md={9} >
                        <div style={{
                             width: "100%",
                             height: '563px',
                             border: '2px solid grey',
                             backgroundColor: 'white'
                        }}>
                            {this.props.isLoading
                                ?  <Spinner size={100} style={{marginTop: '2rem'}}/>
                                : this.props.chartData
                                    ? this.props.chartData.values.length===0
                                        ?   <StatContentSelector chartSelect='noValue' />
                                        : <BarChart chartData={this.props.chartData} />
                                    : <StatContentSelector chartSelect={this.props.chartSelect} />
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const StatContentSelector = ({chartSelect}) => {

    const {startYear, endYear, startMonth, endMonth} = chartSelect
    const noData = <Typography style={{marginTop: '20px'}} variant='h5' color='secondary' align='center'>
        In diesem Zeitraum liegen keine Daten vor. Eingabe überprüfen!
    </Typography>
    const start = <Typography style={{marginTop: '20px'}} variant='h6' color='primary' align='center'>
        Statistik auswählen!
    </Typography>
    const wrongYear = <Typography style={{marginTop: '20px'}} variant='h6' color='secondary' align='center'>
        Das Start Jahr darf nicht kleiner sein als das End Jahr
    </Typography>
     const wrongMonth = <Typography style={{marginTop: '20px'}} variant='h6' color='secondary' align='center'>
     Das Start Monat darf nicht kleiner sein als das End Monat
    </Typography>
    if(chartSelect==='noValue')
        return noData
    if(startYear>endYear)
        return wrongYear
    if(startMonth>endMonth)
        return wrongMonth
    return start

}

export default connect((state) => ({
    isLoading: state.statistic.isLoading,
    activeApp: state.user.settings.activeApp,
    chartSelect: state.statistic.chartSelect,
    chartData: state.statistic.selectedData,
    legendFormater: state.statistic.legendFormater || undefined
}))(StatisticDashboard)
/*
const NoDataMessage = ()=><div style={{padding: '3rem'}}>
    <Typography variant='subtitle1' color='secondary' align='center'>
        {`Für diese Statistik liegen noch keine Daten zum anzeigen vor!`}
    </Typography>

</div>
*/