import React from 'react'
import FileUploadForm from './FileUploadForm'
import FileDataGrid from './FileDataGrid'
import Dialog from './Dialog'
import {connect} from 'react-redux'
import { resetFileUpload, dispatchSaveFile} from 'store/actions/fileStorageActions'


const FileStorage = ({isOpen, isTouched,...rest}) => {
  const disableSaveBtn = !isTouched
  return (
    <div>
      <FileDataGrid  />
      <Dialog
        title='Datei Upload'
        //text='Datei Ausählen und ggf. Dateinnamen ändern. '
        open={isOpen}
        buttons={[
          {children: 'Speichern', disabled: disableSaveBtn, onClick:dispatchSaveFile, color:'primary'},
          {children: 'Abbrechen', onClick:resetFileUpload, color:'primary'}
        ]}>
        <FileUploadForm  />
      </Dialog>
    </div>
  )
}
export default connect((state, props) => {
  return ({
    isTouched: state.fileStorage.isTouched,
    isOpen: state.fileStorage.isOpen,
})})(FileStorage)

