import React from 'react'
import { Typography, Paper, List, ListItem, ListItemText, Button } from '@material-ui/core'
import makeStyles from '@material-ui/styles/makeStyles'
import moment from 'moment'
import OpenIcon from '@material-ui/icons/OpenInBrowserOutlined'
import DeleteIcon from '@material-ui/icons/DeleteForeverOutlined'

const useStyles = makeStyles((theme) =>({
    headlineWrapper: {
        backgroundColor: theme.palette.primary.light,
        color: 'whitesmoke',
        paddingLeft: theme.spacing(1)
    },
    listItem: {
        marginBottom: theme.spacing(1),
        backgroundColor: 'whitesmoke'
    },
    sublistWrapper: {
        margin: 0,
        backgroundColor: 'whitesmoke',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        maxHeight: '120px',
        overflowY: 'scroll'

    },
    sublistItem: {

    },
    totalsWrapper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
        backgroundColor: 'whitesmoke',
        textAlign: 'center'

    },
    stornoBtn: {
        marginTop: theme.spacing(2)
    }

}))

export const ResultsRequester = ({data, stornoCallback, stornoBtnDisabled}) => {

    const classes = useStyles()
    const handleOpenInvoice = () => {
        return window.open(data.pdfFiles.requester)
    }

    return (
        <div style={{color: 'white'}}>
        <Paper className={classes.headlineWrapper} >
            <Typography variant='subtitle1'>Gefundene Abrechnung</Typography>
        </Paper>
        <List dense={true} >
                <ListItem component={Paper} className={classes.listItem}>
                  <ListItemText
                    primary={data.id}
                    //primaryTypographyProps={{style: {color: 'white'}}}
                    secondary={`Abrechnung ${moment(data.date).format('MMMM')} ${data.year}`}
                  />
                </ListItem>
                <ListItem component={Paper} className={classes.listItem}>
                  <ListItemText
                    primary={data.recipient.name}
                    //primaryTypographyProps={{style: {color: 'white'}}}
                    secondary={data.recipient.street}
                  />
                </ListItem>
                <ListItem component={Paper} className={classes.listItem}>
                  <ListItemText
                    secondary={`${data.totals.totalPayment} EURO`}
                    //primaryTypographyProps={{style: {color: 'white'}}}
                    primary='Abrechnungsbetrag'
                  />
                </ListItem>
            </List>
            <Button
                startIcon={<OpenIcon />}
                fullWidth
                variant='outlined'
                color='inherit'
                onClick={handleOpenInvoice}
            >
                Abrechnung öffnen
            </Button>
            <Button
                disabled={stornoBtnDisabled}
                className={classes.stornoBtn}
                startIcon={<DeleteIcon />}
                fullWidth
                variant='contained'
                color='secondary'
                onClick={stornoCallback}
            >
                Abrechchnungen Stornieren
            </Button>
        </div>
    )
}

export default ResultsRequester
