import React from 'react'
import './App.css'
import initApp from './initializeApp'
import PublicApp from './Public/PublicApp'
import PrivateRoutes from '../config/Routes/PrivateRoutes'
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import theme from '../config/themes'
import { ThemeProvider } from '@material-ui/styles';
import { routes } from '../config' 
import { SnackbarProvider } from 'notistack'
/* Just a license Key to test
import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
  'x0jTPl0USVkVZV0SsMjM1kDNyADM5cjM2ETPZJVSQhVRsIDN0YTM6IVREJ1T0b9586ef25c9853decfa7709eee27a1e',
);
*/
//const LoadablePublicApp = loadable(() => import('./Public/PublicApp'))
//const LoadablePrivateRoutes = loadable(() => import('../config/Routes/PrivateRoutes'))
//const LoadableAdminRoutes = loadable(() => import('../config/Routes/_AdminRoutes'))


function App ({isAuth, userRole, emailVerified, dialog, user }) {
  /* const test = []
   let i = 0;
   const max =10
  while (i<max) {
    test.push(i);
    i++
  } */


  const isAppUser = isAuth
  const isAdmin = userRole && userRole.admin
  const role = isAdmin ? 'admin' : 'appUser'
 
  return (

    <div id="App">
       <ThemeProvider theme={theme}>
       <SnackbarProvider maxSnack={3} >
        <Router >
            <Switch>
              {isAppUser && <PrivateRoutes role={role} user={user} />}  
              </Switch>
              {!isAppUser && <PublicApp />}
        
              {!isAuth && <Redirect to={routes.public.LOGIN} />}
              {/*isAuth && <Redirect to={routes.private.ACCOUNTING_PFLEGEKASSEN} />*/}    
        </Router>
        </SnackbarProvider>
        </ThemeProvider>
    </div> 
  )
}


export default initApp()(App)
