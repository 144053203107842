import { getRoleBasedNavigation } from './navigation.cfg'   
import INITAL_STATE from './INITAL_STATE'
import firebaseConfig from './firebase.cfg'
import landingPageBG_desktop from '../static/landingPageBG-desktop.jpg'
import { publicRoutes, privateRoutes } from './routes'
import  actionTypes from './actionTypes'
export const appName = 'GENFAKT 4.9.0'
export const appLogo = 'GENFAKT 4.9.0'

export const bankInfo = 'Bankverbindung: IBAN: DE37 7105 0000 0020 3292 31 , BIC: BYLADEM1BGL'
export const vereinsInfo = ('Vorstand: 1. Vorsitzender: Dr. Dr. Thomas Dietl, Laufen; 2. Vorsitzender: Dr. Henner Krauss, Bad Reichenhall; 3. Vorsitzender: Günter Wolf,Anger; 1. Schriftführerin: Inge Hartmann, Bayerisch Gmain; 1. Kassiererin: Christine Speth,Reichenhall; 2. Kassiererin: Gabi Setzepfandt, Freilassing. Der Generationenbund ist in das Vereinsregister beim Registergericht Traunsteineingetragen und fördert nach dem Freistellungsbescheid des Finanzamtes Traunstein gemeinnützige und mildtätige Zwecke (Altenhilfe,bürgerschaftliches Engagement). Bei den abgerechneten Entgelten handelt es sich um Ehrenamtsentschädigungen, die ab 01.01.2021 bis zu einem Jahresbetrag von 3000 € nach § 3 Ziffer 26 des Einkommenssteuergesetzes steuerfrei sind.')
/*
export const genBundRate = 2
*/
export const genBundCities = [
    'Freilassing',
    'Bad Reichenhall',
    'Anger',
    'Ainring',
    'Aufham',
    'Piding',
    'Teisendorf',
    'Saaldorf-Surheim',
    'Laufen',
    'Schneizlreuth',
    'Bayerisch Gmain',
    'Neukirchen',
    'Saaldorf',
    'Surheim'
]
export const landingPageCFG = {
    title: appName,
    text: 'Herzlich willkommen bei unserer sozialen App, die Generationenen verbindet',
    backgroundImage: landingPageBG_desktop,
    withFooter: true,
}

export {privateRoutes}
export {publicRoutes}
export const routes = {
    public: publicRoutes,
    private: privateRoutes,
}

export const text = {
    login: {
        errors: {
            'auth/too-many-requests': "Sie haben zu oft das falsche Passwort eingegeben. Ihr Konto wurde gesperrt, versuchen Sie es in ein paar Minuten wieder.",
            'auth/wrong-password': 'Sie haben ein falsches Passwort eingegeben. Bitte versuchen Sie es erneut.',
            'auth/user-not-found': 'Zu dieser Email Adresse existiert kein Benutzerkonto. Überprüfen Sie ihre E-Mail Adresse.',
            'auth/invalid-email' :'E-Mail Adresse hat ein ungültiges Format. Bitte überprüfen Sie ihre E-Mail Adresse',
            default: 'Es ist ein Fehler aufgetreten. Anmeldung nicht möglich. Bitte wenden Sie sich an die Niederlassung.' 
        },

    },
    categories: {
        delete: {
            title: 'Kategorie löschen',
            text: "Wollen Sie die Kategorie wirklich löschen?"
        }
    }
}



const config = {
    appName: 'Genbund-Faktura',
    appLogo: 'GENBUND-FAKTURA',
    landingPage: routes.LANDING_PAGE,
    navigation: {
        title: 'Menü',
        logo: false,
        getNavigationItems: (role, activeApp)=>{
            return getRoleBasedNavigation(role, activeApp)}
    }, 
    INITAL_STATE: INITAL_STATE,
    routes: routes,
    actionTypes,
    firebase: {
        config: firebaseConfig.DEV
    },
    text: text,

}

export const kmPauschale = 4.60
export const  getMonthName = (month, short) => {
    
    const names = {
        '01': 'Januar',
        '02': 'Februar',
        '03': 'März',
        '04': 'April',
        '05': 'Mai',
        '06': 'Juni',
        '07': 'Juli',
        '08': 'August',
        '09': 'September',
        '10': 'Oktober',
        '11': 'November',
        '12': 'Dezember',
    }
    const shortNames = {
        '01': 'Jan',
        '02': 'Feb',
        '03': 'Mäz',
        '04': 'Apr',
        '05': 'Mai',
        '06': 'Jun',
        '07': 'Jul',
        '08': 'Aug',
        '09': 'Sep',
        '10': 'Okt',
        '11': 'Nov',
        '12': 'Dez',
    }

    if(short)
        return shortNames[month]
    return names[month]
}

// config event handler

export {
   
    actionTypes
 }
export default config
