import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import ButtonBoard from 'Components/Buttons/ButtonRow'
import FileSelect from '../../../Components/Files/FileSelect'
import SendIcon from '@material-ui/icons/SendOutlined'
import ResetIcon from '@material-ui/icons/HighlightOff';
import { FormGroup } from '@material-ui/core'

const useStyles = makeStyles(
  (theme) => ({
    root: {
    },
    subjectLabel: {
      color: 'whitesmoke'
    },
    
    fileUploadLabel: {
      marginTop: theme.spacing(2),
      color: 'white'
    },
    textField: {
      marginTop: theme.spacing(1)
    }
  })
)

export const MailForm = ({formSubmitCallback, formCloseCallback }) => {
  const classes = useStyles()
  const [filesToUpload, setFilesToUpload] = useState(undefined)
  const [subject, setSubject ] = useState('')
  const [ body, setBody ] = useState('')
  

  const handleFormClose = () =>formCloseCallback()
  
  const handleFiles = files => {
    if(files.length===0)
      return null
    const convertedFiles = files.map(fileData=>({
      fileName: fileData.name,
      file: fileData.file
    }))
    return setFilesToUpload(convertedFiles)
  }
  const handleFormSubmit = () => {
    const attachments = filesToUpload
    return formSubmitCallback({subject, body, attachments})
  }

  const btnIsDisabled = (subject.length<3 || body.length<4)
  return (
    <div className={classes.root} >
      <FormGroup>
          <TextField
          autoFocus
          value={subject}
          label='Betreff'
          InputLabelProps={{shrink: true, style: {color: 'white'}}}
          placeholder='Geben Sie hier den Betreff für die E-Mail ein'
          fullWidth
          onChange={e=>setSubject(e.target.value)}
          className={classes.subjectField}
          inputProps={{style: {backgroundColor: 'whitesmoke', textIndent: '0.5rem'}}} 
          />
          <TextField
            label='Text'
            value={body}
            onChange={e=>setBody(e.target.value)}
            InputLabelProps={{shrink: true, style: {color: 'white'}}}
            placeholder='Geben Sie hier den Text für die E-Mail ein'
            fullWidth
            multiline
            minRows={12}
            className={classes.textField}
            inputProps={{style: {backgroundColor: 'whitesmoke', padding: '0.5rem'}}}
          />
      </FormGroup>
      <Typography variant='caption' className={classes.fileUploadLabel}>
          Anhang
      </Typography>
      <FileSelect fileUploadCallback={handleFiles}/>
      <ButtonBoard 
        buttons={[
          {title: 'Verwerfen', color: 'default', onClick:handleFormClose, icon: <ResetIcon />},
          {title: 'Absenden', disabled: btnIsDisabled, color: 'secondary',onClick:handleFormSubmit, icon: <SendIcon />},
        ]}
      
      />
    </div>
  )
}
export default MailForm

