import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import FullScreenDialog from 'Components/Modals/FullScreenDialog'
import { makeStyles } from '@material-ui/core/styles'
import MemberGrid from 'Components/Grid/DefaultGrid'
import { actionTypes } from 'config'
import FormWrapper from 'Components/Forms/FormWrapper'
import { reduxForm, formValueSelector} from 'redux-form'
import {mySubmit} from './remoteSubmit'
import LoadingSpinner from '@material-ui/core/CircularProgress'
import {Tabs, Tab, Grid, Typography } from '@material-ui/core'
import DefaultReduxForm from 'Components/ReduxForms/DefaultForm'
import NotesForm from './SubForms/NotesSF'
import inputConfigs from './memberInputs'
import validate from './memberValidation'
import { moveMemberToDeleted } from 'firebase/database/membersHandler'
import BankAccountForm from './SubForms/BankAccountSF'
import FilesForm from './SubForms/FilesSF'
import PflegekasseForm from './SubForms/PflegekasseSF'
import { dialog } from 'store/actions/dialogActions'
import values from 'lodash/values'
import { checkInvoicesByMemberId } from 'firebase/database/accounting'
import moment from 'moment'

const useStyles = makeStyles(theme=>({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  gridItem: {
    //backgroundColor: theme.palette.primary.light,
 //  border: '1px soild green'
   // marginLeft: theme.spacing(2.5),
  },
  formGroupPaper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
 
  },
  formGroupTitle: {  
  },
  titleDivider: {
    marginBottom: theme.spacing(1)
  },
  spinnerWrapper: {
    height: '100vh',
    width:  '100vw',
    textAlign: 'center',
    paddingTop: '100px'
  },
  loadingSpinner: {
    color: 'white',
    height: '100px !important',
    width: '100px !important'
  },
  tabs: {
    marginTop: 0,
   // backgroundColor: theme.palette.primary.light,
   backgroundColor: theme.palette.primary.light
  },
  tab: {
    fontSize: '0.9rem',
    minWidth: 0,
    //textTransform: 'none'
  },
  contactExists: {
    minWidth: 0,
    fontSize: '0.9rem',
    backgroundColor: theme.palette.primary.dark,
    color: 'lightgrey'
  },
  copyRecipient: {
    minWidth: 0,
    fontSize: '0.9rem',
    textDecoration: 'underline',
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.secondary.light,
  },
  copyRecipientMsg : {
    fontSize: '0.75rem',
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
}))

let Member = ({member, profile, instituts, roles, tasks, hasContactPerson, hasCopyRecipient, hasBankAccount, dirty, invalid, handleSubmit, dispatch , submit, reset, pristine, submitting, initialized, ...rest}) => {

  const classes = useStyles()
 
  const handleFormSubmit = async () => {
    if(!initialized) {
      const memberSnap = await rest.firestore.collection('members')
      .where('profile.firstName', '==', profile.firstName)
      .where('profile.lastName', '==', profile.lastName)
      .where('profile.postCode', '==', profile.postCode)
      .get()

      const handleSave = () => {
        dialog('reset')
        return dispatch(submit('member'))
      }
      const handleCancelDialog = () => {
        return  dialog('reset')
      }

      if(memberSnap.size>=1){
        const memberData = {}
        memberSnap.forEach(_member => {
            const member = _member.data()
            const id = member.id
            const memberId = member.memberId
            const { firstName, lastName, street, postCode, city, birthday } = member.profile
            memberData[id] = {name: `${firstName} ${lastName}`, street, postCode, city, birthday,  memberId}
        })
      
        
        return dialog({
          isOpen: true,
          title: 'Es ist bereits ein Mitglied in diesem Ort mit gleichem Name vorhanden!',
          text: values(memberData).map(mData=>`${mData.memberId} - ${mData.name} ${mData.birthday.length>3 ? moment(mData.birthday).format('DD.MM.YYYY') : ''} - ${mData.street} - ${mData.city}`),
          buttons: [
            {title: 'Zurück', color:'secondary', onClick: handleCancelDialog},
            {title: 'Trotzdem Speichern', color:'primary', onClick: handleSave},
          ]
        })
        

      }
    }
    return dispatch(submit('member'))
  }

  const handleClose = ()=> {

    if(dirty && !invalid) {
      const confirmExit = () => {
        dispatch(submit('member'))
        return dialog('reset')
      }
      const discardChanges = () => {
        dialog('reset')
        dispatch({type: actionTypes.MEMBER.close})
        return dispatch(reset('member'))
      }
      dialog({
        isOpen: true,
        title: 'Wirklich schießen',
        text: 'Es wurden Änderungen am Mitglied vorgenommen. Wollen Sie die Änderungen speichern?',
        buttons: [
          {title: 'Verwerfen', color:'secondary', onClick: discardChanges},
          {title: 'Speichern', color:'primary', onClick: confirmExit},
        ]
      })
    } else {
      dispatch({type: actionTypes.MEMBER.close})
      return dispatch(reset('member'))
    }
  }

  const handleDeleteMember = async () => {
    const { roles, pflegeRoles, id } = member.data

    const confirmDelete = () => {
      dispatch({type: actionTypes.MEMBER.isLoading, payload: true})

      moveMemberToDeleted(member.data, rest.authUser)
      dialog('reset')
      return dispatch({type: actionTypes.MEMBER.close})
    }

    const loadingText = `Überprüfe ob für das Mitglied ${member.data.userName} offene Abrechnungen existieren!`
    const deleteText = `Soll das Mitglied ${member.data.userName} wirklich gelöscht werden?`

    dialog({
      isOpen: true,
      isLoading: true,
      title: 'Mitglied löschen',
      text: loadingText,
      buttons: [
        {title: 'Abbrechen', color: 'primary', onClick: ()=>dialog('reset')},
      ]
    })
    const resultCheck = await checkInvoicesByMemberId({roles, pflegeRoles, id})
    const noDeleteText = [
      `Für das Mitglied ${member.data.userName} existieren in der ${resultCheck.app} offene Abrechnung.`,
      'Das Mitglied kann daher nicht gelöscht werden!'
    ]
    dialog({
      isOpen: true,
      isLoading: false,
      title: 'Mitglied löschen',
      text: resultCheck.invoicesExist ? noDeleteText : deleteText,
      buttons: !resultCheck.invoicesExist 
      ? [
          {title: 'Abbrechen', color: 'primary', onClick: ()=>dialog('reset')},
          {title: 'Löschen', color: 'secondary', onClick: confirmDelete},
        ]
      : [{title: 'OK', color:'primary', onClick: ()=>dialog('reset')}]
    })
    
    
  }
 
  return (
    <FullScreenDialog
      deleteBtnDisabled={member.isNew}
      saveBtnDisabled={pristine || invalid || submitting}
      isOpen={member.isOpen}
      onCloseCallback={handleClose}
      onSaveCallback={handleFormSubmit}
      onDeleteCallback={handleDeleteMember} //this.props.member.showMember.edit ? deleteMemberCallback : undefined}
      title={member.data && `${member.data.memberId || '0000'} - ${member.title}`}
    >
      {(member.isLoading && instituts) || submitting
        ? <div className={classes.spinnerWrapper}><LoadingSpinner className={classes.loadingSpinner} /></div>
        : <MemberGrid classes={classes} >
            <form onSubmit={handleSubmit} style={{paddingBottom: '2rem'}}>
              <MemberGrid.Row classes={classes}  >
                <AddressContactTabs
                  hasContactPerson={hasContactPerson}
                  hasCopyRecipient={hasCopyRecipient}
                />
                <div>
                  <FormWrapper
                    title='Kommunikationsdaten'
                    children={<DefaultReduxForm sectionName='profile' inputs={inputConfigs.communication} />}
                  />
                  <FormWrapper
                    title='Geburtstag & Eintritt'
                    compProps={{paper: {style: {marginTop: '0.5rem'}}}}
                    children={<DefaultReduxForm sectionName='profile' inputs={inputConfigs.memberDates} />}
                  />
                </div>
                <div>
                <FormWrapper
                  alertBackground={!hasBankAccount}
                  //compProps={{paper: {style: {backgroundColor: 'red'}}}}
                  title='Konto Daten'
                  children={<BankAccountForm dispatch={dispatch} hasBankAccount={hasBankAccount}/>}
                />
                <FormWrapper
                  title='Mitglieitrag & Spende'
                  compProps={{paper: {style: {marginTop: '0.5rem'}}}}
                  children={<DefaultReduxForm sectionName='profile' inputs={inputConfigs.feeAndDonation} />}
                />
                </div>
                <FormWrapper
                  compProps={{bodyWrapper: {style: { height: '22rem',overflowY: 'scroll', overflowX: 'hidden',}} }}
                  title='Notizen'
                  children={<NotesForm authUser={{id: rest.authUser.id, userName: rest.authUser.username}}/>}
                />

              </MemberGrid.Row>
              <MemberGrid.Row classes={classes}>
              <div>
                <FormWrapper
                  compProps={{paper: {style: {marginTop: '0.5rem'}}}}
                  title='Mitglieder Rolle'
                  children={
                    <MiniGrid >
                      <DefaultReduxForm sectionName='roles' inputs={inputConfigs.roles} />
                      <RolesText roles={roles} />
                    </MiniGrid>}
                />
                <FormWrapper
                  compProps={{paper: {style: {marginTop: '0.5rem'}}}}
                  title='PKW & Ausbildung'
                  children={<DefaultReduxForm sectionName='profile' inputs={inputConfigs.pkwAndEducated} />}
                />
                </div>
                <FormWrapper
                  title='Tätigkeiten'
                  compProps={{bodyWrapper: {style: {maxHeight: '15rem', overflowY: 'scroll',}}, paper: {style: {}}}}
                  children={roles.provider
                      ? <DefaultReduxForm sectionName='tasks' inputs={
                          tasks.map((task)=>{
                            const {id} = task
                            return({id, type: 'checkbox', label: task.name, name:task.id, color: 'primary', size:'small', style: {paddingTop: 0, paddingBottom: 0}})
                          })} 
                        />
                      : <Typography align='center' style={{marginTop: '0.85rem'}} variant='subtitle2' color='primary' children='Ist nur bei Helfer (Angebot) sichtbar.' />
                  }
                />
                <FormWrapper
                  compProps={{bodyWrapper: {style: {maxHeight: '15rem', overflowY: 'scroll' }}}}
                  title='Dateien'
                  children={
                    <FilesForm editor={{id: rest.authUser.id, userName: rest.authUser.username}} />
                  }
                />
                <FormWrapper
                  title='Pflegekasse'
                  children={<PflegekasseForm instituts={values(instituts)} />}
                />
              </MemberGrid.Row>
            </form>
        </MemberGrid>
}
    </FullScreenDialog>

  )
}

const AddressContactTabs = ({hasContactPerson, hasCopyRecipient}) => {
  const classes = useStyles()
  const [ activeTab, setTab ] = useState('address')
  const toggleTab = (event, newValue) => {
    if(newValue==='address' && activeTab==='contactPerson')
      return setTab('address')
    if(newValue==='contactPerson' && activeTab==='address')
      return setTab('contactPerson')
  }
  return (
    <FormWrapper
      title={activeTab==='address' ? 'Mitglied Stammdaten' : 'Kontakt Person'}
    /* props={{ paper: { style: {overflow: 'scroll', maxHeight: '480px'}}}} */
    >
      <Tabs
        value={activeTab}
        onChange={toggleTab}
        indicatorColor="secondary"
        textColor="primary"
        className={classes.tabs}
        aria-label='address-tabs'
        centered
        id='address-contact-person-toggler'
      > 
        <Tab label='Mitglied' className={classes.tab} value='address' />
        <Tab
          label='Kontakt'
          className={
            (hasContactPerson)
              ? hasCopyRecipient
                ? classes.copyRecipient
                : classes.contactExists
              : classes.tab
          }
          value='contactPerson'
        />
      </Tabs>
      {hasCopyRecipient && <div className={classes.copyRecipientMsg}>Rechnungskopie aktiv!</div>}
      <div role='tabpanel' hidden={activeTab==='contactPerson'} >
        <DefaultReduxForm sectionName='profile' inputs={inputConfigs.address}/>
      </div>
      <div role='tabpanel' hidden={activeTab==='address'} >
        <DefaultReduxForm sectionName='contactPerson' inputs={inputConfigs.contactPerson} />
      </div>
    </FormWrapper>
  )
}

Member = reduxForm({
  form: 'member', // a unique identifier for this form
  onSubmit: mySubmit,
  validate,
  enableReinitialize: true,
  updateUnregisteredFields: true,
})(Member)

const selector = formValueSelector('member')

export default compose(
  firestoreConnect(() => [  
    //  { collection: 'accounting', doc: 'pflegekasse', subcollections: [{ collection: 'services' }], storeAs: 'servicesPflegekasse' },

   { collection: 'tasks', where: [['type', '==', 'nachbarschaftshilfe']], storeAs: 'tasks' },
   { collection: 'instituts'}

  ]), // or { collection: 'todos' }
  connect((state) => {
    const profile = selector(state, 'profile')
    const contactPerson = selector(state, 'contactPerson')
    const bankAccount = selector(state, 'bankAccount')
    const roles = selector(state, 'roles')
    const hasContactPerson = (contactPerson && contactPerson.name)
    //const hasCopyRecipient = contactPerson ? contactPerson.copyRecipient ? true : false : false
    const hasBankAccount = (bankAccount && Boolean(bankAccount.iban))

      return ({
        profile,
        instituts: state.firestore.data.instituts,
        authUser: state.user,
        roles: roles ? roles : {requester: false, provider: false},
        hasContactPerson,
        //hasCopyRecipient,
        hasBankAccount,
        tasks: state.firestore.ordered.tasks,
        member: state.member,
        initialValues: (state.member.id && !state.member.isNew) 
          ? state.member.data
          : null
    })}),
  )(Member)


const MiniGrid = ({children}) => {
  return <Grid container >
    {children.map((child, index)=><Grid key={index} item md={6} >{child}</Grid>)}
  </Grid>
}

const RolesText = ({roles}) =>
<Typography style={{paddingTop: '1rem'}} align='center' variant='subtitle1' color='primary'>
  {(roles.requester && roles.provider)
          ? ('Mitglied ist A/B')
          : roles.requester
            ? 'Mitglied hat Hilfebedarf'
            : roles.provider
              ? 'Mitglied ist Helfer'
              : 'Mitglied ist Passiv'
  }
</Typography>
