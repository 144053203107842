import { INSTITUTS } from '../../config/actionTypes'
import { firestore } from '../../firebase/firebase'
const institutsREF = firestore.collection('instituts')

const INIT_STATE = {
    dialog: false,
    form: {
        name: '',
        city: '',
        postCode: '',
        versNr: '',
        contactPerson: '',
        phone: '',
        street: ''
    },
    mebersResult: null,
}

function institutsReducer(state = INIT_STATE, action) {
    switch(action.type) {
        case INSTITUTS.DIALOG: {
            return({
                ...state,
                dialog: action.payload || false
            })
        }
        case INSTITUTS.INSTITUT_FORM : {
            return ({
                ...state,
                form: {
                    ...state.form,
                    [action.payload.field]: action.payload.value
                }
            })
        }
        case INSTITUTS.RESET_FROM : {
            return ({
                ...state,
                dialog: false,
                form: {
                    ...INIT_STATE.form
                }
            })
        }
        case INSTITUTS.EDIT_INSTITUT: {
            return({
                ...state,
                dialog: true,
                form: {
                    ...action.payload
                }
            })
        }
        case INSTITUTS.SAVE_INSTITUT : {
            const institutREF = institutsREF.doc(state.form.id || undefined)
            const id = state.form.id || institutREF.id
            
            const newInstitut = {
                ...state.form,
                id
            }
            institutREF.set(newInstitut)
            return ({
                ...state,
                dialog: false,
                form: {
                    ...INIT_STATE.form
                }
            })
        }
        default : return state;
    }
  }
  
  export default institutsReducer