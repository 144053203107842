    const firebaseConfig = {
    PROD: {
        apiKey: "AIzaSyBbqaXUDubrETt3qcnYEbJBnOhL7r3QTNc",
        authDomain: "genfakt-4.firebaseapp.com",
        databaseURL: "https://genfakt-4-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "genfakt-4",
        storageBucket: "genfakt-4.appspot.com",
        messagingSenderId: "152037308052",
        appId: "1:152037308052:web:4ea80472ba44f255eea681"
    },
    DEV: {
        apiKey: "AIzaSyDTJsBrBoMbJowxlhGpzRZ9JB7E92q0ZlE",
        authDomain: "testumgebung-85b49.firebaseapp.com",
        databaseURL: "https://testumgebung-85b49.firebaseio.com",
        projectId: "testumgebung-85b49",
        storageBucket: "testumgebung-85b49.appspot.com",
        messagingSenderId: "269442583176",
        appId: "1:269442583176:web:e11704ad8ce7b10cadad3d",
        measurementId: "G-10LR638PJ5"
    
    },
    EMU : {
        projectId: 'testumgebung-85b49',
        apiKey: "AIzaSyDTJsBrBoMbJowxlhGpzRZ9JB7E92q0ZlE",
        authDomain: "testumgebung-85b49.firebaseapp.com",
        storageBucket: "testumgebung-85b49.appspot.com",
        databaseURL: "https://testumgebung-85b49.firebaseio.com",
        appId: "1:269442583176:web:e11704ad8ce7b10cadad3d",     
      },
      OLD : {
            apiKey: "AIzaSyDLVnRKkC9Jpob0Cfc11yHWHBPcEYRJOQ4",
            authDomain: "genbund-fakt.firebaseapp.com",
            databaseURL: "https://genbund-fakt.firebaseio.com",
            projectId: "genbund-fakt",
            storageBucket: "genbund-fakt.appspot.com",
            messagingSenderId: "939929462134",
            appId: "1:939929462134:web:066f3f2d6948ce84990657"
      }
}

export default firebaseConfig

  