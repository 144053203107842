import React from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { dispatchHandOver } from 'store/actions/planningActions'
import Button from '@material-ui/core/Button'

const HandOverSelect = ({users, usersObj, handOver, formChangedCallback, authUser }) => {

    const handleCancelHandOver = () => {
        formChangedCallback(true)
        return dispatchHandOver({value: '', label: ''})
    }
    const handleSelectChange = userId => {
        const userName = usersObj[userId].username
        formChangedCallback(true)
        return dispatchHandOver({value: userId, label: userName})
    }

    return (
        <Paper className={handOver.value.length!==0 ? 'flashing' : ''} style={{marginBottom: '0.5rem', padding: '1rem', backgroundColor: handOver.value ? '#2ba805' : ''}}>
            <TextField 
                onChange={e=>handleSelectChange(e.target.value)}
                value={handOver.value}
                style={{minWidth: '10rem'}}
                size='small'
                label='Kolleg(in)en zuordnen'
                select
                fullWidth
                InputProps={{style: {color: 'white'}}}
                InputLabelProps={{style: {color: 'white'}}}
            >
                {users.map(user=>{
                    const menuItemDisabled = user.key===authUser.id
                    return <MenuItem disabled={menuItemDisabled} key={user.key} dense value={user.key}>{user.value.username}</MenuItem>
                })}
            </TextField>
            <Button
                disabled={handOver.value.length<3}
                size='small'
                onClick={handleCancelHandOver}
                color={handOver.value.length<3 ? 'primary' : 'secondary'}
                fullWidth
            >
                Abbrechen
            </Button>
        </Paper>
    )
}

export default connect((state, props)=>{
 
    return ({
    handOver: state.planning.event.handOver,
    users: state.firebase.ordered.users || [],
    usersObj: state.firebase.data.users
})})(HandOverSelect)