import React from 'react'
import {Grid, Typography, Switch } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch)

const MyAntSwitch = ({name, isChecked, handleChange, labelRight, labelLeft, style}) => {
 
    return (  
        <Typography component="div" style={style}>
            <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>{labelLeft || 'Off'}</Grid>
                <Grid item>
                <Switch checked={isChecked} color='primary' onChange={e=>handleChange(e.target.checked)} name={name || 'antSwitch'} />
                </Grid>
                <Grid item>{labelRight || 'On'} </Grid>
            </Grid>
        </Typography>
    )
}

export default MyAntSwitch