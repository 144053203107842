
import {generateRandomString, replaceUmlaute} from './helper'
import { DOMParser, XMLSerializer } from '@xmldom/xmldom'
import moment from 'moment'
//import {writeFile}  from './helper'


export const generateDebitXML = async ({transferItems, total, tmpXMLFilePath, callback }) => {
	const totalTransfers = transferItems.length
    const controlSum = total
	
	const _header = renderHeader(totalTransfers, controlSum)

	const _transferItems = renderTransferItems(transferItems).join("")
	
	const _XMLBody = renderXMLBody(_transferItems, total, totalTransfers)
	const doc = renderXMLDoc(_header, _XMLBody)
	const xmldoc = new DOMParser().parseFromString(doc, 'text/xml')
	const serializedXML = new XMLSerializer().serializeToString(xmldoc)
	let fileWriteError = null
    callback(serializedXML)
    return fileWriteError
}

const renderHeader = (totalTransfers, controlSum) => {
	return (
		`<GrpHdr>
			<MsgId>MSG${generateRandomString()}</MsgId>
			<CreDtTm>${moment().format('YYYY-MM-DDTHH:MM:ss')}.000Z</CreDtTm>
			<NbOfTxs>${totalTransfers}</NbOfTxs>
			<CtrlSum>${controlSum}</CtrlSum>
			<InitgPty>
		<Nm>Generationenbund BGL e.V.</Nm>
	</InitgPty>
</GrpHdr>`
	)
}

const renderTransferItems = (transferItems) => {
    return transferItems.map((transferItem)=>{
        const  total = transferItem.total
        if(total<=0) {
            return null
        }
        return (
            `<DrctDbtTxInf>
                <PmtId>
					<EndToEndId>${transferItem.id}</EndToEndId>
				</PmtId>
                <InstdAmt Ccy="EUR">${transferItem.amount.toFixed(2)}</InstdAmt>
                <DrctDbtTx>
                    <MndtRltdInf>
                        <MndtId>${transferItem.id}</MndtId>
                        <DtOfSgntr>${moment().subtract(1, 'days').format('YYYY-MM-DD')}</DtOfSgntr>
                        <AmdmntInd>false</AmdmntInd>
                    </MndtRltdInf>
                </DrctDbtTx>
                <DbtrAgt>
					<FinInstnId>
						<BIC>${transferItem.bic}</BIC>
					</FinInstnId>
                </DbtrAgt>
                <Dbtr>
                    <Nm>${replaceUmlaute(transferItem.owner)}</Nm>
                </Dbtr>
                <DbtrAcct>
					<Id>
						<IBAN>${transferItem.iban}</IBAN>
					</Id>
                </DbtrAcct>
                <RmtInf>
					<Ustrd>${transferItem.text}</Ustrd>
                </RmtInf>
            </DrctDbtTxInf>`
        )
    })
}


const renderXMLBody = (transferItems, total, totalTransfers) => {
 
	const transferDate = moment().add(2, 'days').format('dddd') === 'Sonntag'
        ? moment().add(3, 'days').format('YYYY-MM-DD')
        : moment().add(2, 'days').format('YYYY-MM-DD')
    return `<PmtInf>
                    <PmtInfId>PII${generateRandomString()}</PmtInfId>
                    <PmtMtd>DD</PmtMtd>
                    <NbOfTxs>${totalTransfers}</NbOfTxs>
                    <CtrlSum>${total}</CtrlSum>
                <PmtTpInf>
                    <SvcLvl>
                        <Cd>SEPA</Cd>
                    </SvcLvl>
                    <LclInstrm>
                        <Cd>CORE</Cd>
                    </LclInstrm>
                    <SeqTp>OOFF</SeqTp>
                </PmtTpInf>
                <ReqdColltnDt>${transferDate}</ReqdColltnDt>
                <Cdtr>
                    <Nm>Generationenbund BGL e.V.</Nm>
                </Cdtr>
                <CdtrAcct>
                        <Id>
                            <IBAN>DE37710500000020329231</IBAN>
                        </Id>
                </CdtrAcct>
                <CdtrAgt>
                    <FinInstnId>
                        <BIC>BYLADEM1BGL</BIC>
                    </FinInstnId>
                </CdtrAgt>
                <ChrgBr>SLEV</ChrgBr>
                <CdtrSchmeId>
                    <Id>
                        <PrvtId>
                            <Othr>
                                <Id>DE41ZZZ00001640665</Id>
                                <SchmeNm>
                                    <Prtry>SEPA</Prtry>
                                </SchmeNm>
                            </Othr>
                        </PrvtId>
                    </Id>
                </CdtrSchmeId>
                ${transferItems}
                </PmtInf>`}


const renderXMLDoc = (header, XMLBody) => {
	return( 
		`<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
			<Document xmlns="urn:iso:std:iso:20022:tech:xsd:pain.008.001.02" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="urn:iso:std:iso:20022:tech:xsd:pain.008.001.02 pain.008.001.02.xsd">
				<CstmrDrctDbtInitn>
					${header}
					${XMLBody}
				</CstmrDrctDbtInitn>
			</Document>`
	)
}
export default generateDebitXML