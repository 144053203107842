import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { IconButton, TextField, Typography, Button, Grid } from '@material-ui/core'
import moment from 'moment'
import { createTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import NoRowsOverlay from 'Components/DataGrid/NoRowsOverlay'
import keys from 'lodash/keys'
import { useHistory } from 'react-router-dom'
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import { recoverMembers } from 'firebase/database/membersHandler'
import selectFilterOperators from './Filter/SelectFilter'
import DetailsPopover from './DetailsPopover'
import {
   // LicenseInfo,
    DataGrid,
    //onRowClick,
    //onRowOver,
    deDE,  
    GridToolbarColumnsButton,
    //GridToolbarDensitySelector,
   // GridToolbarExport,
    GridToolbarFilterButton,
  } from '@mui/x-data-grid'

/*
LicenseInfo.setLicenseKey(
  'x0jTPl0USVkVZV0SsMjM1kDNyADM5cjM2ETPZJVSQhVRsIDN0YTM6IVREJ1T0b9586ef25c9853decfa7709eee27a1e',
)
*/
const currencyFormatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => ({
    dataGrid: {
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none',
    }
    },
    searchContainer: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    textField: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      margin: 0,
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme },
)

const columns = [
  {
    field: 'member.memberId',
    headerName: 'MG-Nr',
    editable: false,
    valueGetter: (params) => {
      return params.row.member.memberId
    },
  },
    {
      field: 'member.createdAt',
      headerName: 'Erstellt am',
      editable: false,
      valueGetter: (params) => {
        //console.log(params.row)
      
        return `${moment(params.row.member.createdAt).format('DD.MM.YYYY')}`
      },
    },
    {
      field: 'member.userName',
      headerName: 'Name',
      width: 190,
      editable: false,
      valueGetter: (params) => {
        return params.row.member.userName
      },
    },
    {
      field: 'member.profile.city',
      headerName: 'Wohnort',
      description: 'Wohnort sortieren oder Filtern',
      sortable: true,
      width: 180,
      valueGetter: (params) => {
        return `${params.row.member.profile.city}`
      },
    },
    {
      field: 'editor.name',
      headerName: 'Gelöscht von',
      editable: false,
      width: 200,
      valueGetter: (params)=>{
        return params.row.editor.name
      }
    },
    {
      field: 'timeStamp',
      headerName: 'Gelöscht am',
      width: 140,
      editable: false,
      valueGetter: (params) => {
         
        return `${moment(params.row.timeStamp).format('DD.MM.YYYY - HH:MM')}`
      },
      renderCell: params => {
        return moment(params.row.timeStamp).format('DD.MM.YYYY - HH:MM')
      },
    },
    {
      field: 'member.profile.street',
      width: 180,
      headerName: 'Straße',
      editable: false,
      valueGetter: (params) => {
        //console.log(params.row)
        return `${params.row.member.profile.street}`
      },
    },
    {
      field: 'member.profile.postCode',
      headerName: 'PLZ',
      editable: false,
      valueGetter: (params) => {
        //console.log(params.row)
        return `${params.row.member.profile.postCode}`
      },
    },
    {
      field: 'phone',
      headerName: 'Telefon',
      width: 150,
      editable: false,
      valueGetter: (params) => {
        return `${params.row.member.profile.phone}`
      },
    },
   {
      field: 'mobile',
      headerName: 'Mobil-Tel.',
      width: 150,
      editable: false,
      valueGetter: (params) => {
        return `${params.row.member.profile.mobile}`
      },
    },
    {
      field: 'email',
      headerName: 'E-Mail',
      width: 180,
      editable: false,
      valueGetter: (params) => {
        return `${params.row.member.profile.email}`
      },
      renderCell: params=>{
        return <div
          style={{width: '100%', cursor: 'pointer'}}
          title='Klick um Email Client zu öffnen'
          onClick={()=>{
          window.location.href = `mailto:${params.row.member.profile.email}`
          }}
        >{params.row.member.profile.email}</div>
      }
    },
    { 
      field: 'pflegekasse',
      headerName: 'Pflegekasse',
      //width: 90,
      align: 'center',
      editable: false,
      type: 'boolean'
    },
    { 
      field: 'roles',
      headerName: 'Rolle',
      //width: 90,
      align: 'center',
      filterOperators: selectFilterOperators,
      editable: false,
      renderCell: params => {
        const isRequester = params.row.member.roles.requester
        const isProvider = params.row.member.roles.provider
        let role =''
        role = (isRequester && isProvider)
          ? ('A/B')
          : isRequester
            ? 'Bedarf'
            : isProvider
              ? 'Angebot'
              : 'Passiv'
        return role
      },
      valueGetter: (params) => {
        const isRequester = params.row.member.roles.requester
        const isProvider = params.row.member.roles.provider
        let role =''
        role = (isRequester && isProvider)
          ? ('A/B')
          : isRequester
            ? 'Bedarf'
            : isProvider
              ? 'Angebot'
              : 'Passiv'
        return role
      }
    },
    { 
      field: 'pflegeRoles',
      headerName: 'Rolle Pflegekasse',
      //width: 90,
      align: 'center',
      filterOperators: selectFilterOperators,
      editable: false,
      renderCell: params => {
        const isRequester = params.row.member.pflegeRoles.requester
        const isProvider = params.row.member.pflegeRoles.provider
        let role =''
        role = (isRequester && isProvider)
          ? ('A/B')
          : isRequester
            ? 'Bedarf'
            : isProvider
              ? 'Angebot'
              : ''
        return role
      },
      valueGetter: (params) => {
        const isRequester = params.row.member.pflegeRoles.requester
        const isProvider = params.row.member.pflegeRoles.provider
        let role =''
        role = (isRequester && isProvider)
          ? ('A/B')
          : isRequester
            ? 'Bedarf'
            : isProvider
              ? 'Angebot'
              : ''
        return role
      }
    },
    { 
      field: 'notes',
      headerName: 'Infos',
      //width: 200,
      align: 'left',
      editable: false,
      filterable: false,
      sortable: false,
      renderCell: params => {
        const { notes } = params.row.member.profile
        if(notes && notes.length!==0) {
          const renderNotes = keys(notes).map(noteId=>{
            return notes[noteId].text
          })
          return <DetailsPopover items={renderNotes} /> //<Typography  variant='caption' >{notes.length}</Typography>
        }
          //return params.row.profile.notes[0].text
      }
    },
    { 
      field: 'birthmonth',
      headerName: 'Geburtsmonat',
      editable: false,
      filterOperators: selectFilterOperators,
      align: 'center',
      valueGetter: (params) => {
        const birthday = params.row.member.profile.birthday
        if(!birthday || birthday.length===0)
          return ''
        const birthMonth = moment(params.row.member.profile.birthday).format('MMMM')
        if(birthMonth==='Invalide data')
          return ''
        return birthMonth
       
        /*
        const split = params.row.profile.birthday.split('.')
        const convertedDate = `${split[2]}-${split[1]}-${split[0]}`
        const birthMonth = moment(convertedDate).format('MMMM')
        if(birthMonth==='Invalid date')
          return ''
        return birthMonth
        */
      },
    },
    { 
      field: 'birthday',
      headerName: 'Geburtstag',
      type:'date',
      editable: false,
      align: 'center',
      valueFormatter: (params) => {
        if(!params.value || params.value.length===0)
          return ''
        return moment(params.value).format('DD.MM.YYYY')
      },
      valueGetter: params=>params.row.member.profile.birthday
      /*
      sortComparator: (v1, v2, param1, param2) => {
        param1.api.getCellValue(param1.id, 'birthday') -
        param2.api.getCellValue(param2.id, 'birthday')
      }
      */
    },
    {
      field: 'membershipFee',
      align: 'center',
      headerName: 'Beitrag',
      with: 14,
      type: 'string',
      valueGetter: params=>params.row.member.profile.membershipFee,
      valueFormatter: ({value})=>{
        if(!value || Number(value)===0 )
          return ''
        return currencyFormatter.format(Number(value))
      }
    },
    {
      field: 'donation',
      align: 'right',
      headerName: 'Spende',
      type: 'string',
      with: 14,
      valueGetter: params=>params.row.member.profile.donation,
      valueFormatter: ({value})=>{
        if(!value || Number(value)===0)
          return ''
        return currencyFormatter.format(Number(value))
      }
    },
    {
      field: 'educated',
      align: 'center',
      headerName: 'Ausbildung',
      type: 'boolean',
      valueGetter: params=>params.row.member.profile.educated,
      valueFormatter: ({value})=>value ? 'Ja' : 'Nein'
  
    },
    {
      field: 'car',
      align: 'center',
      headerName: 'PKW',
      type: 'boolean',
      valueGetter: params=>params.row.member.profile.car,
      valueFormatter: ({value})=>value ? 'Ja' : 'Nein',
    },
    {
      field: 'createdAt',
      headerName: 'Erstellt am',
      editable: false,
      type: 'date',
      valueFormatter: (params) => {
        return moment(params.value).format('DD.MM.YYYY')
      }
    },
    
  ]

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
  

const MembersArchiv = ({membersDeleted, isLoaded, firestore, authUser}) => {
  const history = useHistory()
  const classes = useStyles()
  const [filterModel, setFilterModel] = useState({ items: [] })
  const [rows, setRows] = useState([])
  const [searchText, setSearchText] = useState('')
  const [selectedRows, setSelectedRows] = useState({ids: [], data: []})
  useEffect(() => {
      setRows(membersDeleted)
      return () => {
        //   setSelectedRows(ids: [], data: [])
      }
  }, [membersDeleted])

  const requestSearch = (searchValue) => {
  setSearchText(searchValue)
  const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')

  const filteredRows = membersDeleted.filter((row) => {
      let results = []
      results.push(searchRegex.test(row.member.userName))
      results.push(searchRegex.test(moment(row.timeStamp).format('DD.MM.YYYY').toString()))
      return results.includes(true)

  })
    setRows(filteredRows);
  }
  /*
  const handleRowClick = row => {
    console.log(row)
  }
  */
  const handleSelectedRows = () => {
    const fsBatch = firestore.batch()
    const deletedMembersREF = firestore.collection('membersDeleted')
    selectedRows.ids.forEach(memberId=>{
      const deletedMemberREF = deletedMembersREF.doc(memberId)
      fsBatch.delete(deletedMemberREF)
    })
    fsBatch.commit()
    return setSelectedRows({ids: [], data: []})
  }

  const handleRecoverMembers = () => {
    recoverMembers(selectedRows.data, authUser)
    //fsBatch.commit()
    
  }

  return (
  <div>   
    <Grid container style={{marginBottom: '1rem'}}>
      <Grid item md={6} >
        <Typography variant="h6" gutterBottom >Gelöschte Mitglieder</Typography>
        </Grid>
          <Grid item md={6} style={{textAlign: 'right'}}>
            <Button startIcon={<ArrowBackIcon />} size='small' variant='outlined' color='primary' onClick={()=>history.goBack( )} >
              Zurück
        </Button>
      </Grid>
      </Grid>
      <div style={{ display: 'flex', minHeight: 400 }}>
        <div style={{ flexGrow: 1 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              editMode='row'
              initialState={{
                sorting: {
                  sortModel: [{ field: 'member.memberId', sort: 'desc' }],
                },
              }}
              density='compact'
              checkboxSelection
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids)
                const _selectedRows = membersDeleted.filter((row) =>
                  selectedIDs.has(row.id),
                )
                return setSelectedRows({ids: ids, data:_selectedRows})
              }}
              selectionModel={selectedRows.ids}
              disableSelectionOnClick
          
              filterModel={filterModel}
              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              onFilterModelChange={(model) => setFilterModel(model)}
              className={classes.dataGrid}
              allowFiltering={true}
              loading={!isLoaded}
              //onRowClick={row=>{handleRowClick(row)}}
              components={{ 
                  Toolbar: QuickSearchToolbar,
                  NoRowsOverlay: ()=><NoRowsOverlay title='Keine Mitglieder gefunden' />
              }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(''),
                  onDeleteForEverClick: handleSelectedRows,
                  onRecoverClick: handleRecoverMembers,
                  selectedRowsCount: selectedRows.ids.length,
                },
              }}
            />            
          </div>
      </div>
  </div>
  )
}

function QuickSearchToolbar(props) {
  
    const classes = useStyles()
    const isDisabled = props.selectedRowsCount===0
    return (
        <div className={classes.searchContainer} style={{margin: '1rem 0 1.5rem 0'}}>
            <div>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </div>
            <div>
              <Button
                disabled={isDisabled}
                size='small'
                color='secondary'
                variant='outlined'
                startIcon={<DeleteIcon disabled={isDisabled} color={isDisabled ? 'disabled' : 'secondary'} />}
              // style={{color: props.selectedRowsCount<=0 ? 'lightgrey' : '#1976d2'}}
                onClick={props.onDeleteForEverClick}
                //disabled={props.selectedRowsCount<=0}
              >
                Endgültig löschen
            </Button>
            <Button
                disabled={isDisabled}
                size='small'
                color='primary'
                variant='outlined'
                startIcon={<RestoreFromTrashIcon color={isDisabled ? 'disabled' : 'primary'} />}
              // style={{color: props.selectedRowsCount<=0 ? 'lightgrey' : '#1976d2'}}
                onClick={props.onRecoverClick}
                //disabled={props.selectedRowsCount<=0}
              >
                Wiederherstellen
            </Button>
            </div>
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Suchen…"
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                    <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{ visibility: props.value ? 'visible' : 'hidden' }}
                        onClick={props.clearSearch}
                    >
                        <ClearIcon fontSize="small" />
                    </IconButton>
                    ),
            }}
            />
      </div>
    )
}


export default compose(
    firestoreConnect(({activeView}) => {
      return [  
        { collection: 'tasks', where: [['type', '==', 'nachbarschaftshilfe']], storeAs: 'nhTasks' },
      //  { collection: 'accounting', doc: 'pflegekasse', subcollections: [{ collection: 'services' }], storeAs: 'servicesPflegekasse' },
     // { collection: 'tasks', where: [['type', '==', 'nachbarschaftshilfe']], storeAs: 'tasks' },
      { collection: 'membersDeleted'},
  
    ]}), // or { collection: 'todos' }
    connect((state, props) => {
        return ({
        tasks: state.firestore.data.nhTasks,
        authUser: state.user,
        isLoaded: isLoaded(state.firestore.data.membersDeleted),
        //membersDeleted: state.firestore.ordered.membersDeleted || [],
        //member: state.firestore.data.members ? state.firestore.data.members['0Gcc9zcifuxJ0zKNoPDK'] : undefined,
        membersDeleted: state.firestore.ordered.membersDeleted || [], 
      })}),
    )(MembersArchiv)