import { dispatch } from '../index.js'
import { MAILSENDER } from 'config/actionTypes'


export const setRecipients = recipients =>
    dispatch({type: MAILSENDER.SET_RECIPIENTS, payload: recipients})

export const addRecipient = (newRecipient) =>
    dispatch({type: MAILSENDER.ADD_RECIPIENT, payload: newRecipient})

export const removeRecipient = (recipientToRemove) =>
    dispatch({type: MAILSENDER.REMOVE_RECIPIENT, payload: recipientToRemove})

export const setSubjectAndBody = payload =>
    dispatch({type: MAILSENDER.SET_SUBJECT_AND_BODY, payload})

export const setAttachments = attachments => 
    dispatch({type: MAILSENDER.SET_ATTACHMENTS, payload: attachments})

export const addAttachment = attachment => 
    dispatch({type: MAILSENDER.ADD_ATTACHMENT, payload: attachment})

export const removeAttachment = attachment =>
    dispatch({type: MAILSENDER.REMOVE_ATTACHMENT, payload: attachment})

const actions = {
    addRecipient,
    removeRecipient,
    setSubjectAndBody,
    setAttachments,
    addAttachment,
    removeAttachment,
}

export default actions

