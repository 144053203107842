import { dispatch } from '../index.js'
import { INSTITUTS } from 'config/actionTypes'

export const dispatchInstitutForm = payload =>
    dispatch({type: INSTITUTS.INSTITUT_FORM, payload})

export const dispatchResetFrom = () => 
    dispatch({type: INSTITUTS.RESET_FROM})

export const dispatchSaveInstitut = () =>
    dispatch({type: INSTITUTS.SAVE_INSTITUT})

export const dispatchDialogOpen = state => {
    dispatch({type: INSTITUTS.DIALOG, payload: state})
}

export const dispatchEditInstitut = payload =>
    dispatch({type: INSTITUTS.EDIT_INSTITUT, payload})