import React, { useState } from 'react'
import CardGrid from  'Components/Cards/DefaultCardGrid'
import { dispatch } from 'store/index'
import { actionTypes } from 'config/config'
import ButtonBoard from 'Components/Buttons/ButtonRow'
import TitleCard from 'Components/Title/TitleCard'
import ErrorMessage from 'Components/Messages/ErrorMessage'
import moment from 'moment'
import generateInvoiceDataMembers from './generateInvoiceDataMembers'
import { db } from '../../../../../firebase/firebase'
//import { Typography } from '@material-ui/core'

const invoiceResultsRef = db.ref('/invoiceResults/pflegekasseMembers')

const AccountingMonthView = ({selectedInvoices, tasks, editor}) => {

	const [ error, setError ]= useState(undefined)
	const handleCardClick = selectedInvoice => {
		return dispatch({type: actionTypes.SET_ACCOUNTING_INVOICE, payload: selectedInvoice})
	}
	const handleBack = ()=>{
		return dispatch({type: actionTypes.SET_ACCOUNTING_MONTH, payload: undefined})
	}

	const handleStartAccounting = async () => {
		// Write Result Info

		invoiceResultsRef.update({
			'text/0': 'Start accounting' , 
			'isLoading': true
		})
		
		generateInvoiceDataMembers({ selectedInvoices, tasks, editor })	
		
		
		
		/*
		const confirmStartAccounting = async () => {
			invoiceResultsRef.update({
				'text/0': 'Start accounting' , 
				'isLoading': true
			})
			dialog('reset')
			//dispatch({type: actionTypes.START_ACCOUNTING, payload: true})
			const INVOICES = await generateInvoiceData({ selectedInvoices, tasks, editor })	
			return
		}
		/*
		dialog({
			isOpen: true,
			text: `Wollen Sie die Abrechnung starten?`,
			icon: 'help',
			title: 'Abrechnung starten',
			buttons: [
				{title: 'Abbrechen', onClick:confirmStartAccounting, variant: 'outlined', color: 'secondary'},
				{title: 'Starten', onClick:confirmStartAccounting, color: 'primary'}
			]
		})
		*/
		//return dispatch({type: actionTypes.START_ACCOUNTING, payload: true})
	}

	const cardsData = selectedInvoices.map((invoice) => {
	
		return ({
			style: {
				subheader: {backgroundColor: 'red'}
			},
			header: {
				avatar: {
					content: invoice.services.length
				},
				title: invoice.requester.userName,
				subheader: `${invoice.id}`
			},
			item: invoice,	
		})
	})
	const accountingMonth = selectedInvoices.length>0 && `${moment(selectedInvoices[0].date).format('MMMM') || 'FEHLER'}`
	const accountingYear = selectedInvoices.length>0 && `${selectedInvoices[0].year || 'FEHLER'}`
	const title = `${accountingYear} -> ${accountingMonth}`
	if(error)
		return <ErrorMessage error={error} callback={setError}/>
	
	return (
		<>
			<TitleCard title={title} />
			<CardGrid data={cardsData} spacing={1} onCardClick={handleCardClick}/>
			<ButtonBoard
				buttons={[
						{ title: 'Zurück', onClick:handleBack, variant: 'outlined', color: 'primary' },
						{ title:  `${accountingMonth} abrechnen`, onClick: handleStartAccounting }
					]} 	
			/>
		</>
	)
}


export default AccountingMonthView