import React, {createRef} from 'react'
import Container from '@material-ui/core/Container'
import Table from 'Components/DataGrid/DefaultDataGrid'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import moment from 'moment'
import tasksFilterOperators from './Filter/TasksFilter'
import selectFilterOperators from './Filter/SelectFilter'
import { actionTypes } from 'config/actionTypes'
import keys from 'lodash/keys'
//import { editMember } from 'store/actions/memberActions'
import DetailsPopover from './DetailsPopover'
import excelConverter from './excelConverter'

const currencyFormatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

const getColumns = (tasks) =>[
  {
    field: 'memberId',
    headerName: 'MG-Nr',
    width: 70,
    editable: false,
  },
  {
    field: 'salutation',
    hide: true,
    headerName: 'Anrede',
    valueGetter: params =>
      `${params.row.profile.salutation}`
  },
  {
    field: 'userName',
    headerName: 'Name',
    width: 180,
    editable: false,
  },
  {
    field: 'street',
    hide: true,
    headerName: 'Straße',
    valueGetter: params =>
      `${params.row.profile.street}`
  },
  {
    field: 'postCode',
    hide: true,
    headerName: 'Postleistzahl',
    valueGetter: params =>
    `${params.row.profile.postCode}`
  },
  {
    field: 'city',
    headerName: 'Wohnort',
    description: 'Wohnort sortieren oder Filtern',
    sortable: true,
    width: 180,
    valueGetter: (params) => {
      return `${params.row.profile.city}`
    },
  },
  {
    field: 'phone',
    headerName: 'Telefon',
    width: 150,
    editable: false,
    valueGetter: (params) => {
      return `${params.row.profile.phone}`
    },
  },
 {
    field: 'mobile',
    headerName: 'Mobil-Tel.',
    width: 150,
    editable: false,
    valueGetter: (params) => {
      return `${params.row.profile.mobile}`
    },
  },
  {
    field: 'email',
    headerName: 'E-Mail',
    width: 180,
    editable: false,
    valueGetter: (params) => {
      return `${params.row.profile.email}`
    },
    renderCell: params=>{
      return <div
        style={{width: '100%', cursor: 'pointer'}}
        title='Klick um Email Client zu öffnen'
        onClick={()=>{
        window.location.href = `mailto:${params.row.profile.email}`
        }}
      >{params.row.profile.email}</div>
    }
  },
  { 
    field: 'tasks',
    headerName: 'Tätigkeiten',
   // width: 200,
    editable: false,
    //filterable: false,
    sortable: false,
    align: 'center',
    filterOperators: tasksFilterOperators,
    renderCell: params => {
      const selectedTasks = params.row.tasks
      if(!selectedTasks)
        return null
      const renderTasks = keys(selectedTasks).map(selTaskId=>{
        const task = tasks[selTaskId]
        if(!task) {
          return 'Tätgikeit wurde gelöscht'
        }
        return task.name
      })
      return <DetailsPopover items={renderTasks} />
    },
    valueGetter: (params) => {
      const selectedTasks = params.row.tasks
      if(selectedTasks){
        const taskIds = keys(selectedTasks)
        return taskIds
      }
    },
 
  },
  { 
    field: 'pflegekasse',
    headerName: 'Pflegekasse',
    //width: 90,
    align: 'center',
    editable: false,
    type: 'boolean'
  },
  { 
    field: 'roles',
    headerName: 'Rolle',
    //width: 90,
    align: 'center',
    filterOperators: selectFilterOperators,
    editable: false,
    renderCell: params => {
      const isRequester = params.row.roles.requester
      const isProvider = params.row.roles.provider
      let role =''
      role = (isRequester && isProvider)
        ? ('A/B')
        : isRequester
          ? 'Bedarf'
          : isProvider
            ? 'Angebot'
            : 'Passiv'
      return role
    },
    valueGetter: (params) => {
      const isRequester = params.row.roles.requester
      const isProvider = params.row.roles.provider
      let role =''
      role = (isRequester && isProvider)
        ? ('A/B')
        : isRequester
          ? 'Bedarf'
          : isProvider
            ? 'Angebot'
            : 'Passiv'
      return role
    }
  },
  { 
    field: 'pflegeRoles',
    headerName: 'Rolle Pflegekasse',
    //width: 90,
    align: 'center',
    filterOperators: selectFilterOperators,
    editable: false,
    renderCell: params => {
      const isRequester = params.row.pflegeRoles.requester
      const isProvider = params.row.pflegeRoles.provider
      let role =''
      role = (isRequester && isProvider)
        ? ('A/B')
        : isRequester
          ? 'Bedarf'
          : isProvider
            ? 'Angebot'
            : ''
      return role
    },
    valueGetter: (params) => {
      const isRequester = params.row.pflegeRoles.requester
      const isProvider = params.row.pflegeRoles.provider
      let role =''
      role = (isRequester && isProvider)
        ? ('A/B')
        : isRequester
          ? 'Bedarf'
          : isProvider
            ? 'Angebot'
            : ''
      return role
    }
  },
  { 
    field: 'notes',
    headerName: 'Infos',
    //width: 200,
    align: 'left',
    editable: false,
    filterable: false,
    sortable: false,
    renderCell: params => {
      const { notes } = params.row.profile
      if(notes && notes.length!==0) {
        const renderNotes = keys(notes).map(noteId=>{
          return notes[noteId].text
        })
        return <DetailsPopover items={renderNotes} /> //<Typography  variant='caption' >{notes.length}</Typography>
      }
        //return params.row.profile.notes[0].text
    }
  },
  { 
    field: 'birthmonth',
    headerName: 'Geburtsmonat',
    editable: false,
    filterOperators: selectFilterOperators,
    align: 'center',
    valueGetter: (params) => {
      const birthday = params.row.profile.birthday
      if(!birthday || birthday.length===0)
        return ''
      const birthMonth = moment(params.row.profile.birthday).format('MMMM')
      if(birthMonth==='Invalide data')
        return ''
      return birthMonth
     
      /*
      const split = params.row.profile.birthday.split('.')
      const convertedDate = `${split[2]}-${split[1]}-${split[0]}`
      const birthMonth = moment(convertedDate).format('MMMM')
      if(birthMonth==='Invalid date')
        return ''
      return birthMonth
      */
    },
  },
  { 
    field: 'birthday',
    headerName: 'Geburtstag',
    type:'date',
    editable: false,
    align: 'center',
    valueFormatter: (params) => {
      if(!params.value || params.value.length===0)
        return ''
      return moment(params.value).format('DD.MM.YYYY')
    },
    valueGetter: params=>params.row.profile.birthday
    /*
    sortComparator: (v1, v2, param1, param2) => {
      param1.api.getCellValue(param1.id, 'birthday') -
      param2.api.getCellValue(param2.id, 'birthday')
    }
    */
  },
  {
    field: 'membershipFee',
    align: 'center',
    headerName: 'Beitrag',
    with: 14,
    type: 'string',
    valueGetter: params=>params.row.profile.membershipFee,
    valueFormatter: ({value})=>{
      if(!value || Number(value)===0 )
        return ''
      return currencyFormatter.format(Number(value))
    }
  },
  {
    field: 'donation',
    align: 'right',
    headerName: 'Spende',
    type: 'string',
    with: 14,
    valueGetter: params=>params.row.profile.donation,
    valueFormatter: ({value})=>{
      if(!value || Number(value)===0)
        return ''
      return currencyFormatter.format(Number(value))
    }
  },
  {
    field: 'educated',
    align: 'center',
    headerName: 'Ausbildung',
    type: 'boolean',
    valueGetter: params=>params.row.profile.educated,
    valueFormatter: ({value})=>value ? 'Ja' : 'Nein'

  },
  {
    field: 'car',
    align: 'center',
    headerName: 'PKW',
    type: 'boolean',
    valueGetter: params=>params.row.profile.car,
    valueFormatter: ({value})=>value ? 'Ja' : 'Nein',
  },
  {
    field: 'createdAt',
    headerName: 'Erstellt am',
    editable: false,
    type: 'date',
    valueFormatter: (params) => {
      return moment(params.value).format('DD.MM.YYYY')
    }
    /*
    sortComparator: (v1, v2, param1, param2) => {
      console.log(v1, v2)
      
    },
    */
    /*
    valueGetter: (params) => {
      return params.row.createdAt
    },
  */
  },

/*  {
    field: 'Actions',
    type: 'actions',
    editable: false,
    getActions: (params) => [
      <button onClick={()=>alert('NASE')}>test</button>,
    ]
  }*/
]


class MemberList extends React.Component {
  tableRef = createRef()
  //const classes = useStyles();
  //const [page, setPage] = React.useState(0);
 // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  handleRowClick = selectedRow => {
    return this.props.onRowClickCallback(selectedRow.row)
  }

  handleMailSender = _data => {
  
    const data = []
    _data.forEach(member=>{
      const { email  } = member.profile
      if(email.length<=5) {
        return null
      }
      const recipient = {name: member.userName, address: email}
      data.push(recipient)
    })
    return this.props.dispatch({type: actionTypes.MAILSENDER.SET_RECIPIENTS, payload: data})
  }

  handleExcelExport = selectedRows => {
    const excelColumns = getColumns(this.props.tasks)
    excelConverter({columns: excelColumns, data: selectedRows.data})
     //exportToExcel(excelData, 'file-name')
     return
  }
  render() {
   
    const columns = getColumns(this.props.tasks)
    return (
      <Container >
        <Table
          onRowClickCallback={this.handleRowClick}
          data={{rows: this.props.members, columns }}
          mailSenderCallback={this.handleMailSender}
          exportExcelCallback={this.handleExcelExport}
        />
      </Container>
    )
}
}
export default compose(
  firestoreConnect(({activeView}) => [  
    //  { collection: 'accounting', doc: 'pflegekasse', subcollections: [{ collection: 'services' }], storeAs: 'servicesPflegekasse' },
    { collection: 'tasks', where: [['type', '==', 'nachbarschaftshilfe']], storeAs: 'nhTasks' },
    { collection: 'members'},

  ]), // or { collection: 'todos' }
  connect((state, props) => {
      return ({
      tasks: state.firestore.data.nhTasks,
      //membersDeleted: state.firestore.ordered.membersDeleted || [],
      //member: state.firestore.data.members ? state.firestore.data.members['0Gcc9zcifuxJ0zKNoPDK'] : undefined,
      members: state.firestore.ordered.members, 
    })}),
  )(MemberList)


