import { actionTypes } from '../../config'
//import {firestore } from '../../firebase/firebase'
//import {setData, setBankAccount, setError, delError, addInfo, updateInfo, delInfo, showMember, editMember, resetMember, setTasks, setPflegeData  } from '../actions/memberActions'
//import { getTasks } from 'firebase/database/tasksHander'
//import moment from 'moment'

const INIT_STATE = {
	isOpen: false,
	isLoading: true,
	isNew: true,
	id: null,
	title: null,
	data: null,
}
function memberReducer(state = INIT_STATE, action) {
	switch(action.type) {
		case actionTypes.MEMBER.new : {
			return ({
				isOpen: true,
				isLoading: false,
				isNew: true,
				id: 'new-member',
				title: 'Neues Mitglied',
				data: null

			})
		}
		case actionTypes.MEMBER.edit : {
			const {id, profile : { lastName, firstName }} = action.payload
			const newData = { ...action.payload}
			if(newData.institut) {
				newData.institut = action.payload.institut.id
			}
			return ({
				...state,
				id,
				isOpen: true,
				isLoading: false,
				isNew: false,
				title: `${lastName} ${firstName}`,
				data: newData
			})
		}
		case actionTypes.MEMBER.isLoading : {
			return ({
				...state,
				isLoading: !state.isLoading,
			})
		}
		case actionTypes.MEMBER.isOpen : {
			return ({
				...state,
				isOpen: !state.isOpen
			})
		}
		case actionTypes.MEMBER.close : {
			return INIT_STATE
		}
		default : return state
	}
}
/*
const validateNewMember = state => {
	const { addressData } = state
	const { firstName, lastName, street, postCode, city} = addressData.values
	const isValide = (firstName.length>=3) && (lastName.length>3) && (street.length>3) && (postCode.length>=4) && (city.length>=3)
	
	return isValide
}

export const INIT_STATE = { 
	formChanged: false,
	showMember: {
		id: '',
		value: false,
		edit: false,
		title: 'Neues Mitglied',
		set: title=>showMember(title)
	},
	editMember:{
		set: member=>editMember(member),
		reset: ()=>resetMember
	},
	addressData: {
		values: {
			salutation: '',
			nameSufix: '',
			firstName: '',
			lastName: '',
			street: '',
			postCode: '',
			city: ''
		},
		errors: {},
		functions: {
			setData: value=>setData('addressData', value),
			setError: value=>setError('addressData', value),
			delError:value=>delError('addressData', value),
		},
		isLoaded: false,
	},
	communicationData: {
		values: {
			phone: '',
			mobile: '',
			email: '',
		},
		errors: {},
		functions: {
			setData: value=>setData('communicationData', value),
			setError: value=>setError('communicationData', value),
			delError:value=>delError('communicationData', value),
		}
	},
	birthdayData: {
		values: {
			birthday: ''
		},
		errors: {},
		functions: {
			setData: value=>setData('birthdayData', value),
			setError: value=>setError('birthdayData', value),
			delError:value=>delError('birthdayData', value),
		}
	},
	bankData: {
		values: {
			iban: '',
			bic: '',
			id: '',
			institutName: '',
			city: ''
		},
		functions: {
			set: values=>setBankAccount(values),
		}
	},
	entryData:{
		values: {
			entryDate: moment().format('DD.MM.YYYY'),
		},
		errors: {},
		functions: {
			setData: value=>setData('entryData', value),
			setError: value=>setError('entryData', value),
			delError: value=>delError('entryData', value)
		}
	},
	infoData: {
		values: [],
		functions: {
			addInfo: value=>addInfo(value),
			delInfo: value=>delInfo(value),
			updateInfo: value=>updateInfo(value),
		}
	},
	memberTypes: {
		values: {requester: false, provider: false},
		functions: {
			setData: value=>setData('memberTypes', value)
		}
	},
	pkwData: {
		values: {
			pkw: false,
		},
		functions: {
			setData: value=>setData('pkwData', value)
		}
	},
	pflegeData: {
		values: {
			pflegekasse: false,
			institut: undefined,
			requester: false,
			provider: false,
			versNr: ""
		},
		errors: {},
		functions: {
			setData: value=>setPflegeData(value),
			setError: value=>setError('pflegeData', value),
			delError: value=>delError('pflegeData', value)
		}
	},
	annualFeeData: {
		values: {
			annualFee: 10,
			donation: 0,
		},
		errors: {},
		functions: {
			setData: value=>setData('annualFeeData', value),
			setError: value=>setError('annualFeeData', value),
			delError: value=>delError('annualFeeData', value)
		}

	},
	taskData: {
		values: [],
		errors: {},
		functions: {
			setData: values=>setTasks(values),
			setError: value=>setError('taskData', value),
			delError: value=>delError('taskData', value)
		}
	},
	filesData: {
		values: [],
		uploadProgress: undefined,
		functions: {
			
		}
	}
}

function memberReducer(state = INIT_STATE, action) {
    
    switch(action.type) {
		case actionTypes.MEMBER.SHOW_MEMBER : {
			console.log('IN SHOW MEMBER')
			if(state.showMember.value) {
				return INIT_STATE
			}
			return ({
				...state,
				showMember: {
					...state.showMember,
					edit: false,
					value: !state.showMember.value,
					title: action.payload || INIT_STATE.showMember.title,
					id: firestore.collection('members').doc().id,
				}
			})
		}
		case actionTypes.MEMBER.FORM_CHANGED : {
			return ({
				...state,
				formChanged: action.payload || !state.formChanged
			})
		}
		case actionTypes.MEMBER.EDIT_MEMBER : {
			const { id, files, bankAccount, userName, roles, pflegekasse, tasks, institut, pflegeRoles, versNr,
				profile: { entryDate, salutation, nameSufix, firstName, lastName, street, postCode, city, phone, mobile, email, birthday, info, car, membershipFee, donation }} = action.payload
			
			return ({
				...state,
				formChanged: state.formChanged,
				showMember: { 
					...state.showMember,
					value: true,
					edit: true,
					title: userName,
					id: id },
				addressData: {
					...state.addressData,
					values: {
						salutation,
						nameSufix,
						firstName,
						lastName,
						street,
						postCode,
						city
					}
				},
				communicationData: {
					...state.communicationData,
					values: {
						phone,
						mobile,
						email
					}
				},
				birthdayData: {
					...state.birthdayData,
					values: {
						birthday: birthday
					}
				},
				bankData: {
					...state.bankData,
					values: {
						...bankAccount
					}

				},
				entryData: {
					...state.entryData,
					values: {
						entryDate: entryDate
							? moment(entryDate).format('DD.MM.YYYY')
							: moment().format('DD.MM.YYYY')
					}

				},
				infoData: {
					...state.infoData,
					values: typeof info==='object' ? [...info] : []
				},
				memberTypes: {
					...state.memberTypes,
					values: {
						...roles
					}
				},
				pkwData: {
					...state.pkwData,
					values: {
						pkw: car
					}
				},
				pflegeData: {
					...state.pflegeData,
					values: {
						id: institut ? institut.id : '',
						pflegekasse: Boolean(pflegekasse),
						institut: institut ? institut : undefined,
						versNr: (Boolean(pflegekasse) && pflegeRoles.requester) ? versNr ? versNr : '' : '' ,
						provider: Boolean(pflegekasse) ? pflegeRoles ? pflegeRoles.provider : false : false,
						requester: Boolean(pflegekasse) ? pflegeRoles ? pflegeRoles.requester : false : false,
					}
				},
				annualFeeData: {
					...state.annualFeeData,
					values: {
						annualFee: Number(membershipFee),
						donation: donation || 0,
					}
				},
				taskData: {
					...state.taskData,
					values: tasks || []
				},
				filesData: {
					...state.filesData,
					values: files || []
				}
			})
		}
		case actionTypes.MEMBER.RESET_MEMBER : {
			return ({
				...state,
				editMember: {
					...state.editMember,
					value: {}
				}
			})
		}
		case actionTypes.MEMBER.SET_BANK_ACCOUNT : {
			const values = action.payload
			return ({
				...state,
				formChanged: true,
				bankData: {
					...state.bankData,
					values
				}
			})
		}
		case actionTypes.MEMBER.SET_DATA : {
		
			const { formName, propKey, value } = action.payload	
			return ({
				...state,
				formChanged: validateNewMember(state),
				[formName]: {
					...state[formName],
					values: {
						...state[formName].values,
						[propKey]: value
					}
				}
			})
		}
		case actionTypes.MEMBER.SET_ERROR : {
			const { formName, propKey, value } = action.payload
			return ({
				...state,
				[formName]: {
					...state[formName],
					errors: {
						...state[formName].errors,
						[propKey]: value
					}
				}
			})
		}
		case actionTypes.MEMBER.DEL_ERROR : {
			const newErrors = {...state.addressData.errors}
			const { formName, propKey } = action.payload
			delete newErrors[propKey]
			return ({
				...state,
				[formName]: {
					...state[formName],
					errors: {
						...newErrors
					}
				}
			})
		}
		case actionTypes.MEMBER.ADD_INFO : {
			const values = [...state.infoData.values]
			values.push(action.payload)
			return ({
				...state,
				formChanged: true,
				infoData: {
					...state.infoData,
					values
				}
			})
		}
		case actionTypes.MEMBER.UPDATE_INFO : {
			return ({
				...state,
				formChanged: true,
				infoData: {
					...state.infoData,
					values: action.payload,
				}
			})
		}
		case actionTypes.MEMBER.DEL_INFO : {
			const values = [...state.infoData.values]
			let index = action.payload
			if(index===0)
				index=-1
			values.splice(index, 1)
			return ({
				...state,
				formChanged: true,
				infoData: {
					...state.infoData,
					values
				}
			})
		}
		case actionTypes.MEMBER.SELECTED_TASKS : {
			return ({
				...state,
				//formChanged: true,
				taskData: {
					...state.taskData,
					values: action.payload
				}
			})
		}
		case actionTypes.MEMBER.SET_PFLEGE_DATA : {
			/*
			pflegekasse: false,
			institut: {name: '', id:''},
			requester: false,
			provider: false,
			versNr: ""
		
		
			return ({
				...state,
				formChanged: true,
				pflegeData: {
					...state.pflegeData,
					values: {
						...state.pflegeData.values,
						[action.payload.propKey]: action.payload.value

					}
				}
			})
		}
		case actionTypes.SET_MEMBER : {
			return ({
				...state,
				values: action.payload,
				isLoaded: true
			})
		}
		case actionTypes.MEMBER.FILE_UPLOAD_PROGRESS: {
			let uploadProgress = action.payload.progress
			return ({
				...state,
				filesData: {
					...state.filesData,
					uploadProgress,
				}
			})
		}
		case actionTypes.MEMBER.UPDATE_UPLOADED_FILES: {
			return ({
				...state,
				filesData: {
					...state.filesData,
					values: action.payload,
				}
			})
		}
		case actionTypes.MEMBER.SAVE : {
			const fsBatch = firestore.batch()
			const id = state.showMember.id
			const memberRef = firestore.collection('members').doc(id)
		
			const bankAccountRef = firestore.collection('bankAccounts').doc(id)
			const addressData = state.addressData.values
			const communicationData = state.communicationData.values
			const annualFeeData = state.annualFeeData.values
			const bankData = state.bankData.values
			const birthdayData = state.birthdayData.values
			const entryData = state.entryData.values
			const infoData = state.infoData.values
			const memberTypes = state.memberTypes.values
			const pflegeData = state.pflegeData.values
			const pkwData = state.pkwData.values
			//const _taskData = state.taskData.values
			const filesData = state.filesData.values
			const taskData = state.taskData.values.filter(task=>task.checked)
			//console.log('ENTRY DATE', entryData.entryDate)
			/*
			console.log('addressData', addressData)
			console.log('communicationData', communicationData)
			console.log('annualFeeData', annualFeeData)
			console.log('bankData', bankData)
			console.log('birthdayData', birthdayData)
			console.log('entryData', entryData)
			console.log('infoData', infoData)
			console.log('memberTypes', memberTypes)
			console.log('pflegeData', pflegeData)
			console.log('pkwData', pkwData)
			console.log('taskData', taskData)
			
			
			const dateArr = entryData.entryDate.split('.')
			const _entryDate = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`

			
			const hasBankAccount = bankData ? bankData.iban ? bankData.iban.length>8 : false :false
			if(!addressData.salutation)
				addressData.salutation = ''
			const newMember = {
				id,
				profile: {
					...addressData,
					...communicationData,
					name: `${addressData.firstName} ${addressData.lastName}`,
					membershipFee: Number(annualFeeData.annualFee),
					donation: Number(annualFeeData.donation),
					birthday: birthdayData.birthday.length>2 ? birthdayData.birthday : '',
					entryDate: _entryDate,
					info: infoData,
					pflegekasse: pflegeData.pflegekasse,
					car: pkwData.pkw,
				},
				pflegekasse: pflegeData.pflegekasse,
				userName: `${addressData.lastName} ${addressData.firstName}`,
				roles: memberTypes,
				pflegeRoles: pflegeData.pflegekasse
					? {provider: pflegeData.provider, requester: pflegeData.requester}
					: { provider: false,  requester: false},
				bankAccount: hasBankAccount ? bankAccountRef : false,
			}
			
			if(pflegeData.pflegekasse && pflegeData.requester) {
					newMember.institut = pflegeData.institut
						? pflegeData.institut
						: false
					if(pflegeData.institut)
						newMember.versNr = pflegeData.versNr	
			}
			// if pfelgeRoles both not selected reset pflegekasse to false - JUST pflegekasse SW active
			if(pflegeData.pflegekasse && !pflegeData.provider && !pflegeData.requester) {
				newMember.pflegekasse = false
				newMember.profile.pflegekasse = false
			}
			if(hasBankAccount) {
				newMember.bankAccount = bankAccountRef
				fsBatch.set(bankAccountRef, bankData)
			}
			if(memberTypes.provider) {
				newMember.tasks = taskData.map(task=>task)
			}
			if(filesData)
				newMember.files = filesData.map(file=>file)

			fsBatch.set(memberRef, newMember)
			fsBatch.commit()
			return ({
				...INIT_STATE
			})
		}
		default : return state
    }
}
*/
export default memberReducer
