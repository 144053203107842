import moment from 'moment'
import './calendarEventContent.css'
import { statiColors } from './AddEvent/AddEvent'
import Typography from '@material-ui/core/Typography'

const renderEventContent = (t) => {

    const isListView = t.view.type==='listMonth' || t.view.type==='listYear'
    const { requester, provider, eventState, task, editors, info, fixedDate, onHoldEvent, canceledEvent, handOver, responUser} = t.event.extendedProps
    const isConnected = eventState===2
    const renderInfo = <div style={{overflow: 'hidden'}}>{info}</div>

    let renderDateDiv = false
    if(fixedDate) {
      const dateDiv = moment(t.event.start).diff(moment(),'days' )+1
      if(dateDiv>=0) {
        renderDateDiv = `noch ${dateDiv} Tag${dateDiv>1 ? 'e' : ''}`
      }
    }
    if(eventState===0 || eventState===0.1)
      return(
          <div style={{backgroundColor: isListView ? 'lightyellow' : '', color: 'black', fontSize: isListView ? '1rem' : '0.7rem', maxHeight: '6rem', overflow: 'hidden', padding: '2px', whiteSpace:'wrap'}}>
            {info}
          <div className={handOver.value.length!==0 ? 'flashing' : ''}  style={{backgroundColor: 'white', textAlign: 'center', fontSize: '0.7rem', overflow: 'hidden'}}>{
            editors[editors.length-1].userName}
          </div>
        </div>)
  
    return (
      <div title={info ? `Info: ${info}` : null} style={{padding: '2px'}}>
        <div style={(isListView && !isConnected)
          ? fixedDate ? {backgroundColor: 'red', color: 'whitesmoke', textAlign: 'center'} : {backgroundColor: 'darkred', color: 'whitesmoke', textAlign: 'center'}
          : {textAlign: 'center'}}
        >
          {requester.userName} (B)
          <div style={{backgroundColor: 'slategrey', textAlign:'center'}}>
            {!isConnected && task ? task.label : ''}
          </div>
          {fixedDate && <div style={{fontWeight: 'bold', textTransform: 'uppercase', backgroundColor: statiColors.fixedDate, textAlign: 'center', color: 'red'}}>
            {renderDateDiv}
            <Typography style={{fontSize: '0.55rem'}} variant='caption' display='block'>Fester Termin</Typography>
          </div>}
          {onHoldEvent && <div style={{fontWeight: 'bold', textTransform: 'uppercase', backgroundColor: statiColors.onHoldEvent, textAlign: 'center', color: 'red'}}>
            Wartend
          </div>}
          {canceledEvent && <div style={{fontWeight: 'bold', textTransform: 'uppercase', backgroundColor: statiColors.canceledEvent, textAlign: 'center', color: 'darkred'}}>
            Abgesagt
          </div>}
        </div>
        {isConnected && <div
          style={{backgroundColor: isListView ? 'darkgreen' : 'darkgreen', color: 'whitesmoke', textAlign: 'center'}}
        >
          {provider.userName} (A)
        </div>}
        {isListView && renderInfo}
        <div
          className={handOver.value.length!==0 ? 'flashing' : ''}
          style={{textAlign: 'center', fontSize: '0.7rem', backgroundColor: 'white', color: 'green'}}>
              {(responUser && responUser.name) || `${editors[editors.length-1].userName} - alt`}
        </div>
      </div>
    )
  }

  export default renderEventContent