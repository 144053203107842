import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import MailIcon from '@material-ui/icons/EmailOutlined'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { makeStyles } from '@material-ui/styles'
import { createTheme } from '@material-ui/core/styles'
import {
    //onRowClick,
    //onRowOver,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'

const defaultTheme = createTheme();

const useStyles = makeStyles(
    (theme) => ({
        searchContainer: {
            padding: theme.spacing(0.5, 0.5, 0),
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'flex-start',
            flexWrap: 'wrap',
        },
        textField: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        margin: 0,
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        },
    }),
    { defaultTheme },
)

function QuickSearchToolbar(props) {
    const classes = useStyles()
    return (
      <GridToolbarContainer className={classes.searchContainer} style={{margin: '1rem 0 1.5rem 0'}}>
       
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport 
            printOptions={{
              hideFooter: true,
              hideToolbar: true,
            }}
        />
         
        <Tooltip title={props.selectedRowsCount<=0
            ? 'Sie müssen erst Mitglieder auswählen!'
            : `Klicken für Mailversand!`
        }>
          <Button
            size='small'
            startIcon={<MailIcon />}
            color='primary'
            style={{color: props.selectedRowsCount<=0 ? 'lightgrey' : '#1976d2'}}
            onClick={props.onMailSendClick}
            //disabled={props.selectedRowsCount<=0}
          >
            {props.selectedRowsCount<=0
              ? '0 E-Mail'
              : `${props.selectedRowsCount} E-Mail`}
          </Button>
        </Tooltip>
        <Tooltip title={props.selectedRowsCount<=0
            ? 'Sie müssen erst Mitglieder auswählen!'
            : `Klicken für Excel Export!`
        }>
          <Button
              style={{color: props.selectedRowsCount<=0 ? 'lightgrey' : '#1976d2'}}
              size='small'
              color='primary'
              startIcon={<SaveAltIcon />}
              //style={{color: props.selectedRowsCount<=0 ? 'lightgrey' : '#1976d2'}}
              onClick={props.onExcelExportClick}
              //disabled={props.selectedRowsCount<=0}
            >
              {props.selectedRowsCount<=0
                ? '0 Excel'
                : `${props.selectedRowsCount} Excel`
              }
            </Button>
        </Tooltip>
        <TextField
          id='member-search-input-field'
          size='small'
          error={props.value.length>0}
          //variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Suchen…"
          className={classes.textField}
          InputProps={{
            startAdornment: <SearchIcon color={props.value.length>0 ? 'secondary' : 'inherit'} fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: Boolean(props.value) ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" color='secondary' />
              </IconButton>
            ),
          }}
        />
      </GridToolbarContainer>
    );
  }
  
  QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };  

  export default QuickSearchToolbar