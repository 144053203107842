import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Select from 'react-select'


const MemberSelect = props => {
    
    const { selectedRequester, selectedProvider, callback } = props

    const convertSelectOptions = (memberType) => {
        if(props.provider===undefined || props.requester===undefined)
            return {value: 1, label: 'FEHLER - KEIN HELFER'}
        const convertedOptions = props[memberType].map(member=>({value: member, label: member.userName}))
        return convertedOptions
    }
    
    return (
        <Grid container direction="row"  alignItems="center" spacing={3} >
        <Grid item xs={12} md={6}>
        <Typography color={props.donate ? 'secondary' : 'textPrimary'} variant='subtitle1' component='span'>Hilfe-Leistender: </Typography>
        {props.donate && <Typography color='secondary' variant='button' component='span'>Wird gespendet</Typography>}
            <Select
                name='providerSelect'
                autoFocus
                //getOptionLabel={(option) =><Grid container><Grid item md={6}>{option.label}</Grid><Grid item>{option.value.profile.city}</Grid></Grid>}
                getOptionLabel={option=>`${option.label} - ${option.value.profile.street} - ${option.value.profile.city}`}
                options={convertSelectOptions('provider') || []}
                //isLoading={true}
                //options={props.provider || []}
                style={{margin: '4px 0 16px 0'}}
                onChange={(selectValue) => callback(selectValue, 'provider')}
                value={selectedProvider}
                backspaceRemovesValue
                placeholder='Helfer auswählen'
            />
        </Grid>
        <Grid item xs={12} md={6}>
          
        <Typography variant='h6' component='span'> Hilfe-Suchender: </Typography>
            <Select
                name='requesterSelect'
                getOptionLabel={option=>`${option.label} - ${option.value.profile.street} - ${option.value.profile.city}`}
                options={convertSelectOptions('requester') || []}
                style={{margin: '4px 0 16px 0'}}
                onChange={(selectValue) => callback(selectValue, 'requester')}
                value={selectedRequester}
                backspaceRemovesValue
                valueKey=''
                labelKey='userName'
                placeholder='Bedarf auswählen'
            />
          
        </Grid>
    </Grid>
    )
}



export default MemberSelect
