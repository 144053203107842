import React, { Component } from 'react'
import FullScreenDialog from 'Components/Modals/FullScreenDialog'
import { connect } from 'react-redux'
import { compose } from 'redux'
import MailForm from 'Container/Members/MailSender/MailForm'
import { dialog } from '../../../store/actions/dialogActions'
import { db } from '../../../firebase'
import { emailFileUpload } from 'firebase/storage/uploadFiles'
import {forEachSeries} from 'modern-async'
import RecipientsList from './RecipientList'
import RecipientAdd from './RecipientAdd'
import { withSnackbar } from 'notistack'
import { setRecipients, addRecipient, removeRecipient } from 'store/actions/mailSenderActions'

const mailSenderREF = db.ref('/mailSender')

class MailSender extends Component {
 
  handleFormClose = () => {
    return setRecipients([])
  }

  handleFormSubmit = async ({subject, body, attachments}) => {
    const id = mailSenderREF.push().key
    const mailSendREF = mailSenderREF.child(id)
    const mailData = { id, subject, body, recipients: this.props.mailSender.recipients }

    if(attachments && attachments.length>0) {
        mailData.attachments = []
        await forEachSeries(attachments, async (attachment, i)=>{
          const href = await emailFileUpload({file: attachment.file, fileName: attachment.fileName, id })
          mailData.attachments[i] = {
            file: attachment.file,
            filename: attachment.fileName,
            href, id,
          }
        })
      } else {
        mailData.attachments = []
      }
  
      this.props.enqueueSnackbar('E-Mails werden versendet!', 
        {
            variant: 'info', anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
        }})
      mailSendREF.set(mailData)
      return setRecipients([])
  }

  handleRemoveRecipient = (recipientToDelete) => {
    const { recipients } = this.props.mailSender
    if(recipients.length===1)
      return dialog({
        isOpen: true,
        title: 'E-Mail Empfänger',
        text:  `Der letzte E-Mail Empfänger kann nicht gelöscht werden!`,
        buttons: [
          {title: 'ok', onClick: ()=>dialog('reset')}
        ]
      })
    return removeRecipient(recipientToDelete)
  }

  handleAddRecipient = (data) => {
    if(!data)
      return
    return addRecipient(data)
  }
  render() {
    const { recipients } = this.props.mailSender
    return (
        <FullScreenDialog
            isOpen={this.props.mailSender.recipients.length>=1}
            onCloseCallback={this.handleFormClose}
            onSaveCallback={this.saveFormCallback}   
            title={`Serien E-Mail Versand`}
        >
          <div style={{maxWidth: '800px', margin: '1rem auto 0 auto', padding: '1rem', borderRadius :'10px', paddingBottom: '2rem'}}>
            <RecipientsList
              recipientsCount={recipients.length}
              recipients={recipients}
              removeRecipientCallback={this.handleRemoveRecipient}
            >
              <RecipientAdd/>
            </RecipientsList>
            <MailForm
              formSubmitCallback={this.handleFormSubmit}
              formCloseCallback={this.handleFormClose}
            />
          </div>
        </FullScreenDialog>
    )
  }
}

export default compose(
  connect((state) => {
    return ({
  // tasks: state.firestore.data.tasks,
    //member: state.firestore.data.members ? state.firestore.data.members['0Gcc9zcifuxJ0zKNoPDK'] : undefined,
    mailSender: state.mailSender,
  })}),
  withSnackbar
)
(MailSender)
