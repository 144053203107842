import React, { createRef, PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'
//import agendaPlugin from '@fullcalendar/agendaPlugin'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import deLocal from '@fullcalendar/core/locales/de';
import AddEvent from './AddEvent/AddEvent'
import { dispatchNewEvent } from 'store/actions/planningActions'
import LoadingHandler from 'Components/Loading/LoadingHandler'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
//import InputFilter from 'Components/Inputs/InputFilter'
import FilterToolbar from './FilterToolbar'
import renderEventContent from './calendarEventContent'

class Calender extends PureComponent {
  calendarRef = createRef()

	handleDateClick = e => {
    return dispatchNewEvent({start: e.dateStr, end: e.dateStr, responUser: {name: this.props.authUser.username, id:this.props.authUser.id}})
	}
  handleEventClick = e => {
    const { title, start, extendedProps, id } = e.event
    const { handOver, editors } = extendedProps
    const isHandOverUser = this.props.authUser.id===handOver.value

    const newEvent = {
      ...extendedProps,
      title,
      start,
      end: start,
      id,
      isNew: false,
    }
    if(isHandOverUser) {
      const timeStamp = moment().format()
      newEvent.handOver = {value: '', label: ''}
      const editor = {id: handOver.value, userName: handOver.label, timeStamp, msg: 'Gesehen von'}
      newEvent.editors = [...editors, editor]
      this.props.firestore.collection('planning').doc(id).update({editors: newEvent.editors, handOver: {value: '', label: ''}})
    }
    return dispatchNewEvent(newEvent)
  }
 
  handleEventDrop = e => {
    const { start, id, extendedProps } = e.event
    const { editors } = extendedProps
    //const { allday, provider, requester, task } = extendedProps
    const newDate = moment(start).format('YYYY-MM-DD')
    const timeStamp = moment().format()
    const editor = {
			id: this.props.authUser.id,
			userName: this.props.authUser.username,
			timeStamp,
			msg: 'Verschoben von'
		}
    const newEditors  = [...editors, editor]
    return this.props.firestore.collection('planning').doc(id).update({start: newDate, end: newDate, editors: newEditors})
  }
  render () {
    const { events, filteredEvents, isLoaded, authUser } = this.props
    
    return (
      <LoadingHandler isLoaded={isLoaded} style={{paddingBottom: '1rem'}}>
        <FilterToolbar />
        {/*filteredEvents && filteredEvents.length===0 ? <div>Keine Daten mit diesem Filte gefunden</div> : */}
        
        <FullCalendar
          defaultAllDay
          events={filteredEvents || events}
          stickyHeaderDates={true}
          //noEventsDidMount={e=>}
          noEventsContent={e=>{
            const viewType = e.view.type==='listMonth' ? 'Monat' : 'Jahr'
            
          return <Typography variant='h6' color='secondary'>In diesem {viewType} keine Events zum Anzeigen vorhanden!</Typography>}}
          //expandRows={true}
          height={800}
          //contentHeight={400}
          //aspectRatio={1.0}
          editable
        // selectable
          //dayMaxEvents
          locale={deLocal}
          ref={this.calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            right: 'dayGridMonth,dayGridWeek,dayGridDay listMonth listYear',
            left: 'today prev,next',
            center: 'title'
          }}
          buttonText={{
            dayGridMonth: 'Monat',
            dayGridWeek: 'Woche',
            dayGridDay: 'Tag',
            listMonth: 'Liste Monat',
            listYear: 'Liste Jahr',
          }}
         // eventColor='green'
          nowIndicator
          dateClick={(e) =>this.handleDateClick(e)}
          eventClick={(e) =>this.handleEventClick(e)}
          eventContent={renderEventContent}
          eventDrop={e=>this.handleEventDrop(e)}
          />
          <AddEvent authUser={authUser}  />
      </LoadingHandler>
    )
  }
}

export default compose(
  firestoreConnect(() => [
      {
          collection: 'planning', 
      },
  ]), // or { collection: 'todos' }
  connect((state, props) => ({
    authUser: state.user,
    isLoaded: isLoaded(state.firestore.data.planning),
    events: state.firestore.ordered.planning,
    filteredEvents: state.planning.filteredEvents,
  }))
  )(Calender)
