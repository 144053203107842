import { firestore } from '../firebase'
import { auth } from '../firebase'
import moment from 'moment'
import { uploadFileStorage, deleteStorageFile } from 'firebase/storage/uploadFiles'
import {forEach } from 'modern-async'

const filesStorageREF = firestore.collection('fileStorage')

export const getFileId = () => {
    return filesStorageREF.doc().id
}
export const addFile = async ({name, info, blob, fileName, size, ext}) => {
    const { displayName, uid } = auth.currentUser
    const timeStamp = moment().format()
    const fileStorageREF = filesStorageREF.doc()
    const id = fileStorageREF.id
    const url = await uploadFileStorage({id, fileName, ext, blob})
  
    const data = {
        id,
        //size: fileData.blob.size,
        fileName: fileName,
        version: 1,
        size,
        name, ext, url,
        info: info || '',
        editors: {
            created: {
                version: 1,
                id: uid,
                name: displayName,
                timeStamp
            }
        }
    }
    return fileStorageREF.set(data)
}

export const updateUploadedFile = async ({id, url, name, info, blob, fileName, oldFileName, size, ext, editors, version}) => {
  const timeStamp = moment().format()
  const fileStorageREF = filesStorageREF.doc(id)
  const data = {
    id,
    fileName: fileName,
    size,
    name, ext, url,
    info: info || '',
  }

  const editor = {id: auth.currentUser.uid, name: auth.currentUser.displayName, timeStamp}
  const _editors = {...editors}
  const newVersion = blob.length===0 ? (Number(version)+0.1).toFixed(1) : Number(version)+1
  editor.version = newVersion
  data.version = newVersion
  if(_editors.updated) {
    const updated = [..._editors.updated, editor]
    _editors.updated = updated
    data.editors = _editors
  } else {
    _editors.updated = [editor]
    data.editors = _editors
  }

  if(blob.length===0) {
    return fileStorageREF.update(data)
  }
  const newURL = await uploadFileStorage({id, fileName, ext, blob})
  data.url = newURL
  deleteStorageFile(id, oldFileName)
  console.log('UPDATE WITH FILE CHANGE', data)
  return fileStorageREF.update(data)
}

export const deleteFiles = async (rows) => {
    const fsBatch = firestore.batch()
    await forEach(rows, async row=> {
      const deleteStorageFileREF = filesStorageREF.doc(row.id)
      const name = `${row.fileName}.${row.ext}`
      const result = await deleteStorageFile(row.id, name)
      console.log(result, 'result')
      fsBatch.delete(deleteStorageFileREF)
    })

    fsBatch.commit()
}