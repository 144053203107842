import React, { useEffect, useState } from 'react'
import { firestore } from '../../../firebase'
import FullScreenDialog from 'Components/Modals/FullScreenDialog'
import LoadingHanlder from 'Components/Loading/LoadingHandler'
import values from 'lodash/values'
import Container from '@material-ui/core/Container'
//import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import StornoResultsRequester from './ResultsRequester'
import StornoResultsProvider from './ResultsProvider'

const useStyles = makeStyles((theme) =>({
	root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minHeight: '60vh',
    //backgroundColor: 'whitesmoke'
  }
}))


const InvoiceStornoSearch = ({ invoiceId, stornoCallback, onCloseCallback }) => {
    const classes = useStyles()

    const [searchResult, setSearchResult ] = useState({provider: undefined, requester: undefined})
    const [providerResults, setProviderResults ] = useState(undefined)
    const [noData, setNoData ] = useState(false)
    useEffect(() => {
      const PROVIDER = {}
      const getInvoice = async (invoiceId) => {
        const REF = firestore.collection('accounting').doc('nachbarschaftshilfe').collection('requester').doc(invoiceId)
        const result = await REF.get()

        if(!result.data()) {
          setNoData(true)
          return setSearchResult({requester: []})
        }
      
        const data = result.data()
        data.services.forEach(service => {
          PROVIDER[service.provider.id] = service.provider
        })
        setSearchResult({
          requester: result.data(),
          provider: values(PROVIDER)
        })
      }
      getInvoice(invoiceId)
      return () => {
        
      }
    }, [invoiceId])
    const _stornoCallback = () => {
        return stornoCallback({requester: searchResult.requester, provider: providerResults})
    }
    const handleDialogClose = () => {
      return onCloseCallback(undefined)
    }

    return (
      <FullScreenDialog isOpen={Boolean(invoiceId)} onCloseCallback={handleDialogClose} title='Abrechnung stornieren' >
        <LoadingHanlder
          isLoaded={Boolean(searchResult.requester)}
          isEmpty={noData}
          title={`Keine Abrechnung mit der Abrechnungs-Nummer ${invoiceId} vorhanden!`}
          //color='secondary'
        >
          <Container
            maxWidth='md'
            className={classes.root}
          >
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <StornoResultsRequester
                  stornoBtnDisabled={!Boolean(providerResults)}
                  data={searchResult.requester}
                  stornoCallback={_stornoCallback}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <StornoResultsProvider
                  data={searchResult.provider}
                  date={searchResult.requester && searchResult.requester.date}
                  setProviderInvoicesCallback={setProviderResults}
                />
              </Grid>
            </Grid>
          </Container>
        </LoadingHanlder>
      </FullScreenDialog>
    )
  
  }

  export default InvoiceStornoSearch