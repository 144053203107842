/*

import React from 'react'
import { Route } from 'react-router-dom'
import LoginPage from '../../App/Public/LoginPage'
*/
export const routes = {
    LOGIN: '/login',
    PASSWORD_FORGET: '/password_forget'
}

/*
export const PublicRoutes = () => {

    return (
      <React.Fragment>
            <Route exact path={routes.LOGIN} component={() => <LoginPage />} />
            <Route exact path={routes.LANDING_PAGE} component={() => <Test />} />

        </React.Fragment>
    )
}
*/
export default routes