import moment from 'moment'
export const address = [
    {   
        id: 1,
        type: 'select',
        name: 'salutation',
        label: 'Anrede',
        required: true,
        options: [
            {label: 'Herr', value: 'Herr'},
            {label: 'Frau', value: 'Frau'},
            {label: 'Familie', value: 'Familie'},
        ],
        controlProps: {
            required: true
        }
    },
    {id: 2, type: 'text', name: 'nameSufix', label: 'Titel', fullWidth: true, icon: { name: 'AddToQueue' , options: {} } },
    {id: 3, type: 'text', name: 'firstName', label: 'Vorname', icon: {name: 'PersonOutlined'}, required: true },
    {id: 4, type: 'text', name: 'lastName', label: 'Nachname', icon: {name: 'Person'}, required: true},
    {id: 5, type: 'text', name: 'street', label: 'Straße', icon: {name: 'UnreadMailbox'}, required: true },
    {
        id: 6,
        type: 'text',
        name: 'postCode',
        label: 'Postleitzahl', 
        icon: {name: 'Postcode'},
        required: true, 
        inputProps: { maxLength: 5, minLength: 5, size: 5}
    },
    {id: 7, type: 'text', name: 'city', label: 'Wohnort', icon: {name: 'City'}, required: true },
]

export const communication = [
    {id: 8, type: 'tel', name: 'phone', label: 'Festnetz', fullWidth: true, icon: { name: 'Phone' , options: {} }  },
    {id: 9, type: 'tel', name: 'phoneWork', label: 'Telefon Arbeit', icon: {name: 'BusinessCenter'} },
    {id: 10, type: 'tel', name: 'mobile', label: 'Mobil', icon: {name: 'MobilePhone'} },
    {id: 11, type: 'email', name: 'email', label: 'E-Mail', icon: {name: 'Mail'} },
]

export const contactPerson = [
    {id: 12, type: 'text', name: 'relationship', label: 'Beziehung zum Mitglied', icon: { name: 'People' , options: {} } },
    {id: 13, type: 'text', name: 'name', label: 'Name', icon: { name: 'Person' , options: {} } },
    {...address[4], id: 14, required: false },
    {...address[5], id: 15, required: false,},
    {...address[6], id: 16, required: false},
    {...communication[0], id: 17 },
    {...communication[1], id: 18 },
    {...communication[2], id: 19, },
    {...communication[3], id: 20, },
    /*{id: 21, type: 'checkbox', name: 'copyRecipient', label: 'Rechnungskopien Empfänger' },
    {id: 22, type: 'switch', name: 'mailDelivery', label: 'E-Mail Versand' }*/
]

export const memberDates = [
    {id: 23, type: 'date', name: 'birthday', label: 'Geburtstag', InputLabelProps: {shrink: true}, icon : {name: 'Birthday', options: {}}},
    {id: 24, noValue: moment().format('YYYY-MM-DD'), type: 'date', name: 'entryDate', label: 'Eintrittsdatum', InputLabelProps: {shrink: true} ,icon: {name: 'Event', options: {}}},
]

export const bankAccount = [
    {id: 25, disabled: true, type: 'text', name: 'institutName', label: 'Bank Name', icon: {name: 'AccountBalance'} },
    {id: 26, disabled: true, type: 'text', name: 'city', label: 'Stadt', icon: {name: 'City'} },
    {id: 27, disabled: true, type: 'text', name: 'bic', label: 'BIC', icon: {name: 'Money'} },
    {id: 28, disabled: true, type: 'text', name: 'iban', label: 'IBAN', icon: {name: 'IBAN'} },
]

export const roles = [
    {id: 29, type: 'switch', name: 'requester', label: 'Bedarf'},
    {id: 30, type: 'switch', name: 'provider', label: 'Angebot'},
]
/*
export const shipment = [
    {id: 1, type: 'switch', name:'mail', label: 'Briefpost' },
    {id: 2, type: 'switch', name: 'email', label: 'EMail'}
]
export const copyAddress = [
    {id: 1, type: 'switch', name:'copyRecipient', label: 'Kopien Empfänger' },
    {id: 2, type: 'text', name: 'name', label: 'Name'},
    {id: 3, type: 'text', name: 'street', label: 'Straße'},
    {id: 4, type: 'text', name: 'city', label: 'Plz & Ort'},
    {id: 5, type: 'text', name: 'email', label: 'Email'},

    
]
*/
export const pkwAndEducated = [
    {id: 31, type: 'switch', name: 'car', label: 'PKW'},
    {id: 32, type: 'switch', name: 'educated', label: 'Ausbildung'}
]
export const feeAndDonation = [
    {id: 33, type: 'text', name: 'membershipFee', label: 'Mitgliederbeitag €', icon: {name: 'EuroSymbol'}, inputProps: { maxLength: 4} },
    {id: 34, type: 'text', name: 'donation', label: 'Spende €', icon: {name: 'EuroSymbol'}, inputProps: { maxLength: 4 } }
]


const allInputs = {
    //copyAddress,
    //shipment,
    feeAndDonation,
    address,
    communication,
    contactPerson,
    memberDates,
    roles,
    pkwAndEducated,
}

export default allInputs