//import { combineReducers } from 'redux'
import userReducer from './userReducer'
import appReducer from './appReducer'
import accountingReducer from './accountingReducer'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import memberReducer from './memberReducer'
import planningReducer from './planningReducer'
import statisticReducer from './statisticReducer'
import mailSenderReducer from './mailSenderReducer'
import fileStorageReducer from './fileStorageReducer'
import customAccountingReducer from './customAccountingReducer'
import tasksReducer from './tasksReducer'
import institutsReducer from './institutsReducer'
import membersReducer from './membersReducer'
const rootReducer = {
        app: appReducer,
        //member: memberReducer,
        members: membersReducer,
        member: memberReducer,
        planning: planningReducer,
        accounting: accountingReducer,
        user: userReducer,
        statistic: statisticReducer,  
        firebase: firebaseReducer,
        firestore: firestoreReducer,
        mailSender: mailSenderReducer,
        fileStorage: fileStorageReducer,
        customerAccounting: customAccountingReducer,
        tasks: tasksReducer,
        instituts: institutsReducer
}

export default rootReducer