import React, {useState} from 'react'
import { Grid, Input, Select, MenuItem, Button, Typography, TextField, FormHelperText } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import moment from 'moment'
//import { validate } from 'uuid'

const InputRow = ({rowCount, callback, tasks, handleInfoMessage}) => {
    const [date, setDate] = useState(moment().subtract(1, "month").format("YYYY-MM-DD"))
    const [hours, setHours] = useState('01:00')
    const [km, setKm] = useState(0)
    const [taskId, setTaskId] = useState(tasks[0].id)

    const handleNewRow = () => {
     
        const newService = {
            date,
            hours,
            km,
            taskId,
        }
     
        callback(newService)
        return handleInfoMessage('Leistung wurde hinzugefügt.', 'success')
    }
    return (
        <Grid container id='serviceAddRow' alignItems='center' justifyContent="space-between" style={{backgroundColor: '#E8E8E8', padding: '12px 0 12px 0', marginBottom : '3rem' }}>
             <Grid item md={12}><Typography align='right' variant='subtitle1' >Leistung hinzufügen:</Typography></Grid>
             <Grid item md={1} xs={12} style={{textAlign: 'center'}}>
               {rowCount}
            </Grid>
            <Grid item md={2} xs={12}>
                <Input
                    value={date}
                    onChange={e=>setDate(e.target.value)}
                    name='date' type='date'     
                />
            </Grid>
            <Grid item md={2} xs={12}>
                <TextField
                    fullWidth
                    type='time'
                    value={hours}
                    inputProps={{step:300}} 
                    name='hours' onChange={e=>setHours(e.target.value)}
                />
            </Grid>
            <Grid item md={1} xs={12} style={{textAlign: 'center'}}>
                  <Input
                value={km}
                name='km'
                min={0} max={199}
                onChange={e=>setKm(e.target.value)}
                type='number' 
                styles={{textAlign: 'right'}}
                /> 
            </Grid>
            <Grid  item md={3} xs={12}>
                <Select
                    fullWidth
                    //labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={taskId}
                    onChange={(e)=>setTaskId(e.target.value)}
                    >
                        {tasks && tasks.map(_task=>{
                            return <MenuItem key={_task.id} value={_task.id}>{_task.name}</MenuItem>
                        })}
            
                </Select>
                {tasks[0].id==='no-task-exits' && <FormHelperText error={true} focused children='Sie müssen zuerst eine Tätigkeit anlegen!' />}
            </Grid>
            <Grid item md={1} xs={12}>
            <Button
                disabled={tasks[0].id==='no-task-exits'}
                onClick={handleNewRow}
                size='small'
                color='primary'
                variant='contained'
                >
                <AddIcon style={{color: 'white'}} />
                <span style={{fontSize: "0.8em", }} >
                        
                </span>
            </Button>

            </Grid>
        </Grid>
    )
}

export default InputRow