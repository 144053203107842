import React, { PureComponent } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, LabelList } from 'recharts'
import ChartHeadLine from './ChartHeadLine'
import moment from 'moment'
import { toBlob } from 'html-to-image'
import print from 'print-js'
export const _barColors = [
    'slateblue',
    'indianred',
    'limegreen',
    'coral',
    'lightseagreen',
    'violet',
    '#8884d8',
    'darkred',
    'green',
    'orange',
]
export const barColors = [
    '#1966B0',
    '#73D9F0',
    '#F7B301',
    '#EB773E',
    '#F51663',
    '#DBA40E',
    '#787D12',
    '#D773A2',
    '#07BB9C',
    '#FFD743',
    '#A06AB4'
]
/*
const renderLabel = (props) => {
    console.log(props)
    const { x, y, width, height, value, color } = props
    
    const radius = 28
  
    return (
      <g>
        <circle cx={x + width / 2} cy={y - radius} r={radius} fill={color} />
        <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
          {value}
        </text>
      </g>
    );
  }
  */

  const monthTickFormatter = (tick) => {
    //console.log('monthTick', tick)
    return moment(`${tick}-01`).format('MMM')
    //return tick.payload.value.split('-')[1]
  }

  const renderYearTick = (tickProps) => {
    const { x, y, payload, index } = tickProps;
   //console.log('renderYearTick', tickProps)

    const { value, offset } = payload
    const monthNr = Number(value.split('-')[1])

    if(monthNr === 12 || monthNr===2) {
    
        return <text x={x} y={y} textAnchor="end" >{moment(payload.value).format('YYYY')}</text>
    }
      
    if (monthNr % 12 ===1 || index===0) {
        const pathX = Math.floor(x - offset) + 0.5;
    
        return <path d={`M${pathX},${y - 4}v${-35}`} stroke="red" />;
      }
    return null
  }


export default class SuperChart extends PureComponent {
   
    handleDownloadHTMLChart = (withTitle) => {
        const { title } = this.props.chartData
        const chartEl = withTitle ? 'html-chart-with-title' : 'html-chart'
        toBlob(document.getElementById(chartEl))
        .then(function (blob) {
            return window.saveAs(blob, `${title}.png`);
        })
        return
    }
    handlePrintChart = () => {
        const el = document.getElementById('html-chart-with-title')
        
        print({
            printable: el,
            type: 'html',
        })
    }

    render() {
        if(!this.props.chartData)
            return <div>Keine Daten....</div>
        const stacked  = this.props.chartData ? this.props.chartData.stacked : false
        const renderBars = () => {
            return this.props.chartData.dataKeys.map((dataKey, i)=>{
            return (
         
                <Bar  key={dataKey} dataKey={dataKey} stackId={stacked ? 'a1' : undefined} minPointSize={this.props.bars ? this.props.bars.minPointSize : 0} fill={barColors[i]} >
                    {/*<LabelList dataKey={dataKey} content={renderLabel} color={barColors[i]} />*/}
                    <LabelList  dataKey={dataKey} offset={2} position={stacked ? 'center' : 'top'} />
                </Bar>
            )
        })
        }

        const {xAxis} = this.props.chartData
        const isMultixAxis = this.props.chartData.multixAxis
    
        return (
            <ChartHeadLine
                title={this.props.chartData.title}
                subtitle={this.props.chartData.subtitle || false}
                downloadHTMLChart={this.handleDownloadHTMLChart}
                printChart={this.handlePrintChart}
            >
                <div ref={this.props.chartRef}></div>
                <ResponsiveContainer width={'100%'} height={400}>
                    <BarChart
                        width={800}
                        height={400}
                        data={this.props.chartData.values}
                        margin={{
                            right: 20,
                            left: 20,
                            top:0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis padding="no-gap"  tickFormatter={isMultixAxis ? monthTickFormatter : undefined} dataKey="name" interval={0} {...xAxis} />
                        {isMultixAxis &&
                            <XAxis
                                dataKey="name"
                                axisLine={false}
                                tickLine={false}
                                interval={0}
                                tick={renderYearTick}
                                height={1}
                                scale="band"
                                xAxisId="year"
                            />
                        }
                 
                        <YAxis padding={{ top: 16 }} />
                       
                        {/*<Tooltip cursor={{ stroke: 'blue', strokeWidth: 1 }} />*/}
                        <Legend 
                            height={35}
                            formatter={this.props.chartData.legendFormatter
                                ? value=>this.props.chartData.legendFormatter(value)
                                : undefined
                            }
                        />
                        {renderBars()}
                    </BarChart>
                </ResponsiveContainer>
            </ChartHeadLine>
        )
    }
}