import { dispatch } from '../store/index'
import { actionTypes } from '../config/config'

export function validateIBAN(iban) {
	const xhttp = new XMLHttpRequest();
	xhttp.onload = function() {
	return dispatch({type: actionTypes.SET_VALIDATED_IBAN, payload: this.responseText})
	  }
	const url = `https://openiban.com/validate/${iban}/?getBIC=true&validateBankCode=true`
	xhttp.open("GET", url, true);
	xhttp.send()
  }
//Promise.all([validateIban])

export function validateIban(iban) {
	var request = new XMLHttpRequest()
	const url = `https://openiban.com/validate/${iban}/?getBIC=true&validateBankCode=true`
	request.open('GET', url, false);  // `false` makes the request synchronous
	request.send(null);
	if (request.status === 200) {
		return JSON.parse(request.responseText)
	}
}
  
