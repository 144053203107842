import React, { Component } from 'react'
import MembershipFeeDebit from './MembershipFeeDebit'
import DefaultGrid from 'Components/Grid/DefaultGrid'
import FormWrapper from 'Components/Forms/FormWrapper'
import DonationsDebit from './DonationsDebit'
import Button from '@material-ui/core/Button'
import InvoiceStorno from './Storno/InvoiceStorno'
import {Link} from 'react-router-dom'


class SettingsDashboard extends Component {
  render() {
    return (
        <DefaultGrid classes={{}} container={{spacing: 1}}>
            <DefaultGrid.Row classes={{}} >
                <FormWrapper title='Tätigkeiten erstellen/ändern/löschen' compProps={{title: {variant: 'body1', color: 'primary'}}}>
                    <Button style={{marginTop: '0.5rem'}} component={Link} to='/einstellungen/taetigkeiten' variant='outlined' color='primary' >
                        Öffnen
                    </Button>
                </FormWrapper>
                <FormWrapper
                    title='Abrechnung stornieren'
                    compProps={{title: {variant: 'body1', color: 'primary'}}}>
                    <InvoiceStorno />
                </FormWrapper>
            </DefaultGrid.Row>
            <DefaultGrid.Row classes={{}} >
                <FormWrapper title='Mitglieder Jahresbeitrag Lastschrift' compProps={{title: {variant: 'body1', color: 'primary'}}} >
                    <MembershipFeeDebit buttonText='XML Erstellen & Herunterladen' />
                </FormWrapper>
                <FormWrapper title='Speden Lastschrift' compProps={{ title: { variant: 'body1', color: 'primary' } }} >
                    <DonationsDebit buttonText='XML Erstellen & Herunterladen'/>
                </FormWrapper>
            </DefaultGrid.Row>
        </DefaultGrid>

    )
  }
}



export default SettingsDashboard