import actionTypes from '../../config/actionTypes'
import { addFile } from 'firebase/database/fileStorageHandler'
import { updateUploadedFile } from 'firebase/database/fileStorageHandler'
//import { firestore } from '../../firebase/firebase'
const { FILE_STORAGE } = actionTypes

const INIT_STATE = {
  blob:  '',
  name: '',
  fileName: '',
  oldFileName: null,
  ext: '',
  size: 0,
  info: '',
  isTouched: false,
  isOpen: false,
  isEditMode: false,
}
  function fileStorageReducer(state = INIT_STATE, action) {

    switch(action.type) {
        case FILE_STORAGE.NEW_FILE_BLOB : {
            const {blob, name, ext, size, fileName, oldFileName } = action.payload 
            return ({
                ...state,
                oldFileName,
                blob,
                name,
                fileName,
                isTouched: true,
                ext,
                size
            })
        }
        case FILE_STORAGE.OPEN_FILE_UPLOAD : {
            return({
                ...state,
                isOpen: true
            })
        }
        case FILE_STORAGE.UPDATE_FILE_NAME : {
            return({
                ...state,
                fileName: action.payload,
                isTouched: true,
            })
        }
        case FILE_STORAGE.SET_FILE_INFO : {
            return ({
                ...state,
                info: action.payload,
                isTouched: true,
            })
        }
        case FILE_STORAGE.EDIT_FILE : {
            return({
                ...state,
                ...action.payload,
                isOpen: true,
                isEditMode: true
            })
        }
        case FILE_STORAGE.SAVE_FILE : {
            if(state.isEditMode) {
                updateUploadedFile(state)
            } else{
                addFile(state)
            }
            return INIT_STATE
        }
        case FILE_STORAGE.RESET_FILE_UPLOAD : {
            return INIT_STATE
        }
        default : return state;
    }
  }
  
  export default fileStorageReducer