import React from 'react'
import { Grid, Paper, Typography, Divider } from '@material-ui/core'
import { connect } from 'react-redux'
import config from '../config'
import values from 'lodash/values'
import { useHistory } from 'react-router'
import { db } from '../firebase/firebase'
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { v4 as uuid } from 'uuid'
import IconSelector from 'Components/Icons/IconSelector'

const useStyles = makeStyles(theme =>
    createStyles({
        root: {

        },
        gridContainer: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(4)
        },
        nachbarschaftshilfe: {
            backgroundColor: theme.palette.primary.main,
            cursor: 'pointer',
            padding: theme.spacing(1),
            borderRadius: '8px',
            height: '9rem'
        },
        pflegekasse: {
            backgroundColor: theme.palette.secondary.main,
            cursor: 'pointer',
            padding: theme.spacing(1),
            borderRadius: '8px',
            height: '9rem'
        },
        cardTitle: {
            verticalAlign: 'super',
            fontSize: '0.8rem',
            textTransform: 'uppercase',
            color: 'white',
            padding: theme.spacing(1),
            fontWeight: 'bold'
        },
        paperDiscriptionWrapper: {
            backgroundColor: 'whitesmoke',
            padding: '0.5rem',
            borderRadius: '8px',
            height: '5rem'
        },
        paperDiscription: {
            hyphens: 'auto',
            fontSize: '0.75rem',
            overflow: 'hidden'
        },

        

    })
  )

const UserDashboard = ({user}) => {
    const navItemsPflegekasse = config.navigation.getNavigationItems({admin: true, appUser: true}, 'pflegekasse',)
    const navItemsNachbarschaftshile = config.navigation.getNavigationItems({admin: true, appUser: true}, 'nachbarschaftshilfe')
    const classes = useStyles()
    const renderPflegekasse = (<div className={classes.sectionTop}>
            <Typography variant='h6' color='primary'>Pflegekasse</Typography>
                <Divider />
            <NavGrid classes={classes} navItems={navItemsPflegekasse} app='Pflegekasse' user={user} />
        </div>)

    const renderNachbarschaftshilfe = (<div className={classes.sectionBottom}>
            <Typography variant='h6' color='primary'>Nachbarschaftshilfe</Typography>
            <Divider />
            <NavGrid classes={classes} navItems={navItemsNachbarschaftshile} app='Nachbarschaftshilfe' user={user} />
        </div>)
    return (
        <div className={classes.root}>
            {user.settings.activeApp==='pflegekasse'
                ? renderPflegekasse
                : renderNachbarschaftshilfe
            }
            {user.settings.activeApp==='nachbarschaftshilfe'
                ? renderPflegekasse
                : renderNachbarschaftshilfe
            }
       
            
        </div>
  )
}

const NavGrid = ({navItems, app, user, classes}) => {

    return (
        <Grid container spacing={2} className={classes.gridContainer} >
        {values(navItems).map((navItem, i)=>{
          
            if(navItem.nav.hidden || navItem.nav.title==='App Übersicht')
                return null
            return <NavItem classes={classes} key={`${navItem.title}${i}`} item={navItem} app={app} user={user}  />
        })
        }
        </Grid>
    )
}

const NavItem = ({item, user, app, classes}) => {

    const history = useHistory()
    const updates = {}
    const handleItemClick = async () => {
        if(app!==user.settings.activeApp) {
            updates[`/users/${user.id}/settings/activeApp`] = app.toLowerCase()
            await db.ref().update(updates)    
        }
        history.push(item.path)
    }
    return (
            <Grid item md={3} xs={6}>
                <Paper className={classes[app.toLowerCase()]} onClick={handleItemClick} elevation={4}>
                    <div styel={{verticalAlign: 'middle'}}>
                    <IconSelector iconName={item.nav.icon} options={ { style:{color: 'white' } }} />
                    <Typography display='inline' className={classes.cardTitle} variant='subtitle1'>{item.nav.title}</Typography>
                    </div>
                    <Paper className={classes.paperDiscriptionWrapper}  >
                                {item.discription.map((disc)=>{
                                    if(item.discription.length>1) {
                                        return <Typography style={{textDecoration: 'underline'}} className={classes.paperDiscription} key={uuid()} variant='body2'>{disc}</Typography>
                                    }
                                    return <Typography key={uuid()} variant='body2' className={classes.paperDiscription}>{disc}</Typography>
                                })}
                  
                    </Paper>
                </Paper>
            </Grid>
    )
}

export default connect((state) => {
    return ({   
        user: state.user,
    })
})(UserDashboard)