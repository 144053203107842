import { firestore } from '../firebase'
//import { dispatch } from '../../store/index'
//import {actionTypes} from '../../config'
import values from 'lodash/values'


const tasksREF = firestore.collection('tasks')
const membersREF = firestore.collection('members')

const accountingREF = firestore.collection('accounting')

export const checkTaskDepencies = async (taskId, taskType) => {
	const COLLECTOR = { foundMembers: {}, foundServices: {} }
	const servicesWithTaskIdREF = accountingREF.doc(taskType).collection('services').where('taskId', '==', taskId)
	const servicesWithTaskIdSnap = await servicesWithTaskIdREF.get()
	if(!servicesWithTaskIdSnap.empty) {
		servicesWithTaskIdSnap.forEach(service=>{
			const data = service.data()
			COLLECTOR.foundServices[`${data.year}${data.month}-${data.requester.id}`] = data
		})
		return ({error: true, type:'services', values: values(COLLECTOR.foundServices)})
	}
	const membersWithTaskIdREF = membersREF.where(`tasks.${taskId}`, '==', true)
	const membersWithTaskIdSnap = await membersWithTaskIdREF.get()
	if(!membersWithTaskIdSnap.empty) {
		membersWithTaskIdSnap.forEach(member=>{
			COLLECTOR.foundMembers[member.id] = member.data()
		})
		return ({error: true, type:'members', values:values(COLLECTOR.foundMembers)})
	}
	return ({error: false, type: 'success' })
}

export const deleteTaskFromMembers = (members, taskId) => {

	const fsBatch = firestore.batch()
	members.forEach(member=>{
		const memberREF = membersREF.doc(member.id)
		const { tasks } = member
		const newTasks = values(tasks).length===1 ? false : {...tasks}
		if(!tasks)
			return fsBatch.update(memberREF, {tasks: newTasks})

		delete newTasks[taskId]
		
		fsBatch.update(memberREF, {tasks: newTasks} )
	})

	return fsBatch.commit()
}

export const deleteTask = (taskId) => {
	return tasksREF.doc(taskId).delete()
}