import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme=>{
  //console.log(theme)
  return({
    title: {
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`
    }

  })
})

export default  function AlertDialog({open, children, buttons, handleClose, title, text, Form}) {
  const classes = useStyles()
  return (
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="file-upload-dialog-title"
        aria-describedby="file-upload-dialog-description"
      >
        <DialogTitle className={classes.title} id="file-upload-dialog-title">
          {title}
        </DialogTitle>
        <Divider />
        {Form 
          ? Form 
          : <DialogContent>
          {text && <DialogContentText id="file-upload-dialog-description">
            {text}
          </DialogContentText>}
            {children}
        </DialogContent>}
        <DialogActions>
            {buttons && buttons.map((button, i)=>
                <Button key={i} {...button} />
            )}
        </DialogActions>
      </Dialog>
  )
}