import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Instituts from '../../Container/Instituts'
import AddPflegeServices from 'Container/Services/Pflegekasse/Add/AddServices'
import AccountingPflegekassen from '../../Container/Accounting/Pflegekasse/AccountingPflegekassen'
import AccountingRequesters from '../../Container/Accounting/Pflegekasse/Members/AccountingRequesters'
import PflegeBudgets from '../../Container/Accounting/PflegeBudgets'
import ArchivDashboard from 'Container/Archiv/ArchivDashboard'
import StatisticDashboard from 'Container/Statistics/Dashboard'
import Members from 'Container/Members/Members'
import MembersArchiv from 'Container/Members/MembersArchiv'
import FileStorage from 'Container/FileStorage/FileStorage'
import UserDashboard from 'Container/UserDashboard'
//import Admin from 'Container/Admin/Admin'



export const routes = {
    USER_DASHBOARD: {
        title: 'App Übersicht',
        path: '/',
        nav: {
            title: 'App Übersicht',
            icon: 'Apps',
            hidden: false,
        },
        discription: []
    },
    ADD_PFLEGE_SERVICES: {
        title: 'Leistungsnachweis erstellen',
        path: '/pflegekasse_leistungseingabe',
        nav: {
            title: 'Leistungen eingeben',
            icon: 'PostAdd'
        },
        discription: ['Eingabe Leistungsnachweise für Mitglieder die über die Pflegekasse abrechnen']
    },
    ACCOUNTING_PFLEGEKASSEN: {
        title: 'Abrechnung mit Pflegekasse', 
        path: '/abrechnung_pflegekassen',
        nav: {
            title:'Abrechnung Pflegekassen',
            icon: 'AccountBalance'
        },
        discription: ['Abrechnung der eingegebenen Leistungsnachweise mit der zugewiesenen Pflegekasse und Helfern (Angebot)']

    },
    PFLEGE_BUDGETS: {
        title: 'Anteil Pflegekasse eingeben',
        path: '/pflegekassen_bugets',
        nav: {
            title: 'Anteil Pflegekasse',
            icon: 'Business'
        },
        discription: ['Nach Rückmeldung der Pflegekasse, Eingabe des übernommenen Betrages der jeweiligen Pflegekasse']

    },
    ACCOUNTING_PFLEGEKASSEN_MEMBERS: {
        title: 'Abrechnung mit Mitglied (Restbetrag Bedarf)',
        path: '/abrechnung_pflege_mitglieder',
        nav: {
            title: 'Abrechnung Mitglied',
            icon: 'Accessibility'
        },
        discription: ['Abrechung von (Pflegekassen) Mitgliedern bei den der Restbetrag eingegeben wurde, oder Selbzahler sind.']
    },
    MEMBERS: {
        title: 'Mitglieder',
        path: '/mitglieder',
        nav: {
            title: 'Mitglieder',
            icon: 'Loyalty'
        },
        discription: ['Verwaltung der Mitglieder und des Mitglieder-Archivs (gelöschte Mitglieder)']
    },
    INSTITUTS: {
        title: 'Pflegekassen verwalten',
        path: '/pflegekassen',
        nav: {
            title: 'Pflegekassen',
            icon: 'PostAdd'
        },
        discription: ['Verwaltung der Pflegekassen Stammdaten, die dem Mitglied zugeordnet werden können']
    },
    ARCHIV_DASHBAORD: {
        title: 'Abrechnungs Archiv',
        path: '/archiv',
        nav: {
            title: 'Archiv',
            icon: 'Description'
        },
        discription: ['Archiv mit allen Abrechnungen die bis Dato abgrechnet wurden (Pflegekasse, Bedarf, Helfer)']
    },
    STATISTIC_DASHBOARD: {
        title: 'Statistiken und Auswertungen',
        path: '/statistik_dashboard',
        nav: {
            title: 'Statistiken',
            icon: 'BarChart'
        },
        discription: ['Statistiken über Pflegekassen Mitglieder und Abrechnungen über die Pflegekase']
    },
    FILE_STORAGE: {
        title: 'Dateiablage',
        path: '/datei_ablage',
        nav: {
            title: 'Dateiablage',
            icon: 'Storage'
        },
        discription: ['Dateienablage für Dokumente zum Austausch mit Kolleg(inn)en. Die Dateigröße sollte maximal 10 Megabyte betragen.']
    },
    /*
    ADMIN: {
        title: 'Admin',
        path: '/admin',
        nav: {
            title: 'Admin',
            hidden: true
        }
    },
    */

}

export const PrivateRoutes = () =>  
    <React.Fragment>
        <Route exact path={routes.USER_DASHBOARD.path} component={() => <UserDashboard />} />
        <Route exact path={routes.ACCOUNTING_PFLEGEKASSEN.path} component={() => <AccountingPflegekassen />} />
        <Route exact path={routes.ACCOUNTING_PFLEGEKASSEN_MEMBERS.path} component={() => <AccountingRequesters />} />
        <Route exact path={routes.PFLEGE_BUDGETS.path} component={() => <PflegeBudgets />} />
        <Route exact path={routes.ADD_PFLEGE_SERVICES.path} component={() => <AddPflegeServices />} />
        <Route exact path={routes.MEMBERS.path} component={()=><Members />} />
        <Route exact path='/mitglieder_archiv' component={()=><MembersArchiv />} />
        <Route exact path={routes.INSTITUTS.path} component={() => <Instituts />} />
        <Route exact path={routes.ARCHIV_DASHBAORD.path} component={() => <ArchivDashboard/>} />
        <Route exact path={routes.STATISTIC_DASHBOARD.path} component={() => <StatisticDashboard />} />
        <Route exact path={routes.FILE_STORAGE.path} component={() => <FileStorage />} />
        {/*<Route exact path={'/admin'} component={() => <Admin />} />*/}
        <Redirect to={routes.USER_DASHBOARD.path} />
    
    </React.Fragment>


export default PrivateRoutes