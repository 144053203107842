import { publicRoutes, nachbarschaftshilfeRoutes, pflegekassenRoutes  } from './routes'
//import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';

export const nonAuthNavigation = [
    {
        title: 'Login',
        icon: FavoriteIcon,
        path: publicRoutes.LOGIN,
    },
]

export const getRoleBasedNavigation = (role, activeApp) => {
    //mainNavigation=activeApp

    if(role===null) return nonAuthNavigation
    if(role.appUser) {
        if(activeApp==='nachbarschaftshilfe') {
            return nachbarschaftshilfeRoutes
        } else {
            return pflegekassenRoutes
        }
    }
   
    return nonAuthNavigation
}

export default getRoleBasedNavigation
