import React, { Fragment } from 'react'
import ButtonNav from '../Components/Navigation/ButtonNav'
import MobileNav from '../Components/Navigation/MobileNav'
//import { mainNavigation, adminNavigation } from '../../config/navigation.cfg'
import config from '../config'
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {connect} from 'react-redux'


const MainNav = ({userRoles, activeApp}) => {
    const theme = useTheme()
    const navigation = config.navigation.getNavigationItems(userRoles, activeApp)

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Fragment >
            {isMobile ? <MobileNav navItems={navigation} /> : <ButtonNav navItems={navigation} />}
        </Fragment>
    )
}

const mapStateToProps = state => ({
    userRoles: state.user.role
})

export default connect(mapStateToProps)(MainNav)