import React from 'react'
import Button from '@material-ui/core/Button'
import { doSignOut } from '../firebase/auth/index'
import Container from '@material-ui/core/Container'
const Footer = () => {
    return (
        <div id='App-footer'>
            <Container maxWidth='lg'>
            <Button
                onClick={doSignOut}
                variant='outlined'
                color='secondary'
                size='small' 
                fullWidth
            >
                Abmelden
            </Button>
            </Container>
       
        </div>
    )
}

export default Footer