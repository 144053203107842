import React, { useState } from 'react'
import CardGrid from  'Components/Cards/DefaultCardGrid'
import { dispatch } from 'store/index'
import { actionTypes } from 'config/config'
import ButtonBoard from 'Components/Buttons/ButtonRow'
import TitleCard from 'Components/Title/TitleCard'
import ErrorMessage from 'Components/Messages/ErrorMessage'
import moment from 'moment'
//import { resetDialog } from 'js/helper'
import generateInvoiceData from '../generateInvoiceData'
//import { uploadFile } from '../generateInvoiceData'
import { db } from '../../../../firebase/firebase'
import { getPflegekassenInvoiceIds } from 'firebase/database/accounting'
//import { Typography } from '@material-ui/core'
import { dialog } from '../../../../store/actions/dialogActions'
const invoiceResultsRef = db.ref('/invoiceResults/pflegekasse')

const AccountingMonthView = ({servicesByRequester, servicesByProvider, tasks, editor}) => {
	const [ error, setError ]= useState(undefined)
	const handleCardClick = selectedInvoice => {
		return dispatch({type: actionTypes.SET_ACCOUNTING_INVOICE, payload: selectedInvoice})
	}
	const handleBack = ()=>{
		return dispatch({type: actionTypes.SET_ACCOUNTING_MONTH, payload: undefined})
	}

	const handleStartAccounting = async () => {
		// Write Result Info
	
		const addCount = {institut: servicesByRequester.length, provider: servicesByProvider.length}
		const invoiceIds = await getPflegekassenInvoiceIds(addCount) //{institutId: 3000, providerId: 3000} 
		
		if(invoiceIds.institutId==='error' || invoiceIds.providerId==='error')
			return setError({error: 'Fehler bei der Abrechnung', text: ['Es gibt einen  Fehler mit der Abrechnungs-Nummer.',  'Die Abrechnung wird nicht durchgeführt.', 'Bitte wenden Sie sich mit diese Information an den Administrator']})
	
		const confirmStartAccounting = async () => {
		
				invoiceResultsRef.update({
					'text/0': 'Start accounting' , 
					'isLoading': true
				})
			
				dialog('reset')
				//dispatch({type: actionTypes.START_ACCOUNTING, payload: true})
				await generateInvoiceData({
					servicesByRequester,
					servicesByProvider,
					providerDone: true,
					tasks, invoiceIds, editor
				})
				
			return
		}
		const cancelAccounting = () => {
			return dialog('reset')
		}
		dialog({
				isOpen: true,
				text: `Wollen Sie die Abrechnung starten?`,
				icon: 'help',
				title: 'Abrechnung starten',
				buttons: [
					{title: 'Abbrechen', onClick:cancelAccounting, variant: 'outlined', color: 'secondary'},
					{title: 'Starten', onClick:confirmStartAccounting, color: 'primary'}
				]
		})
		
		//return dispatch({type: actionTypes.START_ACCOUNTING, payload: true})
	}

	const cardsData = servicesByRequester.map((services, i) => {
		return ({
			header: {
				avatar: {
					content: services.length
				},
				title: services[0].requester.name,
				subheader: `Leistungen: ${services.length}`
			},
			item: services,	
		})
	})
	const accountingMonth = servicesByRequester.length>0 && `${moment(servicesByRequester[0][0].date).format('MMMM') || 'FEHLER'}`
	const accountingYear = servicesByRequester.length>0 && `${servicesByRequester[0][0].year || 'FEHLER'}`
	const title = `${accountingYear} -> ${accountingMonth}`
	if(error)
		return <ErrorMessage error={error} callback={setError}/>
	return (
		<>
			<TitleCard title={title} />
			<CardGrid data={cardsData} spacing={1} onCardClick={handleCardClick}/>
			<ButtonBoard
				buttons={[
						{ title: 'Zurück', onClick:handleBack, variant: 'outlined', color: 'primary' },
						{ title:  `${accountingMonth} abrechnen`, onClick: handleStartAccounting }
					]} 	
			/>
		</>
	)
}


export default AccountingMonthView