import {db } from '../firebase'

const memberIdREF = db.ref('memberId')
export const getNewMemberId = async  () => {
    let mId = 9000
   await memberIdREF.transaction(memberId=>{
        const newMemberId = memberId+1
        mId=newMemberId
        return newMemberId
   })
   return mId
}

