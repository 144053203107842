import { actionTypes } from '../../config'
//import {firestore } from '../../firebase/firebase'
//import {setData, setBankAccount, setError, delError, addInfo, updateInfo, delInfo, showMember, editMember, resetMember, setTasks, setPflegeData  } from '../actions/memberActions'
//import { getTasks } from 'firebase/database/tasksHander'
//import moment from 'moment'

const INIT_STATE = {
    activeSelectFilter: '',
    tasksSelectFilter: ''
}
function membersReducer(state = INIT_STATE, action) {
	switch(action.type) {
		case actionTypes.MEMBERS.FILTER_SET_ACTIVE_ROLE_ITEM : {
			return ({
                ...state,
                activeSelectFilter: action.payload
			})
		}
        case actionTypes.MEMBERS.FILTER_SET_ACTIVE_TASK_SELECT_ITEM : {
			return ({
                ...state,
                tasksSelectFilter: action.payload
			})
		}
		default : return state
	}
}

export default membersReducer
