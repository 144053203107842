import React from 'react'
import Grid from '@material-ui/core/Grid'
import DefaultCard from './DefaultCard'
//import Tooltip from '@material-ui/core/Tooltip'
import { v4 as uuid } from 'uuid'
const CardGrid = ({data, spacing, onCardClick, tooltip}) => {
	return ( 
			<Grid container spacing={ spacing || 1}>
				{data.map(item=>{
					return (
						<Grid key={uuid()} item xs md={3} >
							<DefaultCard {...item} callback={onCardClick} tooltip={tooltip} />
						</Grid>
					)
				})}
			</Grid>
	)

}

export default CardGrid