import React, { useState, useEffect } from 'react'
//import NoRowsOverlay from './NoRowsOverlay'
import keys from 'lodash/keys'
//import { deDE } from '@mui/x-data-grid'
//import AddIcon from '@material-ui/icons/Add'
import CustomToolbar from './CustomToolbar'
import { DataGridPro, deDE } from '@mui/x-data-grid-pro';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
//import { LicenseInfo } from '@mui/x-license-pro';

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
/*
LicenseInfo.setLicenseKey(
  'x0jTPl0USVkVZV0SsMjM1kDNyADM5cjM2ETPZJVSQhVRsIDN0YTM6IVREJ1T0b9586ef25c9853decfa7709eee27a1e',
)
*/

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => ({
    dataGrid: {
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none',
    }
    },
    searchContainer: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    textField: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      margin: 0,
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
  { defaultTheme },
)

export default function QuickFilteringGrid({data, onRowClickCallback, mailSenderCallback, exportExcelCallback}) {

  const [searchText, setSearchText] = useState('')
  const [rows, setRows] = useState([])
  const [filterModel, setFilterModel] = useState({ items: [] })
  const [selectedRows, setSelectedRows] = useState({ids: [], data: []})
  const [sortModel, setSortModel ] = useState([{field: 'createdAt', sort: 'desc'}])
  const classes = useStyles()

  const profileSearchFields = ['street', 'city', 'phone', 'mobile', 'email']
 // const rootSearchFields = ['userName']
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
 
    const filteredRows = data.rows.filter((row) => {
      return keys(row).some((field) => {
        if(field==='profile') {
          const resultArr = profileSearchFields.map(searchField=>{
            return searchRegex.test(row[field][searchField].toString())
          })
          return resultArr.includes(true)
        } else if(field==='userName') {
          return searchRegex.test(row[field].toString())
        }
        return false
      })
    })
    setRows(filteredRows);
  }

  useEffect(() => {
    setRows(data.rows);

    return () => {
      //   setSelectedRows(ids: [], data: [])
    }

  }, [data.rows]);
  
  const handleRowClick = selectedRow => {
      return onRowClickCallback(selectedRow)
  }
  const handleMailSend = () => {
    //setSelectedRows({ids: [], data: []})
    return mailSenderCallback(selectedRows.data)
  }
  const handleExcelExport = () => {
    //setSelectedRows({ids: [], data: []})
    return exportExcelCallback(selectedRows)
  }

  // csvOptions={{ fields: ['id', 'name'] }}

  return (
    <div style={{ display: 'flex', height: 600 }}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            scrollbarSize={25}
           // Pro pagination
            // Pro autoPageSize
            //error={true}
            aria-label='members-data-grid'
            //columnVisibilityModel={{createdAt: false}}
            className={classes.dataGrid}
            //onColumnVisibilityChange={params=>console.log(params)}
            checkboxSelection
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids)
              const _selectedRows = data.rows.filter((row) =>
                selectedIDs.has(row.id),
              )
              return setSelectedRows({ids: ids, data:_selectedRows})
            }}
            selectionModel={selectedRows.ids}
            disableSelectionOnClick
            /*onCellClick={data=>{
              if(data.field==='email' && data.value.length>=5) {
                window.location.href = `mailto:${data.value}`
              }
            }}*/
            columns={data.columns}
            allowFiltering={true}
            editMode='row'
            rowHeight={50}
            density='compact'
            getRowClassName={(params) =>{
              // No Bank Account Row Color RED
              if(!params.row.bankAccount)
                return 'bg-color-red'
            }}
            getCellClassName={(params) => {
              if(params.field==='pflegeRoles') {
                const fieldColor = params.value==='Bedarf'
                  ? 'color-red'
                  : params.value==='Angebot'
                    ? 'color-blue'
                    : params.value==='A/B'
                      ? 'color-green'
                      : ''
                return fieldColor
              }
            }}
            
            onRowClick={row=>{handleRowClick(row)}}
            //checkboxSelection
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            loading={data.rows===undefined}
            rows={rows || []}
            components={{ 
              Toolbar: CustomToolbar,
              //NoRowsOverlay: ()=><NoRowsOverlay title='Keine Mitglieder gefunden' />
            }}
            filterModel={filterModel}
            onFilterModelChange={(model) => setFilterModel(model)}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(''),
                onMailSendClick: handleMailSend,
                onExcelExportClick: handleExcelExport,
                selectedRowsCount: selectedRows.ids.length,
              },
            }}
          />
        </div>
    </div>
  );
}