import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import ArchivDashboard from 'Container/Archiv/ArchivDashboard'
import StatisticDashboard from 'Container/Statistics/Dashboard'
import Members from 'Container/Members/Members'
import MembersArchiv from 'Container/Members/MembersArchiv'
import AddNachbarshaftsService from 'Container/Services/Nachbarschaftshilfe/AddServices'
import AccountingNachbarschafshilfe from 'Container/Accounting/Nachbarschaftshilfe/AccountingNachbarschaftshilfe'
import Calendar from  'Container/Planning/Calendar'
import SettingsDashboard from 'Container/Settings/SettingsDashboard'
import FileStorage from 'Container/FileStorage/FileStorage'
import TasksDashboard from 'Container/Settings/Tasks/Tasks'
import UserDashboard from 'Container/UserDashboard'
//import Admin from 'Container/Admin/Admin'
export const routes = {
    USER_DASHBOARD: {
        title: 'App Übersicht',
        path: '/',
        nav: {
            title: 'App Übersicht',
            icon: 'Apps',
            hidden: false,
        },
        discription: []
    },
    ADD_NACHBARSCHAFTS_SERVICES: {
        title: 'Leistungen eingeben',
        path: '/nachbarschaftshilfe_leistungseingabe',
        nav: {
            title: 'Leistungen eingeben',
            icon: 'PostAdd'
        },
        discription: ['Eingabe von Leistungsnachweisen für Mitglieder die über die Nachbarschaftshilfe abgrechnet werden.']
    },
    ACCOUNTING_NACHBARSCHAFTSHILFE: {
        title: 'Abrechnung Mitglieder', 
        path: '/abrechnung_nachbarschaftshilfe',
        nav: {
            title:'Abrechnung Mitglieder',
            icon: 'AccountBalance'
        },
        discription: ['Abrechnung der eingegebenen Leistungsnachweise für Mitglieder der Nachbarschaftshilfe']

    },
    MEMBERS: {
        title: 'Mitglieder',
        path: '/mitglieder',
        nav: {
            title: 'Mitglieder',
            icon: 'Loyalty'
        },
        discription: ['Verwaltung der Mitglieder und des Mitglieder-Archivs (gelöschte Mitglieder)']

    },
    ARCHIV_DASHBOARD: {
        title: 'Archiv Abrechnungen',
        path: '/archiv',
        nav: {
            title: 'Archiv',
            icon: 'Description'
        },
        discription: ['Archiv mit allen Abrechnungen die bis Dato abgrechnet wurden (Hilfebedarf und Helfer)']

    },
    STATISTIC_DASHBOARD: {
        title: 'Statistiken und Auswertungen',
        path: '/statistik_dashboard',
        nav: {
            title: 'Statistiken',
            icon: 'BarChart',
        },
        discription: ['Statistiken über Mitglieder und Abrechnungen für die Nachbarschaftshilfe']
   
    },
    CALENDAR: {
        title: 'Planung',
        path: '/plannung',
        nav: {
            title: 'Planung',
            icon: 'Loyalty'
        },
        discription: ['Planungsmodul für die Vermittlung von Nachbarschaftshilfen']

    },
    SETTINGS_DASHBOARD: {
        title: 'Einstellungen',
        path: '/einstellungen',
        nav: {
            title: 'Einstellungen',
            icon:'Settings'
        },
        discription: ['Verwaltung von Tätigkeiten', 'Stornierung von Abrechnungen', 'Lastschriften Mitglieder Jahresbeitrag', 'Lastschriften für Spenden']

    },
    FILE_STORAGE: {
        title: 'Dateiablage',
        path: '/datei_ablage',
        nav: {
            title: 'Dateiablage',
            icon: 'Storage'
        },
        discription: ['Dateienablage für Dokumente zum Austausch mit Kolleg(inn)en. Die Dateigröße sollte maximal 10 Megabyte betragen.']

    },
    /*
    MANUAL_INVOICE: {
        title: 'Manuelle Abrechnung',
        path: '/manuelle_abrechnung',
        nav: {
            title: 'Manuelle Abrechnung',
            icon: 'Receipt'
        }
    },
    */

}

export const PrivateRoutes = () =>
<React.Fragment>
    <Route exact path={routes.USER_DASHBOARD.path} component={() => <UserDashboard />} />
    <Route exact path={routes.CALENDAR.path} component={() => <Calendar />} />
    <Route exact path={routes.ACCOUNTING_NACHBARSCHAFTSHILFE.path} component={() => <AccountingNachbarschafshilfe />} />
    <Route exact path={routes.ADD_NACHBARSCHAFTS_SERVICES.path} component={() => <AddNachbarshaftsService />} />
    <Route exact path={routes.MEMBERS.path} component={() => <Members />} />
    <Route exact path='/mitglieder_archiv' component={()=><MembersArchiv />} />
    <Route exact path={routes.ARCHIV_DASHBOARD.path} component={() => <ArchivDashboard />} />
    <Route exact path={routes.STATISTIC_DASHBOARD.path} component={() => <StatisticDashboard />} />
    <Route exact path={routes.SETTINGS_DASHBOARD.path} component={() => <SettingsDashboard />} />
    <Route exact path={'/einstellungen/taetigkeiten'} component={() => <TasksDashboard />} />
    <Route exact path={routes.FILE_STORAGE.path} component={() => <FileStorage />} />
    {/*<Route exact path={routes.MANUAL_INVOICE.path} component={() => <CustomAccounting />} />*/}
    {/*<Route exact path='/admin' component={() => <Admin />} />*/}
    <Redirect to={routes.USER_DASHBOARD.path} />
</React.Fragment>

export default PrivateRoutes