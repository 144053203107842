import { createTheme } from '@material-ui/core/styles'
import { deDE as coreDeDE } from '@material-ui/core/locale'
//import purple from '@material-ui/core/colors/purple'
import {green} from '@material-ui/core/colors'
//import blue from '@material-ui/core/colors/blue'
//import grey from '@material-ui/core/colors/grey'

let theme = createTheme({
  palette: {
    green: {
      dark: green['900'],
      main: green['500'],
      light: green['100']
    },
 
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2rem',
    },
    body1: {
      fontSize: "1.3rem"
    },
  },
  drawer: {
    width: '300px',
    height: '100%',
    border: 'none'
    //backgroundColor: grey[100]
  },
  overrides: {
    MuiMenuItem: {
      root: {fontSize: '1rem'}
    },
    MuiPopoverPaper: {
      backgroundColor: green
    }
  },
  coreDeDE,
})

theme = createTheme({
  palette: {
    background: '#204161',
    gradient: {
      main: `linear-gradient(to bottom right, ${theme.palette.primary.dark}, ${theme.palette.primary.light})`,
    }
  }
})
//theme = responsiveFontSizes(theme);

//console.log(theme)

export default theme