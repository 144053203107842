import { TextField, Grid, } from '@material-ui/core'
import React, { useState, createRef } from 'react'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { validateEmail } from 'js/validationHelper'
import { addRecipient } from 'store/actions/mailSenderActions'

const RecipientSelect = ({onChangeCallback}) => {
  const textInputREF = createRef()
  const [extRecipient, setExtRecipient ] = useState('')

  const handleAddRecipient = () => {
    console.log(extRecipient)
    addRecipient({name: extRecipient, address: extRecipient})
    setExtRecipient('')
    return textInputREF.current.focus()
  }

  return (
    <div>  
      <Grid container spacing={1}  direction='row' alignItems="flex-end">
        <Grid item xs={12} md={11} >
          <TextField
            label='Externer Empfänger'
            autoFocus
            InputLabelProps={{shrink: true, style: {color: 'white'}}}
            inputRef={textInputREF}
            value={extRecipient}
            placeholder='Geben Sie hier eine E-Mail Adresse ein...'
            fullWidth
            onChange={e=>setExtRecipient(e.currentTarget.value)}
            //className={classes.subjectField}
            inputProps={{style: {backgroundColor: 'whitesmoke', textIndent: '0.5rem'}}} 
          />
        </Grid>
        <Grid item xs={12} md={1} >
          <Button
            size='small'
            disabled={!validateEmail(extRecipient)}
            variant='contained'
            color='secondary'
            onClick={handleAddRecipient}
          >
            <AddIcon />
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default RecipientSelect
