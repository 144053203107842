import React from 'react'
//import Loading from 'Components/Loading/Spinner'
import { db } from '../../../../firebase/firebase.js'
import Grid from '@material-ui/core/Grid'
import ButtonBoard from 'Components/Buttons/ButtonRow'
import FileResults from './FileResults'
import ResultState from './ResultState'
import { dispatch } from 'store/index'
import actionTypes from 'config/actionTypes'
const accountingResultRef = db.ref('invoiceResults/nachbarschaftshilfe')

const AccountingResults = ({data, date})  => {
	const handleCloseResult = () => {
		accountingResultRef.set({isLoading: false})
		return dispatch({type: actionTypes.SET_ACCOUNTING_MONTH, payload: undefined})
	}

	return (
		<Grid container spacing={1} >
			<Grid item md={6} xs={12}>
				<ResultState data={data} date={date} />
			</Grid>
			<Grid item md={6} xs={12} >
				<FileResults data={data} date={date}/>
			</Grid>
			<Grid item md={12} xs={12} >
				<ButtonBoard buttons={[{ 
					title: 'Schließen',
					onClick:handleCloseResult,
					variant: 'outlined',
					color: 'secondary' 
				}]}
				/>
			</Grid>
		</Grid>
	)
}
export default AccountingResults
