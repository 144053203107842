import { dispatch } from '../index.js'
import {actionTypes} from '../../config'
import { validateIban } from '../../js/IbanValidation'
import { firestore } from '../../firebase'
import has from 'lodash/has'

const institutsRef = firestore.collection('instituts')

export const addNewMember = () => {
	const newMemberId = firestore.collection('members').doc().id
	return dispatch({type: 'add_new_member', payload: {id: newMemberId, title: 'Neues Mitglied'}})
}
export const showMember = title => {
	return dispatch({type: actionTypes.MEMBER.SHOW_MEMBER, payload: title})
}

export const memberFormChanged = value => {
	return dispatch({type: actionTypes.MEMBER.FORM_CHANGED, payload: value})
}

export const editMember = async member => {
	const newMember = { ...member }

	if(member.bankAccount) {
		const bankAccountSnap = await member.bankAccount.get()
		const bankAccount = bankAccountSnap.data()
		newMember.bankAccount = bankAccount
	
		if(newMember.bankAccount && newMember.bankAccount.bic.length!==0 && newMember.bankAccount.institutName) {
			newMember.bankAccount.isValide = true
		} else {
			if(newMember.bankAccount.iban.length!==0) {
				const validateResult = validateIban(newMember.bankAccount.iban)
				const { bankData: {bic, city, name, zip}, iban, messages, valid	 } = validateResult
				newMember.bankAccount = {
					institutName: name,
					city: `${zip} ${city}`,
					bic,
					iban,
					messages,
					isValide: valid
				}
			}
		}
	}
	if(member.contactPerson) {
		const contactPersonSnap = await member.contactPerson.get()
		newMember.contacPerson = contactPersonSnap.data()
	}

	return dispatch({type: actionTypes.MEMBER.EDIT_MEMBER, payload: newMember})
}
export const resetMember = () => {
	return dispatch({type: actionTypes.MEMBER.RESET_MEMBER})
}

export const setPflegeData = async payload => {

	if(payload.propKey==='institut' && Boolean(payload.value)) {
		const institutRef= institutsRef.doc(payload.value)
		const institutSnap = await institutRef.get()
		const institut = institutSnap.data()
		institut.id = institutSnap.id
		payload.value = institut
	}
	return dispatch({type: actionTypes.MEMBER.SET_PFLEGE_DATA, payload})
}

export const setData = async (formName, payload) => {
	
	if(formName==='pflegeData' && payload.propKey==='institut' && payload.value ) {
		const institutRef= institutsRef.doc(payload.value)
		const institutSnap = await institutRef.get()
		payload.value = institutSnap.data()
	}
	payload.formName = formName
	return dispatch({type: actionTypes.MEMBER.SET_DATA, payload })
}

export const setBankAccount = (payload) => {
	return dispatch({type: actionTypes.MEMBER.SET_BANK_ACCOUNT, payload })
}


export const setError = (formName, payload) => {
	payload.formName = formName
	return dispatch({type: actionTypes.MEMBER.SET_ERROR, payload})
}
export const delError = (formName, _payload) => {
	const payload = { propKey: _payload, formName}
	return dispatch({type: actionTypes.MEMBER.DEL_ERROR, payload})
}
export const addInfo = value => {
	const payload = value
	return dispatch({type: actionTypes.MEMBER.ADD_INFO, payload})
}
export const updateInfo = value => {
	const payload = value
	return dispatch({type: actionTypes.MEMBER.UPDATE_INFO, payload})
}
export const delInfo = value => {
	const payload = value
	return dispatch({type: actionTypes.MEMBER.DEL_INFO, payload})
}
export const memberIsLoaded = (value) => {
	return dispatch({type: actionTypes.MEMBER.IS_LOADED, payload: value})
}

export const setInitState = () => {
	return 'FAKE'
}
export const setTasks = tasks => {
	return dispatch({type: actionTypes.MEMBER.SELECTED_TASKS, payload: tasks})

}
//---------------

export const setFormError = payload => {
	return dispatch({type: actionTypes.MEMBER.SET_ERROR, payload})
}

export const dispatchMember = async (member) => {
	const bankAccountIsRef = !has(member.bankAccount, ['institutName', 'bic', 'iban'])
	if(bankAccountIsRef) {
		const bankAccountSnap = await member.bankAccount.get()
		const bankAccount = bankAccountSnap.data()
		member.bankAccount = bankAccount
	}
	if(!member.bankAccount.isValide) {
		const result = validateIban(member.bankAccount.iban)
		const additionalData = {
			institutName: result.bankData.name,
			bic: result.bankData.bic,
			city: `${result.bankData.zip} ${result.bankData.city}`,
			isValide: result.valid
		}
		member.bankAccount = {...member.bankAccount, ...additionalData}
	
	}
	const payload = member
    return dispatch({type: actionTypes.SET_MEMBER, payload})
}

export const setAddressData = payload => {
	return dispatch({type: actionTypes.MEMBER.SET_ADDRESS_DATA, payload})
}

export const setFormErrors = payload => {

	return dispatch({type: actionTypes.MEMBER.SET_ERRORS, payload})
}
export const delFormError = payload => {

	return dispatch({type: actionTypes.MEMBER.DEL_ERROR, payload})
}

export const saveMember = () => {
	return dispatch({type: actionTypes.MEMBER.SAVE})
}

export const setFileUploadProgress = (progress) => {
	return dispatch({type: actionTypes.MEMBER.FILE_UPLOAD_PROGRESS, payload: {progress}})
}

export const updateUploadedFiles = files => {
	return dispatch({type: actionTypes.MEMBER.UPDATE_UPLOADED_FILES, payload: files})
}

