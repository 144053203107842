import React from 'react'
import CardGrid from 'Components/Cards/DefaultCardGrid'
import moment from 'moment'
import { connect } from 'react-redux'
import {actionTypes} from 'config/config'
import ButtonBoard from 'Components/Buttons/ButtonRow'
import TitleCard from 'Components/Title/TitleCard'


const AccountingInvoiceView = ({selectedInvoice, tasks, dispatch})=> {

	const handleBack = () => {
		return dispatch({type: actionTypes.SET_ACCOUNTING_INVOICE, payload: undefined})
	}
	//arrSort(selectedInvoice, 'date')
	const cardData = selectedInvoice.services.map((service, i) =>{	
		const currentTask = tasks[service.taskId]
		const { hourlyRate } = currentTask
		const { hours, date, km, provider } = service
		const kmPausch= km!==0 ? service.km*currentTask.kmRate : 0.00
		const total = (hours*hourlyRate)+kmPausch
		return ({
			header: {
				avatar: {
					content: i+1
				},
				title: moment(date).format('DD.MM.YYYY'),
				subheader: provider.name
			},
			content: {
				text: [
					{text: currentTask.name, component: 'li'},
					{text: `${hours.toFixed(2)} Std x ${(hourlyRate).toFixed(2)}€ = ${(hours*hourlyRate).toFixed(2)}€`, component: 'li'},
					{text: `Km-Pauschale: ${kmPausch.toFixed(2)}€`, component: 'li'},
					{text: `Gesamt: ${total.toFixed(2)}€`, component:'li', color: 'primary'}
				]
			},
			item: service	
		})})
		const title = `${selectedInvoice.year} -> ${moment(selectedInvoice.date).format('MMMM')} -> ${selectedInvoice.requester.userName} (${selectedInvoice.id})`
	return (
		<>
			<TitleCard title={title} />
			<CardGrid data={cardData} spacing={1} />
			<ButtonBoard buttons={[
					{title: 'Zurück', onClick:handleBack, variant: 'outlined', color: 'primary'},
				]}
			/>
		</>
	)
}

export default connect((state) => {
	return ({   
		selectedInvoice: state.accounting.selectedInvoice
  	})
})(AccountingInvoiceView)