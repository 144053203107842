import React, {useEffect, useState } from 'react'
import { Typography, Paper, List, ListItem, ListItemText, ListItemIcon, Tooltip } from '@material-ui/core'
import OpenIcon from '@material-ui/icons/OpenInBrowserOutlined'
import makeStyles from '@material-ui/styles/makeStyles'
import { firestore } from '../../../firebase'
import { forEachSeries } from 'modern-async'
import LoadingHandler from 'Components/Loading/LoadingHandler'

const useStyles = makeStyles((theme) =>({
	headlineWrapper: {
        backgroundColor: theme.palette.secondary.light,
        color: 'whitesmoke',
        paddingLeft: theme.spacing(1)
  },
  listItem: {
      marginBottom: theme.spacing(1),
      backgroundColor: 'whitesmoke',
      '&:hover': {
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.light,
	},
  }
}))
export const ResultsProvider = ({data, date, setProviderInvoicesCallback}) => {

    const [invoices, setInvoices ] = useState(undefined)
    useEffect( () => {
        const getData = async () => {
            const REF = firestore.collection('accounting').doc('nachbarschaftshilfe').collection('provider')
            const INVOICES = []
            await forEachSeries(data, async provider=>{
                const invoiceQuery = REF.where("date", "==", date).where("provider.id", "==", provider.id)
                const invoiceSnap = await invoiceQuery.get()
                invoiceSnap.forEach(_invoice=>{
                    const invoice = _invoice.data()
               
                    INVOICES.push(invoice)
                })
            })
            setProviderInvoicesCallback(INVOICES)
            setInvoices(INVOICES)
        }
        getData()

        return () => {
        }
    }, [data, setProviderInvoicesCallback, date])
    
    const classes = useStyles()

    return (
        <div>
            <Paper className={classes.headlineWrapper} >
                <Typography variant='subtitle1'>Betroffene Helfer Abrechnungen</Typography>
            </Paper>
            <LoadingHandler
                isLoaded={invoices}
                color='secondary'
            >
                <List dense={true} >
                    {invoices && invoices.map(invoice=>{
                        return (
                            <Tooltip key={invoice.id} title='Klicken um zu öffnen'>
                                <ListItem button={true} component={Paper} className={classes.listItem}>
                                    <ListItemIcon>
                                        <OpenIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={invoice.id}
                                        //primaryTypographyProps={{style: {color: 'white'}}}
                                        secondary={invoice.provider.userName}
                                        onClick={()=>window.open(invoice.pdfFiles.provider)}
                                    />
                                </ListItem>
                            </Tooltip>
                        )
                    })}
            </List>
                </LoadingHandler>
        </div>
    )
}

export default ResultsProvider
