import { actionTypes } from '../../config'
    
    function userReducer(state = {}, action) {

      switch(action.type) {
        case actionTypes.DISPATCH_AUTH_USER : {
            return ({
                ...state,
                isLoading: false,
                isAuth: true,
                //emailVerified: action.user.emailVerified, 
                id: action.user.id,
                username: action.user.username,
                role: action.user.role,
                firebase: action.user,
                settings: action.user.settings
            })
        }
        case actionTypes.DISPATCH_NON_AUTH_USER : {
          return ({
              ...state,
              ...action.user,
          })
      }
      
        default : return state;
      }
    }
    
    export default userReducer;