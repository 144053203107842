import moment from 'moment'
import { saveAs } from "file-saver";
import * as ExcelJS from "exceljs"

const excludeColumns = ['tasks', 'files', 'notes']

const columnsConverter = (columns) => {
    const _columns = columns.filter(col=>!excludeColumns.includes(col.field))
    const convertedColumns = _columns.map(col=>{
        const convertedValue = {
            header: col.headerName,
            key: col.field,
        }
        if(col.width)
            convertedValue.width = col.width/10
        return convertedValue
    })
    return convertedColumns
}
const convertRows = data => {
  
    return data.map(row=>({
        memberId: row.memberId,
        userName: row.userName,
        createdAt: moment(row.createdAt).format('DD.MM.YYYY'),
        pflegekasse: row.pflegekasse ? 'JA' : 'NEIN',
        salutation: row.profile.salutation,
        street: row.profile.street,
        postCode: row.profile.postCode,
        city: row.profile.city,
        phone: row.profile.phone,
        phoneWork: row.profile.phoneWork,
        mobile: row.profile.mobile,
        email: row.profile.email,
        car: row.profile.car ? 'JA' : 'NEIN',
        roles: (row.roles.requester && row.roles.provider)
            ? 'A/B'
            : row.roles.requester
                ? 'Bedarf'
                : row.roles.provider
                    ? 'Angebot'
                    : 'Passiv',
        pflegeRoles: (row.pflegeRoles.requester && row.pflegeRoles.provider)
            ? 'A/B'
            : row.pflegeRoles.requester
                ? 'Bedarf'
                : row.pflegeRoles.provider
                    ? 'Angebot'
                    : '',
        birthday: row.profile.birthday && row.profile.birthday.length>3
            ? moment(row.profile.birthday).format('DD.MM.YYYY')
            : '',
        birthmonth: row.profile.birthday && row.profile.birthday.length>3
            ? moment(row.profile.birthday).format('MMMM')
            : '',
        educated: row.profile.educated ? 'JA' : 'NEIN',
        membershipFee: `${Number(row.profile.membershipFee)}`,
        donation: Number(row.profile.donation)
    }))
  
}
const excelConverter = async ( {data, columns}) => {
    if(!data || data.length===0)
        return null
    const convertedCols = columnsConverter(columns)
    const convertedRows = convertRows(data)

    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet("My Sheet")
    const fileName = `Genfakt_Mitglieder_Export_${moment().format('YYMMDDHHmm')}.xlsx`
    worksheet.columns = convertedCols
    worksheet.addRows(convertedRows)
    // save under export.xlsx
    const buffer = await workbook.xlsx.writeBuffer()
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const blob = new Blob([buffer], {type: fileType});
    return saveAs(blob, fileName);
}

export default excelConverter