import React, { createRef } from 'react'
import { connect } from 'react-redux'
import { Paper, Button, FormGroup } from '@material-ui/core'
import QuickFilter from './Filter/QuickFilter'
import FilterByEventState from './Filter/FilterByEventState'
import FilterByUser from './Filter/FilterByUser'
import { resetAllEventFilter } from 'store/actions/planningActions'


const FilterToolbar = ({filtersActive}) => {
    const quickFilterInputREF = createRef()
    const handleResetFilters = () => {
        quickFilterInputREF.current.value=null
        return resetAllEventFilter()
    }

    return (
        <Paper square={false} style={{marginBottom: '1rem', padding: '0.6rem'}} elevation={8}>
            <FormGroup row>
                <QuickFilter style={{marginRight: '1rem'}} inputREF={quickFilterInputREF}/>
                <FilterByUser style={{marginRight: '1rem', minWidth: '12rem'}} />
                <FilterByEventState style={{marginRight: '1rem', minWidth: '12rem'}} />
            <Button disabled={!filtersActive} size='small' variant='contained' color='secondary' onClick={handleResetFilters}>Zurücksetzen</Button>
            </FormGroup>
        </Paper>
    )
}

export default connect((state, props) => ({
        //filteredEvents: state.planning.filteredEvents,
        filtersActive: state.planning.quickFilter.isActive || state.planning.byUserFilter.isActive || state.planning.byEventStateFilter.isActive
    }))(FilterToolbar)