import { db } from '../firebase'
import { dispatchAuthUser } from '../../store/actions/userActions'


const usersRef = db.ref('/users')

export const changeUserSetting = ({setting, userId, value}) => {
    const ref = db.ref(`users/${userId}/settings/${setting}`)
    return ref.set(value)
}

export const fetchUser = (userId, emailVerified) => {
    if(userId===false || userId===undefined || userId===null) return dispatchAuthUser(false)
    usersRef.child(userId).on('value', userSnap=>{
        const user = userSnap.val()
        return dispatchAuthUser(user)
    })
}

export const createUserProfile = (userId, profile) => {
    const userData = {
        id: userId,
        emailVerified: false,
        appUser: true,
        profile: profile,
        role: {
            admin: false,
            appUser: true,
            user: false,
        },
        settings: {

        }
    }
   return usersRef.child(userId).set(userData)
}

export const setAppSetting = (userId, appSetting, value) => {
   return usersRef.child(userId).child('app').child(appSetting).update(value)
}

export const updateEmailVerification = (userId) => {
    usersRef.child(userId).child('emailVerified').set(true)
}