import { TASKS } from '../../config/actionTypes'
import { firestore } from '../../firebase/firebase'
const tasksREF = firestore.collection('tasks')

const INIT_STATE = {
    dialog: false,
    form: {
        type: 'nachbarschaftshilfe',
        title: '',
        name: '',
        hourlyRate: '',
        kmRate: '',
        genbundRate: '',
    },
    mebersResult: null,
}

function tasksReducer(state = INIT_STATE, action) {
    switch(action.type) {
        case TASKS.DIALOG: {
            return({
                ...state,
                dialog: action.payload || false
            })
        }
        case TASKS.TASK_FORM : {
            if(action.payload.field==='title') {
                return({
                    ...state,
                    form: {
                        ...state.form,
                        title: action.payload.value,
                        name: action.payload.value
                    }
                })
            }
            return ({
                ...state,
                form: {
                    ...state.form,
                    [action.payload.field]: action.payload.value
                }
            })
        }
        case TASKS.RESET_FROM : {
            return ({
                ...state,
                dialog: false,
                form: {
                    ...INIT_STATE.form
                }
            })
        }
        case TASKS.EDIT_TASK: {
            return({
                ...state,
                dialog: true,
                form: {
                    ...action.payload
                }
            })
        }
        case TASKS.SAVE_TASK : {
            const taskREF = tasksREF.doc(state.form.id)
            const id = state.form.id || taskREF.id
            
            const newTask = {
                ...state.form,
                id
            }
            taskREF.set(newTask)
            return ({
                ...state,
                dialog: false,
                form: {
                    ...INIT_STATE.form
                }
            })
        }
        default : return state;
    }
  }
  
  export default tasksReducer