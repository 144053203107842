import React, { useEffect } from 'react'
import { MEMBERS } from '../../../config/actionTypes'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { connect } from 'react-redux'
//import GetIcon from 'Components/Icons/GetIcon'
//import IconButton from '@material-ui/core/IconButton'
const monthNames = [
  {title: 'Januar'},
  {title: 'Februar'},
  {title: 'April' },
  {title: 'Mai' },
  {title: 'Juni' },
  {title: 'Juli' },
  {title: 'August' },
  {title: 'September' },
  {title: 'Oktober' },
  {title: 'November' },
  {title: 'Dezember' }
]

const rolesAdd = { title: 'Passiv' }

const RolesFilter = ({activeFilter, dispatch, item, applyValue}) => {

  const rolesPflegekasse = [
    {title: 'A/B'},
    {title: 'Bedarf' },
    {title: 'Angebot'},
  ]
  let data = rolesPflegekasse
  if(item.columnField==='roles')
      data.push(rolesAdd)
  if(item.columnField==='birthmonth')
    data = monthNames

  
  
  useEffect(() => {
    if(activeFilter!=='' && item.value===undefined)
      return dispatch({type: MEMBERS.FILTER_SET_ACTIVE_ROLE_ITEM, payload: ''})
        //active Item FOOBAR
}, [item.value, dispatch, activeFilter])

  const handleFilterChange = event => {
    applyValue({...item, value: event.target.value})
    return dispatch({type: MEMBERS.FILTER_SET_ACTIVE_ROLE_ITEM, payload: event.target.value })
   // return setActiveItem(event.target.value)
  }
  return (
    <TextField
      id='role-filter-select'
      select
      label='Role'
      onChange={handleFilterChange}
      value={activeFilter}
    >
      {data.map((item, i)=>{
        const addDivider = i!==data.length-1
              return <MenuItem divider={addDivider} dense key={i} value={item.title}>
                {item.title}
              </MenuItem>
        })}
    </TextField>
  )
  
}

const RolesFilterConnected = connect((state) => {
  return ({
  activeFilter: state.members.activeSelectFilter,
  //membersDeleted: state.firestore.ordered.membersDeleted || [],
  //member: state.firestore.data.members ? state.firestore.data.members['0Gcc9zcifuxJ0zKNoPDK'] : undefined,
  members: state.firestore.ordered.members, 
})})(RolesFilter)

export const selectFilterOperators = [
  {
    label: 'ist',
    value: 'equals',
    getApplyFilterFn: (filterItem) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }

      return (params) =>{
        const filter = (filterItem.value==='Bedarf' || filterItem.value==='Angebot')
          ? params.value===filterItem.value || params.value==='A/B'
          : params.value===filterItem.value
     
        return filter
      }
    },
    InputComponent: RolesFilterConnected,
 
  },
]

export default selectFilterOperators

