import React, {createRef, useEffect } from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { dispatchFilteredEvents } from 'store/actions/planningActions'
import values from 'lodash/values'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'

const FilterByUser = ({events, style, users, filters, byUserFilter}) => {
    const inputRef = createRef()
    const handleOnChange = e => {
        const inputValue = e.target.value
        const isActive = inputValue!=='noValue'
        return dispatchFilteredEvents({filter: filters.byUser, isActive, inputValue, events})
       // users[e.target.value].id
    }
    
    useEffect(() => {
        const inputValue = inputRef.current.value
        const isActive = inputValue!=='noValue'
        if(isActive) {
            dispatchFilteredEvents({filter: filters.byUser, isActive: true, inputValue, events })
        }
      return () => {
        
      }
      // eslint-disable-next-line
    }, [events, filters.byUser])
    

    return(
        <FormControl style={style} error={byUserFilter.isActive} >
            <InputLabel id="demo-simple-select-helper-label">Nach Mitarbeiter filtern</InputLabel>
            <Select
                name='planing-member-filter'
                inputRef={inputRef}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={byUserFilter.inputValue}
                onChange={handleOnChange}
            >
                <MenuItem key='defaultValue' value="noValue">
                    <em style={{color: 'grey'}}>Kein Filter</em>
                </MenuItem>       
                {users && values(users).map(user=>
                    <MenuItem key={user.id} value={user.id}>{user.username}</MenuItem>)}   
            </Select>
            {/*<FormHelperText>Some important helper text</FormHelperText>*/}
        </FormControl>
    )
}

export default compose(
    firebaseConnect(() => [
        { path: 'users' }
    ]), // or { collection: 'todos' }
    connect((state, props) => ({
        events: state.firestore.ordered.planning,
        byUserFilter: state.planning.byUserFilter,
        filters: state.planning.filters,
        users: state.firebase.data.users
    }))
    )(FilterByUser)
/*
export default connect(state=>({
    events: state.firestore.ordered.planning,
    byUserFilter: state.planning.byUserFilter,
    filters: state.planning.filters,
  }))(FilterByUser)
*/