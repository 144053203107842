import actionTypes from '../../config/actionTypes'
import moment from 'moment'
//import { firestore } from '../../firebase/firebase'
import { chartOptions } from 'Container/Statistics/ChartSelect'
const { STATISTIC } = actionTypes


const INIT_STATE = {
  isLoading: false,
  chartSelect: {
    chartCategory: '',
    chartType: '',
    startYear: '',
    endYear: '',
    startMonth: '',
    endMonth: '',
  },
  selectedData: null,
  statisticData: undefined
}
function statisticReducer(state = INIT_STATE, action) {
  switch(action.type) {
    case STATISTIC.CHART_SELECT : {
      const isAccounting = action.payload.value==='accounting'
      if(action.payload.name==='chartCategory') {
        const yearsLength = chartOptions.years[action.payload.value].length-1
        return({
          ...state,
          chartSelect: {
            ...state.chartSelect,
            [action.payload.name]: action.payload.value,
            chartType: chartOptions.chartType[action.payload.value][0].value,
            startYear: isAccounting ? Number(moment().format('YYYY')) : chartOptions.years[action.payload.value][0].value,
            endYear: chartOptions.years[action.payload.value][yearsLength].value,
            startMonth: '01',
            endMonth: '12'
          }
        })
      }
      return({
        ...state,
        chartSelect: {
          ...state.chartSelect,
          [action.payload.name]: action.payload.value
        }
      })
      
    }
    case STATISTIC.IS_LOADING : {
      return ({
          ...state,
          isLoading: action.payload || false,
      })
    }
     case STATISTIC.GET_SELECTED_DATA : {
    
      return ({
          ...state,
          isLoading: false,
          selectedData: action.payload,
      })
    }
    case STATISTIC.RESET : {
        return ({
            ...state,
            ...INIT_STATE,
        })
    }
    default : return state;
  }
}
  
export default statisticReducer