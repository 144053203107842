import moment from 'moment'



export const statMonths = [
    {value: '01', label: 'Januar'},
    {value: '02', label: 'Februar'},
    {value: '03', label: 'März'},
    {value: '04', label: 'April'},
    {value: '05', label: 'Mai'},
    {value: '06', label: 'Juni'},
    {value: '07', label: 'Juli'},
    {value: '08', label: 'August'},
    {value: '09', label: 'September'},
    {value: '10', label: 'Oktober'},
    {value: '11', label: 'November'},
    {value: '12', label: 'Dezember'},
  ]

export const getChartYears = startYear => {
    const currentYear = moment().format('YYYY')
    const years = []
    for(let year=startYear; year<=currentYear; year++) {
        years.push(year)
    }
    return years
}


const generateChartOptions = (selectedChartCategory) => {
    const chartCategories = [
        {label: 'Auswahl', value: 'select'},
        {label: 'Mitglieder', value: 'members'},
        {label: "Abrechnungen", value: 'accounting'}
    ]

    // if selectedChartCategory is undefined return just the Categories
    if(!selectedChartCategory)
        return chartCategories
    const defaultSelectValue = [{label: 'Auswahl', value: 'select'}]
    const result = {
        select: {
            chartType: defaultSelectValue,
            years: defaultSelectValue,
            months: defaultSelectValue
        },
        members: {
            chartType:[
                { label: 'Auswahl', value: 'select'},
                { label: 'Mitglieder gesamt', value: 'members'},
                { label: 'Mitglieder Zuwachs', value: 'memberGrowth' },
                { label: 'Mitglieder pro Ort', value: 'membersByTown'}
            ],
            years: [
                {label: 'Auswahl', value: 'select'},
                ...getChartYears(2015).map(year=>({ label: year.toString(), value: year }))               
            ],
            months: [
                ...statMonths
            ]
        },
        accounting: {
            chartType: [
                { label: 'Auswahl', value: 'select' },
                { label: 'Stunden pro Monat', value: 'hoursByMonth' },
                { label: 'Einsätze pro Monat', value: 'callsByMonth' },
                { label: "Stunden pro Ort", value:  'hoursByTown'},
            ],
            years: [
                { label: 'Auswahl', value: 'select'},
                ...getChartYears(2023).map(year=>({ label: year.toString(), value: year }))
            ],
            months: [
                ...statMonths
            ]
        }
    }
    return result[selectedChartCategory]
}

export default generateChartOptions