import React from 'react';
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import { v4 as uuid } from 'uuid'
import makeStyles from '@material-ui/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { FormGroup, Switch, FormControlLabel } from '@material-ui/core'

const useStyles = makeStyles((theme) =>({
	root: {
		marginTop: theme.spacing(1)
	},
	margin: {
		margin: theme.spacing(1)
	},
	title: {
		paddingLeft: theme.spacing(1),
		paddingTop: theme.spacing(1),
		//textTransform: 'upperCase'
	
	}
}))
export const ButtonBoard = ({buttons, title, children, cardStyles, className}) => {
	const classes = useStyles()

	return (
		<Card raised className={`${classes.root} ${className}`} style={cardStyles} >
			{title && <Typography component='span' className={classes.title} variant='body2'>{title}</Typography>}
			<FormGroup row>
				{buttons.map( button =>{
					if(button.type==='switch') {
						const { type, ...rest} = button
						return <MySwitch key={uuid()} {...rest} />
					}
					return <Button
						size={button.size || 'small'}
						type={button.type || undefined}
						className={classes.margin}
						key={uuid()}
						onClick={button.onClick}
						variant={button.variant || 'contained'}
						color={button.color || 'primary'}
						style={button.style || {} }
						disabled={button.disabled}
						fullWidth={button.fullWidth || false}
						startIcon={button.icon || undefined}
						endIcon={button.endIcon || undefined}
					>
						{typeof button.title ==='string'
						? button.title
						: button.title.map((ti, i)=><span key={i} style={i===0 ? {color: ti.color || 'inherit'} : {color: ti.color || 'red', fontSize: 11, fontWeight: 'bold', marginLeft: '4px', paddingTop: '3px'}}>{ti.value}</span>)}
						
					</Button>}
				)}
				{children}
			</FormGroup>
		</Card>
  	)
}

const MySwitch = ({checked, onChangeCallback, name, label, ...custom}) =>
	<FormControlLabel
	style={{color: 'white', margin: '8px'}}
	checked={checked}
	control={<Switch onChange={onChangeCallback} name={name} {...custom}  />}
	label={label}
  />

export default ButtonBoard
