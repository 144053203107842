import React, {PureComponent} from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { dispatchChartSelection, getStatData } from 'store/actions/statisticActions'
import {Button, Select, MenuItem, FormControl, InputLabel, FormHelperText} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
//import { getTotalMembers } from 'firebase/database/statisticsHandler'
import { getChartYears, statMonths } from './generateChartOptions'
import arrSort from 'array-sort'


const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(1),
    },
  })

export const chartOptions = {
    chartCategory: [
        {label: 'Mitglieder', value: 'members'},
        {label: "Abrechnungen", value: 'accounting'}
    ],
    chartType: {
        members: [
            { label: 'Mitglieder gesamt', value: 'totalMembers'},
            { label: 'Mitglieder Zuwachs', value: 'membersGrowth' },
            { label: 'Mitglieder nach Ort', value: 'membersByTown'},
            { label: 'Mitglieder nach Rolle', value: 'membersByRole'},
            { label: 'Mitgl. Rolle nach Ort', value: 'rolesByTown'},
      
        ],
        accounting: [
            { label: 'Stunden pro Monat', value: 'hoursByMonth' },
            { label: "Stunden nach Ort", value:  'hoursByTown'},
            { label: 'Stunden nach Tätigkeiten', value: 'hoursByTask'},
            { label: 'Top 10 Helfer Stunden', value: 'topTenProvider'},
            { label: 'Top 10 Bedarf Stunden', value: 'topTenRequester'}
        ]
    },
    years: {
        members: getChartYears(2015).map(year=>({label: year.toString(), value: year})),
        accounting: getChartYears(2023).map(year=>({label: year.toString(), value: year}))
    }, 
    months: statMonths
}

class ChartSelect extends PureComponent {

    handleSelectChange = (propKey, value) => {
        dispatchChartSelection({name: propKey, value})
    }
 
    render () {
        const { classes, chartCategory, chartType, startYear, endYear, startMonth, endMonth } = this.props


        return (
            <div>
                <ChartTypeSelect
                    fullWidth
                    classes={classes}
                    info='stat categogire'
                    label='Statistik Art'
                    id='chart-category-select'
                    labelId='chart-category-select-label'
                    value={chartCategory}
                    options={chartOptions.chartCategory}
                    onChange={e=>this.handleSelectChange('chartCategory', e.target.value)}
                />
                <ChartTypeSubSelect
                    fullWidth
                    info='Stat type'
                    disabled={this.props.chartCategory===''}
                    classes={classes}
                    label='Statistik'
                    id='chart-type-select'
                    labelId='chart-type-select-label'
                    value={chartType}
                    options={chartOptions.chartType[this.props.chartCategory] || []}
                    onChange={e=>this.handleSelectChange('chartType', e.target.value)}
                />
                <StartYearSelect
                    fullWidth
                    error={this.props.startYear>this.props.endYear}
                    info='from year'
                    disabled={this.props.chartType===''}
                    classes={classes}
                    label='Von Jahr'
                    id='start-year-select'
                    labelId='start-year-select-label'
                    value={startYear}
                    options={chartOptions.years[this.props.chartCategory] || []}
                    onChange={e=>this.handleSelectChange('startYear', e.target.value)}
                />
                <EndYearSelect
                    fullWidth
                    error={this.props.startYear>this.props.endYear}
                    disabled={this.props.startYear===''}
                    classes={classes}
                    label='Bis Jahr'
                    id='end-year-select'
                    labelId='end-year-select-label'
                    value={endYear}
                    options={chartOptions.years[this.props.chartCategory] || []}
                    onChange={e=>this.handleSelectChange('endYear', e.target.value)}
                    //error={this.state.errors.endYear}
                    //helperText='Das Jahr muss kleiner oder gleich sein!'
                />
                <StartMonthSelect
                    fullWidth
                    error={this.props.startMonth>this.props.endMonth}
                    disabled={this.props.endYear==='' || this.props.chartType==='hoursByMonth'}
                    classes={classes}
                    label='Von Monat'
                    id='start-month-select'
                    labelId='start-month-select-label'
                    value={startMonth}
                    options={arrSort(chartOptions.months, 'value', {reverse: true})}
                    onChange={e=>this.handleSelectChange('startMonth', e.target.value)}
                />
                <EndMonthSelect
                    fullWidth
                    error={this.props.startMonth>this.props.endMonth}
                    disabled={this.props.startMonth==='' || this.props.chartType==='hoursByMonth'}
                    classes={classes}
                    label='End Monat'
                    id='end-month-select'
                    labelId='end-month-select-label'
                    value={endMonth}
                    options={arrSort(chartOptions.months, 'value')}
                    onChange={e=>this.handleSelectChange('endMonth', e.target.value)}
                    //error={this.state.errors.endMonth}
                    //helperText='Das Monat muss kleiner oder gleich sein!'
                />

                <Button 
                    disabled={(startYear==='', endYear==='', startMonth==='', endMonth==='')}
                    variant='contained'
                    color='primary' 
                    size='small' 
                    onClick={()=>getStatData(this.props)}
                    fullWidth
                        >Chart erstellen
                    </Button>
            </div>
        )
    }
}

const ChartTypeSelect = props =>
    <DefaultSelect {...props} />

const ChartTypeSubSelect = (props) =>
    <DefaultSelect {...props} />

const StartYearSelect = props =>
    <DefaultSelect {...props} />

const EndYearSelect = props =>
    <DefaultSelect {...props} />

const StartMonthSelect = props => 
    <DefaultSelect {...props} />

const EndMonthSelect = props =>
    <DefaultSelect {...props} />

const DefaultSelect = ({fullWidth, classes, info, label, labelId, id, value, options, onChange, error, helperText, ...rest}) => {
   
    const renderMenuItems = options.map(opt=><MenuItem key={opt.value} value={opt.value} dense>{opt.label}</MenuItem>)
    
    return (
        <FormControl size='small' fullWidth={fullWidth} className={classes.formControl}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                size='small'
                error={error}
                value={value}
                labelId={labelId}
                id={id}
                onChange={onChange}
                {...rest}
            >
                {renderMenuItems} 
            </Select>
            <FormHelperText>{error && helperText }</FormHelperText>
        </FormControl>

    )
}

export default compose(
    connect(state=>{
        return({
            activeApp: state.user.settings.activeApp,
            chartCategory: state.statistic.chartSelect.chartCategory,
            chartType: state.statistic.chartSelect.chartType,
            startYear: state.statistic.chartSelect.startYear,
            endYear: state.statistic.chartSelect.endYear,
            startMonth: state.statistic.chartSelect.startMonth,
            endMonth: state.statistic.chartSelect.endMonth,
            selectedData: state.statistic.selectedData,
        })
    }),
    withStyles(styles))(ChartSelect)
