 import { PLANNING } from 'config/actionTypes'
import { firestore } from '../../firebase/firebase'



const filters = {quick: 'quickFilter', byUser: 'byUserFilter', byEvent: 'byEventStateFilter'}
const INIT_STATE = {
  event: {
    start: null,
    end: null,
    title: null,
    provider: null,
    requester: null,
    id: null,
    info: '',
    fixedDate: false,
    canceledEvent: false,
    onHoldEvent: false,
    editors: [],
    isNew: true,
    handOver: {label: '', value: ''},
  },
  filters,
  quickFilter: {
    isActive: false,
    error: false,
    inputValue: null,
  },
  byUserFilter: {
    isActive: false,
    error: false,
    inputValue: 'noValue',
  },
  byEventStateFilter: {
    isActive: false,
    error: false,
    inputValue: 'noValue',
  },
  filteredEvents: null,
  requester: undefined,
  provider: undefined,
  functions: {
    saveEvent: (event)=>{

      let ref = ''
      if(event.id) {
        ref = firestore.collection('planning').doc(event.id)
      } else {
        ref = firestore.collection('planning').doc()
      }
      const id = ref.id
      event.id = id
      return ref.set(event)
    },
    deleteEvent: id =>firestore.collection('planning').doc(id).delete()
  }
}
  function planningReducer(state = INIT_STATE, action) {

    switch(action.type) {
      case PLANNING.NEW_EVENT : {
          return ({
              ...state,
              event: {
                ...INIT_STATE.event,
                ...action.payload
              }
          })
      }
      case PLANNING.SAVE_EVENT : {
        return ({
            ...state,
            event: action.payload
        })
      }
      case PLANNING.SET_INFO : {
        return ({
          ...state,
          event: {
            ...state.event,
            info: action.payload
          }
        })
      }
      case PLANNING.TOGGLE_EVENT_STATUS : {
        const eventStati = {fixedDate: false, onHoldEvent: false, canceledEvent: false,}
        eventStati[action.payload] = !state.event[action.payload]

        return({
          ...state,
          event: {
            ...state.event,
            ...eventStati
          }
        })
      }
      case PLANNING.RESET_NEW_EVENT : {
        return({
          ...state,
          event: {...INIT_STATE.event}
        })
      }
      case PLANNING.ADD_EDITOR : {
        const { editors } = state.event
        const newEditors = [...editors, action.payload]
        return({
          ...state,
          event: {
            ...state.event,
            editors: newEditors

          }
        })
      }
      case PLANNING.FILTER_EVENTS : {
        const { filter, inputValue, events, isActive } = action.payload
        const { byUserFilter, byEventStateFilter, quickFilter } = state
        const filters = { byUserFilter, byEventStateFilter, quickFilter }
        filters[filter] = {
          isActive,
          error: false,
          inputValue,
        }
        const filteredEvents = planningFilter(events, filters)
        return({
          ...state,
          [filter] : {
            isActive,
            error: false,
            inputValue,
          },
          filteredEvents
        })
      }
      case PLANNING.RESET_QUICK_FILTER : {
        return({
          ...state,
          quickFilter: INIT_STATE.quickFilter,
          filteredEvents: INIT_STATE.filteredEvents
        })
      }
      case PLANNING.RESET_ALL_FILTER: {
        return({
          ...state,
          quickFilter: INIT_STATE.quickFilter,
          byUserFilter: INIT_STATE.byUserFilter,
          byEventStateFilter: INIT_STATE.byEventStateFilter,
          filteredEvents: INIT_STATE.filteredEvents
        })
      }
      case PLANNING.EVENT_HAND_OVER : {
        return({
          ...state,
          event: {
            ...state.event,
            responUser: {
              name: action.payload.label,
              label: action.payload.label,
            },
            handOver: {
              value: action.payload.value,
              label: action.payload.label,
            }
          }
        })
      }
      default : return state;
    }
}

const _quickFilter = (events, inputValue) =>
  events.filter(event=>{
    const { requester, provider, info } = event
    if(requester && requester.userName.toLowerCase().includes(inputValue.toLowerCase()))
        return true
    if(provider && provider.userName.toLowerCase().includes(inputValue.toLowerCase()))
        return true
    if(info && info.toLowerCase().includes(inputValue.toLowerCase()))
        return true
    return false
  })


const planningFilter = (events, {byUserFilter, byEventStateFilter, quickFilter}) => {
  let result = [...events]
    if(byUserFilter.isActive) {
      result = result.filter(event=>event.editors[event.editors.length-1].id===byUserFilter.inputValue)
 
    }
    if(byEventStateFilter.isActive) {
      result = result.filter(event=>event.eventState===byEventStateFilter.inputValue)

    }
    if(quickFilter.isActive) {
      result = _quickFilter(result, quickFilter.inputValue)
    }


  return result
}

  
  
  export default planningReducer