import React, {useState, useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/styles'
import moment from 'moment'
import NoRowsOverlay from 'Components/DataGrid/NoRowsOverlay'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import {
    // LicenseInfo,
     DataGrid,
     //onRowClick,
     //onRowOver,
     deDE,  
     GridToolbarColumnsButton,
     //GridToolbarDensitySelector,
    // GridToolbarExport,
     GridToolbarFilterButton,
   } from '@mui/x-data-grid'
 
const accountingTypeMapper = {
    member: 'institut',
    institut: 'institut',
    requester: 'requester',
    provider: 'provider'
}

const pdfMapper = {
    member: 'requester',
    requester: 'requester',
    provider: 'provider',
    institut: 'institut'
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        height: '30rem',
        marginTop: theme.spacing(1)
    },
    dataGrid: {
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
        }
        },
        searchContainer: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        },
        textField: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        margin: 0,
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    },
}))

const columns = [
    {
        field: 'id',
        headerName: 'Abr-Nr.',
        editable: false,
        align: 'center',
        headerClassName: 'grid-header',
    },
    {
        field: 'month',
        headerName: 'Monat',
        editable: false,
        width: 50,
        align: 'center',
        headerClassName: 'grid-header',

    },
    {
        field: 'year',
        headerName: 'Jahr',
        editable: false,
        width: 70,
        align: 'center',
        headerClassName: 'grid-header',
    },
    {
        field: 'AbrDate',
        headerName: 'Abr.Datum',
        editable: false,
        width: 100,
        align: 'center',
        valueGetter: (params) => {
            const accountType = accountingTypeMapper[params.row.accountingType]
        
            //console.log(params.row)
            return moment(params.row.editors[accountType].timeStamp).format('DD.MM.YYYY')
          },
    },
    {
        field: 'AbrRecipient',
        headerName: 'Empfänger',
        editable: false,
        width: 200,
        renderCell: params => {
            return <Tooltip title={`${params.row.recipient.street} ${params.row.recipient.postCode} ${params.row.recipient.city}`}>
                <div style={{cursor: 'pointer'}}>{params.row.recipient.name}</div>
            </Tooltip>
        },
        valueGetter: (params) =>params.row.recipient.name
          
    },
    {
        field: 'AbrTime',
        headerName: 'Abr.Zeit',
        editable: false,
        width: 70,
        align: 'center',
        valueGetter: (params) => {
            const accountType = accountingTypeMapper[params.row.accountingType]
            return moment(params.row.editors[accountType].timeStamp).format('HH:MM')
          },
    },
    {
        field: 'AbrMa',
        headerName: 'Abr.Mitarbeiter',
        editable: false,
        width: 200,
        valueGetter: (params) => {
            const accountType = accountingTypeMapper[params.row.accountingType]
            return params.row.editors[accountType].name
          },
    },
    {
        field: 'totalsHours',
        headerName: 'Std.',
        editable: false,
        width: 76,
        align: 'center',
        valueGetter: (params) => params.row.totals.hours
    },
    {
        field: 'totalsHoursPayment',
        headerName: 'Std/€',
        editable: false,
        width: 70,
        align: 'center',
        valueGetter: (params) => params.row.totals.hoursPayment+'€'
    },
    {
        field: 'totalsKm',
        headerName: 'KM',
        editable: false,
        width: 40,
        align: 'center',
        valueGetter: (params) => params.row.totals.km
    },
    {
        field: 'totalsKmPayment',
        headerName: 'KM/€',
        editable: false,
        width: 50,
        align: 'center',
        valueGetter: (params) => params.row.totals.kmPayment+'€'
    },
    {
        field: 'totalPayment',
        headerName: 'Gesamt',
        editable: false,
        width: 70,
        align: 'center',
        valueGetter: (params) => params.row.totals.totalPayment+'€'
    },


]

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
  
const ArchivFilterList = ({invoices, isLoading}) => {
    
    const classes = useStyles()
    const [filterModel, setFilterModel] = useState({ items: [] })
    const [rows, setRows] = useState([])
    const [searchText, setSearchText] = useState('')
    const [selectedRows, setSelectedRows] = useState({ids: [], data: []})
    useEffect(() => {
        setRows(invoices)
        return () => {
          //   setSelectedRows(ids: [], data: [])
        }
    }, [invoices])
  
    const requestSearch = (searchValue) => {
    setSearchText(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
  
    const filteredRows = invoices.filter((row) => {
        return searchRegex.test(row.recipient.name)
    })
      setRows(filteredRows);
    }

    const handleSelectedRows = () => {
      
        return setSelectedRows({ids: [], data: []})
    }
    const handleRowClick = value => {
        const pdfPath = value.row.pdfFiles[pdfMapper[value.row.accountingType]]
        return window.open(pdfPath, '_blank')
    }

    return (
     
        <div className={classes.root}>
            <DataGrid
                rows={rows || []}
                columns={columns}
                editMode='row'
                initialState={{
                    sorting: {
                    sortModel: [{ field: 'timeStamp', sort: 'desc' }],
                    },
                }}
                sx={{
                    boxShadow: 2,
               
                    '& .grid-header': {
                   
                      },
                    '& .MuiDataGrid-cell:hover': {
                  
                    },
                  }}
                density='compact'
                //checkboxSelection
                /*onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids)
                    const _selectedRows = invoices.filter((row) =>
                      selectedIDs.has(row.id),
                    )
                    return setSelectedRows({ids: ids, data:_selectedRows})
                }}
                selectionModel={selectedRows.ids}
                disableSelectionOnClick
                */
                filterModel={filterModel}
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                onFilterModelChange={(model) => setFilterModel(model)}
                className={classes.dataGrid}
                allowFiltering={true}
                loading={isLoading}
                onRowClick={row=>{handleRowClick(row)}}
                components={{ 
                    Toolbar: QuickSearchToolbar,
                    NoRowsOverlay: ()=><NoRowsOverlay title='Keine Abrechnung gefunden!' />
                }}
                componentsProps={{
                    toolbar: {
                    value: searchText,
                    onChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch(''),
                    onDeleteForEverClick: handleSelectedRows,
                    //onRecoverClick: handleRecoverMembers,
                    selectedRowsCount: selectedRows.ids.length,
                    },
                }}
            />
        </div>
    )
}

function QuickSearchToolbar(props) {
    const classes = useStyles()
    return (
        <div className={classes.searchContainer} style={{margin: '1rem 0 1.5rem 0'}}>
            <div>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </div>   
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Suchen…"
                className={classes.textField}
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                    <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        style={{ visibility: props.value ? 'visible' : 'hidden' }}
                        onClick={props.clearSearch}
                    >
                        <ClearIcon fontSize="small" />
                    </IconButton>
                    ),
            }}
            />
      </div>
    )
}

export default ArchivFilterList

