import { firestore } from '../firebase'
import moment from 'moment'

const accountingREF = firestore.collection('accounting')

export const getInvoice = async ({activeApp, subCollection, invoiceId}) => {

    const REF = accountingREF.doc(activeApp).collection(subCollection).doc(invoiceId)
    const invoiceSnap = await REF.get()
    const invoice = invoiceSnap.data()
    return invoice
}


export const searchInvoicesByMemberName = async ({ searchValue, activeApp, searchOption })=> {

    const appREF = accountingREF.doc(activeApp)

    let institutDONEResult = []
    let institutResult = []
    const requesterResult = await _searchInvoices({searchType: 'requester', searchOption, searchValue, appREF})

    const providerResult = await _searchInvoices({searchType: 'provider', searchOption, searchValue, appREF})

    if(activeApp==='pflegekasse') {
        institutDONEResult = await _searchInvoices({searchType: 'institutDONE', searchOption, searchValue, appREF })

        institutResult = await _searchInvoices({searchType: 'institut', searchOption, searchValue, appREF})
    }

    return [...requesterResult, ...providerResult, ...institutDONEResult, ...institutResult]
}


const _searchInvoices = async ({appREF, searchType, searchOption, searchValue }) => {
    const searchREF = appREF.collection(searchType)
        .where(`${searchType}.${searchOption}`, '>=', searchValue)
        .where(`${searchType}.${searchOption}`, '<=', searchValue+'\\utf8')
    const RESULT = []
    const searchSNAP = await searchREF.get()
    if(searchSNAP.size>0) {
        searchSNAP.forEach(inSnap => {
            RESULT.push(inSnap.data())
        })
    }
    return RESULT
}

export const searchInvoicesByDate = async ({activeApp, searchValue}) => {

    const startDate = moment(searchValue).format()
    const endDate = moment(`${searchValue}T23:59:00`).format()

    const appREF = firestore.collection('accounting').doc(activeApp)
    const searchSNAPs = {}
    const results = []
    searchSNAPs.requester = await appREF.collection('requester')
    .where('editors.requester.timeStamp', '>=', startDate)
    .where('editors.requester.timeStamp', '<=', endDate)
    .get()

    searchSNAPs.provider = await appREF.collection('provider')
    .where('editors.provider.timeStamp', '>=', startDate)
    .where('editors.provider.timeStamp', '<=', endDate)
    .get()

    if(searchSNAPs.requester.size!==0)
        searchSNAPs.requester.forEach(reqSnap=>results.push(reqSnap.data()))
    if(searchSNAPs.provider.size!==0)
        searchSNAPs.provider.forEach(provSnap=>results.push(provSnap.data()))

    if(activeApp==='pflegekasse') {
     
        searchSNAPs.institut = await appREF.collection('institut')
        .where('editors.institut.timeStamp', '>=', startDate)
        .where('editors.institut.timeStamp', '<=', endDate)
        .get()

        searchSNAPs.institutDONE = await appREF.collection('institutDONE')
        .where('editors.institut.timeStamp', '>=', startDate)
        .where('editors.institut.timeStamp', '<=', endDate)
        .get()

        if(searchSNAPs.institut.size!==0)
            searchSNAPs.institut.forEach(insSnap=>{
            if(insSnap.data().accountingType!=='member') {
                results.push(insSnap.data())
            }
        })
        if(searchSNAPs.institutDONE.size!==0)
            searchSNAPs.institutDONE.forEach(insDoneSnap=>results.push(insDoneSnap.data()))
    }
    return results
}

export const getInvoicesByMonth = async ({activeApp, accountingType, month, year}) => {

    const accountingTypeSnap = await accountingREF
    .doc(activeApp)
    .collection(accountingType)
    .where('year', '==', `${year}`)
    .where('month', '==', month)
    .get()

    const result = []
    accountingTypeSnap.forEach(accountingType=>{
        result.push(accountingType.data())
    })
    return result
}

//searchInvoiceDate({activeApp: 'pflegekasse', searchValue: '2023-07-17'})
