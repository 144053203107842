import React, { useState, useEffect } from 'react'
import {makeStyles} from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import { useHistory, useLocation } from "react-router-dom"
import IconSelector from 'Components/Icons/IconSelector'
import values from 'lodash/values'

const useStyles = makeStyles(theme => {return ({
  root: {
    minWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'inherit',

  },
  navItem: {
      color: 'white',
      
     
  },

})})


const ButtonNav = ({ navItems, showLabels }) => {
  const location = useLocation()
  const [value, setValue] = useState(location.pathname)
  const items = values(navItems) || []
  const classes = useStyles()
  const history = useHistory()


  useEffect(() => {
    history.listen((location, action) => {
      setValue(location.pathname)
  })
    
  }, [history])
 

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        event.preventDefault()
        setValue(newValue)
        history.push(newValue)
      }}
      showLabels={showLabels || true}
      className={classes.root}
    >
      {items.map(item => {
          if(item.nav.hidden)
            return null
    
          return  <BottomNavigationAction
                        key={item.nav.title}
                        style={item.nav.style || {}} 
                        value={item.path || '/'}
                        className={classes.navItem}
                        label={item.nav.title || 'Home'}
                        icon={<IconSelector iconName={item.nav.icon} />}
                      />
      })}
    </BottomNavigation>   
  )
}


export default ButtonNav