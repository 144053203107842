import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

const TabHeader = ({gridItems}) => {
    return (
        <Grid container justifyContent="space-between">
            {gridItems.map(gridItem=>
                <Grid key={gridItem.label} item {...gridItem.settings} >
                    <Typography variant='subtitle1' >{gridItem.label}</Typography>
                    <Divider />
                </Grid>
            )}
          
        </Grid>
    )
}
export default TabHeader
