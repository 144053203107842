import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import { dispatchInstitutForm } from 'store/actions/institutsActions'
import { withSnackbar } from 'notistack'

const inputFields = [
    {key: 1, size: 'small', label: 'Pflegekassen Bezeichnung', name: 'name', autoFocus: true },
    {key: 2, size: 'small', label: 'Straße:', name: 'street' },
    {key: 3, size: 'small', label: 'Postleistzahl', name: 'postCode',   inputProps: { maxLength: 5, minLength: 5, size: 5}},
    {key: 4, size: 'small', label: 'Ort', name: 'city' },
    {key: 5, size: 'small', type: 'tel', label: 'Telefon', name: 'phone' },
    {key: 6, size: 'small', label: 'Ansprechpartner', name: 'contactPerson' },
    {key: 7, size: 'small', label: 'Versicherungs-Nr', name: 'versNr'},
]

const AddInstitut = ({institut}) =>
    <div style={{}}>
        <form >
            {inputFields.map(input=>
                <TextField
                    id={`add-institut-${input.key}`}
                    type={input.type || 'text'}
                    value={institut[input.name]}
                    //style={{marginBottom: '4px'}}
                    key={input.key} label={input.label}
                    onChange={e=>dispatchInstitutForm({value: e.target.value, field: input.name})}
                    fullWidth
                    inputProps={input.inputProps}
                    autoFocus={input.autoFocus || false}
                />
            )}
        </form>
    </div>
export default compose(
    withSnackbar,
    connect((state) => ({
      institut: state.instituts.form,
    })),
)(AddInstitut)
