import React from 'react'
import {appLogo } from '../config'
import Button from '@material-ui/core/Button'
import { changeUserSetting } from 'firebase/database/userHandler'
import { useHistory } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
const Header = ({user}) => {
    const history = useHistory()
    const handleClick = () => {
     
        let newActiveApp = 'nachbarschaftshilfe'
        if(user.settings.activeApp==='nachbarschaftshilfe') {
            newActiveApp  = 'pflegekasse'
            history.push('/')
        } else {
            newActiveApp = 'nachbarschaftshilfe'
            history.push('/')
        }
        return changeUserSetting({setting: 'activeApp', userId: user.id, value: newActiveApp} )
    }
    return (
        <div id='App-header'>
        <Container maxWidth='lg' >
            <div style={{color: 'whitesmoke', display:'inline-block', width: '50%', verticalAlign: 'middle', textAlign: 'left', paddingTop: '8px'}}>
                <Typography style={{fontSize: 22}} color='inherit' variant='overline'>
                    {appLogo}{' '}
                </Typography>
                <Typography style={{fontSize: 14 }} color={user.settings.activeApp==='nachbarschaftshilfe' ? 'primary' : 'secondary'} variant='overline'>
                    {user.settings.activeApp==='nachbarschaftshilfe' ? 'Nachbarschaftshilfe' : 'Pflegekasse'}
                </Typography>
            </div>
            <div style={{display: 'inline-block', width: '50%', textAlign: 'right', paddingTop: '10px'}}>
                <Button
                    onClick={handleClick}
                    variant='outlined'
                    size='large'
                    color={user.settings.activeApp==='nachbarschaftshilfe' ? 'primary' : 'secondary'}
                >
                    App Wechseln
                </Button>
            </div>
        </Container>
        </div>
     
    )
}

export default Header

