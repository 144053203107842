import React, {useState } from 'react'
//import { firestore } from '../../firebase/firebase'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import { generateDebitXML } from './generateDebitXML'
import { saveAs } from 'file-saver'
import LoadingSpinner from 'Components/Loading/Spinner'
import SaveIcon from '@material-ui/icons/Save'
import { getDonationMembers } from 'firebase/database/membersHandler'

const DonationsDebit = ({members, ...rest}) => {
    const [isLoading, setIsLoading ] = useState(false)
    const [noData, setNoData ] = useState(false)
    const xmlFileSaveCallback = xmlFile => {
        const fileName = `Spenden-Lastschrift-${moment().format('YYYY')}.xml`
        const blob = new Blob([xmlFile])
        new Promise(resolve => {
			return resolve(saveAs(blob, fileName));
		  })
		  .then(result => console.log('FILE DOWNLOADED'))
		  .catch(err => console.log("Error downloading: " + err));
    
        return setIsLoading(false)
    }
    const generateXMLData = async () => {
        setIsLoading(true)
        if(noData)
            setNoData(false)

        const callback = donateData => {
            if(!donateData) {
                setIsLoading(false)
                setNoData(true)
                return
            }
            const { transferItems, total } = donateData
            generateDebitXML({transferItems, total, tempXMLFile: 'dummy', callback: xmlFileSaveCallback, })
        }
        return getDonationMembers(callback)
    }
    const renderNoDataMsg = <span style={{color: 'red', padding: '10px'}}>Keine SPENDEN</span>
  return (
           <div style={{marginTop: '0.5rem'}}>{isLoading 
            ? <LoadingSpinner size={30}  />
            : <Button
                size='small'
                variant='outlined'
                color='primary'
                startIcon={<SaveIcon />}
                onClick={generateXMLData} >
                    {rest.buttonText ? rest.buttonText : 'XML Datei erstellen & speichern'}
            </Button>}
            {noData && renderNoDataMsg}
            </div>
  )
}

export default DonationsDebit
