
import { validateEmail, validateJustNumbers } from 'js/validationHelper'

const generateErrorText = (type, title, minLength) => {
  switch (type) {
    case 'isRequired':
      return `${title} ist ein Pflichtfeld!`
    case 'toShort':
      return `${title} muss min. ${minLength} Zeichen haben!`
    case 'noLetters': 
      return`${title} darf keine Buchstaben oder Leerzeichen enthalten!`
    case 'noNumbers':
      return `${title} darf keine Zahlen enthalten!`
    case 'noSpecialCharacters':
      return `${title} darf keine Sonderzeichen enthalten!`
    case 'invalidPhone':
      return `${title} darf keine Buchstaben oder Sonderzeichen enthalten!`
    case 'invalidEMail': 
      return `${title} hat ein falsches Format! Beispiel: xxx@xxx.de`
    default:
      return 'NO KEY MATCHED!'
  }
}
  
const validate = (values, rest) => {
  const errors = {}
  const { dispatch, change  } = rest
  if(values.profile && values.profile.educated) {
    dispatch(change('pflegeRoles.provider', true))
  } else if(values.profile && !values.profile.educated && values.pflegeRoles && values.pflegeRoles.provider) {
      dispatch(change('pflegeRoles.provider', false))
  }
  if(values.profile) {
    errors.profile = {}
    const { profile : { salutation, firstName, lastName, street, postCode, city, phone, phoneWork, mobile, email, membershipFee, donation} } = values
    // salutation
    if(!salutation) {
      errors.profile.salutation = generateErrorText('isRequired', 'Anrede')
    }
    // firstName
    if(!firstName) {
      errors.profile.firstName = generateErrorText('isRequired', 'Vorname')
    } else if (firstName.length<3) {
      errors.profile.firstName = generateErrorText('toShort', 'Vorname', 3)
    }
    // lastName
    if(!lastName) {
      errors.profile.lastName = generateErrorText('isRequired', 'Nachname')
    } else if (lastName.length<4) {
      errors.profile.lastName = generateErrorText('toShort', 'Nachname', 3)
    }
    // street
    if(!street) {
      errors.profile.street = generateErrorText('isRequired', 'Straße')
    } else if (street.length<4) {
      errors.profile.street = generateErrorText('toShort', 'Straße', 3)
    }
    // postCode
    if(!postCode) {
      errors.profile.postCode = generateErrorText('isRequired', 'Postleitzahl')
    } else if (postCode.length<4) {
      errors.profile.postCode = generateErrorText('toShort', 'Postleitzahl', 4)
    } else if (!(/^[0-9\b]+$/).test(postCode)) {
      errors.profile.postCode = generateErrorText('noLetters', 'Postleitzahl')
    }
    // city 
    if(!city) {
      errors.profile.city = generateErrorText('isRequired', 'Wohnort')
    } else if(city.length<4) {
        errors.profile.city = generateErrorText('toShort', 'Wohnort', 3)
    }
    // Phone
    if(phone && phone.length<=5) {
        errors.profile.phone = generateErrorText('toShort', 'Festnetz', 6)  
    } else if(phone && !validateJustNumbers(phone)) {
      errors.profile.phone = generateErrorText('invalidPhone', 'Festnetz') 
    }
    if(phoneWork && phoneWork.length<=5) {
      errors.profile.phoneWork = generateErrorText('toShort', 'Telefon Arbeit', 6)  
    } else if(phone && !validateJustNumbers(phone)) {
      errors.profile.phoneWork = generateErrorText('invalidPhone', 'Telefon Arbeit') 
    }
    // mobile 
    if(mobile && mobile.length<=9) {
        errors.profile.mobile = generateErrorText('toShort', 'Mobil', 10) 
    } else if(mobile && !validateJustNumbers(mobile)) {
        errors.profile.mobile = generateErrorText('invalidPhone', 'Mobil')
    }
    /*
    if(!rest.initialized && firstName && lastName && street && postCode && city)
      dispatch(change('invoiceDispatching.mailMember', true))
    // disable memberEmail if email ist deleted
    if(!email && values.invoiceDispatching && values.invoiceDispatching.emailMember) {
      if(!values.invoiceDispatching.mailMember)
        dispatch(change('invoiceDispatching.mailMember', true))
      dispatch(change('invoiceDispatching.emailMember', false))
    }
    if(email && validateEmail(email) && values.invoiceDispatching && !values.invoiceDispatching.emailMember) {
      console.log('HETZTT -------------------------')
        dispatch(change('invoiceDispatching.mailMember', false))
        dispatch(change('invoiceDispatching.emailMember', true))

      
    }
    */
    if(email && !validateEmail(email)){
      errors.profile.email = generateErrorText('invalidEMail', 'E-Mail')
      /*
      if(values.invoiceDispatching && values.invoiceDispatching.emailMember) {
        console.log('jomo')
        dispatch(change('invoiceDispatching.emailMember', false))
        dispatch(change('invoiceDispatching.mailMember', true))
      }
      */
    } /*else if(!rest.initialized && validateEmail(email)) {
        console.log('foobar')
        dispatch(change('invoiceDispatching.mailMember', false))
        dispatch(change('invoiceDispatching.emailMember', true))

    }*/
    if(membershipFee && !validateJustNumbers(membershipFee)) {
      errors.profile.membershipFee = generateErrorText('noLetters', 'Mitgliederbeitrag')
    }
    if(donation && !validateJustNumbers(donation)) {
      errors.profile.donation = generateErrorText('noLetters', 'Spende')
    }
  }
  // Contact Person has been added but no profile Data has been added so trow error
  
  if(values.contactPerson) {
    const { name, street, postCode, city, phone, phoneWork, mobile, email, copyRecipient } = values.contactPerson
    errors.contactPerson = {}
    if(name && name.length<=3) {
      errors.contactPerson.name = generateErrorText('toShort', 'Name', 3)
    }
    if(postCode && !(/^[0-9\b]+$/).test(postCode)) {
      errors.contactPerson.postCode = generateErrorText('noLetters', 'Postleitzahl')
    } else if(postCode && postCode.length<5) {
      errors.contactPerson.postCode = generateErrorText('toShort', 'Postleitzahl', 5)
    }
    if(phone && !validateJustNumbers(phone)) {
      errors.contactPerson.phone = generateErrorText('invalidPhone', 'Festnetz')
    }
    if(phoneWork && !validateJustNumbers(phoneWork)) {
      errors.contactPerson.phone = generateErrorText('invalidePhone', 'Telefon Arbeit')
    }
    if(mobile && !validateJustNumbers(mobile)) {
      errors.contactPerson.mobile = generateErrorText('invalidePhone', 'Mobile')
    }
    if(email && !validateEmail(email)) {
      errors.contactPerson.email = generateErrorText('invalideEMail', 'E-Mail')
    }

    if(copyRecipient) {
      if(!(name && street && postCode && city)){
        const text = 'Bitte ausfüllen!'
        errors.contactPerson.name = 'Rechnungs-Kopie benötigt vollständige Adresse!'
        errors.contactPerson.city = text
        errors.contactPerson.street = text
        errors.contactPerson.postCode = text
        rest.touch('member', 'contactPerson.name', 'contactPerson.street', 'contactPerson.city', 'contactPerson.postCode')
    }

    }
  }
  if((values.bankAccount || values.roles || values.contactPerson || values.pflegekasse ) && !values.profile) {
      errors.profile = {
          firstName:generateErrorText('isRequired', 'Vorname'),
          lastName: generateErrorText('isRequired', 'Nachname')
      }
  }   

  if(values.institut && !values.versNr) {
    errors.versNr = 'Bei nicht Selbstzahler ist eine Vers-Nr. erforderlich!'
  }
  return errors
}

  export default validate