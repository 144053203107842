import { CUSTOMER_ACCOUNTING } from 'config/actionTypes'

const { CONFIG } = CUSTOMER_ACCOUNTING
const INIT_STATE = {
    config : {
        appType: '',
        accountingType: '',
        taskType: '',
        recipientType: '',
        selectedMember: null,
    },
    recipient: {
        id: '',
        name: '',
        contactPerson: '',
        street: '',
        city: '',
    }
}
function customAccountingReducer(state = INIT_STATE, action) {
    
    switch(action.type) {
		case CONFIG.SET_APP_TYPE : {	
			return ({
				...state,
                config: {...state.config, appType: action.payload}
			})
		}
        case CONFIG.SET_ACCOUNTING_TYPE : {	
			return ({
				...state,
                config: {...state.config, accountingType: action.payload}
			})
		}
        case CONFIG.SET_TASK_TYPE : {	
			return ({
				...state,
                config: {...state.config, taskType: action.payload }
			})
		}
        case CONFIG.SET_RECIPIENT_TYPE : {	
			return ({
				...state,
                config: {...state.config, recipientType: action.payload}
			})
		}
        case CUSTOMER_ACCOUNTING.SET_RECIPIENT : {
            return({
                ...state,
                recipient: action.payload
            })
        }
        case CONFIG.SET_SELECTED_MEMBER : {
            return({
                ...state,
                config: {
                    ...state.config,
                    selectedMember: action.payload
                }
                
            })
        }
        default : return state;
    }
}
    
    export default customAccountingReducer;