import React, {useState } from 'react'
import { compose } from 'redux'
import { connect} from 'react-redux'
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { Grid, Input, Typography, Divider, Button } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import CheckIcon from '@material-ui/icons/Check'
//import monent from 'moment'
import { withSnackbar } from 'notistack'
import {groupBy, keys } from 'lodash'
import arrSort from 'array-sort'
import { getMonthName } from 'config/config'
import LoadingHandler from 'Components/Loading/LoadingHandler'

const styles = {
    wrapper: {

    },
    tabRow: {
        padding: '4px 0 4px 0',

    }
}

const PflegeBudgets = ({billings, enqueueSnackbar, firestore}) => {
    const [budget, setBudget] = useState(0);

    const  infoMessage = (text, variant) => {
        enqueueSnackbar(text, 
            {
                variant: variant ? variant : 'info', 
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
            }
        })
    }

    const saveBudget = (id) => {
        const serviceRef = firestore.collection('accounting').doc('pflegekasse').collection('institut').doc(id)
        if(budget[id]===undefined) {
            serviceRef.update({takenByInstitut: 0})
        } else {
            serviceRef.update({takenByInstitut: Number(budget[id])})
        }
        return infoMessage('Betrag gespeichert', 'success')
     
    }

    const groupedByYears = groupBy(billings, 'year')
    
    const tabHeaderProps = [
        {title: 'Rechnungs-Nr.'},
        {title: 'Hilfe-Emfpänger'},
        {title: 'Abrechnungsbetrag', gridSettings: {xs: 12, md:3}},
        {title: 'Pflegekassen Anteil', gridSettings: {xs: 12, md:3}}
    ]
    const defaultGridSettings = {xs:12, md:2 }
    
    const _renderTabHeader = (headerProps) => {
      return <Grid  container >
          {headerProps.map( headerProp=> {
              const griditemsSettings =  headerProp.gridSettings ? headerProp.gridSettings : defaultGridSettings
            return (
                <Grid style={{borderBottom: '1px solid grey'}} key={headerProp.title} item {...griditemsSettings}>
                    {headerProp.title}
                </Grid>
            )
        })}
      </Grid>
    }

    return (
        <LoadingHandler isLoaded={isLoaded(billings)} isEmpty={isEmpty(billings)} title='Keine Budget Daten vorhanden...'>  
            <div style={styles.wrapper} >
                {arrSort(keys(groupedByYears)).map( year => {
                    const groupedByMonths = groupBy(groupedByYears[year], 'month')
                    
                    return (
                        <div style={{paddingLeft: '20px'}} key={year}>
                            <Typography color='primary' variant='h5' >{year}</Typography>
                            <Divider style={{marginBottom: '12px'}}/>
                            {arrSort(keys(groupedByMonths)).map( (month, index) => {

                                return (
                                    <div key={index} style={{paddingLeft: '20px'}}>
                                        <Typography color='primary' component='span' variant='h6'>
                                            {`${getMonthName(month)} - `}
                                        </Typography>
                                        <Typography color='primary' variant='subtitle1' component='span'>
                                            {`(${month}/${year})`}
                                        </Typography>
                                        <Divider style={{marginBottom: '12px'}}/>
                                        <div style={{margin: '0 0 12px 20px'}} >
                                        <Typography variant='subtitle1' component='span' >
                                            <Grid container>
                                                {_renderTabHeader(tabHeaderProps)}
                                                
                                                {arrSort(groupedByMonths[month], 'sortId').map( billing =>{
                                                    const isSelfPayer = billing.accountingType==='member'
                                                    const totalPayment = billing.totals.totalPayment
                                                    const takenByInstitut = budget[billing.id] ? budget[billing.id] : billing.takenByInstitut || 0
                                                    const totalRequester = totalPayment.toFixed(3)-takenByInstitut
                                                    const rowStyle = isSelfPayer ? {...styles.tabRow, backgroundColor: '#FFA07A'} : styles.tabRow
                                                    return  <Grid style={rowStyle} key={billing.id} item md={12} container >
                                                            
                                                                <Grid item style={{fontWeight: 'bold'}} xs={12} md={2}>
                                                                    {billing.id}
                                                                </Grid>
                                                                <Grid item xs={12} md={2} >
                                                                    {`${billing.requester.userName}`}
                                                                </Grid>
                                                                <Grid item xs={12} md={3} >
                                                                    {billing.accountingType==='member'
                                                                        ? 'Rechnet direkt ab' 
                                                                        : (`${(totalPayment).toFixed(2)} - ${takenByInstitut.toFixed(2)} = ${totalRequester.toFixed(2)}€`)}
                                                                </Grid>
                                                                <Grid style={(billing.takenByInstitut || billing.takenByInstitut===0) ? {backgroundColor: '#CEF6CE'} : {}} item xs={12} md={3} >
                                                                    <Input
                                                                        defaultValue={billing.takenByInstitut || 0}
                                                                        disabled={isSelfPayer}
                                                                        type='number'   
                                                                        inputProps={{
                                                                            style: {fontSize: '1.0rem', textAlign: 'right' }
                                                                        }}
                                                                        onChange={(e)=>setBudget({[billing.id]: Number(e.target.value)})}
                                                                        style={{maxWidth: '80px'}}
                                                                    />€
                                                                    <Button disabled={billing.accountingType==='member'} variant='contained' size='small' color='primary' onClick={()=>saveBudget(billing.id)} style={{marginLeft: '16px'}}><SaveIcon /></Button>
                                                                    {(billing.takenByInstitut || billing.takenByInstitut ===0)&& <CheckIcon style={{marginLeft: '8px', verticalAlign: 'middle'}} />}
                                                                </Grid>
                                                            </Grid>
                                                })}
                                            </Grid>
                                            </Typography>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })   
                }
            </div>
        </LoadingHandler>
    )
}

export default compose(
    firestoreConnect(() => [  
        { collection: 'accounting', doc: 'pflegekasse', subcollections: [{ collection: 'institut' }], storeAs: 'institutBillings' },
    ]), // or { collection: 'todos' }
    connect((state) => ({
        billings: state.firestore.ordered.institutBillings,
    })),
    withSnackbar
    )(PflegeBudgets)


