
export const MEMBERS = {
    FILTER_SET_ACTIVE_ROLE_ITEM: 'MEMBERS_FILTER_SET_ACTIVE_ROLE_ITEM',
    FILTER_SET_ACTIVE_TASK_SELECT_ITEM: 'MEMBERS_FILTER_SET_ACTIVE_TASK_SELECT_ITEM'
}

export const MEMBER = {
    isOpen: 'MEMBER_IS_OPEN',
    isLoading: 'MEMBER_IS_LOADING',
    new: 'NEW_MEMBER',
    edit: 'EDIT_MEMBER',
    close: 'CLOSE_MEMBER',
    ADD_NEW_MEMBER: 'add_new_member',

    SHOW_MEMBER: 'SHOW_MEMBER',
    FORM_CHANGED: 'FORM_CHANGED',
    EDIT_MEMBER: 'EDIT_MEMBER',
    RESET_MEMBER: 'RESET_MEMBER',
    SET_DATA: 'SET_DATA',
    SET_BANK_ACCOUNT: 'SET_BANK_ACCOUNT',
    SET_ERROR: 'SET_FORM_ERROR',
    DEL_ERROR: 'DEL_FORM_ERROR',
    SET_ERRORS: 'SET_FORM_ERRORS',
    ADD_INFO: 'ADD_INFO',
    UPDATE_INFO: 'UPDATE_INFO',
    DEL_INFO: 'DEL_INFO',
    SAVE: 'SAVE',
    SET_PFLEGE_DATA: 'SET_PFLEGE_DATA',
    SELECTED_TASKS: 'SELECTED_TASKS',
    FILE_UPLOAD_PROGRESS: 'FILE_UPLOAD_PROGRESS',
    UPDATE_UPLOADED_FILES: 'UPDATE_UPLOADED_FILES'
    
}

export const STATISTIC = {
    IS_LOADING: 'STATISTIC_IS_LOADING',
    CHART_SELECT: 'STATISTIC_CHART__SELECTION',
    GET_SELECTED_DATA: 'STATISTIC_GET_SELECTED_DATA',
    RESET: 'RESET_STATISTIC_DATA'

}


export const PLANNING = {
    NEW_EVENT: 'NEW_PLANING_EVENT',
    SET_INFO: 'PLANING_SET_INFO_TEXT',
    TOGGLE_EVENT_STATUS: 'PLANNING_TOGGLE_EVENT_STATUS',
    RESET_NEW_EVENT: 'PLANNING_RESET_NEW_EVENT',
    SAVE_EVENT: 'SAVE_PLANNING_EVENT',
    FILTER_EVENTS: 'PLANNING_FILTER_EVENTS',
    RESET_QUICK_FILTER: 'PLANNING_RESET_QUICK_FILTER',
    RESET_ALL_FILTER: 'PLANNING_RESET_ALL_EVENT_FILTER',
    EVENT_HAND_OVER: 'PLANNING_EVENT_HAND_OVER',
    ADD_EDITOR: 'PLANNING_ADD_EDITOR_TO_EVENT'
}

export const FILE_STORAGE = {
    NEW_FILE_BLOB: 'NEW_FILE_BLOB_FILE_STORAGE',
    SET_FILE_INFO: 'SET_FILE_INFO_STORAGE_FILE',
    EDIT_FILE: 'EDIT_STORAGE_FILE',
    RESET_FILE_UPLOAD: 'RESET_FILE_UPLOAD',
    OPEN_FILE_UPLOAD: 'OPEN_FILE_STORAGE_UPLOAD',
    UPDATE_FILE_NAME: 'UPDATE_FILE_NAME_',
    SAVE_FILE: 'SAVE_FILE_ON_STORAGE'
}

export const MAILSENDER = {
    SET_RECIPIENTS: 'SET_MAIL_RECIPIENTS',
    ADD_RECIPIENT: 'ADD_EXT_MAIL_RECIPIENT',
    REMOVE_RECIPIENT: 'REMOVE_MAIL_RECIPIENT',
    MAILSENDER_DIALOG: 'MAILSENDER_DIALOG'
}

export const CUSTOMER_ACCOUNTING = {
    CONFIG: {
        SET_APP_TYPE: 'CUSTOMER_ACCOUNTING_CONFIG_APP_TYPE',
        SET_ACCOUNTING_TYPE: 'CUSTOMER_ACCOUNTNG_CONFIG_ACCOUNTING_TYPE',
        SET_TASK_TYPE: 'CUSTOMER_ACCOUNTING_TASK_TYPE',
        SET_RECIPIENT_TYPE: 'CUSTOMER_ACCOUNTING_SET_RECIPIENT_TYPE',
        SET_SELECTED_MEMBER: 'CUSTOMER_ACCOUNTING_SET_SELECTED_MEMBER'
    },
    SET_RECIPIENT : 'CUSTOMER_ACCOUNTING_SET_RECIPIENT'

}
export const TASKS = {
    TASK_FORM: 'ADD_TASKS_TO_FORM',
    RESET_FROM: 'RESET_TASK_FORM',
    SAVE_TASK: 'SAVE_TASK',
    DIALOG: 'TASK_DIALOG_OPEN_CLOSE',
    EDIT_TASK: 'TASKS_EDIT_TASK'
}

export const INSTITUTS = {
    INSTITUT_FORM: 'ADD_INSTITUT_TO_FORM',
    RESET_FROM: 'RESET_INSTITUT_FORM',
    SAVE_INSTITUT: 'SAVE_INSTITUT',
    DIALOG: 'INSTITUT_DIALOG_OPEN_CLOSE',
    EDIT_INSTITUT: 'INSTITUTS_EDIT_INSTITUT'
}

export const ARCHIV = {
    FILTER: {
        GET_INVOICES: 'ARCHIV_FILTER_GET_INVOICES'
    }
}

export const actionTypes = {
    DISPATCH_AUTH_USER: 'DISPATCH_AUTH_USER',
    DISPATCH_NON_AUTH_USER: 'DISPATCH_NON_AUTH_USER',
    APP_IS_LOADING: 'APP_IS_LOADING',
    PAGE_IS_LOADING: 'PAGE_IS_LOADING',
    FULLSCREEN_POPUP: 'FULLSCREEN_POPUP',
    DIALOG: 'DIALOG',
    START_ACCOUNTING: 'START_ACCOUNTING',
    SET_ACCOUNTING_MONTH: 'SET_ACCOUNTING_MONTH',
    SET_ACCOUNTING_INVOICE: 'SET_ACCOUNTING_INOICE',
    DEL_ACCOUNTING_SERVICE: 'DEL_ACCOUNTING_SERVICE',
    ACCOUNTING: {
            DEL_SERVICE: 'DEL_ACCOUNTING_TYPE_SERVICE',
            DEL_SERVICES: 'DEL_ACCOUNTING_TYPE_SERVICES',
        	
    },
    ARCHIV,
    TASKS,
    /* OLD ?
    FETCH_MEMBERS: 'FETCH_MEMBERS',
    FETCH_TASKS: 'FETCH_TASKS',
    FETCH_REQUESTERS: 'FETCH_REQUESTERS',
    FETCH_PFLEGE_MEMBERS: 'FETCH_PFLEGE_MEMBERS',
    FETCH_PROVIDERS: 'FETCH_PROVIDERS',
    FETCH_PFLEGEKASSE_OPEN: 'FETCH_PFLEGEKASSE_OPEN',
    FETCH_PFLEGE_PROVIDERS: 'FETCH_PFLEGE_PROVIDERS',
    FETCH_PFLEGE_PDF: 'FETCH_PFLEGE_PDF',
    FETCH_PFLEGE_PDF_REQUESTER: 'FETCH_PFLEGE_PDF_REQUESTER',
    FETCH_PFLEGE_PDF_PROVIDER: 'FETCH_PFLEGE_PDF_PROVIDER',
    SET_MEMBER: 'SET_MEMBER',
    SET_VALIDATED_IBAN: 'SET_VALIDATED_IBAN',
    */
    MEMBERS,
    MEMBER,
    STATISTIC,
    PLANNING,
    MAILSENDER,
    FILE_STORAGE,
    CUSTOMER_ACCOUNTING,
}

export default actionTypes