import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';


export default function ButtonAppBar({childreen, color}) {
  return (
  
      <AppBar position="static" color={color} >
        <Toolbar style={{height: '80px'}}>
         {childreen}
        </Toolbar>
      </AppBar>
   
 
  );
}
