import React from 'react'
import { Field, FieldArray } from 'redux-form'
import { Grid, IconButton, TextField, Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import AddIcon from '@material-ui/icons/Add'
import moment from 'moment'
import { saveAs } from 'file-saver'
const renderTextField = ({ label, input, meta: { touched, invalid, error, ...metaRest }, ...custom }) => {
    //console.log('noVAlue', noValue)
    //console.log('metaRest', metaRest)
   // console.log('input', input)
  //  console.log('cutom', custom)
        return (
         
        <TextField 
            id={`filex-text-filed-${label}`}
            variant='filled'
            //inputRef={custom.ref}
            //variant='outlined'
            multiline
            label={label}
            //placeholder={input.value || null}
            error={touched && invalid}
            //helperText={touched && error}
            {...input}
            {...custom}
        />
    )}

const Files = ({editor}) => {
  return (
    <FieldArray editor={editor} name='files' component={RenderFiles} />
  )
}

export default Files

const RenderFiles = ({fields, meta: { dirty, error, ...metaRest }, editor, ...custom}) => {
   
    const handleFileUpload = (e) =>{ 
        if(e.target.files.length===0)
            return null
        const timeStamp = moment().format()
        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i]
            fields.unshift({name: file.name, url: file, editor, timeStamp})
        }
        return true
    }
    const handleOpenFile = index => {
        const currentField = fields.get(index)
        const fileURL = dirty ? URL.createObjectURL(currentField.url) : currentField.url
        return window.open(fileURL)
    }

    const handleSaveFile = index => {
        const currentField = fields.get(index)
        const fileURL = currentField.url
        return saveAs(fileURL, currentField.name)
    }
    
    return (
        <div>
            <div>
            <input
              
                accept="file/*"
                style={{display: 'none'}}
                id="contained-input-file-upload-file"
                multiple
                type="file"
                onChange={handleFileUpload}
            />
                <label htmlFor="contained-input-file-upload-file">
                    <Button
                        style={{margin: '0.5rem 0'}}
                        variant="contained"
                        color="primary"
                        component="span"
                        size='small'
                    >
                        {/*progress ? `${progress.toFixed(2)}%` : <AddIcon />*/}
                        <AddIcon />
                    </Button>
                </label>
            </div>
            {fields.map((file, index)=>{
                const currentField = fields.get(index)
                return(
                    <Grid key={index} container >
                        <Grid item md={10} >
                        <Field
                                title='Klicken zum öffnen'
                                onClick={()=>handleOpenFile(index)}
                                disabled={true}
                                //inputProps={{readOnly: true}} 
                                fullWidth
                                minRows={2}
                                maxRows={3}
                                name={`${file}.name`}
                                style={{marginBottom: '0.4rem'}}
                                type="file"
                                component={renderTextField}
                                color='primary'
                                label={moment(currentField.timeStamp).format('DD.MM.YYYY - HH:MM')}
                                helperText={currentField.editor.userName}
                            />
                        </Grid>
                        <Grid item md={2} >
                            <IconButton
                                title="Datei Speichern!"
                                onClick={()=>handleSaveFile(index)}
                                children={<CloudDownloadIcon color='primary' fontSize="small" />
                                }
                            />
                            <IconButton
                                title="Datei löschen!"
                                onClick={() => fields.remove(index)}
                                children={<DeleteIcon color='secondary' fontSize="small"/>}
                            />
                        </Grid>
                    </Grid>
                )
            })}
        </div>
    )
}
