import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
//import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { v4 as uuid} from 'uuid'
import clsx from 'clsx';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    maxHeight: props=>props.maxHeight || '350px',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  inline: {
    display: 'inline',
  },
  listItem: {
    maxHeight: theme.spacing(10),
    cursor: 'pointer'
  },
  even: {
    backgroundColor: theme.palette.warning.light,
   // borderLeft: `15px solid ${theme.palette.primary.dark}`,
  },
  odd: {
    backgroundColor: theme.palette.warning.dark,
   // borderLeft: `15px solid ${theme.palette.secondary.dark}`,
  }
}));

export default function AlignItemsList({items, dense, deleteBtn, onItemClick, ...rest}) {
  const classes = useStyles(rest)
  return (
    <List dense={true} className={classes.root}>
	    {items && items.map((item, index)=><SimpleListItem index={index} key={uuid()} classes={classes} {...item} deleteBtn={deleteBtn} onItemClick={onItemClick} />)}
    </List>
  )
}

export const SimpleListItem = ({icon, title, text, deleteBtn, onItemClick, index}) => {

  const classes = useStyles()
  const backgroundClass = (index %2  === 0) ? classes.even : classes.odd
	return <>
		   <ListItem onClick={()=>onItemClick(index)} className={clsx(backgroundClass, classes.listItem)} style={{overflow:'hidden'}}>
          {icon && <ListItemIcon>
            {icon}
          </ListItemIcon>}
          <ListItemText
          
            secondaryTypographyProps={{variant: 'caption'}}
            primary={title || null}
            secondary={text || null}
          />
          {deleteBtn && <ListItemSecondaryAction>
            <IconButton size={deleteBtn.size || 'medium'} aria-label='delete' onClick={()=>deleteBtn.onClick(index, title, text)} color={deleteBtn.color || 'primary'}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>}
      </ListItem>
		{/*<Divider /*variant="inset"*//* component="li" />*/}
	</>
}

export const SimpleList = ({dense, children}) => {
	const classes = useStyles()
	return (
	  <List dense={dense} className={classes.root}>
		{children}
	  </List>
	)
  } 