import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { dispatchFilteredEvents, resetQuickFilter, resetAllEventFilter } from 'store/actions/planningActions'
import { IconButton, TextField, } from "@material-ui/core"
import { Search as SearchIcon, Clear as ClearIcon} from '@material-ui/icons'

const QuickFilter = ({ inputREF, events, quickFilter : {isActive}, style, filters }) => {
    
    useEffect(() => {
        const inputValue = inputREF.current.value
        const isActive = inputValue!==''
        if(isActive) {
            dispatchFilteredEvents({filter: filters.quick, isActive: true, inputValue, events })
        }
        return () => {

            if(isActive)
                resetQuickFilter()
        }
        // eslint-disable-next-line
    }, [events, filters.quick])

    
    
    const formRef = React.createRef()

    const handleSubmit = (e) => {
        e.preventDefault()
        const inputValue = e.target.inputFilter.value
        if(inputValue.length===0) {
            resetQuickFilter()
            return formRef.current.reset()
        }
        return dispatchFilteredEvents({filter: filters.quick, events, isActive: true, inputValue})
    }
 
    return (
        
        <form style={style} ref={formRef} onSubmit={handleSubmit} >
            <TextField
                inputRef={inputREF}
                name='inputFilter'
                error={isActive}
                //style={{marginBottom: '1rem'}}
                //onBlur={clearSearch}
                //value={props.value}
                //onChange={handleChange}
                placeholder="Enter startet Suche!"
                label={isActive ? 'Filter ist aktiv!' : 'Schnellsuche'}
                // helperText={filterIsActiv ? 'Filter ist aktiv' : '-'}
                InputProps={{
                startAdornment: <SearchIcon fontSize="small" color={isActive ? 'secondary' : 'primary'} />,
                endAdornment: (
                    <IconButton
                    type='reset'
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    color='primary'
                    style={{ visibility: isActive ? 'visible' : 'hidden' }}
                    onClick={resetAllEventFilter}
                    >
                        <ClearIcon fontSize="small" color={isActive ? 'secondary' : 'primary'}/>
                    </IconButton>
                ),
                }}
        />
    </form>
    )
}

  export default connect(state=>({
    events: state.firestore.ordered.planning,
    quickFilter: state.planning.quickFilter,
    filters: state.planning.filters,
  }))(QuickFilter)