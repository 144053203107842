import React from 'react'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'


const TitleCard = ({title, classes, variant, color}) =>
<Card raised style={{marginBottom: '1rem', marginTop: '1rem', padding: '0.5rem'}} className={classes && classes.card} >
	<Typography color={color} className={classes && classes.typography} variant={variant || 'h6'}>{title}</Typography>
</Card>


export default TitleCard