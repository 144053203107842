import React, { PureComponent } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { Input, Grid , IconButton} from '@material-ui/core'
import FormWrapper from 'Components/Forms/FormWrapper'
import { getInvoice, searchInvoicesByMemberName, searchInvoicesByDate } from 'firebase/database/archivHandler'
import ArchivSearchOptions from './ArchivSearchOptions'
import { dialog } from 'store/actions/dialogActions'


const searchTitleMapper = {
    invoiceId: 'Abrechnungs-Nummer suchen',
    date: 'Abrechnuns-Datum (Mitarbeiter) suchen',
    userName: 'Mitglieder Nachname Vorname suchen'

}
export class ArchivSearch extends PureComponent {
    state = {
        searchValue: '',
        searchOption: 'invoiceId'
    }

    setInputValue = value => {
  
        if(value.length===0 && this.props.search.state!==0)
            this.props.setSearchState(0)

        if(this.state.searchOption==='invoiceId') {
            if(this.props.activeApp==='nachbarschaftshilfe' && value.length===1 && value!=='A' && value!=='B') {
                return dialog({
                    isOpen: true,
                    text: 'Die Abrechnungs-Nr beginnt immer mit A- oder B-',
                    icon: 'info',
                    title: 'Falsche Eingabe!',
                    buttons: [{title: 'Ok', onClick: ()=>dialog('reset'), variant:'contained', color:'primary'}]
                })
            }
            if(this.props.activeApp==='pflegekasse' && value.length===1 && value!=='A' && value!=='B' && value!=='M' && value!=='P')
                return dialog({
                    isOpen: true,
                    text: 'Die Abrechnungs-Nr beginnt immer mit A-, B-, M- oder P-',
                    icon: 'info',
                    title: 'Falsche Eingabe!',
                    buttons: [{title: 'Ok', onClick: ()=>dialog('reset'), variant:'contained', color:'primary'}]
                })
            if(value.length===2 && value[value.length-1]!=='-') {
                return dialog({
                    isOpen: true,
                    text: 'Die Abrechnungs-Nr. muss nach dem ersten Buchstaben einen Bindestrich (z.B. B-, oder A-) enthalten!',
                    icon: 'info',
                    title: 'Falsche Eingabe!',
                    buttons: [{title: 'Ok', onClick: ()=>dialog('reset'), variant:'contained', color:'primary'}]
                })
            }
        }
        let _value = value
        if(value.length===1)
            _value = value.toUpperCase()
        return this.setState({searchValue: _value})
    }
    handleSearchInvoiceId = async () => {
        const { searchValue } = this.state
        this.props.setSearchState(1, searchValue)
        const subCollections = {
            B: 'requester',
            A: 'provider',
            P: 'institutDONE',
            M: 'requester',
        }
        const collKey = searchValue[0]
        const subCollection = subCollections[collKey]

        const result = await getInvoice({
            activeApp: this.props.activeApp,
            subCollection,
            invoiceId: searchValue
        })
        let searchState = 2
        if(result===undefined)
            searchState=99
        return this.props.setSearchState(searchState, searchValue, [result], this.state.searchOption)
    }
    handleSearchInvoicesByMember = async () => {
        const { searchValue, searchOption } = this.state
        const { activeApp } = this.props
        let searchState = 2
        this.props.setSearchState(1, searchValue)
        
        const searchResult = await searchInvoicesByMemberName({
            activeApp,
            searchOption,
            searchValue
        })
        if(searchResult.length===0)
            searchState = 99

        return this.props.setSearchState(searchState, searchValue, searchResult, this.state.searchOption)
    }

    handleSearchInvoicesByDate = async () => {
        const { activeApp } = this.props
        const { searchValue } = this.state
        let searchState = 2
        this.props.setSearchState(1, searchValue)
        const searchResult = await searchInvoicesByDate({activeApp, searchValue})
        if(searchResult.length===0)
            searchState= 99
        
        return this.props.setSearchState(searchState, searchValue, searchResult, this.state.searchOption)
    }
    onPressEnter = keyCode => {
        if(keyCode==='Enter' && this.state.searchValue.length>=6) {
            return this.searchTypeMapping[this.state.searchOption]()
        }
    }

    handleSearchOptionChange = e => {
        const optionsChange = {searchOption: e.target.value}
        this.props.setSearchState(0, null, null, e.target.value)
        if(this.state.searchValue.length!==0) {
            optionsChange.searchValue = ''
        }
        return this.setState(optionsChange)
    }

    searchTypeMapping = {
        invoiceId: this.handleSearchInvoiceId,
        userName: this.handleSearchInvoicesByMember,
        date: this.handleSearchInvoicesByDate,
    }

    render() {
        const formWrapperStyle = {paper: {style: {height: '4rem'}}, title: {variant: 'body1', color: 'primary'}}
        const searchBtnDisabled = this.state.searchOption==='invoiceId'
            ? this.state.searchValue.length<6
            : this.state.searchValue.length<2
        
        return (
            <Grid style={{}} container spacing={1}>
                <Grid item md={5} xs={12}>
                    <FormWrapper title={searchTitleMapper[this.props.search.option] || 'Suche...'} compProps={formWrapperStyle} >
                        <div style={{marginTop: '0.5rem'}}>
                            <Input   
                                type={this.state.searchOption==='date' ? 'date' : 'text'}
                                onKeyDown={e=>this.onPressEnter(e.key)}
                                size='small'
                                value={this.state.searchValue}
                                onChange={e=>this.setInputValue(e.target.value)}
                                id='archiv-search'
                                name='archivSearch'
                                variant='filled'
                                color='primary'
                            />
                            <IconButton 
                                disabled={searchBtnDisabled}
                                onClick={this.searchTypeMapping[this.state.searchOption]/*this.searchSelector[this.state.searchOption]*/}
                                size='small'
                                variant='contained'
                                color='primary'
                            >
                                <SearchIcon />
                            </IconButton>
                        </div>
                    </FormWrapper>
                </Grid>
                <Grid item md={7} xs={12}>
                <FormWrapper title='Suchen nach:' compProps={formWrapperStyle} >
                    <ArchivSearchOptions
                        value={this.state.searchOption}
                        name='archivSearchOptions'
                        ariaLabel='archiv-search-btn-group'
                        onChange={this.handleSearchOptionChange}
                        options={[
                            { label: 'Abrechnungs-Nr', value: 'invoiceId' },
                            { label: 'Nachname Vorname', value: 'userName' },
                            { label: 'Abrechnungs-Datum', value: 'date' },
                        ]}
                    />
                 
                    </FormWrapper>
                </Grid>
                        
            </Grid>
        )
    }
}

export default ArchivSearch