import { dispatch } from '../index.js'
import actionTypes from '../../config/actionTypes'

const { FILE_STORAGE } = actionTypes

export const dispatchNewFileBlob = (fileBlob, oldFileName) => {
   
    const { name, size, lastModifiedDate, type } = fileBlob
    const splittedFileName = name.split('.')
    const fileSize = Math.ceil(size/1024)
    const fileName = splittedFileName[0]
    const ext = splittedFileName[1]
    const payload = {
        type,
        lastModifiedDate,
        blob: fileBlob, 
        fileName,
        name,
        ext,
        size: fileSize
    }
    if(oldFileName)
        payload.oldFileName = oldFileName

	return dispatch({type: FILE_STORAGE.NEW_FILE_BLOB, payload})
}
export const dispatchFileInfo = (info) => {
    return dispatch({type: FILE_STORAGE.SET_FILE_INFO, payload: info})
}

export const dispatchOpenFileUpload = payload => {
    return dispatch({type: FILE_STORAGE.OPEN_FILE_UPLOAD})
}

export const dispatchEditFile = (payload) => {
    return dispatch({type: FILE_STORAGE.EDIT_FILE, payload})
}
export const resetFileUpload = () => {
    return dispatch({type: FILE_STORAGE.RESET_FILE_UPLOAD})
}

export const dispatchUpdateFileName = payload => {
    return dispatch({type: FILE_STORAGE.UPDATE_FILE_NAME, payload})
}

export const dispatchSaveFile = () => {
    return dispatch({type: FILE_STORAGE.SAVE_FILE})
}
