import React, { useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Field, FieldArray } from 'redux-form'
import { Fade, Grid, IconButton, Button, TextField } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Close'
import moment from 'moment'

const renderTextField = ({ label, input, meta: { touched, invalid, error, ...metaRest }, noValue, ...custom }) => {

    return (
    <TextField 
        id={`text-field-notes-input-${label}-d`}
        variant={'filled'}
        inputRef={custom.ref}
        //variant='outlined'
        multiline
        label={label}
        placeholder={input.value || null}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
    />
)}


const NotesSF = ({authUser}) => {
    return (
        <FieldArray name="profile.notes" authUser={authUser} component={RenderNotes}/>
  )
}

const RenderNotes = ({fields, authUser, meta: { touched, error } }) => {
    const [showNoteInput, setShowNoteInput ] = useState(false)
    const [editIndex, setEditIndex ] = useState(null)
    const timeStamp = moment().format()

    const handleEditItem = i => {
        if(editIndex!==null)
            return setEditIndex(null)
        return setEditIndex(i)
    }
    //console.log(fields.length)
    const handleBtnClick =  () => {
        return setShowNoteInput(true)   
    }
    const handleOnBlur = value => {
        if(value.length<3)
            return setShowNoteInput(false)
        fields.unshift({text: value, authUser, timeStamp})
        setShowNoteInput(false)
    }
    return (
        <>
            <div>
                <Button
                    disabled={showNoteInput}
                    name='note-add-button'
                    style={{margin: '0.5rem 0 0.5rem 0'}}
                    variant='contained'
                    color='primary'
                    size='small'
                    onClick={handleBtnClick}
                    children={<AddIcon size='small' />}
                />
            </div>
            <Fade in={showNoteInput} mountOnEnter unmountOnExit>
                <TextField
                    id='note-input-on-enter'
                    name='noteInput'
                    multiline
                    label='Notiz eingeben'
                    variant='outlined'
                    color='primary'
                    type='text'
                    minRows={8}
                    maxRows={10} 
                    onBlur={e=>handleOnBlur(e.currentTarget.value)}
                />
            </Fade>
            <Fade in={!showNoteInput} mountOnEnter unmountOnExit>
            <div>
            {fields.map((note, index) =>{
                const currentField = fields.get(index)
              
                return (
                    <Grid key={index} container >
                    <Grid item md={10}>
                        <Field
                            disabled={index!==editIndex}
                            //inputProps={{readOnly: index!==0}} 
                            fullWidth
                            minRows={index===editIndex ? 5 :3}
                            maxRows={5}
                            name={`${note}.text`}
                            style={{marginBottom: '0.4rem'}}
                            type="text"
                            component={renderTextField}
                            color='primary'
                            label={moment(currentField.timeStamp).format('DD.MM.YYYY - HH:MM')}
                            helperText={currentField.authUser.userName}
                        />
                    </Grid>
                    <Grid item md={2} >
                        <IconButton
                            title="Notiz bearbeiten!"
                            onClick={()=>handleEditItem(index)}
                            children={editIndex===index
                                ? <CheckIcon color='primary' fontSize='small' /> 
                                : <EditIcon color='primary' fontSize="small" />
                            }
                        />
                        <IconButton
                            title="Notiz löschen!"
                            onClick={() => fields.remove(index)}
                            children={<DeleteIcon color='secondary' fontSize="small"/>}
                        />
                    </Grid>
            </Grid>)
  })}
  </div></Fade>
    </>
    )
  }

export default NotesSF
