import React, { useState, createRef } from 'react'
import ReduxForm from 'Components/ReduxForms/DefaultForm'
import { bankAccount as bankAccountInputs } from '../memberInputs'
import { validateIban } from 'js/IbanValidation'
import { change } from 'redux-form'
//import InputWithButton from 'Components/Inputs/InputWithButton'
import Button from '@material-ui/core/Button'
import { TextField, Typography } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import ImportExportIcon from '@material-ui/icons/ImportExport'
export const BankAccountForm = ({dispatch, hasBankAccount}) => {
    const [ibanInputValue, setIbanInput ] = useState('')
    const [bicInputValue, setBicInput ] = useState('')
    const inputRef = createRef()
   
    const [err, setErr] = useState(null)
    const handleDeleteBankAccount = () => {
        dispatch(change('member', 'bankAccount', {}))
    }
    const handleValidateIBAN = ()=> {
        const result = validateIban(ibanInputValue)
        const newBankAccount = {
            institutName: result.bankData.name || 'Unbekannt',
            city: result.bankData.city || 'Unbekannt',
            bic: result.bankData.bic,
            iban: result.iban
        }

        if(result.valid) {
            if(err)
                setErr(null)
            return dispatch(change('member', 'bankAccount', newBankAccount))
        }
        setErr(result.messages)
        inputRef.current.focus()
        inputRef.current.select()  
    }

    const handleSaveAnyway = () => {
        const manuelBankData = {
            institutName: 'Ohne Überprüfung!',
            city: 'Manuelle Eingabe',
            bic: bicInputValue,
            iban: ibanInputValue
        }
        return dispatch(change('member', 'bankAccount', manuelBankData))
    }
   
    const sectionStyle = hasBankAccount ? {} : {backgroundColor: '#f50057'}
    if(Boolean(err) && ibanInputValue.length===0)
        setErr(null)

    return (
        <div>
            {Boolean(err) && ibanInputValue.length!==0 && bicInputValue.length<5
                ? <IbanErrorMsg messages={err}/>
                : <ReduxForm sectionName='bankAccount' sectionStyle={sectionStyle} inputs={bankAccountInputs} />
            }
            {!hasBankAccount
                ? <>
                   {Boolean(err) && <TextField
                        id='BankAccount-130'
                        value={bicInputValue}
                        size='small'
                        fullWidth
                        color='primary'
                        onChange={e=>setBicInput(e.target.value)}
                        label='BIC EINGEBEN'
                        style={{marginTop: '1rem'}}
                    />}
                    <TextField
                        id='BankAccount-135'
                        inputRef={inputRef}
                        error={Boolean(err)}
                        value={ibanInputValue}
                        size='small'
                        fullWidth
                        color='primary'
                        onChange={e=>setIbanInput(e.target.value)}
                        label='IBAN EINGEBEN'
                        style={{marginTop: '1rem'}}
                    />
                    <Button
                        endIcon={err ? <SaveIcon /> : <ImportExportIcon />}
                        disabled={ibanInputValue.length<20 || (bicInputValue.length<5 && Boolean(err))}
                        style={{marginTop: '0.3rem'}}
                        color={err ? 'secondary' : 'primary'}
                        variant='contained'
                        size='small'
                        fullWidth
                        onClick={err ? handleSaveAnyway : handleValidateIBAN}
                    >
                        {Boolean(err) ? 'Trotzdem speichern!' : 'Überprüfen'}
                    </Button>
                  </>
                  
                : <Button style={{marginTop: '1rem'}} variant='contained' color='secondary' fullWidth size='small' onClick={handleDeleteBankAccount}>Bankdaten löschen</Button>
             }
            
        </div>
    )
}

const IbanErrorMsg = ({messages}) => {

  return  <div style={{minHeight: '10rem'}}>
        <Typography align='center' variant='h6' color='error'>IBAN Fehler!</Typography>
        <Typography align='center' variant='body1' color='error'>Der IBAN ist nicht korrekt.</Typography>
        <ul>{messages.map(msg=><li key={msg}>{msg}</li>)}</ul>
    </div>
}
export default BankAccountForm
