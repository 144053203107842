import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import MembersList from './MembersList'
import Member from './Member/Member'
import Button from '@material-ui/core/Button'
import AddIcon from  '@material-ui/icons/Add'
import { connect } from  'react-redux'
import { compose } from 'redux'
import { withSnackbar } from 'notistack';
//import { dialog } from '../../store/actions/dialogActions'
//import { moveMemberToDeleted } from '../../firebase/database/membersHandler'
import MailSender from './MailSender'
import { actionTypes } from 'config'
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import Grid from '@material-ui/core/Grid'
export class Members extends PureComponent {

  infoMessage = (text, variant) => {
    this.props.enqueueSnackbar(text, 
        {
            variant: variant ? variant : 'info', 
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
        }
    })
  }

  handleRowClick = async member => {
    this.props.dispatch({type: actionTypes.MEMBER.isOpen})
    const _member = { ...member }
    if(member.contactPerson) {
      const contactPersonSnap = await member.contactPerson.get()
      _member.contactPerson = contactPersonSnap.data()
    } else {
      delete _member.contactPerson
    }
    if(member.bankAccount) {
      const bankAccountSnap = await member.bankAccount.get()
      _member.bankAccount = bankAccountSnap.data()
    } else {
      delete _member.bankAccount
    }

    return this.props.dispatch({type: actionTypes.MEMBER.edit, payload: _member})
  }

  handleNewMember = async () => {
    return this.props.dispatch({type: actionTypes.MEMBER.new})
   // return this.props.showMember.set('Neues Mitglied anlegen')
  }

  handleSwitchDeletedMembers = () => {
    return this.props.history.push('/mitglieder_archiv')
  }

  render() {
    return (
      <div>
        <Grid style={{padding: '0.5em 1.5em 0.5em 1.5em'}}container spacing={2}>
          <Grid item md={9}>
            <Button
              fullWidth
              startIcon={<AddIcon />}
              variant='contained'
              onClick={this.handleNewMember}
              size='small'
            >
              Neues Mitglied
            </Button>
          </Grid>
          <Grid item md={3}>
            <Button
              fullWidth
              startIcon={<RestoreFromTrashIcon />}
              variant='outlined'
              onClick={this.handleSwitchDeletedMembers}
              size='small'
            >
              Mitglieder Archiv
            </Button>
          </Grid>
        </Grid>
        <MembersList onRowClickCallback={this.handleRowClick}/>
        <Member />
        {this.props.recipients.length>0 && 
          <MailSender />
        }
   </div>
    )
  }
}


export default compose(
  connect((state) => ({
    recipients: state.mailSender.recipients
  })),
  withRouter,
  withSnackbar
)(Members)
