import { actionTypes } from '../../config'
    
function appReducer(state = {}, action) {
    
      switch(action.type) {
        case actionTypes.APP_IS_LOADING : {
       
            return ({
                ...state,
                isLoading: action.payload,
            })
        }
        case actionTypes.PAGE_IS_LOADING : {
         
          return ({
              ...state,
              page: {
                ...state.page,
                isLoading: {
                  ...state.page.isLoading,
                  value: action.payload.value || state.page.isLoading.value,
                  title: action.payload.title || undefined
                },
              }
          })
        }
        case actionTypes.FULLSCREEN_POPUP : {
          return ({
              ...state,
              popUp: {
                  ...state.popUp,
                  fullScreen: action.payload
              }
          })
        }
        case actionTypes.DIALOG : {
          return ({
              ...state,
              dialog: {
                  ...state.dialog,
                  ...action.payload
              }
          })
        }
    
        default : return state;
      }
    }
    
    export default appReducer;