import { dispatch } from '../index.js'
import { PLANNING } from 'config/actionTypes'


export const dispatchNewEvent = (payload) => {
	return dispatch({type: PLANNING.NEW_EVENT, payload})
}

export const dispatchAddEditor = editor => {
	dispatch({type: PLANNING.ADD_EDITOR, payload: editor})
}

export const dispatchInfoChange = payload =>
	dispatch({type: PLANNING.SET_INFO, payload})

export const dispatchFixedDateChange= payload =>
	dispatch({type: PLANNING.SET_FIXED_DATE, payload})

export const dispatchCanceledEventChange = payload => {
	dispatch({type: PLANNING.TOGGLE_CANCELED_EVENT})
}
export const dispatchOnHoldEventChange = payload => {
	dispatch({type: PLANNING.TOGGLE_ONHOLD_EVENT})
}

export const dispatchEventStatusChange = payload => {
	dispatch({type: PLANNING.TOGGLE_EVENT_STATUS, payload})
}


export const dispatchResetEvent = () =>
	dispatch({type: PLANNING.RESET_NEW_EVENT})

export const dispatchSaveEvent = payload => {
	return dispatch({type: PLANNING.SAVE_EVENT, payload})
}

export const dispatchFilteredEvents = payload => {
	return dispatch({type: PLANNING.FILTER_EVENTS, payload})
}
export const resetQuickFilter = () => {
	return dispatch({type: PLANNING.RESET_QUICK_FILTER})
}

export const resetAllEventFilter = () =>
	dispatch({type: PLANNING.RESET_ALL_FILTER})

export const dispatchHandOver = payload =>
	dispatch({type: PLANNING.EVENT_HAND_OVER, payload})