import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import isArray from 'lodash/isArray'
import { v4 as uuid } from 'uuid'
import WarningIcon from '@material-ui/icons/Warning'
import SuccessIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/Info'
import ErrorIcon from '@material-ui/icons/Error'
import Divider from '@material-ui/core/Divider'
import HelpIcon from '@material-ui/icons/Help'
import { dialog } from 'store/actions/dialogActions'
import LoadingSpinner from '@material-ui/core/LinearProgress'

const iconStyles = { verticalAlign: 'middle', marginLeft: '1rem' }
const Icons = {
  warning: <WarningIcon style={iconStyles} fontSize='large' color='secondary'/>,
  info: <InfoIcon style={iconStyles} fontSize='large' color='primary'/>,
  success: <SuccessIcon style={iconStyles} fontSize='large' color='primary'/>,
  error: <ErrorIcon style={iconStyles} fontSize='large' color='primary'/>,
  help: <HelpIcon style={iconStyles} fontSize='large' color='primary'/>,
}
export default function AlertDialog({data, children}) {
  let _renderButtons
  const isReactElement = React.isValidElement(data.buttons[0])
  if (isReactElement) {
      _renderButtons = data.buttons
  } else {
    if(data.buttons.length===0) {
        _renderButtons = <Button variant='contained' color='primary' onClick={()=>dialog('reset')} >OK</Button>
    } else {
      _renderButtons = data.buttons.map(btn => {
        return <Button 
          key={uuid()}
          variant={btn.variant || 'contained'}
          color={btn.color || 'primary'}
          style={btn.style || {}}
          onClick={btn.onClick}
        >
          {btn.title}
        </Button>
        })
      }
  }

  return (
    
    <div>
      <Dialog
        fullScreen={data.fullScreen || false}
        open={data.isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{style: {backgroundColor: 'white'}}}
      >
        <DialogTitle align='center' id="alert-dialog-title">
          {data.title}
          {data.icon && Icons[data.icon]}
        </DialogTitle>
        {data.isLoading && <LoadingSpinner />}
        <Divider />
        <DialogContent>
        {children}
        <DialogContentText id="alert-dialog-description" component={isArray(data.text) ? 'ul' : 'p'} style={{fontSize: '1rem'}}>
          {isArray(data.text)
              ? data.text.map((textItem,i)=><li key={i}>{textItem}</li>)
              : data.text
          }
        </DialogContentText>
        </DialogContent>
        <DialogActions>
          {_renderButtons}
        </DialogActions>
      </Dialog>
    </div>
  )
}