import React, {useState } from 'react'
//import { firestore } from '../../firebase/firebase'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import { generateDebitXML } from './generateDebitXML'
import { getMemberShipFee } from 'firebase/database/membersHandler'
import { saveAs } from 'file-saver'
import LoadingSpinner from 'Components/Loading/Spinner'
import SaveIcon from '@material-ui/icons/Save'
import { Typography, Paper, Grid} from '@material-ui/core'


const generateFileName = (fileCount) =>
    `Mitgliederbeitrag-Lastschrift-${moment().format('YYYY')}-Teil-${fileCount}.xml`

const MembershipFeeAccounting = ({members, ...rest}) => {
    const [isLoading, setIsLoading ] = useState(false)
    const [result, setResult] = useState(null)
    let fileCount = 0
 
    const xmlFileSaveCallback = xmlFile => {
        const fileName = generateFileName(fileCount)
        const blob = new Blob([xmlFile])
        new Promise(resolve => {
			resolve(saveAs(blob, fileName));
		  })
		  .then(_result =>{} )
		  .catch(err => console.log("Error downloading: " + err));
        if(fileCount>1)
            return setIsLoading(false)
    }
    const generateXMLData = () => {
        setIsLoading(true)
        const resultsCol = []
        const callback = (memberFeeData) => {
            const { total, transferItems, fileNr } = memberFeeData
            fileCount = fileNr
            const resultData = {
                total,
                memberCount: transferItems.length,
                fileName: generateFileName(fileCount)
            }
            resultsCol.push(resultData)
            setResult(resultsCol)
            generateDebitXML({transferItems, total, callback: xmlFileSaveCallback })
            return null
        }
        return getMemberShipFee(callback)
    }
    const handleReset = () => {
        fileCount=0
        return setResult(null)
    }

  return (
           <div style={{marginTop: '0.5rem'}}>
               {isLoading 
                ? <LoadingSpinner size={30} />
                : result 
                    ? <MembershipFeeResult result={result} reset={handleReset} />
                    : <Button
                    size='small'
                    variant='outlined'
                    color='primary'
                    startIcon={<SaveIcon />}
                    onClick={generateXMLData} >
                        {rest.buttonText ? rest.buttonText : 'XML Datei erstellen & speichern'}
                </Button>
                }
            </div>
  )
}

const MembershipFeeResult = ({result, reset}) =>{
    console.log('----------', result)
    return <div style={{padding: '0.5rem'}}>
        <Paper style={{padding: '0.5rem'}} >
            <Grid container spacing={0}>
                <Grid item md={12}>
                    <Typography color='primary' variant='body2' gutterBottom>Es wurden {result.length} XML Dateien für die Lastschrift Mitgliederbeitrag erstellt.</Typography>
                </Grid>
                <Grid item md={12} container spacing={2} >
                    <Grid item md={2}>
                        <Typography variant='body2' align='center'>Mitgl.</Typography>
                    </Grid>
                    <Grid item md={2}>
                        <Typography variant='body2' align='center'>Beitrag</Typography>
                    </Grid>
                    <Grid item md={8}>
                        <Typography variant='body2' >Datei</Typography>
                    </Grid>
                </Grid>
                {result.map((reItem, i)=>
                    <Grid key={i+reItem.total} item md={12} container spacing={2} >
                        <Grid item md={2}>
                            <Typography  variant='body2' align='center'>{reItem.memberCount}</Typography>
                        </Grid>
                        <Grid item md={2}>
                            <Typography variant='body2' align='center'>{reItem.total}€</Typography>
                        </Grid>
                        <Grid item md={8}>
                        <Typography variant='body2' style={{fontSize: '0.8rem'}}>{reItem.fileName}</Typography>
                        </Grid>                            
                    </Grid>
                )}
                <Grid item md={12} style={{marginTop: '0.5rem'}}>
                    <Typography variant='body2' color='primary'>Sie finden die {result.length} XML Datein im Download Ordner</Typography>
                </Grid>
                <Grid item md={12} >
                    <Button onClick={reset} style={{marginTop: '0.5rem'}} variant='contained' color='primary' size='small' fullWidth>
                        Zurücksetzen
                    </Button>
                </Grid>
            </Grid>
    
        </Paper>
        {/*
        <Paper style={{padding: '0.5rem'}}>
            <Typography variant='body2'>
                Bei {membersCount} Mitglieder sind Bankdaten und Betrag vorhanden.
                Insgesamt werden {total} Euro als Lastschirft eingezogen.
                Die XML Datei befindet sich im Downloadordner.
            </Typography>
            <Button onClick={reset} style={{marginTop: '0.5rem'}} variant='contained' color='primary' size='small'>
                Reset
            </Button>
        </Paper>
        */}
    </div>
}
export default MembershipFeeAccounting
