import React from 'react';
//import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function SimpleMenu({anchorElement, headerMenuClick, items}) {

  const handleClose = () => {
    return headerMenuClick(undefined)
  }
  
  return (
      <Menu
        id="simple-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleClose}
      >
        {items && items.map(item=>{
          return <MenuItem
              key={item.title}
              onClick={()=>item.onClick(item)}
            >
              {item.title}
          </MenuItem>}
        )}
      
      </Menu>
  )
}
