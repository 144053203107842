import { storage } from '../firebase'
import { setFileUploadProgress } from 'store/actions/memberActions'
const storageRef = storage.ref()

export const emailFileUpload = async ({file, fileName, id}) => {
    const REF = storageRef.child(`mailSender/${id}/${fileName}`)
    await REF.put(file)
    return REF.getDownloadURL()
}

export const memberFileUpload = async ({fileField, memberId, callbacks }) => {
    const ref = storageRef.child(`members/attachments/${memberId}/${fileField.name}`)
    const uploadTask = await ref.put(fileField.url)
    if(!callbacks) {
        const url = await uploadTask.ref.getDownloadURL()
        return url
    }
    if(callbacks) {
        uploadTask.on('state_changed', 
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                callbacks.progressCallback(progress)
            
            }, 
            (error) => {
                // Handle unsuccessful uploadsc
                console.log(error)
            }, 
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            // console.log('File available at', downloadURL)
                callbacks.downloadURL(downloadURL)
        
                })
            }
        )
    }
    return
}

export const uploadFile = async (fileName, file, memberId, callback) => {
    const ref = storageRef.child(`members/attachments/${memberId}/${fileName}`)
    const uploadTask = ref.put(file)
    uploadTask.on('state_changed', 
        (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setFileUploadProgress(progress)
        
        }, 
        (error) => {
            // Handle unsuccessful uploadsc
            console.log(error)
        }, 
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        // console.log('File available at', downloadURL)
            callback(downloadURL)
            })
        }
    )
}

export const deleteUploadedFile = (fileName, memberId) => {
    const ref = storageRef.child(`members/attachments/${memberId}/${fileName}`)
    ref.delete()
    .then(()=>{
        //'GELÖSCHT'
    })
    .catch(err=>{
        console.error('FILE UPLOAD ERR', err)
    })
}

export const uploadFileStorage = async ({id, fileName, ext, blob}) => {
   
    const fileStorageREF = storageRef.child(`fileStorage/${id}/${fileName}.${ext}`)
    await fileStorageREF.put(blob)
    const downloadURL = fileStorageREF.getDownloadURL()
    return downloadURL
}
export const deleteStorageFile = async (id, name) => {
    console.log(`fileStorage/${id}/${name}`)
    const ref = storageRef.child(`fileStorage/${id}/${name}`)
    await ref.delete()
    .then(()=>{
        console.log('FILE DELETED')
        return {error: false, deleted: true}
    })
    .catch(err=>{
        console.error('FILE UPLOAD ERR', err)
        return {error: err, deleted: false}
    })
}