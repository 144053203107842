import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { firestore } from '../../../firebase/firebase'
import { connect } from 'react-redux'
import { MEMBERS } from '../../../config/actionTypes'
//import GetIcon from 'Components/Icons/GetIcon'
//import IconButton from '@material-ui/core/IconButton'


const MembersTasksFilter = ({item, applyValue, dispatch, activeFilter}) => {
  const [tasks, setTasks] = useState(null)
 // const [selectedTaskId, setSelectedTaskId] = useState('')


  useEffect( () => {
    if(tasks) {
      if(activeFilter.length!==0 && !item.value)
        return dispatch({type: MEMBERS.FILTER_SET_ACTIVE_TASK_SELECT_ITEM, payload: ''})
      return null
    }
    const tasksRef = firestore.collection('tasks').where('type', '==', 'nachbarschaftshilfe')
    const getData = async () => {
      const tasksSnap = await tasksRef.get()
      const _tasks = []
      tasksSnap.forEach(task=>_tasks.push(task.data()))
      return setTasks(_tasks)
    }
    getData()
    return () => {
      //console.log('Tasks Filter umount - useEffect Test')
    }
      // selecteTasksId, tasks FOOBAR
  }, [item.value, tasks, activeFilter.length, dispatch])
  const handleFilterChange = event => {
    applyValue({...item, value: event.target.value})
    return dispatch({type: MEMBERS.FILTER_SET_ACTIVE_TASK_SELECT_ITEM, payload: event.target.value})
  }

  return (
    <TextField
      id='tasks-filter-select'
      select
      label='Filtern'
      onChange={handleFilterChange}
      value={activeFilter}
    >
      {!tasks
        ? <MenuItem value=''>Lade Daten</MenuItem>
        : tasks.map((task, i)=>{
          const addDivider = i===tasks.length-1 ? false : true
            return <MenuItem divider={addDivider} dense key={task.id} value={task.id}>
              {task.name}
            </MenuItem>
        })}
      </TextField>
    )
}

const MembersTasksFilterConnected = connect((state) => {
  return ({
  activeFilter: state.members.tasksSelectFilter,
  //membersDeleted: state.firestore.ordered.membersDeleted || [],
  //member: state.firestore.data.members ? state.firestore.data.members['0Gcc9zcifuxJ0zKNoPDK'] : undefined,
  members: state.firestore.ordered.members, 
})})(MembersTasksFilter)

export const tasksFilterOperators = [
  {
    label: 'ist',
    value: 'equals',
    getApplyFilterFn: (filterItem) => {

      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
     // console.log(filterItem, 'Filter Item')
      return (params) => {
        if(params.value) {
          const result = params.value.filter(taskId=>taskId===filterItem.value)
          return result.length!==0
        }
      
      }
    },
    InputComponent: MembersTasksFilterConnected,
 
  },
]

export default tasksFilterOperators

