import React, { PureComponent } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect, isEmpty } from 'react-redux-firebase'
import { withSnackbar } from 'notistack'
import { withStyles, Grid, Divider, Button, Switch, FormControlLabel } from '@material-ui/core'
import { values } from 'lodash'
import moment from 'moment'
import MemberSelect from './MemberSelect'
import TabHeader from './TabHeader'
import TabRow from './TabRow'
import InputRow from './InputRow'
import Dialog from 'Components/PopUps/Dialog'
import LoadingHandler from 'Components/Loading/LoadingHandler'
import arrSort from 'array-sort'
const styles = theme => ({
    wrapper: {
    },
    memberSelectWrapper: {
        marginBottom: theme.spacing(4)
    },
    form : {
    },
    tableContainer : {
        marginBottom: theme.spacing(4)
    },
    divider: {
        marginBottom: theme.spacing(4)
    },
    buttonBoard: {
        marginBottom: theme.spacing(2)
    },
    TableRow: {
    },
    rowItem: {
    }
})

const resetDialog = {
    isOpen: false,
    text: '',
    title: '',
    buttons: []
}

const INITAL_STATE = {
    rowsToDelete: [],
    selectedRequester: null,
    selectedProvider: null,
    rowCounter: 1,
    data: null,
    donate: false,
    dialog: {...resetDialog},
    resetMemberSelect: false,

}

const tabHeaderItems = [
    {label: 'Nr', settings: {md: 1, xs: 12}},
    {label: 'Datum', settings: {md: 2, xs: 12}},
    {label: 'Stunden', settings: {md: 2, xs: 12}},
    {label: 'KM', settings: {md: 1, xs: 12}},
    {label: 'Tätigkeit', settings: {md: 3, xs: 12}},
    {label: 'Löschen', settings: {md: 1, xs: 12}},  
]

export class AddServices extends PureComponent { 
    
    state = {...INITAL_STATE}
    handleInfoMessage = (text, variant) => {
        this.props.enqueueSnackbar(text, 
            {
                variant: variant || 'info', anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            }})
    }

    memberSelectCallback = (member, memberType) => {
        if(memberType==='provider')
            return this.setState({selectedProvider: member })
        return this.setState({selectedRequester: member })
    }
    setStateByPropKey = (propKey, value, key) => {
        return this.setState({
            data : {
                ...this.state.data,
                [key]: {
                    ...this.state.data[key],
                    [propKey]: value
                },
            }
        })
    }

    addRowCallback = async (newRow) => {
       
        const { selectedProvider, selectedRequester } = this.state
        const { firestore } = this.props
        // exit if no provider or requester selected
        
        if(!selectedProvider)
            return this.handleOpenDialog('Kein Helfer!', 'Kein Hilfe-Leister (Helfer) ausgewählt.')
        if(selectedRequester===null)
            return this.handleOpenDialog('Kein Hilfeempfänger!', 'Kein Hilfe-Suchender (Bedarf) ausgewählt')
        
            const fsKey = await firestore.collection('accounting').doc('nachbarschaftshilfe').collection('services').doc().id
        const splitedDate = newRow.date.split('-')
   
        const additionalRowData = {
            id: fsKey,
            month: splitedDate[1],
            year: splitedDate[0],
            donate: this.state.donate,
            provider: {
                id: selectedProvider.value.id,
                name: selectedProvider.value.userName,
                ref: firestore.doc(`members/${selectedProvider.value.id}`)
            },
            requester: {
                id: selectedRequester.value.id,
                name: selectedRequester.value.userName,
                ref: firestore.collection('members').doc(`${selectedRequester.value.id}`)
            }
        }
        newRow = {...newRow, ...additionalRowData}
        const newCount = this.state.rowCounter+1
        const element = document.getElementById('serviceAddRow')
        element.scrollIntoView(true)
        return this.setState({
            rowCounter: newCount,
            data: {
                ...this.state.data,
                [fsKey] : newRow
            }
        })
    }
    handleRowToDelete = rowId => {
        const rowsToDelete = [...this.state.rowsToDelete]
        rowsToDelete.push(rowId)
        return this.setState({rowsToDelete})
    }
    handleDeleteRows = () => {
        const data = {...this.state.data}
        this.state.rowsToDelete.map( rowToDelete =>{
            return delete data[rowToDelete]
        })
        const rowCounter = values(data).length+1
        this.setState({data, rowsToDelete: [], rowCounter})
        return this.handleInfoMessage('Die Leistugen wurden gelöscht!', 'error')
    }
    handleOpenDialog = (title, text, _buttons) => {
        const defaultButton = [
            <Button key={1} variant='contained' color='primary' onClick={()=>this.setState({dialog: resetDialog})} >OK</Button>
        ]
        const buttons = _buttons || defaultButton
        return this.setState({dialog: {isOpen: true, text, title, buttons: buttons}})
    }

    handleSaveForm = async () => {
        const {data} = this.state
        const { firestore } = this.props
        const fsBatch = firestore.batch()
        const nachbarshaftsServicesRef = await firestore.collection('accounting').doc('nachbarschaftshilfe').collection('services')
        const convertHours = hours => {
            const splitedTime = hours.split(':')
            const _hours = Number(splitedTime[0])
            const _min = Number(splitedTime[1]/60)
            const newHours = _hours+_min
            return Number(newHours)
        }
        await Promise.all(values(data).map( async service=>{
            const nachbarschaftServiceRef = await nachbarshaftsServicesRef.doc(service.id)
            const { hours, km } = service
            service.km = Number(km)
            service.hours = convertHours(hours)
            service.editor = {
                    userId: this.props.authUser.id,
                    timeStamp: moment().format(),
            }
            await fsBatch.set(nachbarschaftServiceRef, service)
            return service
        }))
        fsBatch.commit()
        this.handleInfoMessage('Die Leistungen wurden gespeichert!', 'success')
        return this.setState({...INITAL_STATE, resetMemberSelect: true})
    }

    render() {
        const { classes } = this.props
        let rowCounter=0
        return (
            <LoadingHandler
				isLoaded={Boolean(this.props.provider) && Boolean(this.props.requester) && Boolean(this.props.tasks)}
				title='Keine Daten vorhanden'
				loadingText='Lade Daten...'
				isEmpty={isEmpty(this.props.provider) && isEmpty(this.props.requester)}
			>
				<div className={classes.wrapper}>
					<section className={classes.content}>
						<form className={classes.form}>
							<div className={classes.memberSelectWrapper}>   
								<MemberSelect
                                    donate={this.state.donate}
									callback={this.memberSelectCallback}
									provider={this.props.provider}
									requester={this.props.requester}
									reset={this.state.resetMemberSelect} 
									selectedProvider={this.state.selectedProvider}
									selectedRequester={this.state.selectedRequester}
								/>
							</div>
							<TabHeader gridItems={tabHeaderItems} />
							<div >
							<Grid container className={classes.tableContainer} >
								{values(this.state.data).map((rowItem) =>{
									rowCounter++
									return  <TabRow
										tasks={this.props.tasks}
										rowCount={rowCounter}
										callback={this.setStateByPropKey}
										callbackRowToDelete={this.handleRowToDelete}
										key={rowItem.id}
										item={rowItem} />
								})}
							</Grid>
							<Grid container direction="row" justifyContent="flex-end" alignItems="center" className={classes.buttonBoard} spacing={2}>
                                    <Grid item>
                                    <FormControlLabel 
                                        control={
                                            <Switch
                                            color='secondary'
                                            checked={this.state.donate}
                                            onChange={()=>this.setState({donate: !this.state.donate})} 
                                        />}
                                        label={this.state.donate ? <span style={{color: 'red'}}>Helfer spendet Leistungen</span> : 'Spenden'}
                                        labelPlacement="start"
                                        />
                                    </Grid>
                                    <Grid item  >
										<Button
                                            disabled={this.state.rowsToDelete.length===0}
                                            onClick={this.handleDeleteRows}
                                            style={{}}
                                            size='small'
                                            variant='contained'
                                            color='secondary' 
                                        >
                                                Löschen
                                        </Button>
                                        </Grid>
                                        <Grid item>
										<Button disabled={this.state.data===null} onClick={this.handleSaveForm} style={{}} size='small' variant='contained' color='primary' >Speichern</Button>
									</Grid>
							</Grid>
							<Divider className={classes.divider} />
						
							<Grid container alignItems='center' justifyContent="space-between" >
								{this.props.tasks!==undefined &&
									<InputRow 
										tasks={this.props.tasks}
										rowCount={this.state.rowCounter}
										callback={this.addRowCallback}
										handleInfoMessage={this.handleInfoMessage}
									/>}
							</Grid>
							</div>
						</form>
					</section>
					<Dialog data={this.state.dialog} />
				
				</div>
			</LoadingHandler>
        )
    }
}

export default compose(
    firestoreConnect(() => [
        { collection: 'members', where: [['bankAccount', '!=', false]]},
        {
            collection: 'tasks', 
            where: [['type', '==', 'nachbarschaftshilfe']],
            storeAs: 'nhTasks'
        },
    ]), // or { collection: 'todos' }
    connect((state, props) => ({
     
      provider: state.firestore.ordered.members && arrSort(state.firestore.ordered.members.filter(member=>member.roles.provider===true), 'userName'),
      requester: state.firestore.ordered.members && arrSort(state.firestore.ordered.members.filter(member=>member.roles.requester===true), 'userName'),
      //requesterData: state.firestore.data.requester,
      //providerData: state.firestore.data.provider,
      tasks: state.firestore.ordered.nhTasks,
      authUser: state.user
    })),
    withSnackbar,
    withStyles(styles)
    )(AddServices)
