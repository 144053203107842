import { dispatch } from '../index.js'
import { TASKS } from 'config/actionTypes'

export const dispatchTaskForm = payload =>
    dispatch({type: TASKS.TASK_FORM, payload})

export const dispatchResetFrom = () => 
    dispatch({type: TASKS.RESET_FROM})

export const dispatchSaveTask = () =>
    dispatch({type: TASKS.SAVE_TASK})

export const dispatchDialogOpen = state => {
    dispatch({type: TASKS.DIALOG, payload: state})
}

export const dispatchEditTask = payload =>
    dispatch({type: TASKS.EDIT_TASK, payload})