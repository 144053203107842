import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import isArray from 'lodash/isArray'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
//import CardActionArea from '@material-ui/core/CardActionArea'
//import { red } from '@material-ui/core/colors';
//import FavoriteIcon from '@material-ui/icons/Favorite';
//import ShareIcon from '@material-ui/icons/Share';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SimpleMenu from 'Components/Menus/SimpleMenu'
import { v4 as uuid } from 'uuid';
const useStyles = props => makeStyles( theme => ({
  root: {
	color: theme.palette.text.primary,
	cursor: 'pointer',
	'&:hover': {
		color: theme.palette.primary.contrastText,
		backgroundColor: theme.palette.primary.light},
	},
	media: {
		height: 0,
		paddingTop: '56.25%', // 16:9
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	avatar: {
		backgroundColor: theme.palette.primary.dark,
	},
}))


export default function DefaultCard (props) {
	
	const classes = useStyles(props.theming && props.theming)();
	const [headerAnchor, setHeaderAnchor ] = React.useState(undefined)
 
	const handleHeaderMenuClick = target => {
		if(Boolean(headerAnchor))
				return setHeaderAnchor(undefined)
			return setHeaderAnchor(target)
	}
	const handleItemClick = item =>{
		if(props.callback)
			props.callback(item)
		return
	} 
	
	const CARD = (
		<Card raised className={classes.root} role='button' onClick={()=>props.header.menu || props.buttons ? null : handleItemClick(props.item)} >
			{props.header && props.header.menu && <SimpleMenu
				anchorElement={headerAnchor}
				headerMenuClick={handleHeaderMenuClick}
				items={props.header.menu}
			/>}
			{props.header && <CardHeader
				avatar={props.header && props.header.avatar && <Avatar aria-label="recipe" className={classes.avatar}>
					{props.header.avatar.content}
				</Avatar>
				}
				action={
					props.header.menu &&
						<IconButton
							onClick={e=>handleHeaderMenuClick(e.currentTarget)}
							aria-label="settings"
							aria-controls="simple-menu"
							aria-haspopup="true"
							id='header-menu'
						>
							<MoreVertIcon />
						</IconButton>
				}
				title={props.header.title}
				subheader={props.header.subheader}
			/>}
		{props.media && <CardMedia
			className={classes.media}
			image="/static/images/cards/paella.jpg"
			title="Paella dish"
		/>}
		{props.content && <CardContent>
			{isArray(props.content.text)
				? props.content.text.map(textItem => {
					return <Typography key={uuid()} variant={textItem.variant || "body2"} color={textItem.color || "textSecondary"} component={textItem.component || 'p'} children={textItem.text}/>
				})
				: <Typography variant={props.content.variant || "body2"} color={props.content.color || 				"textSecondary"} component={props.content.component || "p"} >
						{props.content.text}
					</Typography>
			}
		
		</CardContent>}
		{props.buttons && <CardActions disableSpacing>
			{props.buttons.map(button=>{
				return <Button key={uuid()} size={button.size || 'small'} variant={button.variant || 'outlined'} color={button.color || 'primary'} onClick={()=>button.onClick(props.item)}>{button.title}</Button>
			})}
		</CardActions>}
		</Card>
	)
	if(props.tooltip) {
		return <Tooltip enterDelay={100} title={props.tooltip.title}>{CARD}</Tooltip>
	} else {
		return CARD
	}

  
}
