import { MAILSENDER } from 'config/actionTypes'
//import { firestore } from '../../firebase/firebase'
import isEqual from 'lodash/isEqual'

const INIT_STATE = {
  recipients: [],
  subject: '',
  body: '',
  attachments: null,
  isOpen: false,
}
function mailSenderReducer(state = INIT_STATE, action) {

  switch(action.type) {
    case MAILSENDER.SET_RECIPIENTS : {
      return ({
          ...state,
          recipients: action.payload,
      })
    }
    case MAILSENDER.ADD_RECIPIENT : {
      return({
        ...state,
        recipients: [...state.recipients, action.payload]
      })
    }
    case MAILSENDER.REMOVE_RECIPIENT : {
      return({
        ...state,
        recipients: state.recipients.filter(recipient=>!isEqual(recipient, action.payload))
      })
    }
    default : return state;
  }
}
  
  export default mailSenderReducer