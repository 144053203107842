import React from 'react'
import { connect } from 'react-redux'
import InstitutsDataGrid from './InstitutsDataGrid'
import Dialog from './Dialog'
import AddInstitut from './AddInstitut'
import { dispatchResetFrom, dispatchSaveInstitut, dispatchDialogOpen, dispatchEditInstitut } from 'store/actions/institutsActions'
import Typography from '@material-ui/core/Typography'
import { deleteInstitut, checkInstitutsDepencies } from 'firebase/database/institutsHandler'
import Spinner from '@material-ui/core/CircularProgress'
import ItemsFound from './ItemsFound'


const INIT_DATA_STATE = {items: null, type: null, institutId: null}

class Instituts extends React.PureComponent {
  state = {
    data: {...INIT_DATA_STATE},
    dialog: false
  }

  handleNewInstitut= () => {
    return dispatchDialogOpen(true)
  }
  handleSaveInstitut = () => {
    return dispatchSaveInstitut()
  }
  handleCloseDialog = () => {
    this.setState({dialog: false, data: {...INIT_DATA_STATE}})
    return dispatchResetFrom()
  }
  handleCheckInstitut = async row => {
    this.setState({dialog: true})
    const result = await checkInstitutsDepencies(row.id)
    if( result.error === true ) {
      return this.setState({data: {items: result.values, institutId: row.id}})  
    }
    return this.setState({data: {items: null, institutId: row.id, type: 'delete'}})
  }

  handleDeleteInstitut = () => {
    deleteInstitut(this.state.data.institutId)
    return this.setState({dialog: false, data: {...INIT_DATA_STATE}})
  }

  handleEditInsitut = row => {
      return dispatchEditInstitut(row) 
  }

  messageButtons = this.state.data.items 
      ?[
        {
            children: 'OK',
            variant: 'contained',
            color: 'primary',
            onClick:this.handleCloseDialog
        }
       ]
      :[
        {
          children: 'Abbrechen',
          variant: 'outlined',
          color: 'primary',
          onClick: this.handleCloseDialog

        },
        {
          children: 'Löschen',
          variant: 'contained',
          color: 'secondary',
          onClick: this.handleDeleteInstitut

        }
      ] 
  render() {
    const { dialog, data } = this.state
    const { institut } = this.props
    const { form } = institut
    const disableSaveBtnAddInstitut = !(form.name.length>3 && form.street.length>3 && form.postCode.length===5 && form.city.length>3)
    return (
      <div>
        <InstitutsDataGrid
          addInstitutCallback={this.handleNewInstitut}
          deleteItemCallback={this.handleCheckInstitut}
          editItemCallback={this.handleEditInsitut}
        />
        <Dialog
          title={institut.form.id ? 'Pflegekasse bearbeiten' :  'Pflegekasse hinzufügen'}
          open={institut.dialog}
          closeBtn={this.handleCloseDialog}
          buttons={
            [  
              {children: 'Abbrechen', variant: 'outlined', onClick:this.handleCloseDialog, color:'secondary'},
              {
                  children: 'Speichern',
                  disabled: disableSaveBtnAddInstitut,
                  variant: 'outlined',
                  color: 'primary',
                  onClick:this.handleSaveInstitut
              }
            ]
          }
        >
          <AddInstitut  />
        </Dialog>
        <Dialog
          title={'Tätigkeit löschen'}
          open={dialog}
          closeBtn={this.handleCloseDialog}
          buttons={this.buttons}
        >
          {data.items
            ? <ItemsFound data={data} />
            : data.type==='delete'
              ? <DeleteAllowedMessage />
              : <IsLoadingMessage />
          }
        </Dialog>
      </div>
    )
  }
}

const IsLoadingMessage = () =>
  <div style={{textAlign: 'center'}} >
    <Spinner />
    <Typography align='center' variant='subtitle2' color='primary'>Überprüfe Abhängikeiten</Typography>
  </div>

export default connect(state=>({
    institut: state.instituts
}))(Instituts)




const DeleteAllowedMessage = () =>
<div>
    <Typography align='center' varaint='subtitle1' color='primary' gutterBottom>Keine Abhängikeiten gefunden</Typography>
    <Typography align='center' variant='subtitle2' color='error' >Soll die Pflegekasse wirklich gelöscht werden?</Typography>
</div>