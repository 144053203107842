import React from 'react'
import { actionTypes} from 'config/config'
import AccountingResults from './Results/AccountingResultsPK'
import AccountingList from './AccountingList.js'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import groupBy from 'lodash/groupBy'
import AccountingDetails from './DetailView/AccountingDetails'


class AccountingPflegekassen extends React.Component {

    componentWillUnmount () {
        // Clean selected services on leave
        this.props.dispatch({type: actionTypes.SET_ACCOUNTING_INVOICE, payload: undefined })
        this.props.dispatch({type: actionTypes.SET_ACCOUNTING_MONTH, payload: undefined})
        /*
        if(this.props.invoiceResult.isLoading)
            this.props.firebase.ref('invoiceResults/pflegekasse').set({isLoading: false})
            */
    }

    render () {
        
        const {selectedMonth, tasks, groupedByYear, invoiceResult} = this.props
        const date = selectedMonth ? selectedMonth[0].date : 'Datum nicht vorhanden - FEHLER'
        return (
            this.props.invoiceResult && this.props.invoiceResult.isLoading
                ? <AccountingResults data={invoiceResult} date={date} />
                :selectedMonth 
                    ? <AccountingDetails tasks={tasks} />
                    : <AccountingList groupedByYear={groupedByYear} />           
        )  
    }  
}

export default compose(
    firebaseConnect((props) => [
        { path: '/invoiceResults/pflegekasse' }
      ]),
    firestoreConnect(() => [  
      //  { collection: 'accounting', doc: 'pflegekasse', subcollections: [{ collection: 'services' }], storeAs: 'servicesPflegekasse' },
     //   { collection: 'counters',  doc: 'accounting' },
        { collection: 'tasks', where: [['type', '==', 'pflegekasse']], storeAs: 'pkTasks' }

    ]), // or { collection: 'todos' }
    connect((state) => {
        return ({
        invoiceResult: state.firebase.data.invoiceResults ? state.firebase.data.invoiceResults.pflegekasse : {isLoading: false},   
        selectedMonth: state.accounting.selectedMonth,
        selectedInvoice: state.accounting.selectedInvoice,
        services: state.firestore.ordered.services,
       // invoiceIds: state.firebase.data.invoiceIds ? state.firebase.data.invoiceIds.pflegekasse : undefined,
        groupedByYear: groupBy(state.firestore.ordered.services, 'year'),
        authUser: state.user,
        tasks: state.firestore.data.pkTasks,
        currentInvoiceIds: state.firebase.data.invoiceIds ? state.firebase.data.invoiceIds.pflegekasse : undefined,
      })}),
    withSnackbar
    )(AccountingPflegekassen)