import React from 'react'
import Grid from '@material-ui/core/Grid'

const DefaultGrid = ({container, item, children}) => {
	if(!item)
	  item = {}
	if(!container)
	  container = {}
	const elCount = React.Children.count(children)
	return (
	  <Grid container {...container} >
		{elCount===1
		  ? <Grid key={1} item md={12} {...item} >
			  {children}
			</Grid>
		  : children.map((child, i)=>{
		  return (
			<Grid key={i} item md={12} {...item} >
			  {child}
			</Grid>
		  )
		})}
	  </Grid>
	)
  }

  export const GridRow = ({children, classes, style}) => {
	const elCount = React.Children.count(children)

	return (
	  <Grid container justifyContent="center" spacing={1} className={classes.root} style={style}>
		{elCount===1
			? <Grid key={1} item xs={12} md={children.props.gridSize || 12}>
				{children}
			</Grid>
			: children.map((child, i)=>{
			return <Grid
			key={i}
			item xs={12}
			md={child.props.gridSize || Math.floor(12/children.length)}
			className={classes.gridItem}
		>
		  	{child}
		</Grid>})}
	  </Grid>
	  
	)
  }

DefaultGrid.Row = GridRow
export default DefaultGrid
