import React from 'react'
import { Grid, Input, Select, MenuItem, Checkbox, TextField } from '@material-ui/core'

const TabRow = ({item, rowCount, callback, tasks, callbackRowToDelete}) => {

    return (
        <Grid container alignItems='center' justifyContent="space-between" >
             <Grid item md={1} xs={12} style={{textAlign: 'center'}}>
               {rowCount}
            </Grid>
            <Grid item md={2} xs={12}>
                <Input
                    inputProps={{readOnly: true}}
                    value={item.date}
                    onChange={e=>callback('date', e.target.value, item.id)}
                    name='date' type='date'     
                />
            </Grid>
            <Grid item md={2} xs={12}>
                <TextField
                    InputProps={{readOnly: true}}
                    fullWidth
                    type='time'
                    value={item.hours}
                    inputProps={{step:300}} 
                    name='hours'
                    onChange={e=>callback('hours', Number(e.target.value), item.id)} 
                />
            </Grid>
            <Grid item md={1} xs={12} style={{textAlign: 'right'}}>
       
            <TextField
                    InputProps={{readOnly: true}}
                    value={item.km}
                    name='km'
                    onChange={e=>callback('km', Number(e.target.value))}
                    inputProps={{ 'aria-label': 'km checkbox' }}
                />
               
            </Grid>
            <Grid  item md={3} xs={12}>
                <Select
                    inputProps={{readOnly: true}}
                    fullWidth
                    //labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={item.taskId}
                    onChange={(e)=>callback('taskId', e.target.value, item.id)}
                    >
                        {tasks && tasks.map(task=>{
                            return <MenuItem key={task.id} value={task.id}>{task.name}</MenuItem>
                        })}
            
                </Select>
            </Grid>
            <Grid item md={1} xs={12}>
                <Checkbox onChange={()=>callbackRowToDelete(item.id)} value={1} />
            </Grid>
        </Grid>
    )
}

export default TabRow
