import React from 'react'
import { actionTypes} from 'config/config'
import AccountingResultsRequester from './AccountingResultsRequester'
import AccountingList from './AccountingList.js'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import groupBy from 'lodash/groupBy'
//import DataGrid from '@material-ui/core/DataGrid'
import AccountingDetails from './DetailView/AccountingDetails'
/*
const initState = {
    showAccountingList: true,
    showAccountingResult: false,
    selectedInvoices: {
        ids: [],
        month: null,
    },
    accountingWithProvider: true,
    errorMessage: false,    
}
*/
class AccountingRequesters extends React.Component {

    componentWillUnmount () {
        // Clean selected services on leave
        this.props.dispatch({type: actionTypes.SET_ACCOUNTING_INVOICE, payload: undefined })
        this.props.dispatch({type: actionTypes.SET_ACCOUNTING_MONTH, payload: undefined})
        if(this.props.invoiceResult && this.props.invoiceResult.isLoading)
            this.props.firebase.ref('invoiceResults/pflegekasseMembers').set({isLoading: false})
    }
    //const { services, authUser, tasks, currentInvoiceIds, pdfResult, isLoaded} = this.props

    render () {
      
        const {selectedMonth, groupedByYear, invoiceResult, tasks} = this.props
        const date = selectedMonth ? selectedMonth[0].date : 'Datum nicht vorhanden - FEHLER'
       // return <div>XX</div>
        return (
            this.props.invoiceResult && this.props.invoiceResult.isLoading
                ? <AccountingResultsRequester data={invoiceResult} date={date} />
                :selectedMonth
                    ? <AccountingDetails tasks={tasks} />
                    : <AccountingList groupedByYear={groupedByYear} />           
        )  
    }  
}

export default compose(
    firebaseConnect((props) => [
        { path: '/invoiceResults/pflegekasseMembers' }
      ]),
    firestoreConnect(() => [  
        { collection: 'accounting', doc: 'pflegekasse', subcollections: [{ collection: 'institut' }], where: [['takenByInstitut', '!=', false]], storeAs: 'invoices' },
     //   { collection: 'counters',  doc: 'accounting' },
        { collection: 'tasks', where: [['type', '==', 'pflegekasse']], storeAs: 'pkTasks' }

    ]), // or { collection: 'todos' }
    connect((state) => {
        return ({
        invoiceResult: state.firebase.data.invoiceResults ? state.firebase.data.invoiceResults.pflegekasseMembers : {isLoading: false},   
        selectedMonth: state.accounting.selectedMonth,
        selectedInvoice: state.accounting.selectedInvoice,
        invoices: state.firestore.ordered.invoices,
       // invoiceIds: state.firebase.data.invoiceIds ? state.firebase.data.invoiceIds.pflegekasse : undefined,
        groupedByYear: groupBy(state.firestore.ordered.invoices, 'year'),
        authUser: state.user,
        tasks: state.firestore.data.pkTasks,
       // currentInvoiceIds: state.firebase.data.invoiceIds ? state.firebase.data.invoiceIds.pflegekasse : undefined,
      })}),
    withSnackbar
    )(AccountingRequesters)