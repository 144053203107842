import React, { PureComponent } from 'react'
import Dialog from 'Components/Modals/FullScreenDialog'
import PlanningGrid from 'Components/Grid/DefaultGrid'
import FormWrapper from 'Components/Forms/FormWrapper'
import { withStyles  } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import TextField from '@material-ui/core/TextField'
import VSelect from 'Components/Select/VSelect'
import Checkbox from '@material-ui/core/Checkbox'
import { dispatchNewEvent, dispatchInfoChange, dispatchEventStatusChange, dispatchResetEvent, dispatchAddEditor } from 'store/actions/planningActions'
import ButtonRow from 'Components/Buttons/ButtonRow'
import moment from 'moment'
import { FormHelperText } from '@material-ui/core'
import { FormControlLabel } from '@mui/material'
import MemberAddressCard from './MemberAddressCard'
import EditorCard from './EditorCard'
import ScheduleIcon from '@material-ui/icons/Schedule'
import AlarmIcon from '@material-ui/icons/Alarm'
import CancelIcon from '@material-ui/icons/Cancel'
import BlockIcon from '@material-ui/icons/Block'
import PauseIcon from '@material-ui/icons/PauseCircleOutline'
import PauseIconFilled from '@material-ui/icons/PauseCircleFilled'
import HandOverSelect from './HandOverSelect'
import Button from '@material-ui/core/Button'
import { dialog } from 'store/actions/dialogActions'
import { dispatchHandOver } from 'store/actions/planningActions'

const styles = theme => ({
	root: {
		flexGrow: 1,
		marginTop: theme.spacing(1),
	  },
	  gridItem: {
		//backgroundColor: theme.palette.primary.light,
	 //  border: '1px soild green'
	   // marginLeft: theme.spacing(2.5),
	  },
	  formGroupPaper: {
		padding: theme.spacing(1),
		backgroundColor: theme.palette.common.white,
	 
	  },
	  formGroupTitle: {
		
	  },
	  titleDivider: {
		marginBottom: theme.spacing(1)
	  },
	  textField: {
		 // minHeight: '300px'
		 backgroundColor: 'white'
	  },
	  takeOverBtn: {
		 backgroundColor: 'rgb(43, 168, 5)',
		 color: 'white',
		 marginBottom: theme.spacing(1)
	  },
	  buttonRow: {
		border: 'solid',
		borderWidth: '2px',
		borderColor: 'rgb(32 65 97)'
	  }
})

export const statiColors = {
	fixedDate: 'yellow',
	onHoldEvent: 'orange', // orange
	canceledEvent: '#f50057', // kind of red
}

class AddEvent extends PureComponent {
	state = {
		formChanged: false,
		noRequesterSelectMsg: false,
		changeHistory: {
			count: 0,
			lastChange: null,
		}
	 }

	setFormChange = (value) => {
		return this.setState({formChanged: value})
	}
	
	handleSelectRequester = (requesterData)=> {	
		const { noRequesterSelectMsg, formChanged } = this.state
		const { event } = this.props
	
		if(noRequesterSelectMsg) {
			this.setState({noRequesterSelectMsg: false})
		}
		const newEvent = {...event}
		if(!event.handOver)
			newEvent.handOver = { label: '', value: ''}
		if(requesterData===null) {
			this.setState({noRequesterSelectMsg: true})
			if(formChanged)
				this.setState({formChanged: true})
			delete newEvent.requester
		} else {

		newEvent.requester = requesterData
		if(!formChanged)
			this.setState({formChanged: true})
		}
		return dispatchNewEvent(newEvent)
	}

	handleSelectProvider = provider => {
	
		const { event } = this.props
		if(!event.requester) {
			this.setState({noRequesterSelectMsg: true})
			if(this.state.formChanged)
				this.setState({formChanged: false})
		}
		
		const newEvent = {...event }

		if(!provider) {
			delete newEvent.provider
		} else {
			newEvent.provider = provider
		}
		this.setState({formChanged: Boolean(event.requester)})
		return dispatchNewEvent(newEvent)
	}

	handleSelectTasks = e => {
		const { event } = this.props
		const { formChanged } = this.state
		if(!event.requester) {
			if(formChanged)
				this.setState({formChanged: false})
			this.setState({noRequesterSelectMsg: true})
		} else {
			this.setState({formChanged: true})
		}
		const newEvent = { ...event }
		newEvent.task = e
		return dispatchNewEvent(newEvent)
	}

	handleInfoChange = e => {
		const { event } = this.props
		if(e.target.value.length===0 && !event.requester) {
			this.setState({formChanged: false})
			//setNoRequesterSelectedMsg(true)
		} else  {
			if(!this.state.formChanged)
				this.setState({formChanged: true})
			if(this.state.noRequesterSelectMsg)
				this.setState({noRequesterSelectMsg: false})
		}
		return dispatchInfoChange(e.target.value)
	}

	handleSaveEvent = () => {
		//const messages = {created: 'Erstellt', edit: 'Bearbeitet', handOverFrom: 'Übertragen von', handOverTo: 'Übertragen an'}
		const { event, authUser, noRequesterSelectMsg, saveEvent } = this.props
		const { id, requester, provider, task, info, fixedDate, onHoldEvent, canceledEvent, handOver, editors, responUser } = event
		const timeStamp = moment().format()
		const eventDate = moment(event.start).format('YYYY-MM-DD')
		const editor = {
			id: authUser.id,
			userName: authUser.username,
			timeStamp,
			msg: event.isNew 
				? 'Erstellt von'
				: 'Bearbeitet von'
		}
		let updatedEditors = null
		if(editors) {
			updatedEditors = [...editors, editor]
		} else {
			updatedEditors = [editor]
		}
		//const updatedEditors = [...editors, editor]

		if(handOver.value.length>4) {
			updatedEditors.push({id: handOver.value, userName: handOver.label, timeStamp: moment().format(), msg: 'Übertragen an'})
		}
		if(!requester && info) {
			const newInfoEvent = {
				eventState: provider ? 0.1 : 0,
				allday: true,
				start: eventDate,
				end: eventDate,
				backgroundColor: 'lightyellow',
				editors: updatedEditors,
				info,
				id,
				title: 'Info',
				handOver,
				responUser: responUser || {name: authUser.username, id: authUser.id},
			}
			if(provider) {
				newInfoEvent.provider = provider
			}
			if(task)
				newInfoEvent.task = task
			if(noRequesterSelectMsg)
				this.setState({noRequesterSelectMsg: false})
			
			this.setState({formChanged: false})		
			saveEvent(newInfoEvent)
			return dispatchResetEvent()
		}
		let eventState = provider ? 2 : 1
		const newEvent = {
			editors: updatedEditors,
			eventState,
			allday: true,
			start: eventDate,
			end: eventDate,
			backgroundColor: 'darkred',
			id,
			title: requester.userName,
			requester,
			fixedDate,
			handOver,
			onHoldEvent,
			canceledEvent,
			responUser: responUser || {name: authUser.username, id: authUser.id},
		}
		if(task)
			newEvent.task = task
		if(eventState===2) {
			newEvent.title = `${requester.userName} -> ${provider.userName}`
			newEvent.backgroundColor = 'green'
			newEvent.provider = provider
		}
		if(info)
			newEvent.info = info

		this.setState({ formChanged: false })
		if(noRequesterSelectMsg)
			this.setState({noRequesterSelectMsg: false})

		saveEvent(newEvent)
		return dispatchResetEvent()
	}

	handleDeleteEvent = () => {
		const { event: { id }, deleteEvent } = this.props
		if(this.state.formChanged)
			this.setState({formChanged: false})
		if(this.state.noRequesterSelectMsg)
			this.setState({noRequesterSelectMsg: false})
		const delEvent = () =>{
			dispatchResetEvent()
			dialog('reset')
			return deleteEvent(id)
		}
		return dialog({
			isOpen: true,
			title: 'Event löschen?',
			text: "Soll der Eintrag wirklich gelöscht werden",
			buttons: [
				{title: 'Löschen', color: 'secondary', onClick: ()=>delEvent(id)},
				{title: 'Abbrechen', color: 'primary', onClick: ()=>dialog('reset')}
			]
		})

	}

	handleClose = () => {
		const handleSaveEventOnChanges = () => {
			this.handleSaveEvent()
			return dialog('reset')
		}
		const cancelSaveEvent = () =>{
			dialog('reset')
			return dispatchResetEvent()
		}
	
		if(this.state.formChanged) {
			dialog({
				isOpen: true,
				title: 'Änderungen speichern',
				text: 'Der Planungseintrag wurde geändert, Eintrag speichern?',
				buttons: [
					{title: 'Speichern', onClick: handleSaveEventOnChanges, color: 'primary'},
					{title: 'Schließen ohne Speichern', onClick: cancelSaveEvent, color: 'secondary'}
				]
			})
			this.setState({ formChanged: false })
		} else {
			dispatchResetEvent()
		}
		if(this.state.noRequesterSelectMsg)
			this.setState({noRequesterSelectMsg: false})
		return
	}

	handleEventStatusChange = (statusType) => {
		this.setFormChange(true)
		return dispatchEventStatusChange(statusType)
	}
	
	render () {
		if(!this.props.isOpen)
			return null
		const { requesters, providers, event, tasks, classes } = this.props

		const disableSaveBtn = !this.state.formChanged
		const disableDeleteBtn = !(Boolean(event.requester) || Boolean(event.info))

		const borderColor = event.fixedDate 
			? statiColors.fixedDate
			: event.canceledEvent
				? statiColors.canceledEvent
				: event.onHoldEvent
					? statiColors.onHoldEvent
					: 'rgb(32 65 97)'
	
		return (
			<Dialog
				isOpen={this.props.isOpen}
				onCloseCallback={this.handleClose}
				title={`Vermittlung ${moment(event.start).format('dddd Do MMMM')}` }
				status={event.fixedDate ? 'Fester Termin' : event.onHoldEvent ? 'Wartend' : event.canceledEvent ? 'Abgesagt' : ''}
				statusColor={event.fixedDate ? statiColors.fixedDate : event.onHoldEvent ? statiColors.onHoldEvent : event.canceledEvent ? statiColors.canceledEvent : 'inherit' }
			>
				<PlanningGrid classes={classes} >
					<PlanningGrid.Row classes={classes} >
						<FormWrapper title='Hilfe - Bedarf' /*compProps={{title: {variant: 'subtitle1'}}}*/>
							<VSelect
								valueKey='id'
								labelKey='userName'
								data={requesters || []}
								selectedValue={ event.requester || {} }
								callback={this.handleSelectRequester}
								//propKeys={{label: 'userName', value: 'id'}}
								clearable={true}
							/>
							{this.state.noRequesterSelectMsg && <FormHelperText error={true} children='Hilfebedarf muss ausgewählt werden' />}
						</FormWrapper>
						<FormWrapper title='Leistung'>
							<VSelect
								data={tasks || []}
								selectedValue={ event.task || {} }
								callback={this.handleSelectTasks}
								propKeys={{label: 'name', value: 'id'}}
								clearable={true}
							/>
						</FormWrapper>
						<FormWrapper title='Helfer - Angebot'>
							<VSelect
								valueKey='id'
								labelKey='userName'
								data={providers || []}
								selectedValue={ event.provider || {} }
								callback={this.handleSelectProvider}
								//propKeys={{label: 'userName', value: 'id'}}
								clearable={true}
							/>
						</FormWrapper>
					</PlanningGrid.Row>
					<PlanningGrid.Row classes={classes}>
						<TextField
							InputLabelProps={{
								style: {zIndex: 0}
							}}
							name='planing-info'
							id='planning-info-field'
							variant='filled'
							value={event.info}
							onChange={this.handleInfoChange}
							label='Infos eingeben'
							multiline fullWidth
							color='primary'
							minRows={2}
							maxRows={8}
							className={classes.textField}
							//placeholder='asfddsf'
							helperText='Info Eingabe ohne Bedarf wird im Kalender als INFO Feld angezeigt!'
						/>
					
					</PlanningGrid.Row>
					<PlanningGrid.Row classes={classes}>
						<ButtonRow 
							className={classes.buttonRow}
							cardStyles={{borderColor}}
							buttons={[
								{
									title: 'Speichern',
									onClick: this.handleSaveEvent,
									variant: 'contained',
									disabled: disableSaveBtn
								},
								{
									title: 'Abbrechen',
									onClick: this.handleClose,
									color: 'default'
								},
								{
									title: 'Löschen',
									onClick: this.handleDeleteEvent,
									color: 'secondary',
									disabled: disableDeleteBtn //event && !Boolean(event.requester),
								},
							]} 
						>
							<FormControlLabel 
								control={<Checkbox 
									disabled={!Boolean(event.requester)}
									checked={event.fixedDate}
									icon={<ScheduleIcon style={{color: Boolean(event.requester) ? 'whitesmoke' : 'rgb(0 0 0 / 38%)' }} />}
									onChange={()=>this.handleEventStatusChange('fixedDate')}
									checkedIcon={<AlarmIcon style={{color: statiColors.fixedDate }} />}
								/>}
								label='Fester Termin'
								style={{marginLeft: '0.5rem', color: event.fixedDate ? statiColors.fixedDate :'whitesmoke' }}
							/>
							<FormControlLabel 
								control={<Checkbox 
									disabled={!Boolean(event.requester)}
									checked={event.onHoldEvent}
									icon={<PauseIconFilled  style={{color: Boolean(event.requester) ?  'whitesmoke' : 'rgb(0 0 0 / 38%)'}} />}
									onChange={()=>this.handleEventStatusChange('onHoldEvent')}
									checkedIcon={<PauseIcon color='secondary' style={{color: statiColors.onHoldEvent }}/>}
								/>}
								label='Wartend'
								style={{marginLeft: '0.5rem', color: event.onHoldEvent ? statiColors.onHoldEvent :'whitesmoke' }}
							/>
							<FormControlLabel 
								control={<Checkbox 
									disabled={!Boolean(event.requester)}
									checked={event.canceledEvent}
									icon={<CancelIcon style={{color: Boolean(event.requester) ? 'whitesmoke' : 'rgb(0 0 0 / 38%)	'}} />}
									onChange={()=>this.handleEventStatusChange('canceledEvent')}
									checkedIcon={<BlockIcon style={{color: statiColors.canceledEvent}} />}
								/>}
								label='Abgesagt'
								style={{marginLeft: '0.5rem', color: event.canceledEvent ? statiColors.canceledEvent :'whitesmoke' }}
							/>
						</ButtonRow>
					</PlanningGrid.Row>
						<InfoSection
							editors={event.editors}
							authUser={this.props.authUser}
							event={event}
							formChangeCallback={this.setFormChange}
							classes={classes}
						/>
				</PlanningGrid>
			</Dialog>
			)
	}
}

const InfoSection = ({event, authUser, formChangeCallback, classes, editors}) => {

	const handleTakeOver = () => {
		const timeStamp = moment().format()
		const editor = {
			id: authUser.id,
			userName: authUser.username,
			timeStamp,
			msg: 'Übernommen von'
		}
		formChangeCallback(true)
		dispatchHandOver({value: authUser.id, label: authUser.username})
		return dispatchAddEditor(editor)
    }
	const showTakeOverBtn = !event.isNew && authUser.id!==event.editors[event.editors.length-1].id

	return (
		<PlanningGrid.Row classes={classes}>
			<div>
				{event.requester && <MemberAddressCard profile={event.requester.profile} /> }
			</div>
			<div>
			{showTakeOverBtn && <Button className={classes.takeOverBtn} onClick={handleTakeOver} size='small' variant='contained' fullWidth>Vermittlung übernehmen </Button>}
				{(event.requester || event.info) && 
					<HandOverSelect
						editors={editors}
						authUser={authUser}
						formChangedCallback={formChangeCallback}
					/>
				}

				{/*event.task && <AddInfoCard data={[event.task.label]} />*/}
				{event.editors && <EditorCard editors={event.editors} hasTask={Boolean(event.task)} />}
			</div>
			<div>
				{event && event.provider && <MemberAddressCard profile={event.provider.profile} />}
			</div>
		</PlanningGrid.Row>
	)
}

export default compose(
    firestoreConnect(() => [
        {
            collection: 'members', 
        },
		{
            collection: 'tasks', 
            where: [['type', '==', 'nachbarschaftshilfe']],
            storeAs: 'tasksNH'
        },
    ]), // or { collection: 'todos' }
	compose(
	withStyles(styles),
    connect((state) => ({
		isOpen: Boolean(state.planning.event.start),
		isLoaded: (isLoaded(state.firestore.data.providers) && isLoaded(state.firestore.data.requesters)) && isLoaded(state.firestore.data.tasks),
		tasks: state.firestore.ordered.tasksNH,
		event: state.planning.event,
		requesters: state.firestore.ordered.members && state.firestore.ordered.members.filter(member=>member.roles.requester),
		providers: state.firestore.ordered.members && state.firestore.ordered.members.filter(member=>member.roles.provider),
		saveEvent: state.planning.functions.saveEvent,
		deleteEvent: state.planning.functions.deleteEvent
    }))
    ))(AddEvent)