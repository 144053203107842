import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import InfoIcon from '@material-ui/icons/Info'
import { useHistory } from 'react-router-dom'
import { privateRoutes } from 'config/routes'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Box from '@material-ui/core/Box'
import { v4 as uuid } from 'uuid'
import LoadingSpinner from './Spinner'

const LoadingHandler = ({isEmpty, isLoaded, title, loadingText, fontSize, color, align, variant, style, icon, buttons, children }) => {
	const history = useHistory()

	if(!isLoaded)
		return <LoadingSpinner color={color || 'primary'} title={loadingText || 'Loading.....'} />
	if(isEmpty && isLoaded)
		return (
			<Box style={{textAlign: 'center', padding: '1rem'}}>
			<Card  style={{padding: '1rem', maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto'}} variant="outlined">
				<CardContent >
					<Typography
						color={color || 'secondary'}
						align={ align || 'center'}
						variant={variant || 'h6'}
						style={style || {}}
					
					>
						<InfoIcon
							fontSize={fontSize || 'large'}
								style={
									icon
										? icon.style
											? icon.style
											: {verticalAlign: 'middle', marginRight: '4px'}
										: {verticalAlign: 'middle', marginRight: '4px'}
								}
						/>
						{title || 'Keine Daten vorhanden'}
					</Typography>
				</CardContent>
		
			<CardActions >
			{buttons && buttons.map(button =>{
				return <Button
					key={uuid()}
					onClick={button.type==='history'
						? ()=>history.push(privateRoutes[button.ROUTE].path)
						: button.onClick }
					variant={button.variant || 'contained'}
					size={button.size || 'medium'}
					color={button.color || 'primary'}
					fullWidth={button.fullWidth || false}
					style={button.style || {}}>
						{button.title || 'OK'}
				</Button>
			})}
			</CardActions>
			</Card>
			</Box>
		)
	return children
}

export default LoadingHandler
