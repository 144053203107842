
import React from 'react'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'left',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
      marginTop: theme.spacing(2),
      maxHeight: '300px',
      overflowY: 'scroll',
      overflowX: 'hidden',
      padding: theme.spacing(1)
    },
}))

const ItemsFound = ({data}) => {
    const classes = useStyles()
    const renderClip = item =>
        <Chip
            size='small'
            key={item.id}
            avatar={<Avatar>PK</Avatar>}
            color='secondary'
            label={item.userName}
        />
    const text = 'Es existieren Mitlieder mit dieser Pflegekasse. Diesen Mitgliedern muss erst eine neue Pflegekasse zugeordnet werden.'
    return (
        <div>
            {data.items &&
                <div>
                    <Typography
                        gutterBottom
                        align='center'
                        variant='subtitle1'
                        color='error'
                    >
                        {'Pflegekasse ist Mitglied(ern) zugeordnet'}
                    </Typography>
                    <Typography
                        gutterBottom
                        variant='subtitle2'
                    >
                        {text}
                    </Typography>
                </div>
            }
            {data.items && <Paper className={classes.root}>
                {data.items.map(item=>renderClip(item))}
            </Paper>}
        </div>
    )
}

export default ItemsFound